import {SortableContext} from '@dnd-kit/sortable'
import {useMemo} from 'react'
import NoDataFound from '../../NoDataFound'
import Loading from '../../../modules/loading'
import DndDynamicTableRow from './DndDynamicTableRow'

function DndDynamicTable({
  tableData,
  tableColumns,
  isLoading = false,
  readOnly,
  uniqueId,
  TableRow,
  noDataText,
  hasWritePermission,
  searchValue,
  noSearchResultMessage,
  tableRowProps,
}: any) {
  const rowsIds: any = useMemo(() => {
    return tableData?.map((row: any) => row[uniqueId])
  }, [tableData, uniqueId])

  return (
    <div className='card-body p-0'>
      <div className='table-loader-wrapper position-relative'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-5'>
          <thead className='table-row-bordered text-uppercase'>
            <tr className='fs-6 fw-semibold text-muted'>
              {tableColumns?.map((column: any, index: number) => (
                <th key={index} className={column.headerClass}>
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <SortableContext items={rowsIds}>
              {tableData?.map((row: any) => (
                <DndDynamicTableRow
                  key={row[uniqueId]}
                  row={row}
                  readOnly={readOnly}
                  uniqueId={uniqueId}
                  TableRow={TableRow}
                  hasWritePermission={hasWritePermission}
                  tableRowProps={tableRowProps}
                />
              ))}
            </SortableContext>
            {!isLoading && tableData?.length === 0 && (
              <NoDataFound
                colspan={tableColumns?.length || 0}
                message={searchValue && searchValue !== '' ? noSearchResultMessage : noDataText}
              />
            )}
          </tbody>
        </table>
        {isLoading && <Loading />}
      </div>
    </div>
  )
}

export default DndDynamicTable
