import Api from '../../../services/Api'

export const getTopState = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getTopProducts = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getTopCustomers = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getAnalyticsOverview = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getAggregatedProductReport = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}
