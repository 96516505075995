import {useContext} from 'react'
import {ProjectDetailsContext} from '../../context'
import {ProjectMemberActionDropdownTypes} from '../../interfaces'

const ProjectMemberActionDropdown = ({
  memberDetails,
  setDeleteModal,
  setManageModal,
}: ProjectMemberActionDropdownTypes) => {
  const {projectDetails} = useContext(ProjectDetailsContext)
  const handleRemove = () => {
    setDeleteModal(true)
    document.body.click()
  }

  const handleManage = () => {
    setManageModal(true)
    document.body.click()
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      {projectDetails?.is_owner && memberDetails?.is_owner && (
        <span
          onClick={handleManage}
          className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        >
          Manage
        </span>
      )}
      {!memberDetails?.is_owner && (
        <span
          onClick={handleRemove}
          className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        >
          Remove
        </span>
      )}
    </div>
  )
}
export default ProjectMemberActionDropdown
