import type {EditorThemeClasses} from 'lexical';
import './TextEditorTheme.css';

const theme: EditorThemeClasses = {
  autocomplete: 'textEditor_autocomplete',
  blockCursor: 'textEditor_blockCursor',
  characterLimit: 'textEditor_characterLimit',
  code: 'textEditor_code',
  codeHighlight: {
    atrule: 'textEditor_tokenAttr',
    attr: 'textEditor_tokenAttr',
    boolean: 'textEditor_tokenProperty',
    builtin: 'textEditor_tokenSelector',
    cdata: 'textEditor_tokenComment',
    char: 'textEditor_tokenSelector',
    class: 'textEditor_tokenFunction',
    'class-name': 'textEditor_tokenFunction',
    comment: 'textEditor_tokenComment',
    constant: 'textEditor_tokenProperty',
    deleted: 'textEditor_tokenProperty',
    doctype: 'textEditor_tokenComment',
    entity: 'textEditor_tokenOperator',
    function: 'textEditor_tokenFunction',
    important: 'textEditor_tokenVariable',
    inserted: 'textEditor_tokenSelector',
    keyword: 'textEditor_tokenAttr',
    namespace: 'textEditor_tokenVariable',
    number: 'textEditor_tokenProperty',
    operator: 'textEditor_tokenOperator',
    prolog: 'textEditor_tokenComment',
    property: 'textEditor_tokenProperty',
    punctuation: 'textEditor_tokenPunctuation',
    regex: 'textEditor_tokenVariable',
    selector: 'textEditor_tokenSelector',
    string: 'textEditor_tokenSelector',
    symbol: 'textEditor_tokenProperty',
    tag: 'textEditor_tokenProperty',
    url: 'textEditor_tokenOperator',
    variable: 'textEditor_tokenVariable',
  },
  embedBlock: {
    base: 'textEditor_embedBlock',
    focus: 'textEditor_embedBlockFocus',
  },
  hashtag: 'textEditor_hashtag',
  heading: {
    h1: 'textEditor_h1',
    h2: 'textEditor_h2',
    h3: 'textEditor_h3',
    h4: 'textEditor_h4',
    h5: 'textEditor_h5',
    h6: 'textEditor_h6',
  },
  hr: 'textEditor_hr',
  image: 'editor-image',
  indent: 'textEditor_indent',
  inlineImage: 'inline-editor-image',
  layoutContainer: 'textEditor_layoutContainer',
  layoutItem: 'textEditor_layoutItem',
  link: 'textEditor_link',
  list: {
    checklist: 'textEditor_checklist',
    listitem: 'textEditor_listItem',
    listitemChecked: 'textEditor_listItemChecked',
    listitemUnchecked: 'textEditor_listItemUnchecked',
    nested: {
      listitem: 'textEditor_nestedListItem',
    },
    olDepth: [
      'textEditor_ol1',
      'textEditor_ol2',
      'textEditor_ol3',
      'textEditor_ol4',
      'textEditor_ol5',
    ],
    ul: 'textEditor_ul',
  },
  ltr: 'textEditor_ltr',
  mark: 'textEditor_mark',
  markOverlap: 'textEditor_markOverlap',
  paragraph: 'textEditor_paragraph',
  quote: 'textEditor_quote',
  rtl: 'textEditor_rtl',
  table: 'textEditor_table',
  tableCell: 'textEditor_tableCell',
  tableCellActionButton: 'textEditor_tableCellActionButton',
  tableCellActionButtonContainer:
    'textEditor_tableCellActionButtonContainer',
  tableCellEditing: 'textEditor_tableCellEditing',
  tableCellHeader: 'textEditor_tableCellHeader',
  tableCellPrimarySelected: 'textEditor_tableCellPrimarySelected',
  tableCellResizer: 'textEditor_tableCellResizer',
  tableCellSelected: 'textEditor_tableCellSelected',
  tableCellSortedIndicator: 'textEditor_tableCellSortedIndicator',
  tableResizeRuler: 'textEditor_tableCellResizeRuler',
  tableRowStriping: 'textEditor_tableRowStriping',
  tableSelected: 'textEditor_tableSelected',
  tableSelection: 'textEditor_tableSelection',
  text: {
    bold: 'textEditor_textBold',
    code: 'textEditor_textCode',
    italic: 'textEditor_textItalic',
    strikethrough: 'textEditor_textStrikethrough',
    subscript: 'textEditor_textSubscript',
    superscript: 'textEditor_textSuperscript',
    underline: 'textEditor_textUnderline',
    underlineStrikethrough: 'textEditor_textUnderlineStrikethrough',
  },
};

export default theme;
