import {Link, useLocation} from 'react-router-dom'

function UnhideProductRulesPageNavigation() {
  const location = useLocation()

  return (
    <div className=''>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10'>        
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/analytics/automation/appliedrules/executedrules' && 'active')
            }
            to='/analytics/automation/appliedrules/executedrules'
          >
            Executed Rule
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default UnhideProductRulesPageNavigation
