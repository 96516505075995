export const commentMentionUserList = (projectMembers: any) =>
  projectMembers?.map((user: any) => ({
    id: user.username,
    display: user.name,
  }))

export const parseLinkedTicketData = (row: any) => {
  return {
    ticketId: row.tickedID,
    ticketInitial: row.card_identifier,
    ticketName: row.title,
  }
}
