import {generateColors} from '../../../utils/chartColors'

interface PieChartArgs {
  piedata: (null | number)[]
  pielabel: (string | number)[]
}

export function PieCharData({piedata, pielabel}: PieChartArgs) {
  const DistributorData = {
    labels: pielabel || [],
    datasets: [
      {
        data: piedata || [],
        backgroundColor: generateColors(piedata?.length),
        borderWidth: 1,
        spacing: 2,
      },
    ],
  }
  return DistributorData
}

interface BarChartArgs {
  barLabels: (string | number)[]
  barData: (string | number)[]
  barCustomers: string[]
}

export function BarChartData({barData, barLabels, barCustomers}: BarChartArgs) {
  const slicedData = barData?.slice(0, 7)
  const slicedLabel = barLabels?.slice(0, 7)
  const DistributorData = {
    labels: slicedLabel || [],
    datasets: [
      {
        backgroundColor: 'rgba(0, 92, 230, 0.2)',
        borderColor: 'rgba(0, 92, 230, 1)',
        borderWidth: 0,
        hoverBackgroundColor: 'rgba(0, 92, 230, 1)',
        hoverBorderColor: 'rgba(33, 87, 141, 1)',
        hoverBorderWidth: 0,
        borderRadius: 5,
        barThickness: 20,
        data: slicedData || [],
        customerName: barCustomers || [],
      },
    ],
  }
  return DistributorData
}

export const barChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (context: any) => {
          return context[0].formattedValue
        },
        label: (context: any) => {
          const index = context.dataIndex
          return context.chart.data.datasets[0].customerName[index]
        },
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
    },
  },
}

export const pieChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (context: any) => {
          return context[0].formattedValue
        },
        label: (context: any) => {
          const index = context.dataIndex
          return context.chart.data.labels[index]
        },
      },
    },
  },
  cutout: '85%',
}

export const parseVendorsTableRowData = (row: any) => {
  return {
    vendorId: row?.customer_id || 0,
    vendorName: row?.customer_name || '-',
    email: row?.email || '-',
    representative: row?.rep_name || '-',
    orderAmount: row?.total_amount || '-',
    pendingAmount: row?.total_due_amount || '-',
  }
}

export const parseTopProductsOnHoldTableRowData = (row: any) => {
  return {
    product_name: row?.product_name || '-',
    value: row?.value || '-',
    percentage: `${row?.percentage || 0}%`,
  }
}

export const parseDataInfo = (dataInfo: any) => {
  return {
    consignment_total: parseFloat(dataInfo?.consignment_total) || null,
    consignment_total_value: dataInfo?.consignment_total_value || 0,
    block_order_total: parseFloat(dataInfo?.block_order_total) || null,
    block_order_total_value: dataInfo?.block_order_total_value || 0,
    total_valuation_formatted: dataInfo?.total_valuation_formatted || null,
  }
}

export function formatBarChartData(data: any) {
  if (!data || !data?.length) {
    return {}
  }

  const barData = data.map((val: any) => val?.value_amount)
  const barLabels = data.map((val: any) => val?.value)
  const barCustomers = data.map((val: any) => val?.customer_name)
  return {barData, barLabels, barCustomers}
}
