import {InputNumber} from '../../InputNumber'
import {InputText} from '../../InputText'
import './Input.css'
import {HTMLInputTypeAttribute} from 'react'

type Props = Readonly<{
  id?: string
  'data-test-id'?: string
  label?: string
  onChange: (val: string) => void
  placeholder?: string
  value: string
  type?: HTMLInputTypeAttribute
  [key: string]: any
}>

export default function TextInput({
  id,
  label,
  value,
  onChange,
  placeholder = '',
  'data-test-id': dataTestId,
  type = 'text',
  ...rest
}: Props): JSX.Element {
  return (
    <>
      {type === 'text' ? (
        <InputText
          id={id || `${label}-input`}
          inputClass='Input__input'
          className='Input__wrapper'
          labelClass='Input__label'
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          data-test-id={dataTestId}
          {...rest}
        />
      ) : type === 'number' ? (
        <InputNumber
          id={id || `${label}-input`}
          inputClass='Input__input'
          className='Input__wrapper'
          labelClass='Input__label'
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          data-test-id={dataTestId}
          {...rest}
        />
      ) : (
        'Invalid Input Type.'
      )}
    </>
  )
}
