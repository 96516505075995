/* eslint-disable */
import type {
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
} from 'lexical'

import {BlockWithAlignableContents} from '@lexical/react/LexicalBlockWithAlignableContents'
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from '@lexical/react/LexicalDecoratorBlockNode'
import * as React from 'react'

type FigmaComponentProps = Readonly<{
  className: Readonly<{
    base: string
    focus: string
  }>
  format: ElementFormatType | null
  nodeKey: NodeKey
  iframeHTML: string
}>

function FigmaComponent({className, format, nodeKey, iframeHTML}: FigmaComponentProps) {
  return (
    <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
      <div dangerouslySetInnerHTML={{ __html: iframeHTML }} />
    </BlockWithAlignableContents>
  )
}

export type SerializedFigmaNode = Spread<
  {
    iframeHTML: string
  },
  SerializedDecoratorBlockNode
>

export class FigmaNode extends DecoratorBlockNode {
  __iframeHTML: string

  static getType(): string {
    return 'figma'
  }

  static clone(node: FigmaNode): FigmaNode {
    return new FigmaNode(node.__iframeHTML, node.__format, node.__key)
  }

  static importJSON(serializedNode: SerializedFigmaNode): FigmaNode {
    const node = $createFigmaNode(serializedNode.iframeHTML)
    node.setFormat(serializedNode.format)
    return node
  }

  exportJSON(): SerializedFigmaNode {
    return {
      ...super.exportJSON(),
      iframeHTML: this.__iframeHTML,
      type: 'figma',
      version: 1,
    }
  }

  constructor(iframeHTML: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key)
    this.__iframeHTML = iframeHTML
  }

  updateDOM(): false {
    return false
  }

  getIframeHTML(): string {
    return this.__iframeHTML
  }

  getTextContent(): string {
    return this.__iframeHTML
  }

  decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const embedBlockTheme = config.theme.embedBlock || {}
    const className = {
      base: embedBlockTheme.base || '',
      focus: embedBlockTheme.focus || '',
    }
    return (
      <FigmaComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        iframeHTML={this.__iframeHTML}
      />
    )
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const element = document.createElement('div')
    element.setAttribute('data-lexical-figma-embed', 'true')
    element.innerHTML = this.__iframeHTML
    return {element}
  }
}

export function $createFigmaNode(iframeHTML: string): FigmaNode {
  return new FigmaNode(iframeHTML)
}

export function $isFigmaNode(node: FigmaNode | LexicalNode | null | undefined): node is FigmaNode {
  return node instanceof FigmaNode
}
