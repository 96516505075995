import './Modal.css'
import {ReactNode} from 'react'
import {ConfirmationModal} from '../../ConfirmationModal'

export default function Modal({
  onClose,
  children,
  title,
}: {
  children: ReactNode
  closeOnClickOutside?: boolean
  onClose: () => void
  title: string
}): JSX.Element {
  return (
    <ConfirmationModal
      show={true}
      onClose={onClose}
      body={children}
      title={title}
      modalClass={'bg-dark bg-opacity-50'}
      dialogClassName={'modal-dialog-centered modal-ls'}
      disableFooter={true}
    />
  )
}
