import Api from '../../../services/Api'

export const getJobListing = (filters: any) => {
  const url = '/settings/jobs'

  return Api.get(`${url}`, filters).then((d: any) => {
    return d.data
  })
}

export const getJobdata = (filters: any) => {
  const url = '/settings/job'

  return Api.get(`${url}`, filters).then((d: any) => {
    return d.data
  })
}

export const runSpecificTask = (name: any) => {
  const url = '/settings/task'

  return Api.post(
    `${url}`,
    {
      task: name,
    },
    {},
    null
  ).then((d: any) => {
    return d.data
  })
}

export const getWebhookListing = (filters: any) => {
  const url = 'https://mwg.atlantixdigital.com/admin/api/hook/status'

  return Api.get(`${url}`, filters).then((d: any) => {
    return d.data
  })
}

export const addWebhook = (filters: any) => {
  const url = 'https://mwg.atlantixdigital.com/admin/api/hook/status'

  return Api.post(`${url}`, {}, {}, {}).then((d: any) => {
    return d.data
  })
}
