import {createContext} from 'react'

export const BlogsContext = createContext({
  blogs: [],
  onSearch: (searchValue: string) => {},
  pagination: {},
  onPageChange: (e: string) => {},
  onCreateBlog: (e: object) => {},
  onUpdateBlog: (e: object) => {},
  onDeleteBlog: (e: string) => {},
  syncBlogs: () => {},
  refetch: () => {},
  authors: [],
  onCreateAuthor: (e: object) => {},
  onUpdateAuthor: (e: object) => {},
  onDeleteAuthor: (e: string) => {},
  refetchAuthor: () => {},
  category: [],
  onCreateCategory: (e: object) => {},
  onUpdateCategory: (e: object) => {},
  onDeleteCategory: (e: string) => {},
  refetchCategory: () => {},
  isFetching: false,
  isFetchingAuthorData: false,
  isFetchingCategoryData: false,
})

export const AuthorsContext = createContext({
  authors: [],
  onCreateAuthor: (e: object) => {},
  onUpdateAuthor: (e: object) => {},
  onDeleteAuthor: (e: string) => {},
  refetchAuthor: () => {},
})

export const CategoriesContext = createContext({
  category: [],
  onCreateCategory: (e: object) => {},
  onUpdateCategory: (e: object) => {},
  onDeleteCategory: (e: string) => {},
  refetchCategory: () => {},
})

export const AggregatedVendorsTablePageContext = createContext({
  filters: {},
  onSortingChange: (sortBy: string, sortDirection: string) => {},
})
