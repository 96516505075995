import {useContext, useState} from 'react'
import useApi from '../../../../services/useApi'
import {ProjectDetailsContext} from '../../context'

export default function useListViewOperations() {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const [isLoading, setLoading] = useState(false)

  async function createTicket(moduleId: string, data: any, setError: Function) {
    return Api.post(`/agile/projects/${projectId}/modules/${moduleId}/cards`, data, {
      setLoading: setLoading,
      successMessage: 'Ticket created successfully',
      refetch: ['agile-board-structure', 'agile-board-data'],
      setUseFormError: setError,
    })
  }

  async function updateTicket(data: any, ticketId: number, dirtyFields: any, setError: Function) {
    return await Api.patch(`/agile/projects/${projectId}/cards/${ticketId}`, data, {
      dirtyFields: dirtyFields,
      setLoading: setLoading,
      refetch: 'agile-board-ticket-list',
      setUseFormError: setError,
    })
  }

  return {
    createTicket,
    updateTicket,
    isLoading,
  }
}
