import { useAppSelector } from '../../redux/useTypedSelector'
import useApi from '../../services/useApi'

export const useGetNotificationTypeDropdown = () => {
  const Api = useApi()
  const {isLoading} = useAppSelector((state) => state.auth)

  const {data: response, isFetching} = Api.useGetQuery(`/notifications/modules`, {
    queryId: 'product-notifications-modules',
    isToast: false,
  },{
    enabled : !isLoading
  })

  return {
    NotificationsType: response?.['data'] || [],
    loading: isFetching,
  }
}
