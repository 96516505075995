/* eslint-disable react-hooks/exhaustive-deps */

import {useState} from 'react'
import useApi from '../../../services/useApi'

const useSaveFilter = (refetchApi: any) => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const onSaveFilter = (payload: any) => {
    return Api.post('/agile/projects/global/filters/save', payload, {
      setLoading: setIsLoading,
      onSuccess: refetchApi,
    })
  }

  return {
    onSaveFilter: onSaveFilter,
    isOperationLoading: isLoading,
  }
}

export default useSaveFilter
