import {DragOverEvent} from '@dnd-kit/core'
import {arrayMove} from '@dnd-kit/sortable'

export function handleDragOver(
  event: DragOverEvent,
  setTableData: (data: any) => void,
  uniqueId: string
) {
  const {active, over} = event
  if (!over) return

  const activeId = active.id
  const overId = over.id

  if (activeId === overId) return

  const isActiveARow = active.data.current?.type === 'Row'
  const isOverARow = over.data.current?.type === 'Row'

  if (!isActiveARow) return

  // Dropping a Row over another Row
  if (isActiveARow && isOverARow) {
    setTableData((rows: any) => {
      const activeIndex = rows.findIndex((t: any) => t[uniqueId] === activeId)
      const overIndex = rows.findIndex((t: any) => t[uniqueId] === overId)
      if (rows[activeIndex][uniqueId] !== rows[overIndex][uniqueId]) {
        return arrayMove(rows, activeIndex, overIndex)
      }
      return arrayMove(rows, activeIndex, overIndex)
    })
  }
}
