import {useState} from 'react'
import useApi from '../../../../services/useApi'

function useGlobalCustomFieldsOperations() {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  async function createGlobalCustomField(customFieldDetails: any, setError: Function) {
    return Api.post(`/agile/projects/custom-fields`, customFieldDetails, {
      setLoading: setIsLoading,
      successMessage: 'Custom field created successfully',
      setUseFormError: setError,
      refetch: 'global-project-custom-fields',
    })
  }

  async function updateGlobalCustomField(
    cFieldId: string,
    customFieldDetails: any,
    dirtyFields: any,
    setError: Function
  ) {
    return Api.patch(`/agile/projects/custom-fields/${cFieldId}`, customFieldDetails, {
      dirtyFields: dirtyFields,
      setLoading: setIsLoading,
      successMessage: 'Custom field updated successfully',
      setUseFormError: setError,
      refetch: 'global-project-custom-fields',
    })
  }

  async function updateGlobalCustomFieldTableSorting(customFieldsTableRowOrder: any) {
    return Api.patch(`/agile/projects/custom-fields`, customFieldsTableRowOrder)
  }

  async function deleteGlobalCustomField(cFieldId: string) {
    return Api.delete(
      `/agile/projects/custom-fields/${cFieldId}`,
      {},
      {
        setLoading: setIsLoading,
        successMessage: 'Custom field deleted successfully',
        refetch: 'global-project-custom-fields',
      }
    )
  }

  return {
    createGlobalCustomField,
    updateGlobalCustomField,
    updateGlobalCustomFieldTableSorting,
    deleteGlobalCustomField,
    isLoadingGlobalCustomFieldOperation: isLoading,
  }
}

export default useGlobalCustomFieldsOperations
