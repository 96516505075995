import {useContext} from 'react'
import {Link} from 'react-router-dom'
import {TicketDetailsPageContext} from '../../context'

const LinkedTicketsTableRow = ({row}: any) => {
  const {projectId} = useContext(TicketDetailsPageContext)
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center gap-5'>
          <Link
            to={`/projects/all-projects/${projectId}/ticket/${row.ticketId}/${row.ticketInitial}`}
          >
            #{row.ticketInitial}
          </Link>

          {row.ticketName}
        </div>
      </td>
    </tr>
  )
}

export default LinkedTicketsTableRow
