import {useState} from 'react'
import { 
  deleteProductsRule,
  updateProductsRule,
  createProductsRule,
} from '../services'
import useToastify from '../../../../hook/useToastify'
import {useAppSelector} from '../../../../redux/useTypedSelector'
import useGetProductRules from './useGetProductRules'

export default function useHideProducts() {
  const [isLoading, setLoading] = useState(false)
  const {toastMessage} = useToastify()
  const {user} = useAppSelector((state) => state.auth)
  const {refetch} = useGetProductRules()

  // const {refetch} = useGetUserList(false)

  const addProductRule = async (data: any) => {
    try {
      setLoading(true)
      let new_data: any = data
      new_data['created_by'] = user['username']
      const res = await createProductsRule(new_data)
      if (res.status === 200) {
        toastMessage('success', `${res.data.message}`)
        refetch()
      } else {
        toastMessage('error', `${res.data.error}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {
      setLoading(false)
    }
  }

  const deleteProductRule = async (data: string) => {
    try {
      setLoading(true)

      const res = await deleteProductsRule(data)      
      if (res.status === 200) {
        toastMessage('success', 'Product deleted successfully.')
        refetch()
      } else {
        toastMessage('error', `${res.message}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {      
      setLoading(false)
    }
  }

  const updateProductRule = async (data: any) => {
    try {
      setLoading(true)
      let new_data: any = data
      new_data['modified_by'] = user['username']
      const res = await updateProductsRule(data)

      if (res.status === 200) {
        toastMessage('success', 'Product Rule updated successfully.')
        refetch()
      } else {
        toastMessage('error', `${res.data.error}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {
      setLoading(false)
    }
  }

  return {
    isLoading,
    addProductRule,
    updateProductRule,
    deleteProductRule,
  }
}
