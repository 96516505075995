import Api from '../../../../services/Api'

export async function createRule(data: any) {
  const url = `/analytics/automation/rule-engine/variants`
  const response = await Api.post(url, data, null, null)
  return response
}
export async function createProductsRule(data: any) {
  const url = `/analytics/automation/rule-engine/products`
  const response = await Api.post(url, data, null, null)
  return response
}

export async function updateRule(data: any) {
  const url = `/analytics/automation/rule-engine/variants`
  const response = await Api.put(url, data, null, null)
  return response
}
export async function updateProductsRule(data: any) {
  const url = `/analytics/automation/rule-engine/products`
  const response = await Api.put(url, data, null, null)
  return response
}
export const getHideProductList = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}
export const getProductRulesList = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}
export const deleteRule = (variant_sku: string) => {
  const url = `/analytics/automation/rule-engine/variants`
  return Api.delete(`${url}?variant_sku=${variant_sku}`, {}, {}, {}).then((d: any) => {
    return d
  })
}
export const deleteProductsRule = (product_sku: string) => {
  const url = `/analytics/automation/rule-engine/products`
  return Api.delete(`${url}?product_sku=${product_sku}`, {}, {}, {}).then((d: any) => {
    return d
  })
}

export const getProductRulesModalData = (url: string) => {
  return Api.get(`${url}`, {}, {}).then((d: any) => {
    return d.data
  })
}
