import {Controller} from 'react-hook-form'
import EditorComponent from './EditorComponent'
import {TextEditorTypes} from './TextEditorTypes'

const TextEditor = ({
  className = '',
  onChange,
  control,
  inputClass = '',
  registerKey,
  label,
  isRequired,
  error,
  errorClass,
  errorMessageRef,
  disabled,
  value,
  defaultValue = null,
  textEditorRef,
  maxLength,
  id,
  rules,
  isChangeOnSave = false,
  isLoadingOperation = undefined,
  isLoadingData = undefined,
  isReadOnly = false,
  editorClass = '',
  isClearChanges = false,
  placeholder,
  onBlur,
  setIsValid,
  isAutoFocus = true,
}: TextEditorTypes) => {
  return (
    <div className={className} id={id}>
      {label && <label className={`form-label ${isRequired ? 'required' : ''}`}>{label}</label>}
      {control && registerKey ? (
        <Controller
          name={registerKey}
          control={control}
          rules={rules}
          render={({field: {onChange: registerOnChange, value: registeredValue}}) => (
            <>
              <EditorComponent
                id={id}
                onChange={(value: any) => {
                  registerOnChange(value)
                  onChange?.(value)
                }}
                inputClass={error?.message ? 'border border-danger' + inputClass : inputClass}
                disabled={disabled}
                value={value ?? registeredValue}
                defaultValue={defaultValue ?? registeredValue}
                textEditorRef={textEditorRef}
                maxLength={maxLength}
                isChangeOnSave={isChangeOnSave}
                isLoadingOperation={isLoadingOperation}
                isLoadingData={isLoadingData}
                isReadOnly={isReadOnly}
                editorClass={editorClass}
                isClearChanges={isClearChanges}
                placeholderText={placeholder}
                onBlur={onBlur}
                setIsValid={setIsValid}
                isAutoFocus={isAutoFocus}
                registerKey={registerKey}
              />
              {error?.message && (
                <>
                  <div className='is-invalid d-none'></div>
                  <p className={errorClass ? errorClass : 'invalid-feedback'} ref={errorMessageRef}>
                    {error?.message}
                  </p>
                </>
              )}
            </>
          )}
        />
      ) : (
        <EditorComponent
          id={id}
          onChange={onChange}
          inputClass={inputClass}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          textEditorRef={textEditorRef}
          maxLength={maxLength}
          isChangeOnSave={isChangeOnSave}
          isLoadingOperation={isLoadingOperation}
          isLoadingData={isLoadingData}
          isReadOnly={isReadOnly}
          editorClass={editorClass}
          isClearChanges={isClearChanges}
          placeholderText={placeholder}
          onBlur={onBlur}
          setIsValid={setIsValid}
          isAutoFocus={isAutoFocus}
          registerKey={registerKey}
        />
      )}
    </div>
  )
}

export default TextEditor
