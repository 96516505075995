import DefaultColumnsActionsOverlay from '../overlays/DefaultColumnsActionsOverlay'

const DefaultColumnsTableRow = ({dndSettings, row, hasWritePermission, dndImage}: any) => {
  return (
    <tr {...dndSettings}>
      <td>
        {dndImage}
        {row.name}
      </td>
      <td>{row.description}</td>
      {hasWritePermission && (
        <td>
          <DefaultColumnsActionsOverlay defaultColumnData={row} />
        </td>
      )}
    </tr>
  )
}

export default DefaultColumnsTableRow
