import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

const useGetConsignmentVendorsListing = () => {
  const Api = useApi()

  const initialFilters = {
    page: 1,
    limit: 20,
    sort_by: 'total_due_amount/-1',
  }

  const {filters, onSortingChange, onPageChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data: response, isFetching: isLoading} = Api.useGetQuery(
    'analytics/dashboard/consignments/top-customers',
    {
      filters: filters,
    }
  )

  return {
    vendorsList: response?.data || [],
    pagination: response?.meta?.pagination || {},
    filters: filters,
    isLoading: isLoading,
    onPageChange,
    onSortingChange,
  }
}

export default useGetConsignmentVendorsListing
