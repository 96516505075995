export function validateNumberInputField(
  inputValueOrEvent: any,
  isFloat = false,
  decimalLimit = 2,
  isRoundOff = false,
  maxLimit = Infinity,
  isFormatted = false,
  isErrorWarning = false
) {
  // Determine if the input is an event or a direct number
  let inputValue: any
  if (typeof inputValueOrEvent === 'number') {
    inputValue = inputValueOrEvent.toString()
  } else {
    const input = inputValueOrEvent.target
    inputValue = input.value
  }

  let regexPattern = '[^0-9]'
  if (isFloat) {
    regexPattern = '[^0-9.]'
  }

  // Remove non-numeric characters (or non-numeric/non-dot characters for floats)
  inputValue = inputValue.replace(new RegExp(regexPattern, 'g'), '')

  // Ensure there's only one decimal point (if isFloat is true)
  if (isFloat) {
    const decimalCount = (inputValue.match(/\./g) || []).length
    if (decimalCount > 1) {
      const firstDecimalIndex = inputValue.indexOf('.')
      inputValue =
        inputValue.slice(0, firstDecimalIndex) +
        inputValue.slice(firstDecimalIndex + 1).replace(/\./g, '')
    }
  }

  // Round off or limit decimal places
  if (isFloat) {
    const parts = inputValue.split('.')
    if (parts.length > 1) {
      if (parts[1].length > decimalLimit) {
        parts[1] = parts[1].slice(0, decimalLimit)
        inputValue = parts.join('.')
      }

      if (isRoundOff) {
        const remainder = parseFloat(inputValue) % 0.5
        if (remainder !== 0 && remainder !== 0.5) {
          const roundedNumber = Math.round(parseFloat(inputValue) * 2) / 2
          inputValue = roundedNumber.toFixed(decimalLimit)
        }
      }
    }
  }

  // Apply the max limit if provided
  if (maxLimit !== Infinity && !isErrorWarning) {
    if (maxLimit < 0) {
      maxLimit = 0
    } else if (isFloat && isRoundOff) {
      maxLimit = Math.floor(maxLimit * 2) / 2 // Round limit to the nearest 0.5
    } else if (!isFloat) {
      maxLimit = Math.floor(maxLimit) // Convert limit to an integer if isFloat is false
    }
    const numericValue = parseFloat(inputValue)
    if (numericValue > maxLimit) {
      inputValue = maxLimit.toString()
    }
  }

  // Format the number if isFormatted is true
  if (isFormatted) {
    const parts = inputValue?.split('.')
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    inputValue = parts?.join('.')
  }

  // Update the value in case of event input
  if (typeof inputValueOrEvent !== 'number') {
    inputValueOrEvent.target.value = inputValue
  }

  // Return the final numeric value
  return isFloat ? parseFloat(inputValue) : parseInt(inputValue, 10)
}
