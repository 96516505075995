import useFilters from '../../../hook/useFilters';
import useApi from '../../../services/useApi';

const initialFilters = {
  page: 1, // Pagination starts from page 1
  limit: 30, // Display 30 customers per page
  sort_by: 'order_count/1', // Default sorting by bulk order count ascending
  search: '', // Search by customer name, email, or BC Customer ID
  tags: '', // Filter by customer tags
  customer_rep: '', // Filter by customer reps
  start_date: '', // Filter by date range
  end_date: '',
}

const useGetCustomerReportsData = () => {
  const Api = useApi();

  const {
    filters,
    onSearch,
    onSortingChange,
    searchValue,
    onPageChange,
    setMultipleFilters,
    onDateChange,
  } = useFilters(initialFilters, {
    sortType: 'number',
  });

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(`/bulkOrders/reports/customers`, {
    queryId: 'customer-reports',
    filters: filters,
  })

  const onTagFilterChange = (tag: string) => {
    setMultipleFilters({ tags: tag, page: 1 });
  };

  const onRepFilterChange = (rep: string) => {
    setMultipleFilters({ customer_rep: rep, page: 1 });
  };

  return {
    customerReportsData: response?.data || [],
    pagination: response?.meta?.pagination || {},
    filters,
    isLoading: isFetching,
    onSortingChange,
    onSearch,
    searchValue,
    onPageChange,
    onTagFilterChange,
    onRepFilterChange,
    onDateChange,
    refetch,
  };
};

export default useGetCustomerReportsData;
