import useMeta from '../../hook/useMeta'
import Archived from './layouts/Archived'
import Active from './layouts/Active'
import Navigation from '../../components/Navigation/Navigation'

const ProjectsPage = () => {
  useMeta('Projects')

  return (
    <Navigation
      baseUrl='/projects/all-projects'
      pageTitle='All Projects'
      navigationData={[
        {
          key: 'active',
          label: 'Active',
          component: <Active />,
        },
        {
          key: 'archived',
          label: 'Archived',
          component: <Archived />,
        },
      ]}
    />
  )
}

export default ProjectsPage
