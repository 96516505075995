import {useState} from 'react'
import {blockTypeToBlockName} from '../utils'
import useModal from '../../../hooks/useModal'
import {ElementFormatType, LexicalEditor, NodeKey} from 'lexical'

const useToolbarStates = (editor: LexicalEditor) => {
  const [activeEditor, setActiveEditor] = useState(editor)
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph')
  const [rootType, setRootType] = useState('root')
  const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null)
  const [fontSize, setFontSize] = useState<string>('16px')
  const [fontColor, setFontColor] = useState<string>('#000')
  const [bgColor, setBgColor] = useState<string>('#fff')
  const [fontFamily, setFontFamily] = useState<string>('Arial')
  const [elementFormat, setElementFormat] = useState<ElementFormatType>('left')
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isSubscript, setIsSubscript] = useState(false)
  const [isSuperscript, setIsSuperscript] = useState(false)
  const [isCode, setIsCode] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [isRTL, setIsRTL] = useState(false)
  const [codeLanguage, setCodeLanguage] = useState<string>('plain')
  const [isImageCaption, setIsImageCaption] = useState(false)
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())
  const [isMarkdown, setIsMarkdown] = useState(false)
  const [isHtmlMode, setIsHtmlMode] = useState(false)
  const [modal, showModal] = useModal()

  return {
    blockType,
    rootType,
    selectedElementKey,
    fontSize,
    fontColor,
    bgColor,
    fontFamily,
    elementFormat,
    isLink,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    isSubscript,
    isSuperscript,
    isCode,
    canUndo,
    canRedo,
    isRTL,
    codeLanguage,
    isImageCaption,
    isMarkdown,
    isEditable,
    activeEditor,
    modal,
    isHtmlMode,
    showModal,
    setBlockType,
    setRootType,
    setSelectedElementKey,
    setFontSize,
    setFontColor,
    setBgColor,
    setFontFamily,
    setElementFormat,
    setIsLink,
    setIsBold,
    setIsItalic,
    setIsUnderline,
    setIsStrikethrough,
    setIsSubscript,
    setIsSuperscript,
    setIsCode,
    setCanUndo,
    setCanRedo,
    setIsRTL,
    setCodeLanguage,
    setIsImageCaption,
    setIsEditable,
    setIsMarkdown,
    setActiveEditor,
    setIsHtmlMode,
  }
}

export default useToolbarStates
