import {ProductLimitReportContext} from '../contexts'
import {KTCard} from '../../../_metronic/helpers'
import {Pagination} from '../../../utils/pagination'
import ProductLimitReportHeader from '../sections/header/ProductLimitReportHeader'
import ProductLimitReportTable from '../sections/tables/ProductLimitReportTable'
import { useGetProductLimitData } from '../hooks/useGetProductLimitData'

const ProductLimitReport = () => {
  const {
    products,
    filters,
    isLoading,
    onSortingChange,
    onChangeCustomerFilter,
    onSearch,
    onPageChange,
    pagination,
  } = useGetProductLimitData()

  const contextValue = {
    products,
    filters,
    isLoading,
    onSortingChange,
    onChangeCustomerFilter,
    onSearch,
  }

  return (
    <ProductLimitReportContext.Provider value={contextValue}>
      <KTCard>
        <ProductLimitReportHeader />
        <ProductLimitReportTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </KTCard>
    </ProductLimitReportContext.Provider>
  )
}

export default ProductLimitReport
