interface RoleActionDropdownTypes {
  roleId: string
  onEdit: any
  handleModel: any
  users: string | number
  // toggleEditRoleModal: () => void
}

export default function RoleActionDropdown(props: RoleActionDropdownTypes) {
  const {onEdit, handleModel, users} = props

  return (
    <>
      <div className='px-4 py-2' onClick={onEdit}>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>
      {users === "-" && (
        <div className='px-4 py-2'>
          <span className='text-dark fw-bold text-hover-primary fs-6'>
            <label onClick={handleModel}>Delete</label>
          </span>
        </div>
      )}
    </>
  )
}
