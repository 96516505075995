import useApi from '../../../../services/useApi'
import useFilters from '../../../../hook/useFilters'
import {useContext} from 'react'
import {ProjectDetailsContext} from '../../context'

export const useGetAgileBoardData = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)

  const initialFilter = {
    search: true,
  }

  const {filters, onSearch} = useFilters(initialFilter)

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(`/agile/projects/${projectId}/cards`, {
    queryId: 'agile-board-data',
    filters: filters,
  })

  return {
    agileBoardData: response?.data?.cards || [],
    filtersData: response?.data?.filters || [],
    isLoading: isFetching,
    onSearch,
    refetch,
  }
}
