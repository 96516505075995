import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Toast from '../modules/toast/Toast'
import '../_metronic/assets/sass/layout/toast.scss'

function useToastify() {
  const toastMessage = (type: string, message: string) => {
    toast(<Toast type={type} message={message} />)
  }

  return {
    toastMessage,
  }
}

export default useToastify
