import {useContext, useState} from 'react'
import ProjectsDetailsModal from '../modals/ProjectsDetailsModal'
import Search from '../../../../components/Search'
import {ProjectsListPageContext} from '../../context'

const ActivePageHeader = () => {
  const {onSearch, hasProjectsPermission, isActiveProjects} = useContext(ProjectsListPageContext)
  const [showModal, setShowModal] = useState(false)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch && onSearch(searchTerm)
        }}
      />
      {hasProjectsPermission && isActiveProjects && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Create Projects
        </button>
      )}

      {showModal && isActiveProjects && (
        <ProjectsDetailsModal
          modalTitle={'Create Project'}
          show={showModal}
          onClose={() => setShowModal(false)}
          isEdit={false}
        />
      )}
    </div>
  )
}

export {ActivePageHeader}
