import { createContext } from 'react';

type AnalyticsDashboardContextType = {
  dateRangeLable: string
  AnalyticsOverviewData: any[]
  AggregatedProductReportData: any[]
  AggregatedProductReportMeta: any
  isAggregatedProductReportDataLoading: boolean
  TopProductsData: any[]
  TopCustomerData: any[]
  TopStatesData: any[]
  isLoadingChartData: boolean
  isTopCustomerLoading: boolean
  isTopProductsLoading: boolean
  isTopStatesLoading: boolean
  topCustomer: string
  topState: string
  onPageChange: (page: any) => void
  pagination: Object
  filters: any
  onSortingChange: (key: any, value: any) => void
}

export const AnalyticsDashboardContext = createContext<AnalyticsDashboardContextType>({
  dateRangeLable: '',
  AnalyticsOverviewData: [],
  AggregatedProductReportData: [],
  AggregatedProductReportMeta: {},
  isAggregatedProductReportDataLoading: false,
  TopProductsData: [],
  TopCustomerData: [],
  TopStatesData: [],
  isLoadingChartData: false,
  isTopCustomerLoading: false,
  isTopProductsLoading: false,
  isTopStatesLoading: false,
  topCustomer: '',
  topState: '',
  pagination: {},
  onPageChange: (page) => {},
  filters: {},
  onSortingChange: (key, value) => {},
})
