const MatrixTableRow = ({row}: any) => {
  const {value, Total, ...rest} = row
  const isLastRow = value === 'Total'

  return (
    <tr>
      <td>{isLastRow ? <div className='fw-bold'>{value}</div> : value}</td>
      <td className='text-center fw-bold border-end bg-light'>{Total}</td>
      {Object.entries(rest).map(([key, cellValue]: any) => (
        <td className='text-center' key={key}>
          {isLastRow ? <div>{cellValue}</div> : cellValue}
        </td>
      ))}
    </tr>
  )
}

export default MatrixTableRow
