export const validateInput = (input: string, setInputError:any) => {
    const trimmedInput = input.trim()

    const regex = /^(\d+w)(\d+d)?(\d+h)?(\d+m)?$/
    const isValid =
      regex.test(trimmedInput) ||
      /^\d+[wdhm]?( \d+[wdhm])*$/.test(trimmedInput) ||
      /^\d+$/.test(trimmedInput)

    const startsWithZero =
      /^\d+[wdhm]?( \d+[wdhm])*$/.test(trimmedInput) &&
      /^\d+[wdhm]?( \d+[wdhm])*$/.exec(trimmedInput)?.[0]?.startsWith('0')

    setInputError(
      isValid && !startsWithZero
        ? ''
        : 'Input contains an unexpected sequence of symbols or characters'
    )
}