import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { useLayout, usePageData } from '../core';
import { DrawerComponent } from '../../assets/ts/components';
import { WithChildren } from '../../helpers';

const Content: React.FC<WithChildren> = ({ children }) => {
  const [layoutClassName, setLayoutClassName] = useState<string | undefined>('');
  const { classes } = useLayout();
  const location = useLocation();
  const { pageTitle } = usePageData();

  useEffect(() => {
    const nameOfClass = pageTitle?.toLowerCase().split(' ');
    if (nameOfClass && nameOfClass.length > 1) {
      const joinClassName = 'layout-'+nameOfClass.join('-');
      setLayoutClassName(joinClassName);
    } else {
      const joinClassName = 'layout-'+nameOfClass;
      setLayoutClassName(joinClassName);
    }
  }, [pageTitle]);


  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
      <div className={clsx('app-custom-wrapper ', layoutClassName, ' p-6-remove')}>{children}</div>
    </div>
  );
};

export { Content };
