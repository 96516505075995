export function generateColors(dataLength:number) {
    const colors = [];
    const baseColors = [
      'rgba(28, 100, 242, 0.3)', // Blue
      'rgba(230, 0, 50, 0.3)', // Red
      'rgba(255, 205, 86, 0.3)', // Yellow
      'rgba(75, 192, 192, 0.3)', // Teal
      'rgba(105, 169, 80, 0.3)',  // Green
      'rgba(255, 153, 0, 0.3)', // Orange
      'rgba(128, 0, 128, 0.3)', // Purple
      'rgba(0, 128, 0, 0.3)', // Green
      'rgba(255, 0, 255, 0.3)', // Magenta
      'rgba(0, 128, 128, 0.3)', // Teal
      'rgba(128, 128, 0, 0.3)', // Olive
      'rgba(255, 69, 0, 0.3)', // Red-Orange
      'rgba(0, 0, 128, 0.3)', // Navy
      'rgba(255, 99, 71, 0.3)', // Tomato
      'rgba(0, 255, 0, 0.3)', // Lime
      'rgba(139, 69, 19, 0.3)', // SaddleBrown
      'rgba(0, 255, 255, 0.3)', // Aqua
      'rgba(218, 112, 214, 0.3)', // Orchid
      'rgba(255, 165, 0, 0.3)', // Orange
      'rgba(128, 0, 0, 0.3)', // Maroon
    ];
  
    for (let i = 0; i < dataLength; i++) {
      // Use modulo to repeat colors if dataLength exceeds the number of base colors
      const colorIndex = i % baseColors.length;
      colors.push(baseColors[colorIndex]);
    }
  
    return colors;
  }