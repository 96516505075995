import {useContext} from 'react'
import CustomFieldActionsOverlay from '../../overlays/CustomFieldActionsOverlay'
import {BoardSettingsPageContext} from '../../../context'
import {getBadgeColor} from '../../../../../utils/badge'
import {toTitleCase} from '../../../../../utils/string'
import {ReachSelect} from '../../../../../components/reachSelect'

const CustomFIeldsTableRow = ({
  dndSettings,
  row,
  hasWritePermission,
  dndImage,
  isValidForStatusMapping,
  statusMappingDropdownList,
  form,
}: any) => {
  const {
    isLoadingCustomFieldMappingDropdownList,
    mapCustomFieldStatus,
    customFieldOperationId,
    isLoadingCustomFieldOperation,
  } = useContext(BoardSettingsPageContext)

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {errors},
  } = form

  const handleMapProjectTableStatus = () => {
    const cFieldId = String(row?.id)
    const columnValue = watch(cFieldId)
    mapCustomFieldStatus(columnValue, cFieldId, setError)
  }

  const isDropdownLoading =
    isLoadingCustomFieldMappingDropdownList ||
    (isLoadingCustomFieldOperation && customFieldOperationId === String(row?.id))

  const isDropdownDisabled =
    isLoadingCustomFieldMappingDropdownList || isLoadingCustomFieldOperation || !hasWritePermission

  return (
    <tr {...dndSettings}>
      <td>
        {dndImage}
        {row?.customFieldName}
        {row?.isGlobal && (
          <span className={`ms-2 badge badge-lg badge-light-primary`}>
            <div className='align-items-center'>Global</div>
          </span>
        )}
      </td>
      <td>{toTitleCase(row?.fieldType)}</td>
      {isValidForStatusMapping && (
        <td>
          <ReachSelect
            id={row?.id}
            control={control}
            registerKey={`${row?.id}`}
            options={statusMappingDropdownList}
            labelKey='column_label'
            valueKey='column_key'
            disabledKey='disabled'
            isNullable={true}
            isLoading={isDropdownLoading}
            disabled={isDropdownDisabled}
            onChange={handleSubmit(handleMapProjectTableStatus)}
            error={errors?.[row?.id]}
          />
        </td>
      )}
      <td>
        <div className='text-center'>{row?.cardCounts}</div>
      </td>
      <td>
        <div className='text-center'>
          <span className={`badge ${getBadgeColor(row?.visibility, 'light')} badge-lg`}>
            <div className='align-items-center'>{row?.visibility}</div>
          </span>
        </div>
      </td>
      {hasWritePermission && (
        <td>
          {isLoadingCustomFieldOperation && customFieldOperationId === row?.id ? (
            <div className='d-flex justify-content-center'>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </div>
          ) : (
            <CustomFieldActionsOverlay customFieldData={row} />
          )}
        </td>
      )}
    </tr>
  )
}

export default CustomFIeldsTableRow
