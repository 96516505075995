// Helper function to count the number of tasks in each column
export function countColumns(tasks: any[]) {
  return tasks.reduce((acc, task) => {
    const {cID} = task
    acc[cID] = (acc[cID] || 0) + 1
    return acc
  }, {})
}

// Helper function to count the number of tasks in each module
export function countModules(tasks: any[]) {
  return tasks.reduce((acc, task) => {
    const {moduleID} = task
    acc[moduleID] = (acc[moduleID] || 0) + 1
    return acc
  }, {})
}
