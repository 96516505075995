/* eslint-disable react-hooks/exhaustive-deps */
import './styles/gantt.scss'
import './styles/ganttChartTheme.scss'
import {useEffect, useRef, useState} from 'react'
import {Chart} from './components/Chart'
import {GanttTask, GanttLink} from 'wx-react-gantt'
import GanttErrorBoundary from './components/GanttErrorBoundary'
import {highlightTime, transformGanttData} from './utils'
import {GanttChartProps} from './types'
import FullScreenWrapper from './components/FullScreenWrapper'
import {isEmpty} from '../../utils/common'
import Loading from '../../modules/loading'

const GanttChart = ({
  readOnly = false,
  data,
  dataMappingConfig,
  links = [],
  scales,
  tableColumns: columns = [],
  taskDrag = true,
  taskResize = true,
  columnResize = true,
  sort = true,
  filter = true,
  keyboard = true,
  undo = true,
  zoom = true,
  multiselect = true,
  cellHeight = 38,
  cellWidth = 100,
  scaleHeight = 36,
  onTaskDrag,
  onTaskUpdate,
  onLinkCreate,
  childMoveBetweenGroups = false,
  isTaskCreatable = false,
  fullScreen = false,
  taskTemplate,
  weekends = [6, 0],
  highlightWeekend = false,
  startDate,
  endDate,
  cellBorders = 'columns',
  init: externalInit,
  isLoading = false,
}: GanttChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const [tasks, setTasks] = useState<any[]>([])

  function init(api: any) {
    api.intercept('show-editor', () => !!isTaskCreatable)
    externalInit && externalInit(api)
  }

  // Event handlers with proper typing
  const eventHandlers = {
    onTaskDrag: (event: {detail: GanttTask}) => {
      if (onTaskDrag) onTaskDrag(event.detail)
    },
    onTaskUpdate: (event: {detail: GanttTask}) => {
      if (onTaskUpdate) onTaskUpdate(event.detail)
    },
    onLinkCreate: (event: {detail: GanttLink}) => {
      if (onLinkCreate) onLinkCreate(event.detail)
    },
  }

  // Load icons dynamically to avoid SSR issues
  useEffect(() => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://cdn.svar.dev/fonts/wxi/wx-icons.css'
    document.head.appendChild(link)
    return () => {
      document.head.removeChild(link)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(data)) {
      if (dataMappingConfig) {
        setTasks(transformGanttData(data, dataMappingConfig))
      } else {
        setTasks(data)
      }
    }
  }, [data, dataMappingConfig])

  const chartRenderer = () => {
    return (
      <Chart
        init={init}
        tasks={tasks}
        links={links}
        {...(scales ? {scales} : {})}
        columns={columns}
        readonly={readOnly}
        drag={taskDrag}
        resize={taskResize}
        columnResize={columnResize}
        sort={sort}
        filter={filter}
        keyboard={keyboard}
        undo={undo}
        zoom={zoom}
        multiselect={multiselect}
        cellHeight={cellHeight}
        cellWidth={cellWidth}
        scaleHeight={scaleHeight}
        childMoveBetweenGroups={childMoveBetweenGroups}
        fullScreen={fullScreen}
        taskTemplate={taskTemplate}
        weekends={weekends}
        onTaskDrag={eventHandlers.onTaskDrag}
        onTaskUpdate={eventHandlers.onTaskUpdate}
        onLinkCreate={eventHandlers.onLinkCreate}
        highlightTime={highlightWeekend ? highlightTime : false}
        startDate={startDate}
        endDate={endDate}
        cellBorders={cellBorders}
      />
    )
  }

  return (
    <GanttErrorBoundary>
      <div
        className={`wx-willow-theme ${isLoading ? 'position-relative min-h-350px' : ''}`}
        ref={chartRef}
      >
        {isLoading ? (
          <Loading />
        ) : !isLoading && tasks.length > 0 ? (
          fullScreen ? (
            <FullScreenWrapper hotkey='ctrl+shift+f'>{chartRenderer()}</FullScreenWrapper>
          ) : (
            chartRenderer()
          )
        ) : (
          <p className='text-center fw-bold'>No data found.</p>
        )}
      </div>
    </GanttErrorBoundary>
  )
}

export default GanttChart
