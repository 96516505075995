import {useContext} from 'react'
import {TimeSheetReportPageContext} from '../../../context'
import {DynamicTable} from '../../../../../components/DynamicTable'
import {TimeSheetReportTableColumns} from '../../../utils'
import TimeSheetTableRow from './TimeSheetTableRow'

export default function TimeSheetTable() {
  const {
    timeSheetData,
    timeSheetMeta,
    isLoadingTimeSheetData,
    onSortingChange,
    searchValue,
    filters,
  } = useContext(TimeSheetReportPageContext)

  return (
    <DynamicTable
      id={'members-report'}
      data={timeSheetData}
      sortableColumns={TimeSheetReportTableColumns(timeSheetMeta)}
      TableRow={TimeSheetTableRow}
      permissionPath={'projects_settings'}
      loading={isLoadingTimeSheetData}
      filters={filters}
      tableClass='table table-row-dashed table-row-gray-300 align-middle mb-15 gs-0 gy-3 matrix-report-table'
      onSortingChange={onSortingChange}
      searchValue={searchValue}
    />
  )
}
