import {Controller} from 'react-hook-form'
import JoditEditor from 'jodit-react'
import {TextEditorProps} from './textEditorProps'

function TextEditor({
  id,
  textEditorRef,
  control,
  registerKey,
  label,
  isRequired,
  defaultValue = '',
  rules,
  value = '',
  errorClass,
  inputClass,
  error,
  onChange: onEditorChange,
  isReadOnly = false,
  disabled = false,
  errorMessageRef,
  maxLength,
  ...rest
}: TextEditorProps) {
  return (
    <>
      {label && <label className={`form-label ${isRequired ? 'required' : ''}`}>{label}</label>}
      {control && registerKey ? (
        <Controller
          name={registerKey}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({field: {onChange, value, ref: registerRef}}) => (
            <>
              <JoditEditor
                ref={(e: any) => {
                  if (textEditorRef)
                    (textEditorRef as React.MutableRefObject<HTMLTextAreaElement | null>).current =
                      e
                  if (registerRef) registerRef(e)
                }}
                value={value}
                onChange={(content: string) => {
                  onChange(content)
                  onEditorChange?.(content)
                }}
                {...(error && error?.message ? {className: 'border border-danger' + (inputClass)} : {className: inputClass})}
                {...(isReadOnly || disabled ? {config: {readonly: true}} : {})}
                {...rest}
              />
              {error?.message && (
                <>
                  <div className='is-invalid d-none'></div>
                  <p className={errorClass ? errorClass : 'invalid-feedback'} ref={errorMessageRef}>
                    {error?.message}
                  </p>
                </>
              )}
            </>
          )}
        />
      ) : (
        <JoditEditor
          value={value}
          onChange={onEditorChange && onEditorChange}
          {...(isReadOnly || disabled ? {config: {readonly: true}} : {})}
          {...rest}
        />
      )}
    </>
  )
}

export default TextEditor
