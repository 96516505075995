/* eslint-disable */
import {useEffect} from 'react'
import {countColumns, countModules} from './handleTasksCounters'

const useDefaultDataHandler = (
  updatedTasksList: any,
  setTasks: Function,
  setTasksCounter: Function,
  isLoading: boolean,
  dndComponentRef: any,
  setComponentWidth: Function
) => {
  // set tasks and tasks counters from updatedTasksList data
  useEffect(() => {
    setTasks(updatedTasksList)
    setTasksCounter({
      columns: countColumns(updatedTasksList),
      modules: countModules(updatedTasksList),
    })
  }, [isLoading])

  // set dnd component width from dnd component ref
  useEffect(() => {
    const calculateAndSetWidth = () => {
      const dndComponent = dndComponentRef?.current
      if (dndComponent) {
        const width = dndComponent.scrollWidth
        setComponentWidth(width)
      }
    }
    calculateAndSetWidth()
    window.addEventListener('resize', calculateAndSetWidth)
    return () => {
      window.removeEventListener('resize', calculateAndSetWidth)
    }
  }, [isLoading])
}

export default useDefaultDataHandler
