import {useContext} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {SuggestedCustomerReportPageContext} from '../../contexts'

const ActionsOverlay = ({customerId}: any) => {
  const {updateCustomerTags} = useContext(SuggestedCustomerReportPageContext)

  const handleAddToDistro = () => {
    document.body.click()
    updateCustomerTags({tags: 'Distro'}, customerId)
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <div>
            <div className='px-2 py-1'>
              <span
                className='text-dark fw-bold text-hover-primary fs-6'
                onClick={handleAddToDistro}
              >
                <label className='cursor-pointer'>Add Distro to Tracking</label>
              </span>
            </div>
          </div>
        }
      />
    </>
  )
}

export default ActionsOverlay
