import Date from "../../../../components/Date/Date"

function AnonymousCustomerTableRow({row}: any){
  return (
    <tr>
      <td>
        <div>{row?.['customerName']}</div>
      </td>
      <td>
        <div>{row?.['customerEmail']}</div>
      </td>
      <td>
        <div>{row?.['customerRepresentative']}</div>
      </td>
      <td>
        <div>{row?.['customerGroupName']}</div>
      </td>
      <td>
        <Date date={row?.['dateCreated']} />
      </td>
    </tr>
  )
}

export default AnonymousCustomerTableRow
