/* eslint-disable no-loop-func */
import {isEmpty} from '../../../utils/common'
import {formatDate} from '../../../utils/date'
import {capitalizeFirstLetter} from '../../../utils/string'

export const STATUS_OPTIONS = [
  {
    value: '',
    label: 'Select Status',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
]

type Users = {
  id: string
  created_at: string
  last_login: number
  updated_at: number
  username: string
  role: string
  role_id: string
  status: string
  name?: string
}

export function dataToUsers(data: Users) {
  return {
    id: data['id'],
    username: data['username'],
    name: data['name'] ? data['name'] : '',
    nameFirstLetter: data['name'] ? data['name'].charAt(0).toUpperCase() : '',
    role: data?.['role'] ? capitalizeFirstLetter(data['role']) : '-',
    lastLogin: data?.['last_login'] ? formatDate(data['last_login'], false) : '-',
    status: data?.['status'] ? capitalizeFirstLetter(data['status']) : '-',
  }
}

export function dataToRoles(data: any) {
  return {
    role: data?.['role'] ? capitalizeFirstLetter(data['role']) : '-',
    totalUsers: data['total_users'] || '-',
    createdAt: data?.['created_at'] ? formatDate(data['created_at'], false) : '-',
  }
}

export function dataToRolesOptions(data: any) {
  const res: any[] = []

  if (data && data.length) {
    res.push({
      label: 'Select Role',
      value: '',
    })

    data.map((item: any) =>
      res.push({
        label: item['role'],
        value: item['id'],
      })
    )
  }

  return res
}

export const dataToRoleFormValues = (data: any) => {
  const res: any = {}
  res['role'] = data['role']
  res['is_administrator_access'] = data['is_administrator_access']
  res['is_super_admin'] = data['is_super_admin']

  const processPermissions = (permissions: any, prefix: string = 'permissions') => {
    Object.entries(permissions).forEach(([key, value]: [string, any]) => {
      if (!isEmpty(value.operations)) {
        Object.entries(value.operations).forEach(([op, opValue]: [string, any]) => {
          res[`${prefix}.${key}.operations.${op}`] = opValue
        })
      }

      if (!isEmpty(value.children)) {
        processPermissions(value.children, `${prefix}.${key}.children`)
      }
    })
  }

  if (!isEmpty(data.permissions)) {
    processPermissions(data.permissions)
  }

  return res
}

export function transformRolesDetails(roleDetails: any, permissions: any) {
  const defaultValues: any = {}

  permissions.forEach((permission: any) => {
    const featureId = permission.id

    defaultValues[featureId] = {
      operations: {},
      children: {},
    }

    permission.operation.forEach((op: string) => {
      defaultValues[featureId].operations[op] = true
    })

    if (permission.children && permission.children.length > 0) {
      permission.children.forEach((child: any) => {
        const childId = child.id
        defaultValues[featureId].children[childId] = {
          operations: {},
        }

        child.operation.forEach((op: string) => {
          defaultValues[featureId].children[childId].operations[op] = true
        })
      })
    }
  })

  if (roleDetails) {
    defaultValues.role = roleDetails.title || ''
  }

  return defaultValues
}

export function setDefaultValues(data: any, permissions: any, isEdit: boolean) {
  const result: Record<string, {read: boolean; write: boolean; parentId: string | null}> = {}

  result.is_administrator_access = isEdit ? data?.is_administrator_access ?? false : false
  result.is_super_admin = isEdit ? data?.is_super_admin ?? false : false

  // Initialize with default permissions
  function initializePermissions(feature: any, parentId: string | null) {
    if (feature && feature.name) {
      result[feature.name] = {
        read: false,
        write: false,
        parentId: parentId,
      }

      if (feature.children) {
        Object.values(feature.children).forEach((child: any) =>
          initializePermissions(child, feature.name)
        )
      }
    }
  }

  // Update with role details permissions
  function updatePermissions(feature: any, parentId: string | null) {
    if (feature && feature.name) {
      result[feature.name] = {
        read: feature.operations ? feature.operations.read : false,
        write: feature.operations ? feature.operations.write : false,
        parentId: parentId,
      }

      if (feature.children) {
        Object.values(feature.children).forEach((child: any) =>
          updatePermissions(child, feature.name)
        )
      }
    }
  }

  // Initialize default permissions structure
  Object.values(permissions?.permissions || {}).forEach((feature: any) =>
    initializePermissions(feature, null)
  )

  // Update with role details if editing
  if (isEdit) {
    Object.values(data?.permissions || {}).forEach((feature: any) =>
      updatePermissions(feature, null)
    )
  }
  return result
}

export function formatCheckboxValues(checkboxValues: any, setValue: any) {
  // Filter and format checkbox values into a structured format
  const formattedPermissionsValues = Object.keys(checkboxValues)
    .filter((featureId) => typeof checkboxValues[featureId] === 'object')
    .map((featureId) => {
      // Extract operations (read, write) if their values are true
      const operations = Object.keys(checkboxValues[featureId]).filter(
        (op) => (op === 'read' || op === 'write') && checkboxValues[featureId]
      )
      return {
        feature_id: featureId,
        operation: operations,
      }
    })

  // Set values to form state
  setValue('isAdmin', checkboxValues?.is_administrator_access ?? false)
  setValue('isSuperAdmin', checkboxValues?.is_super_admin ?? false)
  setValue('permissions', formattedPermissionsValues)
  setValue('is_administrator_access', checkboxValues?.is_administrator_access ?? false)
  setValue('is_super_admin', checkboxValues?.is_super_admin ?? false)
}

export const handleCheckboxChange = (
  permissionId: string,
  operationType: string,
  setCheckboxValues: any
) => {
  setCheckboxValues((prevValues: any) => {
    const updatedValues = {...prevValues}

    if (operationType === 'administrator') {
      const newValue = !updatedValues[permissionId]
      updatedValues[permissionId] = newValue

      // Update children
      Object.keys(updatedValues).forEach((key) => {
        if (typeof updatedValues[key] === 'object' && updatedValues[key] !== null) {
          if (updatedValues[key].read !== undefined) {
            updatedValues[key].read = newValue
          }
          if (updatedValues[key].write !== undefined) {
            updatedValues[key].write = newValue
          }
        }
      })
    } else if (operationType === 'super_administrator') {
      const newValue = !updatedValues[permissionId]
      updatedValues[permissionId] = newValue
      updatedValues['is_administrator_access'] = newValue

      // Update children
      Object.keys(updatedValues).forEach((key) => {
        if (typeof updatedValues[key] === 'object' && updatedValues[key] !== null) {
          if (updatedValues[key].read !== undefined) {
            updatedValues[key].read = newValue
          }
          if (updatedValues[key].write !== undefined) {
            updatedValues[key].write = newValue
          }
        }
      })
    } else {
      if (updatedValues[permissionId]) {
        updatedValues[permissionId][operationType] = !updatedValues[permissionId][operationType]

        // Update children
        Object.keys(updatedValues).forEach((key) => {
          if (updatedValues[key]?.parentId === permissionId) {
            updatedValues[key][operationType] = updatedValues[permissionId][operationType]
          }
        })

        // Update parents
        let parentId = updatedValues[permissionId]?.parentId
        while (parentId) {
          const siblings = Object.keys(updatedValues).filter(
            (key) => updatedValues[key]?.parentId === parentId
          )

          const parentShouldBeChecked = siblings.some(
            (siblingId) => updatedValues[siblingId][operationType]
          )

          updatedValues[parentId][operationType] = parentShouldBeChecked

          parentId = updatedValues[parentId]?.parentId
        }
      }
    }
    return updatedValues
  })
}

// updateChildren((value as any).children, feature_id, operations);

const transformOperations = (data: any): any => {
  const result: any = {}

  for (const [key, value] of Object.entries(data)) {
    const transformedItem = {
      operations: {
        read: (value as any).operations.includes('read'),
        write: (value as any).operations.includes('write'),
      },
      children: {},
    }

    // Recursively transform children if they exist
    if (Object.keys((value as any).children).length > 0) {
      transformedItem.children = transformOperations((value as any).children)
    }

    result[key] = transformedItem
  }

  return result
}

interface Permission {
  read: boolean
  write: boolean
  parentId: string | null
}


const updatePermissions = (permissions: any, data: Record<string, Permission>): any => {
  // Transform the permissions structure to the desired format
  const transformedPermissions = transformOperations(permissions)
  const updatedPermissions = {...transformedPermissions}

  // Update the permission node with the given read and write values
  const updatePermissionNode = (node: any, read: boolean, write: boolean) => {
    if (node.operations) {
      node.operations.read = read
      node.operations.write = write
    }
  }

  // Recursive function to update all children based on parent permission
  const updateChildrenRecursively = (children: any, read: boolean, write: boolean) => {
    if (!children) return

    for (const child of Object.values(children)) {
      // Update the operations for each child
      updatePermissionNode(child, read, write)

      // Recursively apply the changes to nested children
      if ((child as any).children) {
        updateChildrenRecursively((child as any).children, read, write)
      }
    }
  }

  // Recursively search and update the feature's permissions
  const updateChildren = (children: any, featureId: string, read: boolean, write: boolean) => {
    if (!children) return

    for (const [key, value] of Object.entries(children)) {
      if (key === featureId) {
        updatePermissionNode(value, read, write)

        // If parent permissions are set, propagate them to all children
        updateChildrenRecursively((value as any).children, read, write)
        return
      }
      // Recursive call to update deeper levels
      updateChildren((value as any).children, featureId, read, write)
    }
  }

  // Initialize nodes with read/write permissions
  const initializePermissions = (node: any, featureId: string, read: boolean, write: boolean) => {
    if (node[featureId]) {
      updatePermissionNode(node[featureId], read, write)

      // Propagate the permissions to children recursively
      updateChildrenRecursively(node[featureId].children, read, write)
    } else {
      // Check in the children nodes if not found at the root level
      updateChildren(node, featureId, read, write)
    }
  }

  // Loop through data and update permissions
  for (const [featureId, permission] of Object.entries(data)) {
    const {read, write, parentId} = permission // Destructure with type assertion
    if (parentId === null) {
      // Update root level permissions
      initializePermissions(updatedPermissions, featureId, read, write)
    } else {
      // Update child level permissions
      initializePermissions(updatedPermissions, featureId, read, write)
    }
  }

  return updatedPermissions
}

  



export const filterPermissionsWithOperations = (data: any, permission: any) => {
  if (data?.is_super_admin) {
    data['is_administrator_access'] = true
  }
  return {
    role: data['role'],
    is_administrator_access: data['is_administrator_access'],
    is_super_admin: data['is_super_admin'],
    permissions: updatePermissions(permission?.permissions, data),
  }
}
