import {useState} from 'react'
import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'
import {makeSemicolonSepratedValues} from '../../../utils/common'

const initialFilters = {
  page: 1,
  limit: 50,
  sort_by: 'name/1',
  search: '',
  customer_rep: '',
  product_filter: '',
  sorting_bop_id: '',
  state: '',
}

const useGetGlobalProductsData = () => {
  const Api = useApi()
  const [isFetchingCsvData, setIsFetchingCsvData] = useState(false)

  const {
    filters,
    onSearch,
    onSortingChange,
    searchValue,
    onPageChange,
    setMultipleFilters,
    onDateChange,
  } = useFilters(initialFilters, {
    sortType: 'number',
  })

  const csvFilters = {
    sort_by: 'name/1',
    product_filter: filters?.product_filter || '',
    state: '',
    sorting_bop_id: '',
    customer_rep: '',
  }

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(`/bulkOrders/reports/products/global`, {
    queryId: 'global-products',
    filters: filters,
  })

  const onProductFilterChange = (product_filter: any) => {
    setMultipleFilters({product_filter: makeSemicolonSepratedValues(product_filter), page: 1})
  }

  const onRepFilterChange = (rep: string) => {
    setMultipleFilters({customer_rep: rep, page: 1})
  }

  const onStateFilterChange = (state: string) => {
    setMultipleFilters({state: state, page: 1})
  }

  const onApplySortingOnProduct = (productIds: any) => {
    setMultipleFilters({sorting_bop_id: productIds, page: 1})
  }

  const onDownloadCsv = async (payload: any) => {
    return Api.get(`/bulkOrders/reports/products/global/csv`, {
      filters: csvFilters,
      setLoading: setIsFetchingCsvData,
      isToast: true,
      isErrorPageRedirection: false,
    })
  }
  
  return {
    GlobalProductsDataData: response?.data || [],
    pagination: response?.meta?.pagination || {},
    isFetchingCsvData,
    onDownloadCsv,
    filters,
    isLoading: isFetching,
    onSortingChange,
    onSearch,
    searchValue,
    onPageChange,
    onProductFilterChange,
    onRepFilterChange,
    onStateFilterChange,
    onApplySortingOnProduct,
    onDateChange,
    refetch,
  }
}

export default useGetGlobalProductsData
