import React from 'react';
import defaultImage from '../../../../../images/default-bc-product.png'
import {formatPrice} from '../../../../../utils/common'
import Date from '../../../../../components/Date/Date'

const ProductTableRow = ({ row }: { row: any }) => {
  return (
    <tr>
      <td>
        <div className='d-flex align-items-center py-2'>
          <div className='symbol symbol-40px me-3 border'>
            <img src={row['product_image'] || defaultImage} alt='productimage' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a
              href={`/orders/bo/manage/products/${row?.status}/${row?.bop_id}/customer-tracking`}
              target='_blank'
              rel='noopener noreferrer'
              className='text-dark text-hover-primary fw-semibold'
            >
              {row['product_name']}
            </a>
          </div>
        </div>
      </td>
      <td>{row.bc_sku}</td>
      <td className='text-center'>{row.unique_customers}</td>
      <td className='text-center'>{row.total_order_count}</td>
      <td>{formatPrice(row.order_total, false)}</td>
      <td>
        <Date date={row?.last_order_date} />
      </td>
    </tr>
  )
};

export default ProductTableRow;
