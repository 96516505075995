import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'

const initialFilters = {
  search: true,
}

const useGetGlobalDefaultColumnsList = () => {
  const Api = useApi()
  const {filters, onSearch, searchValue} = useFilters(initialFilters)

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/default/columns`, {
    queryId: 'project-default-columns',
    filters: filters,
  })

  return {
    globalDefaultColumnsList: response?.data || [],
    isLoading: isFetching,
    onSearch,
    searchValue,
  }
}

export default useGetGlobalDefaultColumnsList
