import {useContext} from 'react'
import Checkbox from '../../CheckBox/CheckBox'
import {SideMenuContext} from '../context'

const GlobalDefaultColumnsTableRow = ({dndSettings, row, dndImage}: any) => {
  const {selectedColumns, setSelectedColumns} = useContext(SideMenuContext)

  const handleCheckboxChange = (key: string, title: string, isChecked: boolean) => {
    setSelectedColumns((prev: any) =>
      isChecked ? [...prev, {key, title}] : prev.filter((col: any) => col.key !== key)
    )
  }

  return (
    <tr {...dndSettings}>
      <td>
        {dndImage}
        {row?.label}
      </td>
      <td>
        <Checkbox
          id={row?.value}
          checked={selectedColumns.some((col: any) => col.key === row?.value)}
          onChange={(e) => handleCheckboxChange(row?.value, row?.label, e.target.checked)}
          className='form-check-custom form-check-solid'
          disabled={row?.default}
        />
      </td>
    </tr>
  )
}

export default GlobalDefaultColumnsTableRow
