import React, {useContext} from 'react'
import {UserRoleContext} from '../../context'
import RoleTableRow from '../../sections/table/RoleTableRow'
import usePermission from '../../../../hook/usePermission'
import Loading from '../../../loading'
import NoDataFound from '../../../../components/NoDataFound'

const RoleTable = ({row, index, publishedDeploy, refetch}: any) => {
  const {roles, isFetching} = useContext(UserRoleContext)
  const {hasPermission} = usePermission()

  return (
    <div className='table-responsive position-relative'>
      <div className='table-loader-wrapper'>
        <table
          id='kt_table_products'
          className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15'
        >
          <thead className='table-row-bordered'>
            <tr className='fs-6 fw-semibold text-muted text-uppercase'>
              <th className='min-w-300px'>ROLE NAME</th>
              <th className='min-w-120px text-center'>TOTAL USERS</th>
              <th className='min-w-150px'>CREATED ON</th>
              {hasPermission('settings_users', 'write') && <th className='w-80px text-center'>Action</th>}
            </tr>
          </thead>

          <tbody>
            {roles.length
              ? roles?.map((row: any, index: number) => (
                  <RoleTableRow row={row} key={row['id']} index={index} />
                ))
              : !isFetching && <NoDataFound colspan={4} />}
          </tbody>
        </table>
        {isFetching && <Loading />}
      </div>
    </div>
  )
}

export default RoleTable
