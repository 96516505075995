import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import UnhideProductRulesPageNavigation from './UnhideProductRulesPageNavigation'
import ExecutedRuleContent from './sections/ExecutedRuleContent'

const analyticsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Automation',
    path: '/analytics/automation/rules',
    isSeparator: false,
    isActive: true,
  },
]
// const AutomationPageBreadCrumbs: Array<PageLink> = [
//   {
//     title: 'Analytics',
//     path: '/analytics/sold-products',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: 'Automation',
//     path: '/analytics/automation/appliedrules',
//     isSeparator: false,
//     isActive: true,
//   },
// ]

const UnhideProductRules = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <UnhideProductRulesPageNavigation />
            <Outlet />
          </>
        }
      >       
        <Route
          path='/executedrules'
          element={
            <>
              <PageTitle breadcrumbs={analyticsBreadCrumbs}>Executed Rule</PageTitle>
              <ExecutedRuleContent />
            </>
          }
        />
        <Route index element={<Navigate to='/analytics/automation/appliedrules/executedrules' />} />
      </Route>
    </Routes>
  )
}

export default UnhideProductRules
