import {createContext} from 'react'
export const UnhideProductRulesPageContext = createContext({
  unhideRules: [],
  onSearch: (searchValue: string) => {},
  pagination: {},
  onPageChange: (e: string) => {},
  addUnhideProductRule: (e: any) => {},
  deleteUnhideProductRule: (e: string) => {},   
  refetch: () => {},
  onSortingChange: (key: string, value: string) => {},
  filters: {},
  // isLoading: false,
  isFetching: false,
})

export const ExecutedProductRulesPageContext = createContext({
  executedRules: [],
  onSearch: (searchValue: string) => {},
  pagination: {},
  onPageChange: (e: string) => {},   
  refetch: () => {},
  onSortingChange: (key: string, value: string) => {},
  filters: {},  
  isFetching: false,
})