export const SET_USER_FILTER = 'SET_USER_FILTER';
export const SET_SUPPLIER_FILTER = 'SET_SUPPLIER_FILTER';

export const setUserFilter = (user: string) => ({
  type: SET_USER_FILTER,
  payload: {value: user, label: user},
});

export const setSupplierFilter = (supplier: []) => ({
  type: SET_SUPPLIER_FILTER,
  payload: supplier,
});