/* eslint-disable */
import {useEffect, useState, useCallback} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {initializeColumns} from './utils'
import {SideMenuContext} from './context'
import useColumnsOperations from './hooks/useColumnsOperations'
import useGetColumnsList from './hooks/useGetColumnsList'
import useDrawerToggle from './hooks/useDrawerToggle'
import SidebarMenu from './components/SidebarMenu'
import {TableSettingsMenuProps} from './TableSettingsMenuTypes'

const TableSettingsMenu = ({
  defaultColumns,
  type,
  permissionPath,
  refetch,
}: TableSettingsMenuProps) => {
  const {
    globalDefaultColumnsList,
    isLoadingGlobalDefaultColumnsList,
    refetchGlobalDefaultColumnsList,
  } = useGetColumnsList()
  const {updateGlobalDefaultColumnsTableSorting, isLoadingGlobalDefaultColumnOperation} =
    useColumnsOperations()
    const {isDrawerOpen, toggleDrawer} = useDrawerToggle()

    const [selectedColumns, setSelectedColumns] = useState<any>([])
    const [updatedDefaultColumn, setUpdatedDefaultColumn] = useState<any>([])
    const [initialSelectedColumns, setInitialSelectedColumns] = useState<any>([])

    const initializeColumnsCallback = useCallback(() => {
      initializeColumns(
        globalDefaultColumnsList,
        defaultColumns,
        setUpdatedDefaultColumn,
        setSelectedColumns,
        selectedColumns,
        setInitialSelectedColumns
      )
    }, [globalDefaultColumnsList, defaultColumns])

    useEffect(() => {
      initializeColumnsCallback()
    }, [initializeColumnsCallback, isDrawerOpen])

    const contextValue = {
      updatedDefaultColumn,
      type,
      permissionPath,
      defaultColumns,
      initialSelectedColumns,
      globalDefaultColumnsList,
      selectedColumns,
      isLoadingGlobalDefaultColumnsList,
      isLoadingGlobalDefaultColumnOperation,
      refetch,
      setUpdatedDefaultColumn,
      setSelectedColumns,
      refetchGlobalDefaultColumnsList,
      updateGlobalDefaultColumnsTableSorting,
    }

  return (
    <SideMenuContext.Provider value={contextValue}>
      <span id='kt_drawer_column_setting_toggle' className='cursor-pointer' onClick={toggleDrawer}>
        <KTSVG path={`/media/ad-theme-icons/settings.svg`} className='svg-icon-2' svgClassName='' />
      </span>
      <SidebarMenu />
    </SideMenuContext.Provider>
  )
}

export default TableSettingsMenu
