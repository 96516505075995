import { SET_USER_FILTER, SET_SUPPLIER_FILTER } from './ReplenishmentActions'

interface FilterState {
  global_user: {};
  global_suppliers: [];
}
// sessionStorage.getItem('global_suppliers');
// sessionStorage.getItem('global_user');
const initialState: FilterState = {
    global_user: {value: '', label: ''},
    global_suppliers: [],
};

const filterReducer = (state = initialState, action: any): FilterState => {  
  switch (action.type) {
    case SET_USER_FILTER:
        const newUser = action.payload !== undefined ? action.payload?.value : state.global_user;        
        sessionStorage.setItem('global_user', JSON.stringify({value: newUser, label: newUser}));
      return {
        ...state,
        global_user: {value: newUser, label: newUser},
      };
    case SET_SUPPLIER_FILTER:
        const newSuppliers = action.payload !== null && action.payload !== undefined ? action.payload : state.global_suppliers;
        sessionStorage.setItem('global_suppliers', JSON.stringify(newSuppliers));
      return {
        ...state,
        global_suppliers: newSuppliers,
      };
    default:
      return state;
  }
};

export default filterReducer;