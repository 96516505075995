/* eslint-disable */
import {useContext, useEffect} from 'react'
import {isEmpty} from '../../../utils/common'
import {useQueryClient} from 'react-query'
import {ProjectDetailsContext} from '../context'
import {formatForLink} from '../../../utils/string'
import {formatDateObject} from '../../../utils/date'
import TaskTimeline from '../components/GanttChart/TaskTimeline'
import {
  estimationColumnCell,
  progressColumnCell,
  spentTimeColumnCell,
  ticketNameColumnCell,
} from '../components/GanttChart/CustomTableCells'

//<---------------------- Agile Board Page ----------------------->

// Default Values
export const createTicketDefaultValues = (
  columns: any,
  modules: any,
  statuses: any,
  priorities: any,
  isCreateFromBoard: boolean,
  columnId: number | null,
  moduleId: number | null
) => {
  const defaultStatus = statuses?.find((status: any) => status?.is_default)
    ? statuses?.find((status: any) => status?.is_default)?.id
    : statuses?.[0]?.id || 1

  const defaultPriority = priorities?.find((priority: any) => priority?.is_default)
    ? priorities.find((priority: any) => priority?.is_default)?.id
    : priorities?.[0]?.id || 1

  return {
    currentColumnId: isCreateFromBoard ? columnId : columns?.[0]?.column_id,
    moduleId: isCreateFromBoard ? moduleId : modules?.[0]?.module_id,
    status: defaultStatus,
    priority: defaultPriority,
    title: '',
    description: '',
    assignedTo: '',
    estimation: '',
    dueDate: null,
    isArchived: false,
  }
}

export const defaultAgileBoardTicketValues = (row: any) => ({
  assignedTo: row?.defaultAssignee,
  assigneeName: row?.assigneeName,
  dueDate: row?.dueDate,
  dueDateLabel: row?.dueDateLabel,
})

//<---------------------- ListView Page ----------------------->

export const listViewTicketColumns: any[] = [
  {
    key: 'title',
    label: 'TICKET NAME',
    isSorted: true,
    headerStyle: 'min-w-500px',
  },
  {
    key: 'module_name',
    label: 'MODULE',
    isSorted: true,
    headerStyle: 'min-w-175px',
  },
  {
    key: 'column_name',
    label: 'STAGE',
    isSorted: true,
    headerStyle: 'min-w-120px',
  },
  {
    key: 'assignee',
    label: 'ASSIGNEE',
    isSorted: true,
    headerStyle: 'min-w-150px',
  },
  {
    key: 'due_date',
    label: 'DUE DATE',
    isSorted: true,
    headerStyle: 'min-w-120px',
  },
  {
    key: 'priority_label',
    label: 'PRIORITY',
    isSorted: true,
    headerStyle: 'min-w-120px',
  },
  {
    key: 'created_at',
    label: 'CREATED DATE',
    isSorted: true,
    headerStyle: 'min-w-150px',
  },
  {
    key: 'updated_at',
    label: 'UPDATED DATE',
    isSorted: true,
    headerStyle: 'min-w-150px',
  },
]

export const defaultListViewTicketValues = (row: any) => ({
  assignedTo: row?.defaultAssignee,
  dueDate: row?.dueDate,
})

// ticket details path
export const generateTicketDetailsPath = (ticketDetails: any, projectDetails: any) => {
  const {projectId} = projectDetails
  const {ticketId, ticketInitial} = ticketDetails
  return `/projects/all-projects/${projectId}/ticket/${ticketId}/${formatForLink(ticketInitial)}`
}

//<---------------------- Members Page ----------------------->

export const memberPageTableColumns = [
  {key: 'name', label: 'NAME', isSorted: true, headerStyle: 'min-w-200px'},
  {key: 'role', label: 'SYSTEM ROLE', isSorted: false, headerStyle: 'min-w-100px'},
  {
    key: 'card_count',
    label: 'TICKET ASSIGNED',
    isSorted: true,
    headerStyle: 'min-w-100px',
    style: 'justify-content-center',
  },
  {key: 'created_at', label: 'CREATED AT', isSorted: true, headerStyle: 'min-w-150px'},
  {
    key: 'action',
    label: 'ACTION',
    isSorted: false,
    headerStyle: 'w-80px',
  },
]

//<---------------------- Gantt Chart Page ----------------------->

export const mapGanttData = (data: any[]): any[] => {
  if (isEmpty(data)) return []
  return data.reduce((acc: any[], module) => {
    if (!module.columnItems.length) return acc

    return [
      ...acc,
      {
        ...module,
        id: module.mID,
        text: module.moduleName,
        taskProgress: module.total_progress,
        type: 'summary',
        open: true,
        spent_time: module?.total_spent_time,
      },
      ...module.columnItems?.map((item: any) => {
        return {
          ...item,
          id: item.ticketID,
          text: item.title,
          start: unixToDate(item.start_date, false),
          end: unixToDate(item.due_date, true),
          parent: module.mID,
          type: 'task',
          progress: calculatePercentage(
            unixToDate(item.start_date, false),
            unixToDate(item.due_date, true),
            item?.estimation
          ),
          taskProgress: item.progress,
        }
      }),
    ]
  }, [])
}

export const ganttChartConfig = (projectId: string, chartView: string) => ({
  features: {
    taskDrag: true,
    taskResize: true,
    columnResize: true,
    sort: true,
    filter: true,
    taskTemplate: TaskTimeline,
    fullScreen: true,
    highlightWeekend: true,
  },
  tableColumns: [
    {
      id: 'text',
      header: 'Ticket Name',
      width: 300,
      sort: true,
      template: (data: any) => ticketNameColumnCell(data, projectId),
    },
    {
      id: 'progress',
      header: 'Estimation',
      align: 'center',
      width: 150,
      sort: true,
      template: estimationColumnCell,
    },
    {
      id: 'spent_time',
      header: 'Spent Time',
      align: 'center',
      width: 150,
      sort: true,
      template: spentTimeColumnCell,
    },
    {
      id: 'taskProgress',
      header: 'Progress',
      width: 120,
      sort: true,
      template: progressColumnCell,
    },
  ],
  zoomConfig: {
    maxCellWidth: 200,
    level: chartView === 'month' ? 4 : 3,
    levels: [
      {
        minCellWidth: 200,
        maxCellWidth: 400,
        scales: [
          {
            unit: 'year',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'month'}),
          },
        ],
      },
      {
        minCellWidth: 150,
        maxCellWidth: 400,
        scales: [
          {
            unit: 'year',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'month'}),
          },
          {
            unit: 'quarter',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'quarter'}),
          },
        ],
      },
      {
        minCellWidth: 250,
        maxCellWidth: 350,
        scales: [
          {
            unit: 'quarter',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'quarter'}),
          },
          {
            unit: 'month',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'month'}),
          },
        ],
      },
      {
        minCellWidth: 100,
        scales: [
          {
            unit: 'month',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'month'}),
          },
          {
            unit: 'week',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'week'}),
          },
        ],
      },
      {
        minCellWidth: 70,
        maxCellWidth: 70,
        scales: [
          {
            unit: 'month',
            step: 1,
            format: (date: Date, isFirstInScale?: boolean) =>
              formatChartScale({date, formatType: 'month', isFirstInScale}),
          },
          {
            unit: 'day',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'day'}),
          },
        ],
      },
      {
        minCellWidth: 25,
        maxCellWidth: 100,
        scales: [
          {
            unit: 'day',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'day'}),
          },
          {
            unit: 'hour',
            step: 6,
            format: (start: Date, end: Date) =>
              formatChartScale({date: start, endDate: end, formatType: 'hour-range'}),
          },
        ],
      },
      {
        maxCellWidth: 120,
        scales: [
          {
            unit: 'day',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'day'}),
          },
          {
            unit: 'hour',
            step: 1,
            format: (date: Date) => formatChartScale({date, formatType: 'hour'}),
          },
        ],
      },
    ],
  },
})

const formatChartScale = ({
  date,
  formatType,
  isFirstInScale,
  endDate,
}: {
  date: Date
  formatType: string
  isFirstInScale?: boolean
  endDate?: Date
}): string => {
  switch (formatType) {
    case 'quarter':
      return `<div class='ms-5'>  Q${Math.floor(date.getMonth() / 3) + 1}</div>`

    case 'week':
      return `<div class='ms-5'>  W <span class='text-gray-800'>${formatDateObject(
        date,
        'w'
      )}</span></div>`

    case 'hour-range':
      if (!endDate) return formatDateObject(date, 'HH:mm')
      return `<div class='ms-5'>  ${formatDateObject(date, 'HH:mm')} - ${formatDateObject(
        endDate,
        'HH:mm'
      )}</div>`

    case 'hour':
      return `<div class='ms-5'>  ${formatDateObject(date, 'HH:mm')}</div>`

    case 'month':
      const prevDate = new Date(date)
      prevDate.setDate(prevDate.getDate() - 1)

      if (isFirstInScale || prevDate.getMonth() !== date.getMonth()) {
        return `<div class="ms-5">
          ${new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
          }).format(date)}
        </div>`
      }
      return ''

    case 'day':
      const dayNum = date.getDate()
      const weekday = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
      }).format(date)[0]

      return `<div class="w-100 d-flex justify-content-center gap-1">
        <div class="text-gray-800">${dayNum}</div>
        <div>${weekday}</div>
      </div>`

    default:
      return `<div class='ms-5'>  ${formatDateObject(date, 'yyyy-MM-dd')}</div>`
  }
}

// Function to convert Unix timestamp to Date object with time set to midnight
const unixToDate = (timestamp: number | null, isEndDate: boolean = false): Date => {
  if (!timestamp) return new Date(new Date().setHours(0, 0, 0, 0))
  const date = new Date(timestamp * 1000)
  if (isEndDate) {
    date.setDate(date.getDate() + 1)
  }
  return new Date(date.setHours(0, 0, 0, 0))
}

export const calculatePercentage = (startDate: Date, dueDate: Date, estimation: string): number => {
  // Parse estimation time
  const estimationHours = parseFloat(estimation.replace('h', ''))

  // Calculate the total duration in hours considering 1 day = 8 working hours
  const durationInMs = dueDate.getTime() - startDate.getTime()
  const durationInHours = (durationInMs / (1000 * 60 * 60)) * (8 / 24) // Adjusting for 8 working hours per day

  // Calculate the percentage
  const percentage = (estimationHours * 100) / durationInHours

  const floatPercentage = parseFloat(percentage.toFixed(2))
  // Return the percentage with 2 decimal precision
  return floatPercentage
}

export const convertPercentageToEstimation = (
  percentage: number,
  startDate: Date,
  dueDate: Date
): string => {
  // Calculate total duration in hours considering 1 day = 8 working hours
  const durationInMs = dueDate.getTime() - startDate.getTime()
  const durationInHours = (durationInMs / (1000 * 60 * 60)) * (8 / 24) // Adjusting for 8 working hours per day

  // Calculate estimation hours based on the percentage
  const estimationHours = (percentage / 100) * durationInHours

  // Convert estimation hours to hours and minutes
  const hours = Math.floor(estimationHours) // Whole hours
  const minutes = Math.round((estimationHours - hours) * 60) // Remaining minutes

  // Return in "Xh Ym" format
  return `${hours}h ${minutes}m`
}

//<---------------------- Board Settings Page ----------------------->

// Table Columns
export const boardColumnsTableColumns = (isValidForStatusMapping: boolean) => [
  {key: 'name', label: 'NAME', headerClass: 'min-w-300px'},
  {key: 'description', label: 'DESCRIPTION', headerClass: 'min-w-300px'},
  {
    key: 'columnMapping',
    label: 'COLUMN MAPPING',
    headerClass: 'w-250px',
    show: isValidForStatusMapping,
  },
  {key: 'cards', label: 'CARDS', headerClass: 'w-100px text-center'},
  {key: 'isVisible', label: 'VISIBILITY', headerClass: 'w-100px text-center'},
  {key: 'action', label: 'ACTION', headerClass: 'w-80px text-center'},
]

export const boardModulesTableColumns = [
  {
    key: 'moduleName',
    label: 'NAME',
    headerClass: 'min-w-300px',
  },
  {
    key: 'description',
    label: 'Description',
    headerClass: 'min-w-300px',
  },
  {
    key: 'cards',
    label: 'CARDS',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'isVisible',
    label: 'VISIBILITY',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'action',
    label: 'ACTION',
    headerClass: 'w-80px text-center',
  },
]

export const boardCustomFieldsColumns = (isValidForStatusMapping: boolean) => [
  {
    key: 'customFieldName',
    label: 'NAME',
    headerClass: 'min-w-400px',
  },
  {
    key: 'fieldType',
    label: 'FIELD TYPE',
    headerClass: 'w-150px',
  },
  {
    key: 'customFieldMapping',
    label: 'CUSTOM FIELD MAPPING',
    headerClass: 'w-200px',
    show: isValidForStatusMapping,
  },
  {
    key: 'cardCounts',
    label: 'USED IN CARDS',
    headerClass: 'w-150px text-center',
  },
  {
    key: 'isVisible',
    label: 'VISIBILITY',
    headerClass: 'w-100px text-center',
  },
  {
    key: 'action',
    label: 'ACTION',
    headerClass: 'w-80px text-center',
  },
]

// Default Values

export const projectMappingDefaultValues = (
  projectMappingDetails: any,
  isEdit: boolean = false
) => {
  if (isEdit && isEmpty(projectMappingDetails)) return {}
  return {
    projectType: isEdit ? projectMappingDetails?.projectType || 'standard' : 'standard',
    tableId: isEdit ? projectMappingDetails?.tableId || 0 : 0,
    columnKey: isEdit ? projectMappingDetails?.columnKey || '' : '',
    ticketName: isEdit ? projectMappingDetails?.ticketName || '' : '',
    defaultAssignee: isEdit ? projectMappingDetails?.defaultAssignee || '' : '',
  }
}

export const AddBoardColumnDefaultValues = (columnData: any, isEdit: boolean): any => ({
  name: isEdit ? columnData.columnName : '',
  description: isEdit ? columnData.description : '',
  isVisible: isEdit ? columnData.is_visible : true,
  is_resolved: isEdit ? columnData.isResolved : false,
})

export const AddBoardModuleDefaultValues = (moduleData: any, isEdit: boolean): any => ({
  name: isEdit ? moduleData.moduleName : '',
  description: isEdit ? moduleData.description : '',
  isVisible: isEdit ? moduleData.isVisible : true,
})

export const addBoardCustomFieldsData = (customFieldData: any, isEdit: boolean) => {
  return {
    type: isEdit ? customFieldData?.type : 'global',
    name: isEdit ? customFieldData?.customFieldName : '',
    customfieldMetaIds: [],
    groupValues: isEdit
      ? customFieldData?.groupValues?.split(',').map((value: any) => ({value: value.trim()}))
      : '',
    datatype: isEdit ? customFieldData?.fieldType : '',
  }
}

export const defaultBoardColumnStatusMappingValues = (boardColumnsList: any) => {
  return boardColumnsList?.reduce(
    (acc: any, column: any) => ({
      ...acc,
      [column.columnId]: column.mappedColumnValue,
    }),
    {}
  )
}

export const defaultBoardCustomFieldStatusMappingValues = (boardCustomFieldsList: any) => {
  return boardCustomFieldsList?.reduce(
    (acc: any, customField: any) => ({
      ...acc,
      [customField.id]: customField.mappedColumnValue,
    }),
    {}
  )
}

export const customFieldTypesDropdownList = [
  {label: 'Text', value: 'text'},
  {label: 'Number', value: 'number'},
  {label: 'Date', value: 'date'},
  {label: 'Select', value: 'select'},
  {label: 'Multi Select', value: 'multi select'},
]

// helper functions

export const useChangeProjectMappingValues = (reset: any, formValues: any, isDirty: boolean) => {
  const queryClient = useQueryClient()
  const {projectMappingDetails, isLoading} = useContext(ProjectDetailsContext)
  const {projectType, tableId, columnKey} = formValues
  const isCustomProject = projectType === 'custom'

  useEffect(() => {
    if (!isEmpty(projectMappingDetails) && !isLoading) {
      reset(projectMappingDefaultValues(projectMappingDetails, true))
      queryClient.setQueryData(['projectMappingData'], projectMappingDetails)
    }
  }, [projectMappingDetails, isLoading])

  useEffect(() => {
    if (!isCustomProject && isDirty) {
      reset(projectMappingDefaultValues(projectMappingDetails))
    }
  }, [isCustomProject, isDirty])

  useEffect(() => {
    if (isDirty) {
      queryClient.setQueryData(['projectMappingData'], {tableId, columnKey})
    }
  }, [tableId, columnKey, isDirty])
}

export const formatBoardCustomFieldSubmitData = (data: any, isEdit: boolean) => {
  const isGroup = ['multi select', 'select'].includes(data.datatype)
  const {groupValues, customfieldMetaIds, ...remainingData} = data

  if (data?.type === 'global') {
    return isEdit
      ? {name: data?.name}
      : {
          type: data?.type,
          customfieldMetaIds,
        }
  }

  return {
    ...(isGroup && {
      groupValues: data.groupValues.map((field: any) => field.value).join(','),
    }),
    ...remainingData,
  }
}

// Gantt Chart
export const DAYS_IN_MONTH = 31
export const ROW_HEIGHT = 40
export const GRID_PADDING = 8

export function calculateTaskPosition(startDay: number, durationDays: number) {
  const dayWidth = 100 / DAYS_IN_MONTH
  const left = (startDay - 1) * dayWidth
  const width = durationDays * dayWidth

  return {
    left: `${left}%`,
    width: `${width}%`,
  }
}

export function calculateDayFromOffset(offset: number, dayWidth: number): number {
  const day = Math.round(offset / dayWidth) + 1
  return Math.max(1, Math.min(day, DAYS_IN_MONTH))
}

export const calculateDayWidth = (timelineWidth: number) => {
  return timelineWidth / DAYS_IN_MONTH
}

export const TasksReportTableColumns = [
  {key: 'card_title', label: 'TICKET NAME', isSorted: true, headerStyle: 'min-w-400px'},
  {key: 'project_name', label: 'PROJECT', isSorted: true, headerStyle: 'min-w-200px'},
  {key: 'assignee', label: 'ASSIGNEE', isSorted: true, headerStyle: 'min-w-175px'},
  {key: 'module_name', label: 'MODULE', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'estimation', label: 'ESTIMATION', isSorted: true, headerStyle: 'min-w-120px'},
  {key: 'spent_time', label: 'SPENT TIME', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'remaining_time', label: 'REMAINING TIME', isSorted: true, headerStyle: 'min-w-175px'},
  {
    key: 'progress',
    label: 'PROGRESS',
    isSorted: true,
    headerStyle: 'min-w-80px',
    style: 'flex-end',
  },
]
export const MembersReportTableColumns = [
  {key: 'assignee', label: 'ASSIGNEE', isSorted: true, headerStyle: 'min-w-400px'},
  {key: 'total_cards', label: 'TOTAL TASK', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'total_estimation', label: 'ESTIMATION', isSorted: true, headerStyle: 'min-w-120px'},
  {key: 'total_spent_time', label: 'SPENT TIME', isSorted: true, headerStyle: 'min-w-150px'},
  {
    key: 'total_remaining_time',
    label: 'REMAINING TIME',
    isSorted: true,
    headerStyle: 'min-w-175px',
  },
  {
    key: 'progress_percentage',
    label: 'PROGRESS',
    isSorted: true,
    headerStyle: 'min-w-80px',
    style: 'flex-end',
  },
]

export const TimeSheetReportTableColumns = (meta: any) => {
  if (isEmpty(meta)) return []
  return [
    {
      key: 'customer_name',
      label: 'USERS',
      isSorted: true,
      headerStyle: 'min-w-400px w-400px border-end head-col-sticky',
    },
    {
      key: 'total_spent_time',
      label: 'TOTAL TIME',
      isSorted: false,
      headerStyle: 'min-w-120px w-120px head-col-sticky border-end',
    },
    ...Object?.entries(meta)?.map(([key, value]: any, index, array: any) => {
      const [day, year] = value.split('/ ')
      const month = year.split(' ')[0]
      const prevMonth = index > 0 ? array[index - 1][1].split(' / ')[1].split(' ')[0] : ''
      const monthStart = month !== prevMonth ? `${year}` : ''
      const className = key.includes('S') || day.includes('S') ? 'bg-light' : ''

      return {
        key,
        label: `${monthStart} <br/> ${day}`,
        isSorted: false,
        headerStyle: `min-w-100px ${className}`,
        className,
      }
    }),
  ]
}

// Matrix Page

export const matrixAxisOptions = [
  {label: 'Assignee', value: 'Assignee'},
  {label: 'Module', value: 'Module'},
  {label: 'Priority', value: 'Priority'},
  {label: 'Stage', value: 'Stage'},
]
