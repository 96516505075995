/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import { useForm, Controller } from 'react-hook-form';
import { InquiresPageListingContext } from '../../context';
import useGetInquiriesDetails from '../../hooks/useGetInquiresDetails';
import Loading from '../../../loading';

const InquiresModal = ({
  show,
  onClose,
  inquiryId,
}: {
  show: boolean;
  onClose: () => void;
  inquiryId: string | number;
}) => {
  const { onUpdateStatus, updateLoading, fetchInquiries, fetchLoading } = useGetInquiriesDetails();
  const { refetch, clickedId } = useContext(InquiresPageListingContext);

  const { handleSubmit, reset, setValue, control } = useForm<any>();
  const [inquiryDetails, setInquiryDetails] = useState<{
    productName: string;
    message: string;
    customerName: string;
  }>({
    productName: '',
    message: '',
    customerName: '',
  });

  const hasFetched = useRef(false); // Ref to track if the inquiry has been fetched

  const onSubmit = async (formData: any) => {
    const res: any = await onUpdateStatus(inquiryId, formData.status);
    if (res?.status === 200) {
      refetch();
    }
    reset();
    onClose();
  };

  const cancelCreate = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    const fetchInquiry = async () => {
      if (clickedId && !hasFetched.current) {
        const inquiryData = await fetchInquiries(clickedId);
        if (inquiryData) {
          setInquiryDetails({
            productName: inquiryData.product_name,
            message: inquiryData.message,
            customerName: inquiryData.customer_name,
          });
          setValue('status', inquiryData.status || '');
          hasFetched.current = true; // Mark as fetched
        }
      }
    };

    if (show) {
      reset();
      fetchInquiry();
    } else {
      reset();
      setInquiryDetails({
        productName: '',
        message: '',
        customerName: '',
      });
      hasFetched.current = false; // Reset fetching status when modal is closed
    }
  }, [clickedId, show, setValue, reset]);

  if (!show) {
    return null; // Return null if the modal is not shown
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_inquires'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-md'
      aria-hidden='true'
      onHide={onClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Message From: {inquiryDetails?.customerName || '-'}</h4>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={cancelCreate}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body'>
            <div className='mb-5'>
              <label className='form-label fw-bold'>Product Name:</label>
              <div>
                <p>{inquiryDetails.productName || '-'}</p>
              </div>
            </div>
            <div className='mb-10 border-bottom pb-5'>
              <label className='form-label fw-bold'>Message:</label>
              <div className='mh-275px overflow-auto min-h-100px'>
                <p>{inquiryDetails.message || '-'}</p>
              </div>
            </div>

            {/* Status field with controlled value */}
            <div>
              <label className='form-label'>Status:</label>
              <Controller
                name='status'
                control={control}
                defaultValue='' // ensure default is empty
                render={({field}) => (
                  <select id='status' className='form-select' {...field}>
                    <option value=''>Select Status</option>
                    <option value='New'>New</option>
                    <option value='To Do'>To Do</option>
                    <option value='In Progress'>In Progress</option>
                    <option value='Pending'>Pending</option>
                    <option value='Done'>Done</option>
                    <option value='Archived'>Archive</option>
                  </select>
                )}
              />
            </div>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-outline btn-active-light-primary'
              onClick={cancelCreate}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary' disabled={updateLoading}>
              {updateLoading ? 'Updating...' : 'Save'}
              {updateLoading && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </button>
          </div>
        </form>
        {fetchLoading && <Loading />}
      </div>
    </Modal>
  )
};

export { InquiresModal };
