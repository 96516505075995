import {createRoot} from 'react-dom/client'
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {MetronicI18nProvider} from './i18n/Metronici18n'
import {AppRoutes} from './routes/AppRoutes'
import {AuthProvider, setupAxios} from './modules/auth'
import './sass/plugins.scss'
import './sass/style.scss'
import './sass/style.react.scss'
import {Provider} from 'react-redux'
import store, {persistor} from './redux/store'
import {PersistGate} from 'redux-persist/lib/integration/react'
import {ToastContainer} from 'react-toastify'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AppRoutes />
            </PersistGate>
            <ToastContainer
              position='top-right'
              className='toastify_container'
              toastClassName='toastify_root'
              bodyClassName='toastify_body'
              hideProgressBar
              closeButton={false}
              closeOnClick={false}
            />
          </Provider>
        </AuthProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
