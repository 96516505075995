/* eslint-disable */
import {useEffect} from 'react'
import {$isCodeNode} from '@lexical/code'
import {TOGGLE_LINK_COMMAND} from '@lexical/link'
import {mergeRegister} from '@lexical/utils'
import {
  $getRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import {sanitizeUrl} from '../../../utils/url'

const useToolbarEffects = (
  editor: LexicalEditor,
  activeEditor: LexicalEditor,
  isLink: boolean,
  setIsLinkEditMode: (isLinkEditMode: boolean) => void,
  setActiveEditor: (activeEditor: LexicalEditor) => void,
  setIsMarkdown: (isMarkdown: boolean) => void,
  setIsEditable: (isEditable: boolean) => void,
  setCanUndo: (canUndo: boolean) => void,
  setCanRedo: (canRedo: boolean) => void,
  $updateToolbar: () => void,
  setIsHtmlMode: (isHtmlMode: boolean) => void,
) => {
  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor)
        $updateToolbar()
        return false
      },
      COMMAND_PRIORITY_CRITICAL
    )
  }, [editor, $updateToolbar])

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      $updateToolbar()
    })
  }, [activeEditor, $updateToolbar])

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable)
      }),
      activeEditor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [$updateToolbar, activeEditor, editor])

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload
        const {code, ctrlKey, metaKey} = event

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault()
          let url: string | null
          if (!isLink) {
            setIsLinkEditMode(true)
            url = sanitizeUrl('https://')
          } else {
            setIsLinkEditMode(false)
            url = null
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url)
        }
        return false
      },
      COMMAND_PRIORITY_NORMAL
    )
  }, [activeEditor, isLink, setIsLinkEditMode])

  useEffect(() => {
    return editor.registerUpdateListener(({editorState}) => {
      editorState.read(() => {
        const root = $getRoot()
        const firstChild = root.getFirstChild()
        setIsMarkdown($isCodeNode(firstChild) && firstChild.getLanguage() === 'markdown')
      })
    })
  }, [editor])

  useEffect(() => {
    return editor.registerUpdateListener(({editorState}) => {
      editorState.read(() => {
        const root = $getRoot()
        const firstChild = root.getFirstChild()
        if (firstChild) {
          const content = firstChild.getTextContent()
          const isHtml = content.trim().startsWith('<') && content.trim().endsWith('>')
          setIsHtmlMode(isHtml)
        }
      })
    })
  }, [editor, setIsHtmlMode])
}

export default useToolbarEffects
