export const convertInputToReadableFormat:any = (input: string, days:any, hours:any) => {
    const regex = /(\d+)([wdhm]?)/g
    let totalMinutes = 0
    let match
    while ((match = regex.exec(input)) !== null) {
      const value = parseInt(match[1])
      const unit = match[2] || 'h'

      switch (unit) {
        case 'w':
          totalMinutes += value * days * hours * 60
          break
        case 'd':
          totalMinutes += value * hours * 60
          break
        case 'h':
          totalMinutes += value * 60
          break
        case 'm':
          totalMinutes += value
          break
        default:
          break
      }
    }

    const weeks = Math.floor(totalMinutes / (days * hours * 60))
    const remainingMinutes = totalMinutes % (days * hours * 60)
    const day = Math.floor(remainingMinutes / (hours * 60))
    const remainingMinutesAfterDays = remainingMinutes % (hours * 60)
    const hoursy = Math.floor(remainingMinutesAfterDays / 60)
    const minutes = remainingMinutesAfterDays % 60

    const formattedString = `${weeks > 0 ? weeks + 'w ' : ''}${day > 0 ? day + 'd ' : ''}${
      hoursy > 0 ? hoursy + 'h ' : ''
    }${minutes > 0 ? minutes + 'm' : ''}`

    return formattedString.trim()
  }