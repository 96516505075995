interface UserActionDropdownProps {
  onEdit: any
  handleModel: any
}

export default function HideProductActionDropdown(props: UserActionDropdownProps) {
  const {onEdit, handleModel} = props

  return (
    <div className=''>
      <div className='px-4 py-2' onClick={onEdit}>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>
      <div className='px-4 py-2'>
        <span className='text-dark fw-bold text-hover-primary fs-6'>
          <label onClick={handleModel}>Delete</label>
        </span>
      </div>
    </div>
  )
}
