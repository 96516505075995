import {useEffect} from 'react'
import {useQuery} from 'react-query'
import useFilters from '../../../hook/useFilters'
import {getAllDefaultColumns} from '../services'

const initialFilters = {
  type: 'product_inquiries',
}

const useGetColumnsList = () => {
  const {filters} = useFilters(initialFilters)

  const {
    data: globalDefaultColumnsList,
    isFetching,
    refetch,
  } = useQuery(`get-all-default-columns`, () => {
    return getAllDefaultColumns(filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  return {
    globalDefaultColumnsList: globalDefaultColumnsList?.['columns']
      ? globalDefaultColumnsList['columns']
      : [],
    isLoadingGlobalDefaultColumnsList: isFetching,
    refetchGlobalDefaultColumnsList: refetch,
  }
}

export default useGetColumnsList
