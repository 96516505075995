import useApi from '../../../../services/useApi'
import {useContext} from 'react'
import {ProjectDetailsContext} from '../../context'

export const useGetDropdownMembers = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/${projectId}/dropdown/members`,
    {
      queryId: 'projects-members-dropdown-list',
    }
  )

  return {
    membersList: response?.data || [],
    isLoading: isFetching,
  }
}
