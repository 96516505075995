import ProjectsMembersPageHeader from '../sections/headers/ProjectsMembersPageHeader'
import {Pagination} from '../../../utils/pagination'
import {ProjectsMembersPageContext} from '../context'
import GlobalProjectMembersTable from '../sections/tables/GlobalProjectMembersTable'
import {useGetGlobalProjectsMembersList} from '../hooks/members/useGetGlobalProjectsMembersList'
import useGlobalProjectsMembersOperations from '../hooks/members/useGlobalProjectsMembersOperations'
import {useGetGlobalProjectsMembersDropdownList} from '../hooks/members/useGetGlobalProjectsMembersDropdownList'
import usePermission from '../../../hook/usePermission'

export default function ProjectsMembers() {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {globalProjectsMembersDropdownList, isLoading: isLoadingGlobalProjectsMembersDropdownList} =
    useGetGlobalProjectsMembersDropdownList()
  const {
    globalProjectsMembersList,
    isLoading: isLoadingGlobalProjectsMembersList,
    pagination,
    onPageChange,
    onSearch,
    searchValue,
    filters,
    onSortingChange,
  } = useGetGlobalProjectsMembersList()
  const {
    addGlobalProjectMember,
    removeGlobalProjectMember,
    isLoadingGlobalProjectsMembersOperation,
  } = useGlobalProjectsMembersOperations()

  const contextValue = {
    hasProjectSettingsPermission,
    globalProjectsMembersDropdownList: globalProjectsMembersDropdownList,
    isLoadingGlobalProjectsMembersDropdownList: isLoadingGlobalProjectsMembersDropdownList,

    globalProjectsMembersList: globalProjectsMembersList,
    isLoadingGlobalProjectsMembersList: isLoadingGlobalProjectsMembersList,
    onSearch: onSearch,
    searchValue,
    filters,
    onSortingChange,

    addGlobalProjectMember: addGlobalProjectMember,
    removeGlobalProjectMember: removeGlobalProjectMember,
    isLoadingGlobalProjectsMembersOperation: isLoadingGlobalProjectsMembersOperation,
  }

  return (
    <ProjectsMembersPageContext.Provider value={contextValue}>
      <ProjectsMembersPageHeader />
      <GlobalProjectMembersTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </ProjectsMembersPageContext.Provider>
  )
}
