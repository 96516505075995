import clsx from 'clsx'
import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {AsideUserMenu} from '../../../partials'
import {useThemeMode} from '../../../partials/layout/theme-mode/ThemeModeProvider'
import {useState, useEffect} from 'react'
import {useAppSelector} from '../../../../redux/useTypedSelector'
import {dataToSideBarStoreList} from '../../../../utils/common'
import StoreListModal from './StoreListModal'
import { toSnakeCase } from '../../../../modules/analyticsReplenishment/utils'

const AsideDefault = () => {
  const {classes} = useLayout()
  const {mode} = useThemeMode()
  const [isToggled, setIsToggled] = useState(false)

  const {user} = useAppSelector((state) => state.auth)
  const stores = dataToSideBarStoreList(user['stores'], user['default_store'])
  const activeStoreId = localStorage.getItem('x-store-id')

  const handleToggleClick = () => {
    setIsToggled(!isToggled)
  }
  const [isStoreListVisible, setIsStoreListVisible] = useState(false)
  const [activeStoreName, setActiveStoreName] = useState('')

  useEffect(() => {
    const storeName = stores.find((store: any) => store['storeId'] === activeStoreId)['name']
    const storeKey = `is_supplier_${toSnakeCase(storeName)}`
    localStorage.setItem('is_supplier', localStorage.getItem(storeKey) || 'false')
    setActiveStoreName(storeName)
    // eslint-disable-next-line
  }, [])

  return (
    <div
      id='kt_aside'
      className={clsx('aside py-9', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      // data-kt-drawer-toggle='#kt_aside_toggle'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      <button
        id='kt_toggle_example_basic'
        className='sidebar-toggle-button'
        data-kt-toggle='true'
        data-kt-toggle-state='active'
        data-kt-toggle-target='#kt_toggle_navigation'
        data-kt-toggle-name='toggle-name'
        onClick={handleToggleClick}
      >
        <i className='bi bi-arrow-left-short'></i>
      </button>

      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto px-7 mb-9' id='kt_aside_logo'>
        {/* begin::Logo */}
        <div>
          {mode === 'light' && (
            // <img
            //   alt='Logo'
            //   className='h-20px logo theme-light-show'
            //   src={toAbsoluteUrl('/media/logos/demo3.svg')}
            // />
            <>
              <h2 className='fw-bolder aside-default-logo'>{activeStoreName}</h2>
              {stores && stores.length > 1 && (
                <p
                className='fs-6 fw-bold text-primary mb-0 text-underline d-flex align-items-center cursor-pointer app-workspace-switch'
                onClick={() => setIsStoreListVisible(true)}
              >
                Switch Workspace
                <i className="las la-random ms-2 fs-4 text-primary fw-bold"></i>
              </p>  
              )}
              
              <h2 className='fw-bolder aside-toggle-logo'>MA</h2>
            </>
          )}

          {mode === 'dark' && (
            <img
              alt='Logo'
              className='h-20px logo theme-dark-show'
              src={toAbsoluteUrl('/media/logos/demo3-dark.svg')}
            />
          )}
        </div>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div id='kt_aside_menu' className='aside-menu flex-column-fluid ps-5 pe-5 mb-9'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} isToggleActive={isToggled} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto px-9' id='kt_aside_footer'>
        <AsideUserMenu />
      </div>
      {/* end::Footer */}
      <StoreListModal
        show={isStoreListVisible}
        onClose={() => setIsStoreListVisible(false)}
        stores={stores}
        setActiveStoreName={setActiveStoreName}
      />
    </div>
  )
}

export {AsideDefault}
