import { useContext } from 'react'
import OverlayComponent from '../../../_metronic/layout/components/Popover'
import {InquiresModal} from './modal/InquiresModal'
import { DropdownListing } from '../components/DropdownListing'
import { InquiresPageListingContext } from '../context'

const OverlayModal = ({row}: any) => {
const {setOpenModal, openModal, clickedId} = useContext(InquiresPageListingContext)
const handleModalOpen = () => {
  setOpenModal(true)
  document.body.click()
}

const onClose = () => {
  setOpenModal(false)
}

return (
  <>
    <OverlayComponent
      btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
      children={
        <DropdownListing inquiryId={row?.id} status={row?.status} handleModel={handleModalOpen} />
      }
    />

    <InquiresModal
      show={openModal && clickedId === row?.id}
      onClose={onClose}
      inquiryId={clickedId}
    />
  </>
)
}

export default OverlayModal
