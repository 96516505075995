import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'

const initialFilters = {
  search: true,
}

const useGetGlobalCustomFieldsList = () => {
  const Api = useApi()
  const {filters, onSearch, searchValue} = useFilters(initialFilters)

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/custom-fields`, {
    queryId: 'global-project-custom-fields',
    filters: filters,
  })

  return {
    globalCustomFieldsList: response?.data || [],
    isLoading: isFetching,
    onSearch,
    searchValue,
  }
}

export default useGetGlobalCustomFieldsList
