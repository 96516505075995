/* eslint-disable react-hooks/exhaustive-deps*/
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {SectionForm} from '../../../../components/SectionForm'
import {InputText} from '../../../../components/InputText'
import TextEditor from '../../../../components/lexicalTextEdiror/TextEditor'
import {useContext, useEffect, useState} from 'react'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {LinkTicketModalTypes} from '../../interface'
import {createTFromTValidationSchema} from '../../validations'
import {
  createTFromTDefaultValues,
  filterLinkedTickets,
  onTicketSourceChange,
  setLinkedTicketId,
} from '../../utils'
import {TicketDetailsPageContext} from '../../context'

function LinkTicketModal({show, onClose, menuEvent}: LinkTicketModalTypes) {
  const {ticketDetails} = useContext(TicketDetailsPageContext)
  const {projectMembers, isLoadingProjectMembers} = useContext(TicketDetailsPageContext)
  const {projectKanbanStages, isLoadingProjectKanbanStages} = useContext(TicketDetailsPageContext)
  const {projectModules, isLoadingProjectModules} = useContext(TicketDetailsPageContext)
  const {projectStatuses, isLoadingProjectStatuses} = useContext(TicketDetailsPageContext)
  const {projectPriorities, isLoadingProjectPriorities} = useContext(TicketDetailsPageContext)
  const {projectTickets, isLoadingProjectTickets} = useContext(TicketDetailsPageContext)
  const {createTicket, updateTicket, isLoadingTicketOperation, isLoadingTicketDetails} =
    useContext(TicketDetailsPageContext)

  const [isEditorValid, setIsEditorValid] = useState<boolean>(true)

  const defaultValues = createTFromTDefaultValues(
    projectKanbanStages,
    projectModules,
    projectStatuses,
    projectPriorities,
    ticketDetails
  )

  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isValid, isDirty, dirtyFields},
    setError,
    setValue,
    reset,
    watch,
  } = useForm<any>({
    defaultValues: defaultValues,
    resolver: yupResolver(createTFromTValidationSchema(menuEvent)),
    mode: 'onChange',
  })

  const isSourceNew = watch('ticketSource') === 'new'

  const onCreate = (data: any) => {
    const {ticketSource, moduleId, ...payload} = data
    if (!isEditorValid) return
    if (ticketSource === 'new') {
      createTicket(moduleId, payload, setError)
    } else {
      updateTicket(data, dirtyFields, setError)
    }
  }

  useEffect(() => {
    setLinkedTicketId(isSourceNew, menuEvent, ticketDetails, setValue)
  }, [isSourceNew])

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      dialogClassName={isSourceNew ? 'modal-xl' : ''}
      onAction={handleSubmit(onCreate)}
      actionName='Create'
      actionBtnClass='btn-primary'
      isOperationLoading={isLoadingTicketOperation}
      isDataLoading={isLoadingTicketDetails}
      title={
        <span className='d-flex align-items-center gap-2'>
          Assign{' '}
          {menuEvent ? (menuEvent === 'parent-ticket' ? 'Parent Ticket' : 'Sub Ticket') : 'Ticket'}{' '}
          to <span className='badge badge-light fs-5'>#{ticketDetails?.card_identifier}</span>
        </span>
      }
      isDisabled={
        isLoadingProjectKanbanStages ||
        isLoadingProjectModules ||
        !isValid ||
        !isDirty ||
        !isEditorValid
      }
      body={
        <>
          <SectionForm
            id='link-type'
            commonClassNames='mb-10'
            register={register}
            control={control}
            inputs={[
              {
                id: 'ticket-source',
                isRadio: true,
                name: 'ticketSource',
                class: 'd-flex gap-5',
                registerKey: 'ticketSource',
                onChange: (e: any) => onTicketSourceChange(e.target.value, reset, defaultValues),
                children: [
                  {label: 'Existing Ticket', value: 'existing'},
                  {label: 'New Ticket', value: 'new'},
                ],
              },
              {
                id: 'existing-tickets',
                isReachSelect: true,
                options: filterLinkedTickets(projectTickets, ticketDetails, menuEvent),
                labelKey: 'title',
                valueKey: 'tickedID',
                valueType: 'number',
                passAllOptionData: true,
                isLoading: isLoadingProjectTickets,
                show: !isSourceNew,
                registerKey: menuEvent === 'parent-ticket' ? 'parentCardId' : 'childCardId',
                getOptionLabel: (data: any) => (
                  <div className='d-flex align-items-center gap-2'>
                    <span className='badge badge-light fs-6'>#{data?.card_identifier}</span>
                    <span>{data?.title}</span>
                  </div>
                ),
              },
            ]}
          />
          {isSourceNew && (
            <>
              <InputText
                id='ticket-title'
                label='Ticket Name'
                className='mb-10'
                isRequired={true}
                register={register('title')}
                placeholder='Enter Ticket Name'
                error={errors?.title}
                maxLength={255}
              />
              <div className='row gx-5'>
                <div className='col modal-ticket-detail-maincontent w-100'>
                  <TextEditor
                    id='description'
                    control={control}
                    registerKey='description'
                    inputClass='min-h-350px'
                    setIsValid={setIsEditorValid}
                    maxLength={62500}
                  />
                </div>
                <div className='col modal-ticket-detail-sidebar w-300px mw-300px'>
                  <div className='border border rounded-3 p-6 pt-6 pb-3 position-relative position-relative bg-light bg-opacity'>
                    <SectionForm
                      id='create-ticket-status'
                      className='mb-10'
                      commonClassNames='mb-6'
                      commonLabelClass='w-100'
                      commonInputClass='col'
                      register={register}
                      control={control}
                      inputs={[
                        {
                          id: 'assignee',
                          label: 'Assignee',
                          isReachSelect: true,
                          options: projectMembers,
                          isLoading: isLoadingProjectMembers,
                          labelKey: 'name',
                          valueKey: 'username',
                          registerKey: 'assignedTo',
                        },
                        {
                          id: 'kanban-stage',
                          label: 'Kanban Stage',
                          isReachSelect: true,
                          options: projectKanbanStages,
                          isLoading: isLoadingProjectKanbanStages,
                          registerOptions: {valueAsNumber: true},
                          valueType: 'number',
                          labelKey: 'name',
                          valueKey: 'column_id',
                          registerKey: 'currentColumnId',
                        },
                        {
                          id: 'module',
                          label: 'Module',
                          isReachSelect: true,
                          options: projectModules,
                          isLoading: isLoadingProjectModules,
                          labelKey: 'name',
                          valueKey: 'module_id',
                          valueType: 'number',
                          registerKey: 'moduleId',
                        },
                        {
                          id: 'status',
                          label: 'Status',
                          isSelect: true,
                          options: projectStatuses,
                          isLoading: isLoadingProjectStatuses,
                          registerOptions: {valueAsNumber: true},
                          labelKey: 'label',
                          valueKey: 'id',
                          registerKey: 'status',
                        },
                        {
                          id: 'priority',
                          label: 'Priority',
                          isSelect: true,
                          options: projectPriorities,
                          isLoading: isLoadingProjectPriorities,
                          registerOptions: {valueAsNumber: true},
                          labelKey: 'label',
                          valueKey: 'id',
                          registerKey: 'priority',
                        },
                        {
                          id: 'estimation-time',
                          label: 'Estimation',
                          isInputTime: true,
                          registerKey: 'estimation',
                        },
                        {
                          id: 'due-date',
                          label: 'Due Date',
                          isInputDate: true,
                          isNullable: true,
                          registerKey: 'dueDate',
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      }
    />
  )
}

export default LinkTicketModal
