import {useContext} from 'react'
import {AgileBoardPageContext, ProjectDetailsContext} from '../context'
import {useGetAgileBoardStructure} from '../hooks/agileBoard/useGetAgileBoardStructure'
import {useGetAgileBoardData} from '../hooks/agileBoard/useGetAgileBoardData'
import useUpdateAgileBoard from '../hooks/agileBoard/useUpdateAgileBoard'
import useAgileBoardOperations from '../hooks/agileBoard/useAgileBoardOperations'
import {AgileBoardPageHeader} from '../sections/headers/AgileBoardPageHeader'
import useGetProjectKanbanStages from '../hooks/agileBoard/useGetProjectKanbanStages'
import useGetProjectModules from '../hooks/agileBoard/useGetProjectModules'
import useGetProjectStatuses from '../hooks/agileBoard/useGetProjectStatuses'
import useGetProjectPriorities from '../hooks/agileBoard/useGetProjectPriorities'
import useGetProjectMembers from '../hooks/agileBoard/useGetProjectMembers'
import KanbanBoard from '../sections/KanbanBoard'

function AgileBoard() {
  const {projectId, projectName, projectStatus, hasProjectPermission} =
    useContext(ProjectDetailsContext)
  const {agileBoardStructure, isLoading: isLoadingBoardStructure} = useGetAgileBoardStructure()
  const {
    agileBoardData,
    onSearch,
    isLoading: isLoadingAgileBoardData,
    refetch: refetchAgileBoardData,
    filtersData,
  } = useGetAgileBoardData()
  const {projectMembers, isLoading: isLoadingProjectMembers} = useGetProjectMembers()
  const {projectKanbanStages, isLoading: isLoadingProjectKanbanStages} = useGetProjectKanbanStages()
  const {projectModules, isLoading: isLoadingProjectModules} = useGetProjectModules()
  const {projectStatuses, isLoading: isLoadingProjectStatuses} = useGetProjectStatuses()
  const {projectPriorities, isLoading: isLoadingProjectPriorities} = useGetProjectPriorities()
  const {
    createTicket,
    updateTicket,
    isLoading: isLoadingTicketOperation,
  } = useAgileBoardOperations()
  const {updateAgileBoard, isLoading: isUpdatingAgileBoard} = useUpdateAgileBoard()

  const contextValue = {
    hasBoardPermission: hasProjectPermission,

    projectId,
    projectName,
    projectStatus,

    agileBoardStructure,
    isLoadingBoardStructure,

    agileBoardData,
    filtersData,
    refetchAgileBoardData,
    isLoadingAgileBoardData,
    onSearch,

    projectMembers,
    isLoadingProjectMembers,

    projectKanbanStages,
    isLoadingProjectKanbanStages,

    projectModules,
    isLoadingProjectModules,

    projectStatuses,
    isLoadingProjectStatuses,

    projectPriorities,
    isLoadingProjectPriorities,

    createTicket,
    updateTicket,
    isLoadingTicketOperation,

    updateAgileBoard,
    isUpdatingAgileBoard,
  }

  return (
    <AgileBoardPageContext.Provider value={contextValue}>
      <AgileBoardPageHeader />
      <KanbanBoard />
    </AgileBoardPageContext.Provider>
  )
}

export default AgileBoard
