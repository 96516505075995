export interface NoDataFoundProps {
  colspan: number
  message?: string
}

const NoDataFound: React.FC<NoDataFoundProps> = ({colspan, message}) => {
  return (
    <tr>
      <td colSpan={colspan} className='text-center'>
        <div>
          <p className='my-10 fw-bold'>{message ? message : 'No records available to display.'}</p>
        </div>
      </td>
    </tr>
  )
}

export default NoDataFound