import {useContext} from 'react'
import {ProjectsDefaultColumnsPageContext} from '../../context'
import { DefaultColumnActionsDropdownTypes } from '../../interfaces'

const DefaultColumnActionsDropdown = ({setShowModal, setShowDeleteModal}: DefaultColumnActionsDropdownTypes) => {
  const {globalDefaultColumnsList} = useContext(ProjectsDefaultColumnsPageContext)
  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setShowDeleteModal(true)
    document.body.click()
  }
  return (
    <div className=' d-flex align-items-start flex-column'>
      <span className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer' onClick={() => handleEditClick()}>
        Edit
      </span>
      {globalDefaultColumnsList?.length > 1 && (
        <span onClick={() => handleDeleteClick()} className='text-dark fw-bold text-hover-danger fs-6 px-2 py-1 cursor-pointer'>
          Delete
        </span>
      )}
    </div>
  )
}

export default DefaultColumnActionsDropdown
