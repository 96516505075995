import OverlayComponent from '../../../../../_metronic/layout/components/Popover'
import ProjectsTableActionDropdown from '../actionDropdowns/ProjectsTableActionDropdown'

const ProductLimitReportTableActionsOverlay = ({id,name}:any) => {
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={<ProjectsTableActionDropdown id={id} name={name}/>}
      />
    </>
  )
}

export default ProductLimitReportTableActionsOverlay
