import {useContext} from 'react'
import {ProductLimitReportContext} from '../../contexts'
import ProductLimitReportTableActionsOverlay from '../modals/overlays/ProductLimitReportTableActionsOverlay'
import ProductLimitReportTableRow from './ProductLimitReportTableRow'
import {DynamicTable} from '../../../../components/DynamicTable'
import {parseProductRowData} from '../../utils'

const sortableColumns = [
  {key: 'name', label: 'Product Name', headerStyle: 'min-w-400px', isSorted: true},
  {key: 'sku', label: 'SKU', headerStyle: 'min-w-150px', isSorted: true},
  {key: 'inventory_level', label: 'Inventory', headerStyle: 'min-w-150px', isSorted: true},
  {
    key: 'order_quantity_minimum',
    label: 'Min Purchase Qty',
    headerStyle: 'min-w-200px',
    isSorted: true,
  },
  {
    key: 'order_quantity_maximum',
    label: 'Max Purchase Qty',
    headerStyle: 'min-w-200px',
    isSorted: true,
  },
  {key: 'availability_description', label: 'Availability', headerStyle: 'min-w-300px'},
  {
    key: 'action',
    label: 'Action',
    headerStyle: 'w-80px text-center',
    render: (row: any) => (
      <ProductLimitReportTableActionsOverlay id={row?.productId} name={row?.productName} />
    ),
  },
]

const ProductLimitReportTable = () => {
  const {products, isLoading, filters, onSortingChange} = useContext(ProductLimitReportContext)

  return (
    <DynamicTable
      id='products-limit-report-table'
      data={products}
      sortableColumns={sortableColumns}
      TableRow={ProductLimitReportTableRow}
      loading={isLoading}
      filters={filters}
      onSortingChange={onSortingChange}
      permissionPath={'analytics_product limit report'}
      rowParsingFunction={parseProductRowData}
    />
  )
}

export default ProductLimitReportTable
