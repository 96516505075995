import {useContext, useState} from 'react'
import {TasksReportPageContext} from '../../context'
import {SideFilter} from '../../../../components/SideFilter'
import useSaveFilter from '../../hooks/useSaveFilter'

const TasksReportHeader = () => {
  const {
    filtersData,
    refetch,
    isLoading: loading,
  } = useContext(TasksReportPageContext)
  const {isOperationLoading, onSaveFilter} = useSaveFilter(refetch)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div></div>
      <div>
        <SideFilter
          filterData={filtersData}
          onApply={onSaveFilter}
          isOperationLoading={isOperationLoading || loading}
          onCancel={() => setIsDrawerOpen(false)}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          filterType={'individual_project_task_report'}
        />
      </div>
    </div>
  )
}

export default TasksReportHeader
