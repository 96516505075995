import {useQuery} from 'react-query'
import {getRepresantetives} from '../../customers/services'
import {formatToLabelValue} from '../utils'

export const useGetRepresantetives = (): any => {
  const {data: response, isFetching} = useQuery(`get-Customers-Rep-Inquires`, () => {
    return getRepresantetives(`/products/inquiries/customer/reps`)
  })

  return {
    Representatives: formatToLabelValue(response),
    loading: isFetching,
  }
}