import {useState} from 'react'
import useApi from '../../../../services/useApi'

export default function useGlobalProjectsMembersOperations() {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  async function addGlobalProjectMember(globalProjectMemberDetails: any) {
    return Api.post(`/agile/projects/members`, globalProjectMemberDetails, {
      successMessage: 'Member added successfully',
      setLoading: setIsLoading,
      refetch: ['global-projects-members', 'global-project-members-dropdown-list'],
    })
  }

  async function removeGlobalProjectMember(globalMemberUsername: string) {
    return Api.delete(
      `/agile/projects/members/${globalMemberUsername}`,
      {},
      {
        successMessage: 'Member removed successfully',
        setLoading: setIsLoading,
        refetch: ['global-projects-members', 'global-project-members-dropdown-list'],
      }
    )
  }

  return {
    addGlobalProjectMember,
    removeGlobalProjectMember,
    isLoadingGlobalProjectsMembersOperation: isLoading,
  }
}
