/* eslint-disable */
import {DatePicker} from 'rsuite'
import InputDateTypes from './inputDateTypes'
import {isEmpty} from '../../utils/common'
import {useEffect, useState, useRef} from 'react'
import {Controller} from 'react-hook-form'
import {formatDate} from '../../utils/date'

function convertToUTC(dateString: string) {
  const date = new Date(dateString)
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function convertToTimestamp(datetime: any) {
  if (typeof datetime !== 'string') {
    return datetime
  }
  const [datePart, timePart] = datetime.split(' ')
  const [year, month, day] = datePart.split('-').map(Number)
  const [hour, minute, second] = timePart.split(':').map(Number)
  const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second))
  return Math.floor(date.getTime() / 1000)
}

const InputDate = ({
  id,
  className = '',
  inputClass = '',
  labelClass = '',
  isRequired = false,
  label,
  onChange,
  format = 'dd MMM yyyy',
  menuClassName = 'input-date-picker',
  placement = 'auto',
  error,
  errorClass,
  cleanable = true,
  value,
  control,
  registerKey,
  defaultValue,
  placeholder = 'DD MMM YYYY',
  isLoading,
  isDisabled,
  isReadOnly,
  onBlur,
  rules,
  floatingElement = true,
  autoFocus = false,
  disableFutureDate = false,
  ...rest
}: InputDateTypes) => {
  const [selectedTime, setSelectedTime] = useState<Date | null>(() => {
    if (!isEmpty(value) && value !== 'None') {
      const dateValue = new Date(value)
      return dateValue
    }
    return null
  })
  const [registeredValue, setRegisteredValue] = useState<any>(null)
  const datePickerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const getPreservedDate = (dateStr: any) => {
      if (!isEmpty(dateStr) && dateStr !== 'None') {
        const formattedDate = formatDate(convertToTimestamp(dateStr), false)
        return new Date(formattedDate)
      }
      return null
    }

    setSelectedTime(
      !isEmpty(registeredValue) && registeredValue !== 'None'
        ? getPreservedDate(registeredValue)
        : !isEmpty(value) && value !== 'None'
        ? getPreservedDate(value)
        : null
    )
  }, [value, registeredValue])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef?.current && !datePickerRef?.current?.contains(event?.target as Node)) {
        onBlur?.(selectedTime)
        setIsOpen(false)
      }
    }
    document?.addEventListener('mousedown', handleClickOutside)
    return () => {
      document?.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onBlur, selectedTime])

  useEffect(() => {
    if (!autoFocus) return
    const timeoutId = setTimeout(() => {
      const inputElement = datePickerRef.current?.querySelector('input')
      if (inputElement && !isDisabled && !isReadOnly) {
        inputElement.focus()
        setIsOpen(true)
      }
    }, 0)
    return () => clearTimeout(timeoutId)
  }, [isDisabled, isReadOnly, autoFocus])

  const handleDateChange = (time: Date | null, onChange?: (value: any) => void) => {
    setSelectedTime(time)
    if (!time) {
      onChange?.(null)
      return
    }
    const utcDate = convertToUTC(time.toISOString())
    onChange?.(utcDate)
    setIsOpen(false)
  }

  const getStartOfDay = (date: Date) => {
    const normalized = new Date(date)
    normalized.setHours(0, 0, 0, 0)
    return normalized.getTime()
  }

  const renderDatePicker = (onChange?: (value: any) => void) => (
    <DatePicker<any>
      id={id}
      className={`ad-date-picker d-flex w-100 z-index-0 position-relative px-0 ${inputClass}`}
      placement={placement}
      menuClassName={menuClassName}
      format={format}
      cleanable={cleanable}
      onChange={(time) => handleDateChange(time, onChange)}
      value={selectedTime}
      defaultValue={
        !isEmpty(defaultValue) && defaultValue !== 'None'
          ? (() => {
              const dateValue = new Date(defaultValue)
              dateValue.setMinutes(dateValue.getMinutes() + dateValue.getTimezoneOffset())
              return dateValue
            })()
          : null
      }
      placeholder={placeholder}
      disabled={isDisabled}
      readOnly={isLoading || isReadOnly}
      container={!floatingElement ? () => datePickerRef.current as HTMLElement : undefined}
      open={autoFocus ? isOpen : undefined}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      shouldDisableDate={(date) => {
        if (!disableFutureDate) return false
        return getStartOfDay(date) > getStartOfDay(new Date())
      }}
      {...rest}
    />
  )

  return (
    <div className={className} id={id}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <style>
        {isLoading &&
          `#${id} .rs-stack-item {
            .rs-icon{
                display: none;
              }
            }
          `}
      </style>
      <div className='position-relative' ref={datePickerRef}>
        {registerKey && control ? (
          <Controller
            name={registerKey}
            control={control}
            rules={rules}
            render={({field: {onChange, value}}) => {
              setRegisteredValue(value)
              return renderDatePicker(onChange)
            }}
          />
        ) : (
          renderDatePicker(onChange)
        )}

        {isLoading && (
          <div className='form-field-loader'>
            <span className='text-primary spinner-border spinner-border-sm align-middle z-index-1 bg-white'></span>
          </div>
        )}

        {error && error?.message ? (
          <p className={`invalid-feedback ${errorClass}`}>{error.message}</p>
        ) : null}
      </div>
    </div>
  )
}

export default InputDate
