import React, {useState} from 'react'
import {formatPrice} from '../../../../../utils/common'
import CostumersListModal from '../../modal/CostumersListModal'
import Date from '../../../../../components/Date/Date'

const SalesRepTableRow = ({row}: {row: any}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <tr>
      <td>{row.rep_name}</td>
      <td>
        <div className='text-center'>
          <span
            className={`${
              row.total_customers !== 0
                ? 'cursor-pointer text-decoration-underline text-hover-primary'
                : ''
            }`}
            onClick={() => row.total_customers !== 0 && setShowModal(true)}
          >
            {row.total_customers}
          </span>
        </div>
      </td>
      <td className='text-center'>{row.total_orders}</td>
      <td>{formatPrice(row.total_order_value, false)}</td>
      <td>
        <Date date={row?.last_order_date} />
      </td>
      {showModal && (
        <CostumersListModal
          show={showModal}
          onClose={() => setShowModal(false)}
          repEmail={row?.rep_email}
        />
      )}
    </tr>
  )
}

export default SalesRepTableRow
