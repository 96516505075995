/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'
import {useGetProjectsDropdownList} from '../useGetProjectsDropdownList'

const initialFilters = {
  page: 1,
  limit: 30,
  sort_by: 'assignee/1',
  project_id: '',
  report_type:'project_members_report'
}

const useGetMembersReportData = () => {
  const Api = useApi()
  const {isLoading, projectsDropdownList} = useGetProjectsDropdownList()

  const {filters, onSearch, onSortingChange, searchValue, setMultipleFilters, onPageChange} =
    useFilters(initialFilters, {
      sortType: 'number',
    })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(
    `/agile/projects/members-report`,
    {
      queryId: 'members-report',
      filters: filters,
    },
    {
      enabled: !isLoading && projectsDropdownList.length !== 0,
    }
  )

  const onProjectChange = (id: any) => {
    setMultipleFilters({
      project_id: id,
      page: 1,
    })
  }

  useEffect(() => {
    onProjectChange(projectsDropdownList.map((item: {id: string}) => item.id).join(','))
  }, [isLoading])

  return {
    membersReportData: response?.data?.members_report?.data || [],
    pagination: response?.data?.members_report?.meta?.pagination || {},
    filtersData: response?.data?.filters || [],
    filters,
    isLoading: isFetching || isLoading,
    onSortingChange,
    onSearch,
    onProjectChange,
    searchValue,
    onPageChange,
    refetch
  }
}

export default useGetMembersReportData
