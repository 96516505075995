import useApi from '../../../services/useApi'
import useFilters from '../../../hook/useFilters'

const useGetTasksReportsData = (email: any, projectIds: any) => {
  const initialFilters = {
    page: 1,
    limit: 30,
    sort_by: 'project_name/1',
    project_id: projectIds,
    email: email === 'Unassigned' ? '' : email,
    search: '',
  }
  const Api = useApi()

  const {filters, onSearch, onSortingChange, searchValue, onPageChange} = useFilters(
    initialFilters,
    {
      sortType: 'number',
    }
  )

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(
    `/agile/projects/all-cards`,
    {
      queryId: 'task-report',
      filters: filters,
    },
    {
      enabled: filters.project_id !== '',
    }
  )

  return {
    tasksReportsData: response?.data?.cards || [],
    userName: response?.data?.user_name || '',
    ticketCount: response?.data?.total_cards || 0,
    pagination: response?.meta?.pagination || {},
    filters,
    isLoading: isFetching,
    onSortingChange,
    onPageChange,
    onSearch,
    searchValue,
    refetch,
  }
}

export default useGetTasksReportsData
