import {Pagination} from '../../../utils/pagination'
import ArchivedProjectsTable from '../sections/tables/ArchivedProjectsTable'
import {ProjectsListPageContext} from '../context/index'
import {useGetBusinessUnitsList} from '../hooks/useGetBusinessUnitsList'
import {useGetProjectsList} from '../hooks/useGetProjectsList'
import {ActivePageHeader} from '../sections/headers/ActivePageHeader'
import useProjectOperations from '../hooks/useProjectOperations'
import usePermission from '../../../hook/usePermission'
import {useSearchParams} from 'react-router-dom'

function Archived() {
  const {hasPermission} = usePermission()
  const isActiveProjects = useSearchParams()[0].get('view') === 'active'
  const hasProjectsPermission = hasPermission('projects_all projects', 'write')
  const {
    projectsList,
    isLoading,
    pagination,
    onSearch,
    onPageChange,
    filters,
    searchValue,
    refetchProjectsList,
    onSortingChange,
  } = useGetProjectsList()
  const {businessUnitsList, isLoadingBusinessUnits}: any = useGetBusinessUnitsList()
  const {
    createNewProjectDetails,
    updateProjectDetails,
    addProjectToFavorite,
    deleteProject,
    isLoading: isOperationLoading,
  } = useProjectOperations()

  const contextValue = {
    hasProjectsPermission,
    isActiveProjects,
    projectsList,
    isLoading,
    isOperationLoading,
    filters,
    businessUnitsList,
    isLoadingBusinessUnits,
    onSearch,
    searchValue,
    updateProjectDetails,
    createNewProjectDetails,
    refetchProjectsList: refetchProjectsList,
    onSortingChange,
    addProjectToFavorite,
    deleteProject,
  }

  return (
    <ProjectsListPageContext.Provider value={contextValue}>
      <ActivePageHeader />
      <ArchivedProjectsTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </ProjectsListPageContext.Provider>
  )
}

export default Archived
