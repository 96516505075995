/* eslint-disable react-hooks/exhaustive-deps */
import {KTCard} from '../../../_metronic/helpers'
import {Pagination} from '../../../utils/pagination'
import {InquiresPageListingContext} from '../context'
import InquiresPageTable from '../sections/table/InquiresPageTable'
import useGetInquiresData from '../hooks/useGetInquiresData'
import {useEffect, useState} from 'react'
import {useGetRepresantetives} from '../hooks/useGetRepresantetives'

const InquiresAll = ({tab}: any) => {
  const {
    InquiresData,
    filters,
    isLoading,
    onSortingChange,
    onSearch,
    onPageChange,
    pagination,
    onStatusChange,
    columns,
    refetch,
    onChangeCustomerFilter,
  } = useGetInquiresData(tab)
  const [openModal, setOpenModal] = useState(false)
  const [clickedId, setClickedId] = useState('')
  const {Representatives} = useGetRepresantetives()

  useEffect(() => {
    onStatusChange(tab)
  }, [tab])

  const contextValue = {
    InquiresData,
    filters,
    isLoading,
    onSortingChange,
    onPageChange,
    onSearch,
    columns,
    refetch,
    openModal,
    setOpenModal,
    clickedId,
    setClickedId,
    Representatives,
    onChangeCustomerFilter,
  }

  return (
    <InquiresPageListingContext.Provider value={contextValue}>
      <KTCard>
        <InquiresPageTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </KTCard>
    </InquiresPageListingContext.Provider>
  )
}

export default InquiresAll
