import {useForm} from 'react-hook-form'
import {useContext} from 'react'
import {ProjectsDefaultColumnsPageContext} from '../../context'
import {SectionForm} from '../../../../components/SectionForm'
import {yupResolver} from '@hookform/resolvers/yup'
import {defaultColumnsSchema} from '../../validations'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {DefaultColumnsModalTypes} from '../../interfaces'
import {DefaultColumnsDefaultValues} from '../../parsings'

const DefaultColumnsModal = ({
  show,
  onClose,
  isEdit,
  defaultColumnData,
}: DefaultColumnsModalTypes) => {
  const {
    isLoadingGlobalDefaultColumnsList,
    createGlobalDefaultColumn,
    updateGlobalDefaultColumn,
    isLoadingGlobalDefaultColumnOperation,
  } = useContext(ProjectsDefaultColumnsPageContext)

  const {
    register,
    handleSubmit,
    formState: {dirtyFields, errors, isDirty, isValid},
    setError,
  } = useForm<any>({
    defaultValues: DefaultColumnsDefaultValues(defaultColumnData, isEdit),
    resolver: yupResolver(defaultColumnsSchema),
  })

  const onSubmit = async (data: any) => {
    const defaultColumnDetailsPayload = {
      ...data,
      is_archived: false,
    }
    if (isEdit) {
      await updateGlobalDefaultColumn(`${defaultColumnData?.id}`, data, dirtyFields, setError)
    } else {
      await createGlobalDefaultColumn(defaultColumnDetailsPayload, setError)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={isEdit ? 'Edit Default Column' : 'Create Default Column'}
      onAction={handleSubmit(onSubmit)}
      isDisabled={
        !isDirty ||
        !isValid ||
        isLoadingGlobalDefaultColumnOperation ||
        isLoadingGlobalDefaultColumnsList
      }
      isOperationLoading={isLoadingGlobalDefaultColumnOperation}
      isDataLoading={isLoadingGlobalDefaultColumnsList}
      actionName={isEdit ? 'Update' : 'Create'}
      actionBtnClass='btn-primary'
      body={
        <SectionForm
          id='global-projects-default-columns'
          register={register}
          commonClassNames='mb-8'
          errors={errors}
          inputs={[
            {
              id: 'default-project-column-name',
              placeholder: 'Type Column Name',
              label: 'Column Name',
              registerKey: 'name',
              required: true,
            },
            {
              id: 'default-project-column-description',
              isTextArea: true,
              label: 'Column Description',
              registerKey: 'description',
              inputClass: 'resize-none',
              maxLength: 128,
            },
          ]}
        />
      }
    />
  )
}

export default DefaultColumnsModal
