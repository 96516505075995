import ProductLimitReportTableActionsOverlay from '../modals/overlays/ProductLimitReportTableActionsOverlay'

const ProductLimitReportTableRow = ({row, actionComponent}: any) => {
  return (
    <tr>
      <td>{row.productName}</td>
      <td>{row.sku}</td>
      <td>{row.inventory}</td>
      <td>{row.minOrderQuantity}</td>
      <td>{row.maxOrderQuantity}</td>
      <td>{row.availabilityDescription}</td>
      {actionComponent && (
        <td>
          <ProductLimitReportTableActionsOverlay id={row?.productId} name={row?.productName} />
        </td>
      )}
    </tr>
  )
}

export default ProductLimitReportTableRow
