import {useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import Tasks from './layouts/Tasks'
import {MembersDetailsReportPageContext} from './contexts'
import useGetTasksReportsData from './hooks/useGetTasksReportsData'
import usePermission from '../../hook/usePermission'

const ProjectReportsDetailsPageCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/projects/all-projects',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Reports',
    path: '/projects/reports',
    isSeparator: false,
    isActive: false,
  },
]

const ProjectReportsDetailsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('projectId');
  const emailId = searchParams.get('emailId');

  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_reports', 'write')

  const {
    isLoading,
    tasksReportsData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onPageChange,
    pagination,
    ticketCount,
    userName,
    refetch,
  } = useGetTasksReportsData(emailId, projectId)

  const contextValue = {
    hasProjectSettingsPermission,
    isLoading,
    tasksReportsData,
    onSortingChange,
    onSearch,
    searchValue,
    filters,
    refetch,
    onPageChange,
    pagination,
  }

  return (
    <div>
      <MembersDetailsReportPageContext.Provider value={contextValue}>
        <PageTitle
          breadcrumbs={ProjectReportsDetailsPageCrumbs}
        >{`${userName} : ${ticketCount}`}</PageTitle>
        <Tasks />
      </MembersDetailsReportPageContext.Provider>
    </div>
  )
}

export default ProjectReportsDetailsPage
