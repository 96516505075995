import {formatForLink} from '../../../../utils/string'

export const ticketNameColumnCell = (data: any, projectId: string) => {
  return (
    <div className='pe-10'>
      {data.card_identifier && (
        <a
          className='text-primary cursor-pointer'
          href={`/projects/all-projects/${projectId}/ticket/${data.ticketID}/${formatForLink(
            data.card_identifier
          )}`}
        >
          {data.card_identifier} -
        </a>
      )}
      {data.text}
    </div>
  )
}

export const estimationColumnCell = (data: any) => {
  return <div>{data} days</div>
}

export const progressColumnCell = (value: any) => {
  return (
    <div className='d-flex justify-content-end gap-2 pe-3'>
      <span className={`${value > 100 ? 'text-danger' : 'text-gray-800'}`}>
        {value?.toFixed(2)}%
      </span>{' '}
      <svg width='20' height='20' viewBox='-2 -2 40 40'>
        <circle cx='18' cy='18' r='16' fill='none' stroke='#f3f4f6' strokeWidth='6' />
        <circle
          cx='18'
          cy='18'
          r='16'
          fill='none'
          stroke={value > 100 ? '#F8285A' : '#466AEC'}
          strokeWidth='6'
          strokeDasharray={`${value} 100`}
          transform='rotate(-90 18 18)'
        />
      </svg>
    </div>
  )
}
