import 'bootstrap/dist/css/bootstrap.min.css'
import React, {ReactNode} from 'react'
// import '../../_metronic/assets/sass/layout/popover.scss'
import '../../assets/sass/layout/popover.scss'

import {Popover, OverlayTrigger} from 'react-bootstrap'

type props = {
  btnIcon: ReactNode | string
  children: ReactNode
  placement?: any
  trigger?: any
  rootClose?: boolean
  className?: string
  popoverClass?: string
  popoverBodyClass?: string
  outerDivClass?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const OverlayComponent: React.FC<props> = ({
  btnIcon,
  children,
  placement = 'left',
  trigger = 'click',
  rootClose = true,
  className = '',
  popoverClass = '',
  popoverBodyClass = '',
  outerDivClass = '',
  onClick,
  disabled = false,
}) => {
  const popover = (
    <Popover className={popoverClass} onClick={(e: any) => e.preventDefault()}>
      <Popover.Body className={popoverBodyClass}>{children}</Popover.Body>
    </Popover>
  )

  return (
    <div
      className={`${
        outerDivClass
          ? outerDivClass
          : 'd-flex justify-content-center align-items-center flex-shrink-0'
      }`}
    >
      <OverlayTrigger
        trigger={trigger}
        placement={placement}
        overlay={popover}
        rootClose={rootClose}
      >
        <button
          type='button'
          className={`${className}  btn btn-sm btn-icon btn-active-color-primary text-start fw-normal`}
          onClick={onClick}
          disabled={disabled}
        >
          {btnIcon}
        </button>
      </OverlayTrigger>
    </div>
  )
}

export default OverlayComponent
