import {CheckboxTypes} from './CheckBoxTypes'

function Checkbox({
  id,
  name,
  value,
  className = '',
  inputClass = '',
  control,
  label,
  register,
  isRequired,
  onChange,
  onBlur,
  onKeyDown,
  checked,
  checkboxRef,
  disabled = false,
  error,
  isReadOnly = false,
  labelClass,
  ...rest
}: CheckboxTypes) {
  const {
    name: registerName = name,
    onChange: registerOnChange = onChange,
    onBlur: registerOnBlur = onBlur,
    ref: registerRef = checkboxRef,
  } = register && register.name ? register : {}

  const handleChange = (e: any) => {
    if (onChange) onChange(e)
    if (registerOnChange) registerOnChange(e)
  }

  const handleBlur = (e: any) => {
    if (onBlur) onBlur(e)
    if (registerOnBlur) registerOnBlur(e)
  }

  const handleRef = (e: any) => {
    if (checkboxRef) (checkboxRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = e
    if (registerRef) registerRef(e)
  }

  return (
    <div
      className={`form-check form-check-custom ${
        disabled ? 'form-check-solid' : ''
      } form-check-sm ${className}`}
    >
      <input
        id={id}
        className={`form-check-input ${labelClass} ${inputClass} ${error ? 'is-invalid' : ''}`}
        type='checkbox'
        name={registerName}
        ref={handleRef}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        onKeyDown={onKeyDown}
        checked={checked}
        disabled={disabled}
        readOnly={isReadOnly}
        {...rest}
      />
      {label && <span className={`form-check-label ${isRequired ? 'required' : ''}`}>{label}</span>}
    </div>
  )
}
export default Checkbox
