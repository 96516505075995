import {useContext, useMemo} from 'react'
import {MatrixReportPageContext} from '../../context'
import {isEmpty} from '../../../../utils/common'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import MatrixTableRow from './MatrixTableRow'

const MatrixTable = () => {
  const {xAxisMatrixData, yAxisMatrixData, isLoadingMatrixData, filters, onSortingChange} =
    useContext(MatrixReportPageContext)

  const {data, columns} = useMemo(() => {
    if (isEmpty(xAxisMatrixData) || isEmpty(yAxisMatrixData)) {
      return {data: [], columns: []}
    }

    // Pre-compute xAxis values for faster lookup
    const xAxisValues = Object.values(xAxisMatrixData)

    // Create columns
    const columns = [
      {key: 'value', label: 'Name', isSorted: true, headerStyle: 'min-w-200px'},
      {
        key: 'Total',
        label: 'Total',
        isSorted: false,
        headerStyle: 'min-w-150px text-center border-end bg-light',
        style: 'justify-content-center',
      },
      ...xAxisValues.map((header) => ({
        key: header,
        label: header,
        isSorted: false,
        headerStyle: 'min-w-150px text-center',
        style: 'justify-content-center',
      })),
    ]

    // Initialize column totals map for faster updates
    const columnTotalsMap: any = new Map<string, number>()
    xAxisValues.forEach((key: any) => columnTotalsMap.set(key, 0))
    let grandTotal = 0

    // Process rows
    const rowsData = yAxisMatrixData.map(({value, card_counts}: any) => {
      const row: any = {value, Total: 0}

      // Process each cell and update totals
      Object.entries(xAxisMatrixData).forEach(([key, fieldName]: any) => {
        const count = card_counts[key] || 0
        row[fieldName] = count
        row.Total += count
        columnTotalsMap.set(fieldName, (columnTotalsMap.get(fieldName) || 0) + count)
        grandTotal += count
      })

      return row
    })

    // Add totals row
    const totalsRow = {
      value: 'Total',
      Total: grandTotal,
      ...Object.fromEntries([...columnTotalsMap]),
    }

    return {
      data: [...rowsData, totalsRow],
      columns,
    }
  }, [xAxisMatrixData, yAxisMatrixData])

  return (
    <DynamicTable
      data={data}
      sortableColumns={columns}
      loading={isLoadingMatrixData}
      filters={filters}
      tableClass='table table-row-dashed table-row-gray-300 align-middle mb-15 gs-0 gy-3'
      onSortingChange={onSortingChange}
      TableRow={MatrixTableRow}
    />
  )
}

export default MatrixTable
