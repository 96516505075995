import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'
import {TicketDetailsContext} from '../context'
import {useContext} from 'react'

const initialFilters = {
  search: '',
}

const useGetProjectTickets = () => {
  const Api = useApi()
  const {projectId} = useContext(TicketDetailsContext)
  const {filters} = useFilters(initialFilters)

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/cards-list`, {
    queryId: `${projectId}-tickets`,
    filters: filters,
    isCaching: false,
  })

  return {
    projectTickets: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetProjectTickets
