import useApi from '../../../../services/useApi'
import {useContext, useState} from 'react'
import { ProjectDetailsContext } from '../../context'

function useColumnOperations() {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const [isLoading, setLoading] = useState(false)
  const [columnOperationId, setColumnOperationId] = useState<any>(null)

  async function createColumn(payload: any, setError: Function) {
    return Api.post(`/agile/projects/${projectId}/columns`, payload, {
      successMessage: 'Column created successfully.',
      setLoading: setLoading,
      refetch: 'board-columns',
      setUseFormError: setError,
    })
  }

  async function updateColumn(
    columnId: string,
    payload: any,
    dirtyFields?: any,
    setError?: Function
  ) {
    return Api.patch(`/agile/projects/${projectId}/columns/${columnId}`, payload, {
      dirtyFields: dirtyFields,
      successMessage: 'Column updated successfully.',
      setLoading: setLoading,
      refetch: ['board-columns','project-details'],
      setUseFormError: setError,
      onLoadingStart: () => setColumnOperationId(columnId),
      onLoadingEnd: () => setColumnOperationId(null),
    })
  }

  async function updateColumnTableSorting(sortOrder: any) {
    return Api.patch(`/agile/projects/${projectId}/columns`, sortOrder)
  }

  async function deleteColumn(columnId: string) {
    return Api.delete(
      `/agile/projects/${projectId}/columns/${columnId}`,
      {},
      {
        successMessage: 'Column deleted successfully.',
        setLoading: setLoading,
        refetch: 'board-columns',
      }
    )
  }

  const mapColumnStatus = (selectedOption: any, columnId: string, setError: Function) => {
    return Api.post(
      `/agile/projects/${projectId}/column-mapping/${columnId}`,
      {column_value: selectedOption},
      {
        successMessage: selectedOption
          ? 'Column mapped successfully'
          : 'Column mapping removed successfully',
        setLoading: setLoading,
        setUseFormError: setError,
        onLoadingStart: () => setColumnOperationId(columnId),
        onLoadingEnd: () => setColumnOperationId(null),
      }
    )
  }

  return {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    mapColumnStatus,
    columnOperationId,
    isLoading,
  }
}

export default useColumnOperations
