/* eslint-disable react-hooks/exhaustive-deps */
import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

const initialFilters = {
  page: 1,
  limit: 30,
  sort_by: 'total_order_count/-1',
  search: '',
}

const useGetProductsReportsData = () => {
  const Api = useApi()

  const {filters, onSearch, onSortingChange, searchValue, onPageChange, setMultipleFilters} =
    useFilters(initialFilters, {
      sortType: 'number',
    })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(`/bulkOrders/reports/products`, {
    queryId: 'product-reports',
    filters: filters,
  })

  const onSalesRepSearch = (salesRepName: string) => {
    setMultipleFilters({
      search: salesRepName,
      page: 1,
    })
  }

  return {
    productsReportsData: response?.data || [],
    pagination: response?.meta?.pagination || {},
    filters,
    isLoading: isFetching,
    onSortingChange,
    onPageChange,
    onSearch,
    onSalesRepSearch,
    searchValue,
    refetch,
  }
}

export default useGetProductsReportsData
