import {useContext} from 'react'
import {TeamsActionsDropdownTypes} from '../../interfaces'
import {ProjectsTeamsPageContext} from '../../context'

const TeamsActionsDropdown = ({
  data,
  setShowModal,
  setShowDeleteModal,
}: TeamsActionsDropdownTypes) => {
  const {updateProjectTeam} = useContext(ProjectsTeamsPageContext)

  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setShowDeleteModal(true)
    document.body.click()
  }

  const handelChangeStatus = (status: any) => {
    const newStatus = status === 'active' ? 'inactive' : 'active'
    updateProjectTeam(data?.id, {status: newStatus}, () => {})
    document.body.click()
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={() => handleEditClick()}
      >
        Edit
      </span>
      <span
        onClick={() => handleDeleteClick()}
        className='text-dark fw-bold text-hover-danger fs-6 px-2 py-1 cursor-pointer'
      >
        Delete
      </span>
      <span
        onClick={() => handelChangeStatus(data?.status)}
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1'
      >
        <label>{data?.status === 'active' ? 'Inactive' : 'Active'}</label>
      </span>
    </div>
  )
}
export default TeamsActionsDropdown
