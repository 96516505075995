import {HasValueOrNot} from '../../../utils/common'
import {formatDate} from '../../../utils/date'
import { capitalizeFirstLetter } from '../../../utils/string'

type UnHideProductRules = {
  product_name: string
  product_id: number
  category: string
  is_executed: boolean
  executed_date: string
  created_at: string
  is_active: boolean
  created_by: string

}

export default function parseUnHideProductRules(data: UnHideProductRules) {
  return {
    product_name: HasValueOrNot(data?.['product_name']),
    product_id: data['product_id'],
    category: data['category'],
    created_at: formatDate(data['created_at'], false),
    is_active:
      data['is_active'] !== null ? capitalizeFirstLetter(data['is_active'].toString()) : '-',
    is_executed:
      data['is_executed'] !== null ? capitalizeFirstLetter(data['is_executed'].toString()) : '-',
    created_by: data['created_by'],
    executed_date: formatDate(data['executed_date'], false),
  }
}





