import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import DefaultColumnsModal from '../modals/DefaultColumnsModal'
import DefaultColumnActionsDropdown from '../actionsDropdowns/DefaultColumnActionsDropdown'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectsDefaultColumnsPageContext} from '../../context'
import {DefaultColumnsActionsOverlayTypes} from '../../interfaces'

const DefaultColumnsActionsOverlay = ({defaultColumnData}: DefaultColumnsActionsOverlayTypes) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {
    deleteGlobalDefaultColumn,
    isLoadingGlobalDefaultColumnOperation,
    isLoadingGlobalDefaultColumnsList,
  } = useContext(ProjectsDefaultColumnsPageContext)

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <DefaultColumnActionsDropdown
            setShowModal={setShowModal}
            setShowDeleteModal={setShowDeleteModal}
          />
        }
      />

      {showModal && (
        <DefaultColumnsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          isEdit={true}
          defaultColumnData={defaultColumnData}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onAction={() => deleteGlobalDefaultColumn(defaultColumnData?.id)}
          isDataLoading={isLoadingGlobalDefaultColumnsList}
          isOperationLoading={isLoadingGlobalDefaultColumnOperation}
          body={
            <p>
              Do you really want to remove '<strong>{defaultColumnData?.name}</strong>' from default
              columns?
            </p>
          }
        />
      )}
    </>
  )
}

export default DefaultColumnsActionsOverlay
