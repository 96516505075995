import React from 'react'
import {Pagination} from '../../../utils/pagination'
import {SalesRepReportPageContext} from '../contexts'
import usePermission from '../../../hook/usePermission'
import useGetSalesRepReportsData from '../hooks/useGetSalesRepReportsData'
import SalesRepHeader from '../sections/headers/SalesRepHeader'
import SalesRepTable from '../sections/tabels/BySalesRep/SalesRepTable'

const SalesRep = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {
    isLoading,
    salesRepReportsData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onPageChange,
    pagination,
    onProjectChange,
    refetch,
  } = useGetSalesRepReportsData()

  const contextValue = {
    hasProjectSettingsPermission,
    isLoading,
    salesRepReportsData,
    onSortingChange,
    onProjectChange,
    onSearch,
    searchValue,
    filters,
    refetch,
  }

  return (
    <>
      <SalesRepReportPageContext.Provider value={contextValue}>
        <SalesRepHeader />
        <SalesRepTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </SalesRepReportPageContext.Provider>
    </>
  )
}

export default SalesRep
