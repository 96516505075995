import {useContext} from 'react'
import ModuleActionsOverlay from '../../overlays/ModuleActionsOverlay'
import {BoardSettingsPageContext} from '../../../context'
import {getBadgeColor} from '../../../../../utils/badge'

const ModulesTableRow = ({dndSettings, row, hasWritePermission, dndImage}: any) => {
  const {isLoadingModulesOperation, moduleOperationId} = useContext(BoardSettingsPageContext)
  return (
    <tr {...dndSettings}>
      <td>
        {dndImage}
        {row?.moduleName}
      </td>
      <td>{row?.description}</td>
      <td>
        <div className='text-center'>{row?.cards}</div>
      </td>
      <td>
        <div className='text-center'>
          <span className={`badge ${getBadgeColor(row?.visibility, 'light')} badge-lg`}>
            <div className='align-items-center'>{row?.visibility}</div>
          </span>
        </div>
      </td>
      {hasWritePermission && (
        <td>
          {isLoadingModulesOperation && moduleOperationId === row?.moduleId ? (
            <div className='d-flex justify-content-center'>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </div>
          ) : (
            <ModuleActionsOverlay moduleData={row} />
          )}
        </td>
      )}
    </tr>
  )
}

export default ModulesTableRow
