import React, {useContext, useState} from 'react'
import SpentTimeModal from '../modals/SpentTimeModal'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {TicketDetailsPageContext} from '../../context'
import {convertUTCToLocal, formatDate} from '../../../../utils/date'
import {useAppSelector} from '../../../../redux/useTypedSelector'
import {ActionButtons} from '../../../../components/ActionButtons'

const SpentTimeTab: React.FC = () => {
  const {user} = useAppSelector((state) => state.auth)
  const {ticketSpentTimeHistory, isSpentTimeLoading, SpentTimePagination, onLoadMoreSpentTime} =
    useContext(TicketDetailsPageContext)
  const {deleteSpentTime, isTimeLogOperationLoading} = useContext(TicketDetailsPageContext)

  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectData, setSelectData] = useState<any>({})

  const onClose = () => {
    setShowModal(false)
    setShowDeleteModal(false)
  }

  const handleDeleteConfirm = (id: any) => {
    deleteSpentTime(id)
  }

  return (
    <>
      {ticketSpentTimeHistory?.map((item, index) => (
        <div key={index} className=''>
          <div className='border-bottom pb-4 mb-4'>
            <div>
              {/* User Info */}
              <div className='d-flex justify-content-between align-items-center mb-2'>
                <div className='d-flex align-items-center gap-2'>
                  <div className='fw-bold'>{item.created_by_name}</div>
                  {/* <div className='rounded-circle bg-success mx-2 w-10px h-10px'></div> */}

                  <div className='app-default-date-cell position-relative'>
                    <span className='text-gray-500 fs-8 app-default-date-area position-relative'>
                      {convertUTCToLocal(item.time_log_created_at, true, true)}
                      <span className='popover app-default-date-tooltip fw-semibold lh-1 fs-8'>
                        {convertUTCToLocal(item.time_log_created_at)}
                      </span>
                    </span>
                  </div>
                  {item.is_updated && <div className='text-gray-500 fs-8'>(Edited)</div>}
                </div>

                {/* Edit and Delete Icons */}

                {user?.username === item.created_by && (
                  <ActionButtons
                    buttons={[
                      {
                        id: 'edit',
                        label: 'Edit',
                        className: 'btn-link btn-color-muted btn-active-color-primary py-2',
                        onClick: () => {
                          setShowModal(true)
                          setSelectData(item)
                        },
                      },
                      {
                        id: 'delete',
                        label: 'Delete',
                        className: 'btn-link btn-color-muted btn-active-color-danger py-2 ms-4',
                        onClick: () => {
                          setShowDeleteModal(true)
                          setSelectData(item)
                        },
                      },
                    ]}
                  />
                )}
              </div>

              {/* Work Type and Description */}
              <div className='row'>
                <div className='col-xxl-5 col-md-4'>
                  <div className='d-flex align-items-center'>
                    <div className='fs-5 fw-bold'>{item.spent_time_diff}</div>
                    <div className='ps-4 text-muted'>
                      {formatDate(item.spent_time_created_at, true)}
                    </div>
                  </div>
                </div>
                {item.log_description && (
                  <div className='col-xxl-7 col-md-8'>
                    <div className='ps-6'>
                      <p className='mb-0'>{item.log_description}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      {SpentTimePagination?.next_page && (
        <ActionButtons
          buttons={[
            {
              id: 'loadMore',
              label: 'More logs',
              className: 'btn-light-primary',
              size: 'sm',
              labelIcon: <i className='fa-solid fa-plus'></i>,
              loadingLabel: 'Loading...',
              isLoading: isSpentTimeLoading,
              onClick: () => onLoadMoreSpentTime(SpentTimePagination?.next_page),
            },
          ]}
        />
      )}

      {showModal && (
        <SpentTimeModal
          showModal={showModal}
          onClose={onClose}
          isEdit={true}
          logData={selectData}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          uniqueID={selectData?.id}
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onAction={handleDeleteConfirm}
          isOperationLoading={isTimeLogOperationLoading}
          isDataLoading={isSpentTimeLoading}
          body={'Are you sure you want to delete a spent time log?'}
        />
      )}
    </>
  )
}

export default SpentTimeTab
