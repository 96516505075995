import {useContext} from 'react'
import TasksReportTableRow from './TasksReportTableRow'
import {DynamicTable} from '../../../../components/DynamicTable'
import {MembersDetailsReportPageContext} from '../../contexts'
import {dataToTasksReportRow} from '../../parsings'
import {TasksReportTableColumns} from '../../../projectReports/utils'

export default function TasksReportTable() {
  const {isLoading, tasksReportsData, onSortingChange, searchValue, filters} = useContext(
    MembersDetailsReportPageContext
  )

  return (
    <DynamicTable
      id={'members-reports-details'}
      data={tasksReportsData}
      sortableColumns={TasksReportTableColumns}
      TableRow={TasksReportTableRow}
      permissionPath={'projects_reports'}
      loading={isLoading}
      tableClass='table-row-dashed table-row-gray-300 align-middle mb-15 gs-0 gy-3'
      filters={filters}
      onSortingChange={onSortingChange}
      searchValue={searchValue}
      rowParsingFunction={dataToTasksReportRow}
    />
  )
}
