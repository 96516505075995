import usePermission from '../../../hook/usePermission'
import {Pagination} from '../../../utils/pagination'
import {SuggestedCustomerReportPageContext} from '../contexts'
import {useGetRepresantetives} from '../../inquires/hooks/useGetRepresantetives'
import SuggestedCustomerTable from '../sections/tabels/SuggestedCustomer/SuggestedCustomerTable'
import useGetSuggestedCustomerData from '../hooks/useGetSuggestedCustomerData'
import SuggestedCustomerHeader from '../sections/headers/SuggestedCustomerHeader'
import useUpdateCustomerTag from '../hooks/useUpdateCustomerTag'

const SuggestedCustomers = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {
    isLoading,
    suggestedCustomersData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onPageChange,
    pagination,
    onRepFilterChange,
    refetch,
  } = useGetSuggestedCustomerData()
  const {Representatives} = useGetRepresantetives()
  const {updateCustomerTags} = useUpdateCustomerTag()

  const contextValue = {
    hasProjectSettingsPermission,
    isLoading,
    suggestedCustomersData,
    onSortingChange,
    onRepFilterChange,
    onSearch,
    searchValue,
    filters,
    refetch,
    Representatives,
    updateCustomerTags
  }

  return (
    <SuggestedCustomerReportPageContext.Provider value={contextValue}>
      <SuggestedCustomerHeader />
      <SuggestedCustomerTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </SuggestedCustomerReportPageContext.Provider>
  )
}

export default SuggestedCustomers
