import {useQuery} from 'react-query'
import useFilters from '../../../hook/useFilters'
import {getJobdata, runSpecificTask} from '../services'
import {useState} from 'react'
import useToastify from '../../../hook/useToastify'

function useGetJobData(job_id: string) {
  const [isProcessing, setIsProcessing] = useState(false)
  const {toastMessage} = useToastify()
  const initialFilters = {
    job_id: job_id,
  }
  const {filters} = useFilters(initialFilters)

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(`get-job-data`, () => {
    return job_id !== '' ? getJobdata(filters) : {}
  })

  const runTask = async (name: string) => {
    setIsProcessing(true)
    const res = await runSpecificTask(name)
    if (res?.['id']) {
      refetch()
      toastMessage('success', 'Task Scheduled Successfully.')
      setIsProcessing(false)
    }
  }

  return {
    jobData: response || [],
    isLoading: isLoading,
    runTask,
    isProcessing,
    refetchJobData: refetch,
  }
}

export default useGetJobData
