import {useState} from 'react'
import {HideVariantSearchComponent} from './HideVariantSearchComponent'
import CreateRule from '../modal/CreateRule'
import usePermission from '../../../../../hook/usePermission'

const HideVariantRuleHeader = ({hide_variant_details}: any) => {
  const [modal, setModal] = useState(false)
  const {hasPermission} = usePermission()

  const toggleModal = () => {
    setModal(!modal)
  }
  const onClose = () => {
    setModal(false)
  }

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex'>
        <HideVariantSearchComponent hide_variant_details={hide_variant_details} />
      </div>
      {hasPermission('analytics_automation', 'write') && (
        <div>
          <button onClick={toggleModal} className='btn btn-primary'>
            + Create Rule
          </button>
        </div>
      )}
      {modal && <CreateRule show={modal} onClose={onClose} />}
    </div>
  )
}

export {HideVariantRuleHeader}
