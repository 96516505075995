import {useContext} from 'react'
import {DynamicTable} from '../../../../../components/DynamicTable'
import CustomerTableRow from './CustomerTableRow'
import {CustomerReportPageContext} from '../../../contexts'
import {CustomerTableColumns} from '../../../utils'

const CustomerTable = () => {
  const {customerReportsData, isLoading, filters, onSortingChange} =
    useContext(CustomerReportPageContext)

  return (
    <DynamicTable
      id='customer-table'
      data={customerReportsData}
      sortableColumns={CustomerTableColumns}
      loading={isLoading}
      filters={filters}
      onSortingChange={onSortingChange}
      TableRow={CustomerTableRow}
      permissionPath='orders_bulk orders_reports'
    />
  )
}

export default CustomerTable
