import {useContext} from 'react'
import {ProjectsMembersPageContext} from '../../context'
import GlobalProjectMembersTableRow from './GlobalProjectMembersTableRow'
import {DynamicTable} from '../../../../components/DynamicTable'
import {globalProjectMembersTableColumns} from '../../utils'

export default function GlobalProjectMembersTable() {
  const {
    globalProjectsMembersList,
    isLoadingGlobalProjectsMembersList,
    filters,
    onSortingChange,
    searchValue,
  } = useContext(ProjectsMembersPageContext)

  return (
    <DynamicTable
      id={'global-project-members'}
      data={globalProjectsMembersList}
      sortableColumns={globalProjectMembersTableColumns}
      TableRow={GlobalProjectMembersTableRow}
      permissionPath={'projects_settings'}
      loading={isLoadingGlobalProjectsMembersList}
      filters={filters}
      onSortingChange={onSortingChange}
      searchValue={searchValue}
      noDataMessage={
        <p>
          No global project members have been added yet. <br /> Please click on 'Add Members' button
          to add members.
        </p>
      }
    />
  )
}
