import {createContext} from 'react'

export const IdleCustomersDashboardContext = createContext({
  inactiveCustomers: [],
  inactiveCustomersLoading: false,
  totalInactiveCustomers: 0,
  loadMoreInactiveCustomers: () => {},
  recentlyInactiveCustomers: [],
  recentlyInactiveCustomersLoading: false,
  totalRecentlyInactiveCustomers: 0,
  loadMoreRecentlyInactiveCustomers: () => {},
  anonymousCustomers: [],
  anonymousCustomersLoading: false,
  totalAnonymousCustomers: 0,
  loadMoreAnonymousCustomers: () => {},
  onInactiveCustomerClickSorting: (sortBy: string, sortDirection: any) => {},
  InactiveCustomerFilters: {},
  onRecentlyInactiveCustomerClickSorting: (sortBy: string, sortDirection: any) => {},
  RecentlyInactiveCustomerFilters: {},
  onAnonymouslyActiveCustomerClickSorting: (sortBy: string, sortDirection: any) => {},
  AnonymouslyActiveCustomerFilters: {},
})