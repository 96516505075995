/* eslint-disable react-hooks/exhaustive-deps */
import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

const initialFilters = {
  page: 1,
  limit: 30,
  sort_by: 'total_orders/1',
  search: '',
}

const useGetSalesRepReportsData = () => {
  const Api = useApi()

  const {filters, onSearch, onSortingChange, searchValue, onPageChange, setMultipleFilters} =
    useFilters(initialFilters, {
      sortType: 'number',
    })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(`/bulkOrders/reports/salesrep`, {
    queryId: 'task-report',
    filters: filters,
  })

  const onProjectChange = (id: any) => {
    setMultipleFilters({
      project_id: id,
      page: 1,
    })
  }

  const onProductSearch = (searchTerm: string) => {
    setMultipleFilters({
      search: searchTerm,
      page: 1,
    })
  }

  return {
    salesRepReportsData: response?.data || [],
    pagination: response?.meta?.pagination || {},
    filters,
    isLoading: isFetching,
    onSortingChange,
    onProjectChange,
    onProductSearch,
    onPageChange,
    onSearch,
    searchValue,
    refetch,
  }
}

export default useGetSalesRepReportsData
