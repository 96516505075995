import {INSERT_HORIZONTAL_RULE_COMMAND} from '@lexical/react/LexicalHorizontalRuleNode'
import {INSERT_PAGE_BREAK} from '../../../plugins/PageBreakPlugin'
import {INSERT_EMBED_COMMAND} from '@lexical/react/LexicalAutoEmbedPlugin'
import {INSERT_COLLAPSIBLE_COMMAND} from '../../../plugins/CollapsiblePlugin'
import DropDown, {DropDownItem} from '../../../ui/DropDown'
import {EmbedConfigs} from '../../../plugins/AutoEmbedPlugin'
import {$getRoot, LexicalEditor} from 'lexical'
import {InsertImageDialog} from '../../ImagesPlugin'
import {InsertInlineImageDialog} from '../../InlineImagePlugin'
import {InsertTableDialog} from '../../TablePlugin/TablePlugin'
import {InsertPollDialog} from '../../PollPlugin'
import InsertLayoutDialog from '../../LayoutPlugin/InsertLayoutDialog'
import {$createStickyNode} from '../../../nodes/StickyNode'

export function InsertDropDown({
  disabled,
  activeEditor,
  showModal,
}: {
  disabled: boolean
  activeEditor: LexicalEditor
  showModal: (title: string, showModal: (onClose: () => void) => JSX.Element) => void
}) {
  return (
    <DropDown
      disabled={disabled}
      buttonClassName='toolbar-item spaced'
      buttonLabel='Insert'
      buttonAriaLabel='Insert specialized editor node'
      buttonIconClassName='icon plus'
    >
      <DropDownItem
        onClick={() => {
          activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)
        }}
        className='item'
      >
        <i className='icon horizontal-rule' />
        <span className='text'>Horizontal Rule</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          activeEditor.dispatchCommand(INSERT_PAGE_BREAK, undefined)
        }}
        className='item'
      >
        <i className='icon page-break' />
        <span className='text'>Page Break</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          showModal('Insert Image', (onClose) => (
            <InsertImageDialog activeEditor={activeEditor} onClose={onClose} />
          ))
        }}
        className='item'
      >
        <i className='icon image' />
        <span className='text'>Image</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          showModal('Insert Inline Image', (onClose) => (
            <InsertInlineImageDialog activeEditor={activeEditor} onClose={onClose} />
          ))
        }}
        className='item'
      >
        <i className='icon image' />
        <span className='text'>Inline Image</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          showModal('Insert Table', (onClose) => (
            <InsertTableDialog activeEditor={activeEditor} onClose={onClose} />
          ))
        }}
        className='item'
      >
        <i className='icon table' />
        <span className='text'>Table</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          showModal('Insert Poll', (onClose) => (
            <InsertPollDialog activeEditor={activeEditor} onClose={onClose} />
          ))
        }}
        className='item'
      >
        <i className='icon poll' />
        <span className='text'>Poll</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          showModal('Insert Columns Layout', (onClose) => (
            <InsertLayoutDialog activeEditor={activeEditor} onClose={onClose} />
          ))
        }}
        className='item'
      >
        <i className='icon columns' />
        <span className='text'>Columns Layout</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          activeEditor.update(() => {
            const root = $getRoot()
            const stickyNode = $createStickyNode(0, 0)
            root.append(stickyNode)
          })
        }}
        className='item'
      >
        <i className='icon sticky' />
        <span className='text'>Sticky Note</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          activeEditor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined)
        }}
        className='item'
      >
        <i className='icon caret-right' />
        <span className='text'>Collapsible container</span>
      </DropDownItem>
      {EmbedConfigs.map((embedConfig) => (
        <DropDownItem
          key={embedConfig.type}
          onClick={() => {
            activeEditor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type)
          }}
          className='item'
        >
          {embedConfig.icon}
          <span className='text'>{embedConfig.contentName}</span>
        </DropDownItem>
      ))}
    </DropDown>
  )
}
