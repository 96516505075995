const SampleCSVDownload = (data: any[], fileName: string): void => {
  const csvHeaders = Object.keys(data[0]).join(',')
  const csvContent = data.map((row: any) => Object.values(row).join(',')).join('\n')
  const csvBlob = new Blob([csvHeaders + '\n' + csvContent], {type: 'text/csv'})
  const csvUrl = URL.createObjectURL(csvBlob)
  const a = document.createElement('a')
  a.href = csvUrl
  a.download = fileName || 'download.csv'
  a.click()
  URL.revokeObjectURL(csvUrl)
}

export default SampleCSVDownload
