import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getInquiries} from '../services'
import useFilters from '../../../hook/useFilters'
import {dataToInquiriesPage, defaultColumns} from '../utils'
import {useAppSelector} from '../../../redux/useTypedSelector'

export default function useGetInquiresData(tab: any) {
  const {user} = useAppSelector((state) => state.auth)
  const {filters, setSingleFilter, setMultipleFilters} = useFilters({
    page: 1,
    limit: 20,
    filter: '',
    sort_by: 'date_submitted/-1',
    status: tab,
    rep_email: `${user?.is_representative ? user?.username : ''}`,
  })

  const {data, refetch, isFetching} = useQuery(`InquiresData`, () => {
    return getInquiries(`/products/inquiries`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSearch = (searchValue: string) => {
    setSingleFilter('filter', searchValue)
  }

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  const onSortingChange = (key: string, value: string) => {
    const direction = value === 'asc' ? 1 : -1
    const sortFlag = key + '/' + direction

    setMultipleFilters({
      sort_by: sortFlag,
      page: 1,
    })
  }

  const onStatusChange = (tab: any) => {
    setSingleFilter('status', tab)
  }

  function onChangeCustomerFilter(email: string) {
    setMultipleFilters({
      rep_email: email,
    })
  }

  return {
    InquiresData: data?.data ? dataToInquiriesPage(data?.data) : [],
    columns: data?.['meta']?.['columns']?.length ? data?.['meta']?.['columns'] : defaultColumns,
    pagination: data?.['meta']?.['pagination'] || {},
    refetch,
    isLoading: isFetching,
    onPageChange,
    onSearch,
    onSortingChange,
    filters,
    onStatusChange,
    onChangeCustomerFilter,
  }
}
