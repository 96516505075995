import {useRef} from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {useContext} from 'react'
import {DndBoardPageContext} from '../context'

function TaskCard({task}: any) {
  const ticketData = task
  const {handleTicketLayout} = useContext(DndBoardPageContext)
  const cardRef: any = useRef<HTMLDivElement>(null)
  const {setNodeRef, attributes, listeners, transform, transition, isDragging} = useSortable({
    id: task.ticketID,
    data: {
      type: 'Task',
      task,
    },
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    width: '100%',
    display: 'flex',
  }

  if (isDragging) {
    return <div style={style}>{handleTicketLayout({ticketData, isDragging})}</div>
  }

  return (
    <div
      {...attributes}
      {...listeners}
      style={style}
      ref={(node) => {
        setNodeRef(node)
        cardRef.current = node
      }}
    >
      {handleTicketLayout({ticketData})}
    </div>
  )
}

export default TaskCard
