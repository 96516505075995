import {formatPrice, isEmpty} from '../../../utils/common'

export const dataToProductDetails = (data: any) => ({
  isVisible: data?.isVisible,
  name: data?.name,
  sku: data?.sku,
  brandId: data?.brandId,
  type: data?.type,
  categories: data?.categories,
  description: data?.description,
  mpn: data?.mpn,
  upc: data?.upc,
  gtin: data?.gtin,
  binPickingNumber: data?.binPickingNumber,
  price: data?.price,
  taxClassId: data?.taxClassId,
  costPrice: data?.costPrice,
  retailPrice: data?.retailPrice,
  salePrice: data?.salePrice,
  options: data?.options,
  variants: data?.variants,
  searchKeywords: data?.searchKeywords,
  sortOrder: data?.sortOrder,
  warranty: data?.warranty,
  availabilityDescription: data?.availabilityDescription,
  condition: data?.condition,
  isConditionShown: data?.isConditionShown,
  customFields: data?.customFields,
  weight: data?.weight,
  width: data?.width,
  height: data?.height,
  depth: data?.depth,
  isFreeShipping: data?.isFreeShipping,
  fixedCostShippingPrice: data?.fixedCostShippingPrice,
  availability: data?.availability,
  orderQuantityMinimum: data?.orderQuantityMinimum,
  orderQuantityMaximum: data?.orderQuantityMaximum,
  pageTitle: data?.pageTitle,
  metaDescription: data?.metaDescription,
  status: data?.status,
  images: data?.images,
  error: data?.error,
})

export const SIDEBAR_SECTIONS_DATA: any[] = [
  {
    title: 'PRODUCT INFORMATION',
    links: [
      {href: '#basic-info', label: 'Basic Information'},
      {href: '#pricing', label: 'Pricing'},
      {href: '#description', label: 'Description'},
      {href: '#images', label: 'Images'},
      {href: '#product-identifier', label: 'Product Identifiers'},
    ],
  },
  {
    title: 'PRODUCT OPTIONS',
    links: [{href: '#product-options', label: 'Options'}],
  },
  {
    title: 'STOREFRONT',
    links: [
      {href: '#storefront-details', label: 'Storefront Details'},
      {href: '#custom-fields', label: 'Custom Fields'},
    ],
  },
  {
    title: 'FULFILLMENT',
    links: [
      {href: '#dimensions-weight', label: 'Dimensions & Weight'},
      {href: '#shipping-details', label: 'Shipping Details'},
      {href: '#purchasability', label: 'Purchasability'},
    ],
  },
  {
    title: 'SEO & SHARING',
    links: [{href: '#seo', label: 'SEO'}],
  },
]

export const CUSTOM_FIELDS_CONFIG = {
  title: 'Custom Fields',
  csvFile: {
    importConfig: {
      maxFileSize: 1 * 1024 * 1024,
      duplicates: {
        name: true,
        value: true,
        rows: false,
      },
      validationRules: {
        name: {
          maxLength: 255,
        },
        value: {
          maxLength: 255,
        },
      },
    },
    downloadSampleCsv: {
      sampleCsvFileName: 'Sample Custom Fields File',
      sampleCsvData: [
        {Name: 'Warranty', Value: '2 years'},
        {Name: 'Return Policy', Value: '30 days'},
        {Name: 'Shipping', Value: 'Free'},
        {Name: 'Availability', Value: 'In Stock'},
      ],
    },
  },
  tableConfig: {
    data: [
      {
        key: 'name',
        label: 'Name',
        placeholder: 'Enter name',
        maxLength: 255,
        type: 'text',
        isDuplicate: true,
        isRequired: true,
      },
      {
        key: 'value',
        label: 'Value',
        placeholder: 'Enter value',
        maxLength: 255,
        type: 'text',
        isDeletable: true,
        isRequired: true,
        isDuplicate: true,
      },
    ],
    noDataMessage: 'No Custom fields available',
  },
  maxFields: 200,
  isDeletable: true,
  enableMaxFieldMessage: true,
  isDuplicateRow: false,
  addButtonLabel: 'Add Custom Field',
}

export const PRODUCT_OPTIONS_CONFIG = {
  title: 'Product Options',
  csvFile: {
    importConfig: {
      maxFileSize: 1 * 1024 * 1024,
      duplicates: {
        name: true,
        values: true,
      },
      transformData: {
        uniqueKey: [{name: 'name'}],
        groupedKey: [{values: 'values'}],
      },
      validationRules: {
        name: {
          maxLength: 255,
        },
        values: {
          maxLength: 255,
        },
      },
      isNullable: false,
    },
    downloadSampleCsv: {
      sampleCsvFileName: 'Sample options Fields File',
      sampleCsvData: [
        {Name: 'Color', Values: 'Red'},
        {Name: 'Color', Values: 'Blue'},
        {Name: 'Size', Values: 'Small'},
        {Name: 'Brand', Values: 'Nike'},
      ],
    },
  },
  tableConfig: {
    data: [
      {
        key: 'name',
        label: 'Name',
        maxLength: 255,
        type: 'text',
        isRequired: true,
        isDuplicate: false,
      },
      {
        key: 'values',
        label: 'Values',
        maxLength: 255,
        type: 'text',
        isDeletable: true,
        isRequired: true,
        isMultiple: true,
        isDuplicate: false,
      },
    ],
    noDataMessage: 'No product options available.',
  },
  isDuplicateRow: false,
  addButtonLabel: 'Add Option',
  deleteBtnLabel: 'Delete Option',
}

export const PRODUCT_VARIANTS_CONFIG = {
  title: 'Product Variants',
  tableConfig: {
    data: [
      {
        key: 'variant',
        label: 'Variant',
        type: 'static',
        isDuplicate: false,
      },
      {
        key: 'sku',
        label: 'Sku',
        type: 'text',
        maxLength: 255,
        isDuplicate: false,
        isCaseSensitive: true,
      },
      {
        key: 'price',
        label: 'Default Price',
        type: 'price',
        isFloat: true,
        isDuplicate: true,
      },
    ],
    noDataMessage: 'No product variants available',
  },
  maxFields: 600,
  isDuplicateRow: false,
}

export const VARIANT_TABLE_COLUMNS = [
  {
    label: 'Purchasable',
    style: 'min-w-100px',
    isHeaderColumn: true,
    hideCheckBox: true,
  },
  {label: 'Variant', style: 'min-w-150px'},
  {label: 'SKU', style: 'min-w-150px'},
  {label: 'Default Price', style: 'min-w-150px'},
  {label: 'Stock', style: 'w-100px'},
]

export const setDefaultValues = (productDetails: any) => ({
  isVisible: productDetails?.isVisible ?? false,
  name: productDetails?.name || '',
  sku: productDetails?.sku || null,
  brandId: productDetails?.brandId ?? 0,
  type: productDetails?.type || 'physical',
  categories: productDetails?.categories || [],
  description: productDetails?.description || '',
  mpn: productDetails?.mpn || '',
  upc: productDetails?.upc ?? null,
  gtin: productDetails?.gtin ?? null,
  binPickingNumber: productDetails?.binPickingNumber || '',
  price: formatPrice(productDetails?.price, false, true, true) ?? null,
  costPrice: formatPrice(productDetails?.costPrice, false, true, true) ?? null,
  retailPrice: formatPrice(productDetails?.retailPrice, false, true, true) ?? null,
  salePrice: formatPrice(productDetails?.salePrice, false, true, true) ?? null,
  taxClassId: productDetails?.taxClassId || '0',
  options: productDetails?.options || [],
  variants: productDetails?.variants || [],
  searchKeywords: productDetails?.searchKeywords || '',
  sortOrder: productDetails?.sortOrder ?? null,
  warranty: productDetails?.warranty || '',
  availabilityDescription: productDetails?.availabilityDescription || '',
  condition: productDetails?.condition || 'New',
  isConditionShown: productDetails?.isConditionShown ?? false,
  customFields: productDetails?.customFields || [],
  weight: productDetails?.weight ?? null,
  width: productDetails?.width ?? null,
  height: productDetails?.height ?? null,
  depth: productDetails?.depth ?? null,
  isFreeShipping: productDetails?.isFreeShipping ?? false,
  fixedCostShippingPrice:
    formatPrice(productDetails?.fixedCostShippingPrice, false, true, true) ?? null,
  availability: productDetails?.availability || 'available',
  orderQuantityMinimum: productDetails?.orderQuantityMinimum ?? null,
  orderQuantityMaximum: productDetails?.orderQuantityMaximum ?? null,
  pageTitle: productDetails?.pageTitle || '',
  metaDescription: productDetails?.metaDescription || '',
  images: productDetails?.images || [],
})

export function generateProductVariants(variants: any[], defaultVariants: any[]) {
  if (isEmpty(variants)) return []

  const combine = (options: string[][]): string[][] => {
    return options.reduce<string[][]>(
      (acc, curr) => acc.flatMap((option) => curr.map((value) => [...option, value])),
      [[]]
    )
  }

  const filteredVariants = variants
    .filter((variant) => variant.name.trim() !== '')
    .map((variant) => ({
      ...variant,
      values: variant.values.filter((value: any) => value.trim() !== ''),
    }))
    .filter((variant) => variant.values.length > 0)

  if (filteredVariants.length === 0) return []

  const variantValues = filteredVariants.map((variant) => variant.values)
  const combinations = combine(variantValues)

  return combinations.map((combination) => {
    const variantString = combination.join(', ')

    const matchingDefault = defaultVariants?.find(
      (defaultVariant) => defaultVariant.variant === variantString
    )

    const optionValues = combination.map((value, index) => ({
      label: value,
      option_display_name: filteredVariants[index].name,
    }))

    return {
      variant: variantString,
      sku: matchingDefault?.sku || '',
      price: matchingDefault?.price || null,
      optionValues,
    }
  })
}

export const formatUploadedImages = (images: any) => {
  return images.map((path: any) => ({
    imageUrl: path?.imageUrl ?? path,
    isThumbnail: path?.isThumbnail ?? false,
    description: path?.description ?? '',
  }))
}

export const filterFalsieCustomFields = (customFields: any) => {
  if (isEmpty(customFields)) return []
  const filteredCustomFields = [...customFields]
  const isFieldFalsie = (field: any) => {
    return Object.values(field).every((value) => {
      if (Array.isArray(value)) {
        return value.every((v: any) => v === '' || v === null || v === undefined)
      }
      return value === '' || value === null || value === undefined
    })
  }

  const cleanField = (field: any) => {
    const cleanedField = {...field}
    Object.keys(cleanedField).forEach((key) => {
      if (Array.isArray(cleanedField[key])) {
        cleanedField[key] = cleanedField[key].filter(
          (v: any) => v !== '' && v !== null && v !== undefined
        )
      }
    })
    return cleanedField
  }

  return filteredCustomFields
    .map((field: any) => cleanField(field))
    .filter((field: any) => !isFieldFalsie(field))
}

export function isError(customFieldErrors: any, combinations: any, combinationErrorRef: any) {
  if (
    !isEmpty(customFieldErrors?.productOptionErrors) ||
    !isEmpty(customFieldErrors?.productVariantErrors) ||
    !isEmpty(customFieldErrors?.customFieldErrors) ||
    combinations?.length > 600
  ) {
    if (!isEmpty(combinations)) {
      if (combinationErrorRef.current) {
        combinationErrorRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
      }
    }
    return true
  }
  return false
}

export function setValues(
  productId: string | number | undefined,
  isDetailsLoading: boolean,
  isLoadingCategoriesList: boolean,
  isDefaultValueSet: boolean,
  reset: Function,
  productDetails: any,
  setIsDefaultValueSet: Function,
  setProductStatus: Function
) {
  if (productId && !isDetailsLoading && !isLoadingCategoriesList && !isDefaultValueSet) {
    reset(setDefaultValues(productDetails))
    setIsDefaultValueSet(true)
    setProductStatus(productDetails?.status || 'draft')
  }
}

export function handleErrorsAndValidations(
  watch: Function,
  setIsReadyToPublish: Function,
  setProductType: Function,
  errors: any,
  descriptionErrorRef: any,
  productType: string | null,
  setValue: Function,
  publishErrors: any,
  publishErrorRef: any
) {
  const requiredFields = ['name', 'price', 'categories', 'weight']

  watch((values: any) => {
    setIsReadyToPublish(requiredFields.every((field) => !isEmpty(values[field])))
    setProductType(values.type)
  }, requiredFields)

  if (errors.description) {
    if (descriptionErrorRef.current) {
      descriptionErrorRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
    return
  }

  if (productType === 'digital') {
    const dimensionsAndWeightFields = ['weight', 'width', 'height', 'depth']
    for (const field of dimensionsAndWeightFields) {
      if (field === 'weight') {
        setValue(field, 0.0)
      } else {
        setValue(field, null)
      }
    }
  } else {
    setValue('weight', watch('weight') ?? null)
  }

  if (publishErrors) {
    if (publishErrorRef.current) {
      publishErrorRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }
}
