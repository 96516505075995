import {useState} from 'react'
import {createRule, updateRule, deleteRule} from '../services'
import useToastify from '../../../../hook/useToastify'
import {useAppSelector} from '../../../../redux/useTypedSelector'
import useGetHideProducts from './useGetVariantRules'
// import useGetUserList from './useGetUserList'

export default function useHideVariants() {
  const [isLoading, setLoading] = useState(false)
  const {toastMessage} = useToastify()
  const {user} = useAppSelector((state) => state.auth)
  const {refetch} = useGetHideProducts()

  // const {refetch} = useGetUserList(false)

  const addVariantRule = async (data: any) => {
    try {
      setLoading(true)
      let new_data: any = data
      new_data['created_by'] = user['username']
      const res = await createRule(new_data)
      if (res.status === 200) {
        toastMessage('success', `${res.data.message}`)
        refetch()
      } else {
        toastMessage('error', `${res.data.error}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {
      setLoading(false)
    }
  }

  const deleteVariantRule = async (data: string) => {
    try {
      setLoading(true)

      const res = await deleteRule(data)      
      if (res.status === 200) {
        toastMessage('success', 'Product deleted successfully.')
        refetch()
      } else {
        toastMessage('error', `${res.message}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {      
      setLoading(false)
    }
  }

  const updateVariantRule = async (data: any) => {
    try {
      setLoading(true)
      let new_data: any = data
      new_data['modified_by'] = user['username']
      const res = await updateRule(data)

      if (res.status === 200) {
        toastMessage('success', 'Product updated successfully.')
        refetch()
      } else {
        toastMessage('error', `${res.data.error}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {
      setLoading(false)
    }
  }

  return {
    isLoading,
    addVariantRule,
    updateVariantRule,
    deleteVariantRule,
  }
}
