import {toTitleCase} from '../../../../utils/string'
import GlobalCustomFieldActionsOverlay from '../overlays/GlobalCustomFieldActionsOverlay'

const GlobalProjectsCustomFieldsTableRow = ({
  dndSettings,
  row,
  hasWritePermission,
  dndImage,
}: any) => {
  return (
    <tr {...dndSettings}>
      <td>
        {dndImage}
        {row.custom_field_name}
      </td>
      <td>{toTitleCase(row.field_type)}</td>
      <td>{row.used_in_projects}</td>
      {hasWritePermission && (
        <td>
          <GlobalCustomFieldActionsOverlay customFieldData={row} />
        </td>
      )}
    </tr>
  )
}

export default GlobalProjectsCustomFieldsTableRow
