import { Link } from 'react-router-dom';

const ProjectsTableActionDropdown = ({ id, name }: any) => {
  return (
    <div className='px-2 py-1'>
      <Link 
        to={`/products/all-products/${id}/${name}/#purchasability`} 
        className='text-dark fw-bold text-hover-primary fs-6'
      >
        Edit
      </Link>
    </div>
  );
};

export default ProjectsTableActionDropdown;
