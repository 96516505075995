import {useEffect} from 'react'
import BarChart from '../../../components/charts/BarChart'
import {useParams} from 'react-router-dom'
import {dataToBarChart, extractChartData} from '../utils'
import {useGetReorderRateReport} from '../hooks/useGetReorderRateReport'

const ReorderRateReport = ({setProductName}: any) => {
  const params = useParams<any>()
  const productId = params?.id as string
  const {ReorderRateReportData, TotalPercentage, ProductName} = useGetReorderRateReport(productId)
  const {labels, chartData} = extractChartData(ReorderRateReportData, TotalPercentage)
  const {CustomerReportBarChartOption, ChartData} = dataToBarChart(
    chartData,
    labels,
    TotalPercentage
  )

  useEffect(() => {
    setProductName(ProductName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProductName])

  return (
    <div className='card border'>
      <div className='card-body p-7'>
        <div className='pb-5 mb-5'>
          <div className='row d-flex align-items-center'>
            <div className='col-xl-9 col-md-8 d-flex flex-wrap align-items-center'>
              <h2 className='text-dark fw-bolder me-5 mb-5'>Reorder Rate Report</h2>
            </div>
          </div>
        </div>

        <BarChart data={ChartData} options={CustomerReportBarChartOption} />
      </div>
    </div>
  )
}

export default ReorderRateReport
