import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

interface InfiniteScrollWrapperProps {
  name: string
  height: number
  children: React.ReactNode
  data?: any[]
  loaderComponent?: React.ReactNode
  loadMore: () => void
}

/**
 * Wrapper component for implementing infinite scroll functionality.
 *
 * @component
 * @param {InfiniteScrollWrapperProps} props - The props for the InfiniteScrollWrapper component.
 * @returns {React.ReactElement} The rendered InfiniteScrollWrapper component.
 */
const InfiniteScrollWrapper: React.FC<InfiniteScrollWrapperProps> = ({
  name,
  height,
  children,
  data,
  loaderComponent,
  loadMore,
}) => {
  return (
    <div
      id={`${name}-scrollableDiv`}
      style={{
        height: height,
        overflow: 'auto',
      }}
    >
      <InfiniteScroll
        dataLength={data?.length || 0}
        next={loadMore}
        hasMore={true}
        loader={loaderComponent}
        scrollableTarget={`${name}-scrollableDiv`}
        scrollThreshold={0.6}
      >
        {children}
      </InfiniteScroll>
    </div>
  )
}

export default InfiniteScrollWrapper
