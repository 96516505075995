import {useContext} from 'react'
import useApi from '../../../../services/useApi'
import {ProjectDetailsContext} from '../../context'

const useGetGanttChartData = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/gantt-chart`)

  return {
    ganttChartData: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetGanttChartData
