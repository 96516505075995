import {isEmpty} from '../../../utils/common'

export const TasksReportTableColumns = [
  {key: 'card_title', label: 'TICKET NAME', isSorted: true, headerStyle: 'min-w-400px'},
  {key: 'project_name', label: 'PROJECT', isSorted: true, headerStyle: 'min-w-200px'},
  {key: 'assignee', label: 'ASSIGNEE', isSorted: true, headerStyle: 'min-w-175px'},
  {key: 'module_name', label: 'MODULE', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'estimation', label: 'ESTIMATION', isSorted: true, headerStyle: 'min-w-120px'},
  {key: 'spent_time', label: 'SPENT TIME', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'remaining_time', label: 'REMAINING TIME', isSorted: true, headerStyle: 'min-w-175px'},
  {key: 'progress', label: 'PROGRESS', isSorted: true, headerStyle: 'min-w-80px', style: 'flex-end'},
]
export const MembersReportTableColumns = [
  {key: 'assignee', label: 'ASSIGNEE', isSorted: true, headerStyle: 'min-w-400px'},
  {key: 'total_cards', label: 'TOTAL TASK', isSorted: true, headerStyle: 'min-w-150px'},
  {key: 'total_estimation', label: 'ESTIMATION', isSorted: true, headerStyle: 'min-w-120px'},
  {key: 'total_spent_time', label: 'SPENT TIME', isSorted: true, headerStyle: 'min-w-150px'},
  {
    key: 'total_remaining_time',
    label: 'REMAINING TIME',
    isSorted: true,
    headerStyle: 'min-w-175px',
  },
  {key: 'progress_percentage', label: 'PROGRESS', isSorted: true, headerStyle: 'min-w-80px', style: 'flex-end'},
]

export const TimeSheetReportTableColumns = (meta: any) => {
  if (isEmpty(meta)) return []
  return [
    {key: 'customer_name', label: 'USERS', isSorted: true, headerStyle: 'min-w-400px w-400px border-end head-col-sticky'},
    {key: 'total_spent_time', label: 'TOTAL TIME', isSorted: false, headerStyle: 'min-w-120px w-120px head-col-sticky border-end'},
    ...Object?.entries(meta)?.map(([key, value]: any, index, array: any) => {
      const [day, year] = value.split('/ ')
      const month = year.split(' ')[0]
      const prevMonth = index > 0 ? array[index - 1][1].split(' / ')[1].split(' ')[0] : ''
      const monthStart = month !== prevMonth ? `${year}` : ''
      const className = key.includes('S') || day.includes('S') ? 'bg-light' : ''

      return {
        key,
        label: `${monthStart} <br/> ${day}`,
        isSorted: false,
        headerStyle: `min-w-100px ${className}`,
        className,
      }
    }),
  ]
}

// Matrix Page

export const matrixAxisOptions = [
  {label: 'Assignee', value: 'Assignee'},
  {label: 'Module', value: 'Module'},
  {label: 'Priority', value: 'Priority'},
  {label: 'Stage', value: 'Stage'},
]