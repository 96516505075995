import {useContext} from 'react'
import {useForm} from 'react-hook-form'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {SectionForm} from '../../../../components/SectionForm'
import {yupResolver} from '@hookform/resolvers/yup'
import {AddSpentTimeFormSchema, EditSpentTimeFormSchema} from '../../validations'
import {TicketDetailsPageContext} from '../../context'
import {defaultSpentTimeModalValues} from '../../utils'
import {SpentTimeModalTypes} from '../../interface'

const SpentTimeModal = ({showModal, onClose, isEdit, logData}: any) => {
  const {updateTicket, isLoadingTicketOperation, isSpentTimeLoading} =
    useContext(TicketDetailsPageContext)
  const {updateSpentTime, isTimeLogOperationLoading} = useContext(TicketDetailsPageContext)

  const {
    control,
    handleSubmit,
    setError,
    register,
    formState: {errors, isDirty, dirtyFields},
  } = useForm<any>({
    defaultValues: defaultSpentTimeModalValues(logData, isEdit),
    resolver: yupResolver(isEdit ? EditSpentTimeFormSchema : AddSpentTimeFormSchema),
  })

  const handleFormSubmit = (data: SpentTimeModalTypes) => {
    if (isEdit) {
      updateSpentTime(data, dirtyFields, setError, logData?.id)
    } else {
      updateTicket(data, {}, setError)
    }
  }

  return (
    <ConfirmationModal
      uniqueID='spent-time-modal'
      show={showModal}
      title={`${isEdit ? 'Edit' : 'Add'} Spent Time`}
      onClose={onClose}
      actionName={isEdit ? 'Save' : 'Add'}
      actionBtnClass='btn-primary'
      isDataLoading={isSpentTimeLoading}
      onAction={handleSubmit(handleFormSubmit)}
      isDisabled={!isDirty}
      isCloseOnBackdrop={false}
      isOperationLoading={isLoadingTicketOperation || isTimeLogOperationLoading}
      body={
        <SectionForm
          id='create-ticket-status'
          commonLabelClass='w-100'
          commonInputClass='col'
          register={register}
          control={control}
          errors={errors}
          inputs={[
            {
              id: 'start-spent_time_created_at',
              label: 'Date',
              required: true,
              isInputDate: true,
              class: 'col-md-6 mb-7',
              registerKey: 'spent_time_created_at',
              disableFutureDate: true,
            },
            {
              id: 'spent-time',
              label: 'Spent Time',
              required: true,
              registerKey: `${isEdit ? 'spent_time_diff' : 'spent_time'}`,
              isInputTime: true,
              class: 'col-md-6 mb-7',
              isNormalInput: true,
            },
            {
              id: 'log_description',
              label: 'Description',
              isTextArea: true,
              inputClass: 'resize-none',
              registerKey: 'log_description',
              maxLength: 255,
            },
          ]}
        />
      }
    />
  )
}

export default SpentTimeModal
