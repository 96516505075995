import useApi from '../../../../services/useApi'
import {useContext} from 'react'
import {ProjectDetailsContext} from '../../context'

const useGetProjectMembers = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/members`)

  return {
    projectMembers: response?.data?.filter((member: any) => member?.is_active) || [],
    isLoading: isFetching,
  }
}

export default useGetProjectMembers
