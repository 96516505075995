import {useContext, ReactNode} from 'react'
import {TicketDetailsPageContext} from '../context'

const HtmlPlaceholder = ({
  children,
  className = 'w-100',
}: {
  children: ReactNode
  className?: string
}): JSX.Element => {
  const {isLoadingTicketDetails, ticketDetails} = useContext(TicketDetailsPageContext)
  if (isLoadingTicketDetails && ticketDetails?.length === 0) {
    return (
      <span
        className={`w-100 h-100 placeholder bg-gray-200 rounded-2 cursor-default ${className}`}
      ></span>
    )
  } else {
    return <>{children}</>
  }
}

export default HtmlPlaceholder
