import React, {useContext} from 'react'
import {DynamicTable} from '../../../../../components/DynamicTable'
import ProductsTableRow from './ProductsTableRow'
import {ProductsReportPageContext} from '../../../contexts'
import {ProductsTableColumns} from '../../../utils'

const ProductsTable = () => {
  const {productsReportsData, isLoading, filters, onSortingChange} =
    useContext(ProductsReportPageContext)

  return (
    <DynamicTable
      id='products-table'
      data={productsReportsData}
      TableRow={ProductsTableRow}
      sortableColumns={ProductsTableColumns}
      loading={isLoading}
      filters={filters}
      onSortingChange={onSortingChange}
      permissionPath='orders_bulk orders_reports'
    />
  )
}

export default ProductsTable
