import {ElementNode, NodeKey, SerializedElementNode, LexicalNode, EditorConfig} from 'lexical'

type SerializedExtendedElementNode = SerializedElementNode & {
  className?: string
  attributes?: Record<string, string>
}

export class ExtendedElementNode extends ElementNode {
  __className: string
  __attributes: Record<string, string>

  static getType(): string {
    return 'extended-element'
  }

  static clone(node: ExtendedElementNode): ExtendedElementNode {
    const clone = new ExtendedElementNode(node.__className, node.__attributes, node.__key)
    return clone
  }

  constructor(className: string = '', attributes: Record<string, string> = {}, key?: NodeKey) {
    super(key)
    this.__className = className
    this.__attributes = attributes
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement('div')
    if (this.__className) {
      dom.className = this.__className
    }
    for (const [key, value] of Object.entries(this.__attributes)) {
      if (isValidAttributeName(key)) {
        dom.setAttribute(key, value)
      }
    }
    return dom
  }

  updateDOM(prevNode: ExtendedElementNode, dom: HTMLElement): boolean {
    const shouldUpdate =
      prevNode.__className !== this.__className ||
      JSON.stringify(prevNode.__attributes) !== JSON.stringify(this.__attributes)

    if (shouldUpdate) {
      if (this.__className !== prevNode.__className) {
        dom.className = this.__className
      }

      // Update attributes
      const prevAttributes = prevNode.__attributes
      const currentAttributes = this.__attributes

      // Remove old attributes
      Object.keys(prevAttributes).forEach((key) => {
        if (!(key in currentAttributes)) {
          dom.removeAttribute(key)
        }
      })

      // Set new or changed attributes
      Object.entries(currentAttributes).forEach(([key, value]) => {
        if (prevAttributes[key] !== value && isValidAttributeName(key)) {
          dom.setAttribute(key, value)
        }
      })
    }

    return shouldUpdate
  }

  // Getters and setters
  getClassName(): string {
    return this.__className
  }

  setClassName(className: string): void {
    const self = this.getWritable()
    self.__className = className
  }

  getAttributes(): Record<string, string> {
    return this.__attributes
  }

  setAttributes(attributes: Record<string, string>): void {
    const writableNode = this.getWritable()
    writableNode.__attributes = {...writableNode.__attributes, ...attributes}
  }

  // Serialization
  exportJSON(): SerializedExtendedElementNode {
    return {
      ...super.exportJSON(),
      type: 'extended-element',
      version: 1,
      className: this.__className,
      attributes: this.__attributes,
    }
  }

  static importJSON(serializedNode: SerializedExtendedElementNode): ExtendedElementNode {
    const node = new ExtendedElementNode(serializedNode.className, serializedNode.attributes)
    node.setFormat(serializedNode.format)
    node.setIndent(serializedNode.indent)
    node.setDirection(serializedNode.direction)
    return node
  }
}

// Helper function
function isValidAttributeName(name: string): boolean {
  return /^[a-zA-Z_:][-a-zA-Z0-9_:.]*$/.test(name)
}

// Factory function
export function $createExtendedElementNode(
  className: string = '',
  attributes: Record<string, string> = {}
): ExtendedElementNode {
  return new ExtendedElementNode(className, attributes)
}

// Type guard
export function $isExtendedElementNode(
  node: LexicalNode | null | undefined
): node is ExtendedElementNode {
  return node instanceof ExtendedElementNode
}
