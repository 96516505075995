import {useState, useRef, useEffect, useMemo} from 'react'
import {Modal} from 'react-bootstrap'
import {isEmpty} from '../../utils/common'
import {MultiSelectTreeTypes} from './MultiSelectTreeTypes'

function MultiSelectTree({
  id,
  treeData = [],
  modalBodyClass = '',
  placeholder = 'Select',
  labelKey = '',
  valueKey = 'id',
  defaultValue = [],
  onSubmit,
  label = '',
  isRequired = false,
  isDisable = false,
  isReadOnly = false,
  className = '',
  isLoading = false,
  isSearch = false,
  expandAllBtn = false,
  collapseAllBtn = false,
  childPadding = 10,
  returnKeys = ['id'],
  isDefaultExpandAll = false,
  isDisableExpand = false,
  error,
}: MultiSelectTreeTypes) {
  // Convert defaultValue to numbers before setting the state
  const convertToNumber = (value: string | number) =>
    typeof value === 'string' ? Number(value) : value

  // Recursive function to find a node by item ID at any depth
  const findNodeById = (nodes: any[], itemId: number): any | null => {
    for (const node of nodes) {
      if (convertToNumber(node[valueKey]) === itemId) return node
      if (node.children) {
        const found = findNodeById(node.children, itemId)
        if (found) return found
      }
    }
    return null
  }

  // Format default values
  const initialDefaultValue = useMemo(() => {
    if (Array.isArray(defaultValue) && typeof defaultValue[0] === 'object') {
      return defaultValue.map((item: any) => item[valueKey])
    }
    return defaultValue.map((item: any) => convertToNumber(item))
  }, [defaultValue, valueKey])

  // State Management
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<number[]>(initialDefaultValue)
  const [tempSelectedItems, setTempSelectedItems] = useState<number[]>(initialDefaultValue)
  const [expandedNodes, setExpandedNodes] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [isDefaultValueAssigned, setDefaultValueAssigned] = useState(false)
  const multiSelectTreeDropdownBtnRef = useRef<HTMLButtonElement>(null)

  //Set default value
  useEffect(() => {
    if (
      !isEmpty(defaultValue) &&
      !isDefaultValueAssigned &&
      (selectedItems.length === 0 || tempSelectedItems.length === 0)
    ) {
      const numericDefaultValue = initialDefaultValue
      setSelectedItems(numericDefaultValue)
      setTempSelectedItems(numericDefaultValue)
      setDefaultValueAssigned(true)
    }
  }, [defaultValue, initialDefaultValue, isDefaultValueAssigned, selectedItems, tempSelectedItems])

  useEffect(() => {
    if (isDefaultExpandAll && !isEmpty(treeData)) {
      expandAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultExpandAll, treeData])

  const toggleModal = () => {
    setIsOpen(!isOpen)
    setTempSelectedItems(selectedItems)
  }

  const handleSelect = (id: string | number) => {
    const numericId = convertToNumber(id)
    setTempSelectedItems((prev) =>
      prev.includes(numericId) ? prev.filter((item) => item !== numericId) : [...prev, numericId]
    )
  }

  const saveSelection = () => {
    setSelectedItems(tempSelectedItems)
    setIsOpen(false)

    if (!onSubmit) return

    const formatSelection = () => {
      if (returnKeys.includes('all')) {
        return tempSelectedItems.map((item) => findNodeById(treeData, item)).filter(Boolean) // Keep only found nodes
      }

      return tempSelectedItems
        .map((item) => {
          const node = findNodeById(treeData, item)
          if (!node) return null

          // Case 1: Return single key values
          if (returnKeys.length === 1) {
            return node[returnKeys[0]]
          }

          // Case 2: Return objects with specified keys
          const selectedObj = returnKeys.reduce((acc: any, key: string) => {
            if (node[key] !== undefined) acc[key] = node[key]
            return acc
          }, {})

          return Object.keys(selectedObj).length > 0 ? selectedObj : null
        })
        .filter(Boolean) // Remove null or empty objects
    }

    onSubmit(formatSelection())
  }

  const toggleNode = (id: string) => {
    setExpandedNodes((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    )
  }

  const expandAll = () => {
    const allNodes = getAllNodeIds(treeData)
    setExpandedNodes(allNodes)
  }

  const collapseAll = () => {
    setExpandedNodes([])
  }

  const getAllNodeIds = (nodes: any[]): string[] => {
    return nodes.reduce((acc: string[], node: any) => {
      if (node.children) {
        return [...acc, node[valueKey], ...getAllNodeIds(node.children)]
      }
      return [...acc, node[valueKey]]
    }, [])
  }

  const getSelectedNodeLabels = (nodes: any[], selectedIds: number[]): string[] => {
    return nodes.reduce((acc: string[], node: any) => {
      if (selectedIds.includes(Number(node[valueKey]))) {
        acc.push(node[labelKey])
      }
      if (node.children) {
        acc.push(...getSelectedNodeLabels(node.children, selectedIds))
      }
      return acc
    }, [])
  }

  // format selected option's labels and show it inside button
  const getSelectedLabels = useMemo((): string => {
    const selectedLabels = getSelectedNodeLabels(treeData, selectedItems)
    if (selectedLabels.length === 0) return placeholder

    const labelText = selectedLabels.join(', ')
    const maxWidth = multiSelectTreeDropdownBtnRef.current
      ? multiSelectTreeDropdownBtnRef.current.offsetWidth - 70
      : 0

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = getComputedStyle(multiSelectTreeDropdownBtnRef.current as HTMLElement).font
      let truncatedText = labelText
      while (context.measureText(truncatedText).width > maxWidth && truncatedText.length > 0) {
        truncatedText = truncatedText.slice(0, -1)
      }
      return truncatedText.length < labelText.length ? truncatedText.trim() + '...' : labelText
    }

    return labelText
    //eslint-disable-next-line
  }, [selectedItems, placeholder, treeData])

  // filter nodes based on search term
  const filterNodes = (nodes: any[], term: string, expandedNodeIds: string[]): any[] => {
    return nodes.reduce((acc: any[], node: any) => {
      if (node[labelKey].toLowerCase().includes(term.toLowerCase())) {
        acc.push(node)
      } else if (node.children) {
        const filteredChildren = filterNodes(node.children, term, expandedNodeIds)
        if (filteredChildren.length > 0) {
          acc.push({...node, children: filteredChildren})
          expandedNodeIds.push(node[valueKey]) // Expand the parent node if children match
        }
      }
      return acc
    }, [])
  }

  // manage filtered nodes based on expanded or collapsed
  const filteredTreeData = useMemo(() => {
    const expandedNodeIds: string[] = []
    const filteredData = searchTerm ? filterNodes(treeData, searchTerm, expandedNodeIds) : treeData
    setExpandedNodes((prevExpandedNodes) => [
      ...prevExpandedNodes,
      ...expandedNodeIds.filter((id) => !prevExpandedNodes.includes(id)),
    ])
    return filteredData
    //eslint-disable-next-line
  }, [searchTerm, treeData])

  const renderTreeNodes = (nodes: any[], isChildNode: boolean = false) => (
    <ul style={{paddingLeft: isChildNode ? `${childPadding}px'` : '8px'}}>
      {nodes.map((node: any) => (
        <li
          key={node[valueKey]}
          className='border-top-1 border-gray-300 border-top-dashed list-unstyled'
        >
          <div className='d-flex align-items-center'>
            {node.children && (
              <button
                onClick={() => toggleNode(node[valueKey])}
                className={`btn btn-sm btn-link text-muted p-0 me-4`}
                aria-label={expandedNodes.includes(node[valueKey]) ? 'Collapse' : 'Expand'}
                type='button'
                disabled={isDisableExpand}
              >
                {expandedNodes.includes(node[valueKey]) ? (
                  <i className='bi bi-dash-circle fs-3 p-0'></i>
                ) : (
                  <i className='bi bi-plus-circle fs-3 p-0'></i>
                )}
              </button>
            )}
            {!node.children && <div style={{width: '2.30rem'}}></div>}
            <input
              type='checkbox'
              id={node[valueKey]}
              checked={tempSelectedItems.includes(Number(node[valueKey]))}
              onChange={() => handleSelect(node[valueKey])}
              className='form-check-input'
            />
            <label htmlFor={node[valueKey]} className='p-4 cursor-pointer'>
              {node[labelKey]}
            </label>
          </div>
          {node.children &&
            expandedNodes.includes(node[valueKey]) &&
            renderTreeNodes(node.children, true)}
        </li>
      ))}
    </ul>
  )

  return (
    <div className={`position-relative ${className}`} id={`${id}-multi-select-tree`}>
      <label
        className={`form-label ${isRequired ? 'required' : ''}`}
        htmlFor={`${id}-multi-select-tree-dropdown-btn`}
      >
        {label}
      </label>
      <button
        ref={multiSelectTreeDropdownBtnRef}
        onClick={() => !isReadOnly && treeData && treeData?.length !== 0 && toggleModal()}
        className={`d-flex justify-content-between align-items-center form-select cursor-default ${
          error ? 'is-invalid' : ''
        }`}
        style={{cursor: isReadOnly || isDisable ? 'default' : 'pointer'}}
        aria-haspopup='dialog'
        aria-expanded={isOpen}
        type='button'
        id={`${id}-multi-select-tree-dropdown-btn`}
        disabled={isDisable}
      >
        {getSelectedLabels}
        {(isLoading || (treeData && treeData?.length === 0)) && (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        )}
      </button>
      {error && <p className='text-danger'>{error.message}</p>}
      <Modal
        show={isOpen}
        onHide={toggleModal}
        dialogClassName='modal-l mw-700px'
        aria-labelledby='select-category-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id='select-category-modal'>{placeholder}</Modal.Title>
        </Modal.Header>
        <Modal.Title id='select-category-modal' className='ps-8 pe-8 mt-5 mb-5'>
          {expandAllBtn ||
            (collapseAllBtn && (
              <div className='d-flex justify-content-between mb-2'>
                {expandAllBtn && (
                  <button type='button' onClick={expandAll} className='btn btn-outline-secondary'>
                    Expand All
                  </button>
                )}
                {collapseAllBtn && (
                  <button type='button' onClick={collapseAll} className='btn btn-outline-secondary'>
                    Collapse All
                  </button>
                )}
              </div>
            ))}
          {isSearch && (
            <input
              type='text'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className='form-control form-control-solid'
            />
          )}
        </Modal.Title>
        <Modal.Body className={`overflow-scroll pt-0 ${modalBodyClass}`}>
          {renderTreeNodes(filteredTreeData, false)}
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-outline' onClick={toggleModal}>
            Close
          </button>
          <button type='button' className='btn btn-primary' onClick={saveSelection}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default MultiSelectTree
