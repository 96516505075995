import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getInActiveCustomers} from '../services'
import useFilters from '../../../hook/useFilters'
import {dataToAnonymousCustomerListing} from '../utils'


export const useGetAnonymouslyActiveCustomers = (limit:number): any => {
  const initialFilters = {
    page: 1,
    limit: limit,
    sort_by: 'total_orders/1',
  }
  const {filters, setSingleFilter ,setMultipleFilters} = useFilters(initialFilters)

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`active-customers-no-${limit}-orders`, () => {
    return getInActiveCustomers(`/analytics/customers/active/no-orders`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  const onClickSorting = (sortBy: string, sortDirection: any) => {
    const direction = sortDirection === 'asc' ? 1 : -1
    const sortFlag = sortBy + '/' + direction
    setMultipleFilters({
      sort_by: sortFlag,
      page: 1,
    })
  }

  return {
    anonymousCustomers: response?.data ? dataToAnonymousCustomerListing(response.data) : [],
    totalAnonymousCustomers:
      response?.meta?.pagination?.total || (response?.data ? response.data.length : 0),
    pagination: response?.['meta']?.['pagination'] || {},
    isLoading: isFetching,
    onPageChange,
    onAnonymouslyActiveCustomerClickSorting: onClickSorting,
    AnonymouslyActiveCustomerFilters: filters,
  }
}
