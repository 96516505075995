import {useQuery} from 'react-query'
import {getNewCustomerReport} from '../services'

export const useGetNewCustomerReport = (productId: any) => {
  const {data, isFetching} = useQuery(
    ['get-NewCustomerReport'],
    () => {
      return getNewCustomerReport(
        `/analytics/sold-products/new-customers-report?product_id=${productId}`
      )
    },
    {
      enabled: !!productId,
    }
  )

  return {
    NewCustomerReportData: data?.data || [],
    TotalPercentage: data?.total_percentage || {},
    isLoading: isFetching,
  }
}
