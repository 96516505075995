import {useContext, useState} from 'react'
import useApi from '../../../../services/useApi'
import {ProjectDetailsContext} from '../../context'

export default function useUpdateAgileBoard() {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const [isLoading, setLoading] = useState(false)

  async function updateAgileBoard(payload: any, taskId: string) {
    return Api.patch(`/agile/projects/${projectId}/cards/${taskId}`, payload, {
      setLoading: setLoading,
    })
  }

  return {
    updateAgileBoard,
    isLoading: isLoading,
  }
}
