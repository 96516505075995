import useApi from '../../../services/useApi'

const useGetProjectMembersList = (projectId: string) => {
  const Api = useApi()

  const {data: response, isFetching} = Api.useGetQuery(`agile/projects/${projectId}/members`)

  return {
    projectMembersList: response?.data?.filter((item: any) => item?.name !== 'Unassigned') || [],
    isLoading: isFetching,
  }
}

export default useGetProjectMembersList
