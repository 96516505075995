import {useState} from 'react'
import useApi from '../../../services/useApi'

const useProjectOperations = () => {
  const Api = useApi()
  const [isLoading, setLoading] = useState(false)

  const createNewProjectDetails = (payload: any, setError: Function) => {
    return Api.post('/agile/projects', payload, {
      setLoading: setLoading,
      successMessage: 'Project created successfully.',
      refetch: 'projects-list',
      setUseFormError: setError,
    })
  }

  const updateProjectDetails = (payload: any, projectId: number, dirtyFields: any, setError?: Function) => {
    return Api.patch(`/agile/projects/${projectId}`, payload, {
      dirtyFields: dirtyFields,
      setLoading: setLoading,
      successMessage:
        payload?.isArchived === undefined
          ? 'Project updated successfully'
          : payload?.isArchived
          ? 'Project moved to Archived'
          : 'Project moved to Active',
      refetch: 'projects-list',
      setUseFormError: setError,
    })
  }

  const addProjectToFavorite = (projectId: string) => {
    return Api.post(
      `/agile/projects/${projectId}/fevorite`,
      {},
      {
        setLoading: setLoading,
        refetch: 'projects-list',
      }
    )
  }

  const deleteProject = (projectId: string) => {
    return Api.delete(
      `/agile/projects/${projectId}`,
      {},
      {
        setLoading: setLoading,
        refetch: 'projects-list',
        successMessage: 'Project deleted successfully',
      }
    )
  }

  return {
    createNewProjectDetails,
    updateProjectDetails,
    addProjectToFavorite,
    deleteProject,
    isLoading: isLoading,
  }
}

export default useProjectOperations
