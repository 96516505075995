/* eslint-disable react-hooks/exhaustive-deps */
import {useContext} from 'react'
import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'
import {ProjectDetailsContext} from '../../context'

const useGetMembersReportData = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  
  const initialFilters = {
    page: 1,
    limit: 30,
    sort_by: 'assignee/1',
    project_id: projectId,
    report_type: 'individual_project_members_report',
  }

  const {filters, onSearch, onSortingChange, searchValue, setMultipleFilters, onPageChange} =
    useFilters(initialFilters, {
      sortType: 'number',
    })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(
    `/agile/projects/members-report`,
    {
      queryId: 'members-report',
      filters: filters,
    },
    {
      enabled: projectId !== '',
    }
  )

  const onProjectChange = (id: any) => {
    setMultipleFilters({
      project_id: id,
      page: 1,
    })
  }


  return {
    membersReportData: response?.data?.members_report?.data || [],
    pagination: response?.data?.members_report?.meta?.pagination || {},
    filtersData: response?.data?.filters || [],
    filters,
    isLoading: isFetching,
    onSortingChange,
    onSearch,
    onProjectChange,
    searchValue,
    onPageChange,
    refetch
  }
}

export default useGetMembersReportData
