import {useEffect} from 'react'
import {useSettings} from '../../context/SettingsContext'

const DynamicStyles = () => {
  const {
    settings: {isDraggableBlock, isFooterActions, isCharLimit},
  } = useSettings()

  useEffect(() => {
    const root = document.documentElement
    root.style.setProperty(
      '--editor-content-editable-padding',
      `10px ${isDraggableBlock ? '28px' : '15px'} ${
        isFooterActions ? '40px' : isCharLimit ? '30px' : '15px'
      }`
    )
  }, [isDraggableBlock, isFooterActions, isCharLimit])

  return null
}

export default DynamicStyles
