import usePermission from '../../../hook/usePermission'
import {TimeSheetReportPageContext} from '../context'
import useGetTimeSheetData from '../hooks/TimeSheetReport/useGetTimeSheetData'
import TimeSheetHeader from '../sections/headers/TimeSheetHeader'
import TimeSheetTable from '../sections/tables/timesheetReport/TimeSheetTable'

const TimeSheet = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_reports', 'write')

  const {
    isLoading: isLoadingTimeSheetData,
    timeSheetData,
    timeSheetMeta,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onProjectChange,
    onDateChange,
    filtersData,
    refetch,
  } = useGetTimeSheetData()

  const contextValue = {
    hasProjectSettingsPermission,
    isLoadingTimeSheetData,
    timeSheetData,
    timeSheetMeta,
    onSortingChange,
    onSearch,
    searchValue,
    filters,
    onProjectChange,
    onDateChange,
    filtersData,
    refetch,
  }

  return (
    <TimeSheetReportPageContext.Provider value={contextValue}>
      <TimeSheetHeader />
      <TimeSheetTable />
    </TimeSheetReportPageContext.Provider>
  )
}

export default TimeSheet
