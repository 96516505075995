import React, {Component, ErrorInfo, ReactNode} from 'react'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class GanttErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    return {hasError: true}
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Gantt Chart Error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return <div className='gantt-error'>Error loading Gantt chart. Please try refreshing.</div>
    }

    return this.props.children
  }
}

export default GanttErrorBoundary
