type KeyHandler = (event: KeyboardEvent) => void

type ScopeCheck = () => boolean

class ScreenKeys {
  private store: Map<string, KeyHandler> = new Map()
  private scopeCheck?: ScopeCheck

  limit(check: ScopeCheck): void {
    this.scopeCheck = check
  }

  isActive(): boolean {
    return !this.scopeCheck || this.scopeCheck()
  }

  add(key: string, handler: KeyHandler): void {
    const normalizedKey = key.toLowerCase().replace(/\s+/g, '')
    this.store.set(normalizedKey, handler)
  }

  getHandler(key: string): KeyHandler | undefined {
    return this.store.get(key)
  }
}

const keyChain: ScreenKeys[] = []
let isInitialized = false

function handleKeyDown(event: KeyboardEvent): void {
  if (!keyChain.length) return

  if (
    event.ctrlKey ||
    event.altKey ||
    event.metaKey ||
    event.shiftKey ||
    event.key.length > 1 ||
    event.key === ' '
  ) {
    const keyParts: string[] = []
    if (event.ctrlKey) keyParts.push('ctrl')
    if (event.altKey) keyParts.push('alt')
    if (event.metaKey) keyParts.push('meta')
    if (event.shiftKey) keyParts.push('shift')

    const keyName = event.key === ' ' ? 'space' : event.key.toLowerCase()
    keyParts.push(keyName)

    const combinedKey = keyParts.join('+')
    const target = event.target as HTMLElement

    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') return

    for (let i = keyChain.length - 1; i >= 0; i--) {
      const screenKey = keyChain[i]
      const handler = screenKey.getHandler(combinedKey) || screenKey.getHandler(keyName)

      if (handler && screenKey.isActive()) {
        handler(event)
        event.preventDefault()
        break
      }
    }
  }
}

export const hotkeys = {
  subscribe: (callback: (screenKeys: ScreenKeys) => void): (() => void) => {
    if (!isInitialized) {
      document.addEventListener('keydown', handleKeyDown)
      isInitialized = true
    }

    const screenKey = new ScreenKeys()
    keyChain.push(screenKey)
    callback(screenKey)

    return () => {
      const index = keyChain.indexOf(screenKey)
      if (index >= 0) keyChain.splice(index, 1)
    }
  },
}
