import {Link, useLocation} from 'react-router-dom'

function UserNavigations() {
  const location = useLocation()

  return (
    <div className=''>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10'>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` + (location.pathname === '/settings/users' && 'active')
            }
            to='/settings/users'
          >
            Users
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/settings/users/roles' && 'active')
            }
            to='/settings/users/roles'
          >
            Roles & Permission
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default UserNavigations
