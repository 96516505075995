import useApi from '../../../../services/useApi'

const useGetMemberProjectsList = (memberId: string) => {
  const Api = useApi()

  const filters = {
    username: memberId,
  }

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/members/projectlist`, {
    filters: filters,
  })

  return {
    projectMembersList: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetMemberProjectsList
