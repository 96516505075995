import Api from '../../../services/Api'

export const getProductSales = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}
export const getMainProduct = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}


export const getSkuReport = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getSoldProductOverview = (url: string, filters: any) => {
  return Api.get(`${url}`,filters,  {}).then((d: any) => {
    return d.data
  })
}

export const getTopCustomerByState = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getProductPerformance = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getSkuDistribution = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getAllStateList = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getPriceReport = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getRevenueReport = (url: string) => {
  return Api.get(`${url}`, {}, {}).then((d: any) => {
    return d.data
  })
}

export const getQuantitySoldReport = (url: string) => {
  return Api.get(`${url}`, {}, {}).then((d: any) => {
    return d.data
  })
}

export const getReorderRateReport = (url: string) => {
  return Api.get(`${url}`, {}, {}).then((d: any) => {
    return d.data
  })
}

export const getNewCustomerReport = (url: string) => {
  return Api.get(`${url}`, {}, {}).then((d: any) => {
    return d.data
  })
}