import {useContext, useState} from 'react'
import {ProjectMembersPageContext} from '../../context'
import Search from '../../../../components/Search'
import AddBoardMembersModal from '../modals/AddBoardMembersModal'

const MemberPageHeader = () => {
  const {hasMembersPermission, onSearch} = useContext(ProjectMembersPageContext)
  const [showModal, setShowModal] = useState(false)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <Search onSearch={onSearch} />
      {hasMembersPermission && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Add Member
        </button>
      )}
      {showModal && <AddBoardMembersModal show={showModal} onClose={() => setShowModal(false)} />}
    </div>
  )
}

export {MemberPageHeader}
