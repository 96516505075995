import {useParams} from 'react-router-dom'
import useApi from '../../../services/useApi'
import {projectMappingDetailsData} from '../parsings'

const useGetProjectDetails = () => {
  const Api = useApi()
  const {projectId} = useParams()

  const {
    data: response,
    isLoading,
    isFetching,
  } = Api.useGetQuery(`/agile/projects/${projectId}`, {
    queryId: 'project-details',
  })

  return {
    projectDetails: response?.data?.[0] || {},
    projectMappingDetails: projectMappingDetailsData(response?.data?.[0]?.project_mapping),
    isLoading,
    isFetching,
  }
}

export default useGetProjectDetails
