export const RenderBothArrow = () => {
  return (
    <>
      <div className='sort-both-arrow'>
        <div className='sort-top-arrow lh-1'>
          <i className='bi bi-caret-up-fill fs-8 lh-1'></i>
        </div>
        <div className='sort-bottom-arrow lh-1'>
          <i className='bi bi-caret-down-fill fs-8 lh-1'></i>
        </div>
      </div>
    </>
  )
}
