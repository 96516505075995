import Api from '../../../services/Api'

export const getPoRequestsList = (filters: any) => {
  const url = `/purchase/orders`
  return Api.get(`${url}`, filters).then((d: any) => {
    return d.data
  })
}

export const getBoProductsDropdownList = () => {
  const url = `/bulkOrders/dropdown/products`
  return Api.get(url).then((d: any) => {
    return d.data
  })
}

export const getPendingPoRequestDetails = (po_id: string) => {
  const url = `/purchase/orders/${po_id}`
  return Api.get(url).then((d: any) => {
    return d.data
  })
}

export const getOrderShippingAddressDetails = (po_id: string, customer_id: string) => {
  const url = `/bulkOrders/purchaseOrders/${po_id}/customers/${customer_id}/addresses`
  return Api.get(url).then((d: any) => {
    return d.data
  })
}

export const getCompletedPoRequestDetails = (po_id: string) => {
  const url = `/purchase/orders/${po_id}/completed`
  return Api.get(url).then((d: any) => {
    return d.data
  })
}

export function createPoReq(bop_id: string, data: any) {
  const url = `/purchase/orders/${bop_id}/create/order`
  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function deletePoReq(bop_id: string, data: any) {
  const url = `/purchase/orders/${bop_id}`

  return Api.put(url, data, null, null).then((d: any) => {
    return d
  })
}
