import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import FloatingNotificationButton from '../../../../../components/FloatingNotificationButton/FloatingNotificationButton'
import {useAppSelector} from '../../../../../redux/useTypedSelector'

const DefaultTitle = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs, pageTitleClassName, pageContent, isLoading} =
    usePageData()
  const hasPageTitle = isLoading ? true : !!pageTitle
  const {config} = useLayout()
  const {user} = useAppSelector((state) => state.auth)
  console.log('01-23 user: ', user)

  return (
    <>
      <div className='d-flex justify-content-between mb-10'>
        <div className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap pb-5 pb-lg-0'>
          {/* begin::Heading */}
          <div>
            {hasPageTitle && (
              <h1
                className={`d-flex flex-column ${
                  pageTitleClassName ? pageTitleClassName : 'text-dark fw-bolder'
                } my-0 fs-1`}
              >
                {isLoading && !pageTitle && (
                  <span className='placeholder-glow'>
                    <span className='placeholder bg-gray-300 h-30px min-w-120px rounded-2' />
                  </span>
                )}
                {pageTitle && pageTitle}
                {pageDescription && (
                  <small className='text-muted fs-6 fw-bold ms-1 pt-1'>{pageDescription}</small>
                )}
              </h1>
            )}
            {/* end::Heading */}

            {pageBreadcrumbs &&
              pageBreadcrumbs.length > 0 &&
              config.pageTitle &&
              config.pageTitle.breadCrumbs && (
                <ul className='breadcrumb breadcrumb-dot fs-base m-0 mt-3'>
                  {Array.from(pageBreadcrumbs)
                    .filter((b) => !b.isSeparator)
                    .map((item, index) => (
                      <li
                        className={clsx('breadcrumb-item', {
                          'text-dark': !item.isSeparator && item.isActive,
                          'text-muted': !item.isSeparator && !item.isActive,
                        })}
                        key={`${item.path}${index}`}
                      >
                        <Link className='text-muted' to={item.path}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  <li className='breadcrumb-item text-dark'>{pageTitle}</li>
                </ul>
              )}
          </div>

          {pageContent}
        </div>
        <div className={`${user?.role_id === '66f4f1aacb3b3b19fd774f98' ? '' : 'd-none'}`}>
          <FloatingNotificationButton />
        </div>
      </div>
    </>
  )
}

export {DefaultTitle}
