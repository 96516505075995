export const formatProjectDetails = (row: any) => ({
  id: row.id,
  buId: row?.business_unit_id,
  name: row?.name,
  description: row?.description,
  projectInitials: row?.project_initials,
  businessUnit: row?.business_unit,
  members: row?.members,
  ownerName: row?.owner_name,
  createdAt: row?.created_at,
  isFevorite: row?.is_fevorite,
  isArchived: row?.is_archived,
  ownerUsername: row?.owner_username,
})

export const defaultProjectCreateData = (isEdit: boolean, projectDetails: any) => ({
  name: isEdit ? projectDetails?.name : '',
  description: isEdit ? projectDetails?.description : '',
  buId: isEdit ? projectDetails?.buId : 0,
})
