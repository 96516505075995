import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {useContext, useState} from 'react'
import {ProjectsListPageContext} from '../../context'
import ProjectMembersListingModal from '../modals/ProjectMembersListingModal'
import Date from '../../../../components/Date/Date'

const ActiveProjectsTableRow = ({row, actionComponent}: any) => {
  const {addProjectToFavorite} = useContext(ProjectsListPageContext)
  const [isFavorite, setIsFavorite] = useState(row?.isFevorite === 1 ? true : false)
  const [showModal, setShowModal] = useState(false)

  const handleFavorite = () => {
    setIsFavorite(!isFavorite)
    addProjectToFavorite(row?.id)
  }

  return (
    <tr>
      <td>
        <div className='project-table-name-col'>
          <div className='d-flex align-items-center'>
            <div
              onClick={handleFavorite}
              className={`min-w-25px rating-label me-2 ${isFavorite ? 'checked' : ''}`}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen029.svg'
                className='svg-icon svg-icon-2 me-1 cursor-pointer'
              />
            </div>
            <Link
              to={`/projects/all-projects/${row?.id}`}
              className='text-gray-800 text-hover-primary'
            >
              {row?.name}
            </Link>
          </div>
        </div>
      </td>
      <td>{row?.businessUnit}</td>
      <td className='text-center'>
        <span
          className='cursor-pointer text-decoration-underline text-hover-primary'
          onClick={() => setShowModal(true)}
        >
          {row?.members}
        </span>
      </td>
      <td>{row?.ownerName}</td>
      <td>
        <Date date={row['createdAt']} />
      </td>
      {actionComponent && <td>{actionComponent(row)}</td>}

      {showModal && (
        <ProjectMembersListingModal
          show={showModal}
          onClose={() => setShowModal(false)}
          projectId={row?.id}
        />
      )}
    </tr>
  )
}

export default ActiveProjectsTableRow
