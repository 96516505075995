import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'

import UserCreate from './layout/UserCreate'
import UserNavigations from './UserNavigations'
import {UserRoles} from './UserRoles'

const UserBreadCrums: Array<PageLink> = [
  {
    title: 'User',
    path: '/settings/users',
    isSeparator: false,
    isActive: false,
  },
]

export default function UserPage() {
  return (
    <Routes>
      <Route
        element={
          <>
            <UserNavigations />
            <Outlet />
          </>
        }
      >
        <Route
          path=''
          element={
            <>
              <PageTitle breadcrumbs={UserBreadCrums}>Users</PageTitle>
              <UserCreate />
            </>
          }
        />
        <Route
          path='/roles'
          element={
            <>
              <PageTitle breadcrumbs={UserBreadCrums}>Roles and Permissions</PageTitle>
              <UserRoles />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
