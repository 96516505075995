import useApi from '../../../../services/useApi'
import {useContext} from 'react'
import {ProjectDetailsContext} from '../../context'

export const useGetAgileBoardStructure = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/structure`, {
    queryId: 'agile-board-structure',
  })

  return {
    agileBoardStructure: response?.data || [],
    isLoading: isFetching,
  }
}
