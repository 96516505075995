import {useInfiniteQuery} from 'react-query'
import useFilters from '../../../hook/useFilters'
import Api from '../../../services/ApiV2'


export default function useGetCustomers(repEmail: any) {
  const initialFilters = {
    page: 1,
    limit: 10,
    rep_email: repEmail || '',
  }
  const {filters} = useFilters(initialFilters)

  const {data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading} = useInfiniteQuery(
    `get-assigned-customers-${repEmail}`,
    ({pageParam = initialFilters.page}) => {
      return getCustomers('/bulkOrders/reports/salesrep/customers', {
        ...filters,
        page: pageParam,
      })
    },
    {
      enabled: Boolean(repEmail),
      getNextPageParam: (res) => {
        return res?.meta?.next_page || undefined
      },
      cacheTime: 0,
    }
  )

  const onLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  return {
    customers: data?.pages?.flatMap((pageData) => pageData['data']) || [],
    pagination: data?.pages?.[data.pages.length - 1]?.meta?.pagination || {},
    isLoading,
    isFetchingNextPage,
    loadMore: onLoadMore,
  }
}

export const getCustomers = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d.data
  })
}
