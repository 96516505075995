import usePermission from '../../../hook/usePermission'
import {Pagination} from '../../../utils/pagination'
import {MembersReportPageContext} from '../context'
import useGetMembersReportData from '../hooks/MembersReport/useGetMembersReportData'
import MembersReportHeader from '../sections/headers/MembersReportHeader'
import MembersReportTable from '../sections/tables/MembersReportTable'

const MembersReport = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {
    isLoading,
    membersReportData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onPageChange,
    pagination,
    onProjectChange,
    filtersData,
    refetch,
  } = useGetMembersReportData()

  const contextValue = {
    hasProjectSettingsPermission,
    isLoading,
    membersReportData,
    onSortingChange,
    onPageChange,
    onProjectChange,
    onSearch,
    searchValue,
    filters,
    filtersData,
    refetch
  }

  return (
    <>
      <MembersReportPageContext.Provider value={contextValue}>
        <MembersReportHeader />
        <MembersReportTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </MembersReportPageContext.Provider>
    </>
  )
}

export default MembersReport
