import {CODE_LANGUAGES} from '../../../editorSettings'
import DropDown, {DropDownItem} from '../../../ui/DropDown'
import {getLanguageFriendlyName} from '@lexical/code'

export function CodeLanguageDropDown({
  disabled,
  codeLanguage,
  onCodeLanguageSelect,
}: {
  disabled: boolean
  codeLanguage: string
  onCodeLanguageSelect: (value: string) => void
}) {
  const CODE_LANGUAGE_OPTIONS = CODE_LANGUAGES
  return (
    <DropDown
      disabled={disabled}
      buttonClassName='toolbar-item code-language'
      buttonLabel={getLanguageFriendlyName(codeLanguage)}
      buttonAriaLabel='Select language'
    >
      {CODE_LANGUAGE_OPTIONS.map(({key, label, visible}: any) => {
        if (!visible) return null
        return (
          <DropDownItem
            className={`item ${key === codeLanguage ? 'active dropdown-item-active' : ''}`}
            onClick={() => onCodeLanguageSelect(key)}
            key={key}
          >
            <span className='text'>{label}</span>
          </DropDownItem>
        )
      })}
    </DropDown>
  )
}
