import {useEffect, useState} from 'react'
import {convertUTCToLocal} from '../utils/date'

function useDynamicDate(timestamp?: string) {
  const [dynamicTimestamp, setDynamicTimestamp] = useState<any>('')

  useEffect(() => {
    const updateTimes = () => {
      if (timestamp) {
        setDynamicTimestamp(convertUTCToLocal(timestamp, true, true))
      }
    }

    updateTimes()
    const intervalId = setInterval(updateTimes, 60000) // Update every minute

    return () => clearInterval(intervalId)
  }, [timestamp])

  return {dynamicTimestamp}
}

export default useDynamicDate
