import useApi from '../../../services/useApi'
import useFilters from '../../../hook/useFilters'
import {useContext, useState} from 'react'
import {groupedHistoryByUser} from '../utils'
import {TicketDetailsContext} from '../context'

const useGetTicketActivitiesHistory = () => {
  const Api = useApi()
  const {ticketId} = useContext(TicketDetailsContext)
  const [activities, setActivities] = useState<any[]>([])

  const initialFilters = {
    page: 1,
    limit: 20,
  }

  const {filters, onPageChange} = useFilters(initialFilters)

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/cards/${ticketId}/history`,
    {
      queryId: `${ticketId}-activities-history`,
      filters: filters,
    },
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      onSuccess: (response: any) => {
        if (filters.page === 1) {
          setActivities(response?.data?.data || [])
        } else {
          setActivities((prev: any) => [...prev, ...(response?.data?.data || [])])
        }
      },
    }
  )

  return {
    ticketActivitiesHistory: groupedHistoryByUser(activities),
    pagination: response?.data?.meta,
    isLoading: isFetching,
    onLoadMore: onPageChange,
    currentPage: filters.page,
  }
}

export default useGetTicketActivitiesHistory
