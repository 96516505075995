import React, {useContext} from 'react'
import {DynamicTable} from '../../../../../components/DynamicTable'
import SalesRepTableRow from './SalesRepTableRow'
import {SalesRepReportPageContext} from '../../../contexts'
import {SalesRepTableColumns} from '../../../utils'

const SalesRepTable = () => {
  const {salesRepReportsData, isLoading, filters, onSortingChange} =
    useContext(SalesRepReportPageContext)

  return (
    <DynamicTable
      id='sales-rep-table'
      data={salesRepReportsData}
      TableRow={SalesRepTableRow}
      sortableColumns={SalesRepTableColumns}
      loading={isLoading}
      filters={filters}
      onSortingChange={onSortingChange}
      tableClass="table table-row-dashed table-row-gray-300 align-middle mb-15 gs-0 gy-4"
      permissionPath='orders_bulk orders_reports'
    />
  )
}

export default SalesRepTable
