import React from 'react'
import {Link} from 'react-router-dom'
import Initials from '../../../../../components/Initials'
import {formatPrice} from '../../../../../utils/common'
import Date from '../../../../../components/Date/Date'

const CustomerTableRow = ({row}: {row: any}) => {
  return (
    <tr>
      <td>
        <Link
          to={`/customers/all-customer/details?id=${row.customer_id}`}
          state={{
            id: row.customer_id,
          }}
          className='d-flex align-items-center flex-grow-1 text-start cursor-pointer'
        >
          <div className='symbol symbol-circle symbol-35px me-3'>
            <Initials text={row.name[0]} />
          </div>
          <div className='d-flex flex-column'>
            <div className='align-items-center text-dark fw-semibold'>{row.name}</div>
            <div className='align-items-center text-muted'>{row.email}</div>
          </div>
        </Link>
      </td>
      <td>{row.rep_name}</td>
      <td>{row.tag_labels}</td>
      <td className='text-center '>{row.order_count}</td>
      <td>{formatPrice(row.order_total, false)}</td>
      <td className='text-center '>{row.lifetime_order_count || '-'}</td>
      <td className=''>{formatPrice(row.lifetime_order_total, false)}</td>
      <td>
        <Date date={row?.last_order_date} />
      </td>
    </tr>
  )
}

export default CustomerTableRow
