import {useParams} from 'react-router-dom'
import {PageLink} from '../../_metronic/layout/core'
import useMeta from '../../hook/useMeta'
import TicketDetails from './layouts/TicketDetails'
import {toTitleCase} from '../../utils/string'
import useGetProjectDetails from './hooks/useGetProjectDetails'
import usePermission from '../../hook/usePermission'
import {TicketDetailsContext} from './context'
import Navigation from '../../components/Navigation/Navigation'

function ProjectTicketDetailsPage() {
  useMeta('Projects')
  const {hasPermission} = usePermission()
  const {projectId, ticketId, ticketIdentifier} = useParams<any>()
  const {projectDetails, isLoading, isFetching} = useGetProjectDetails()
  const {name, status} = projectDetails
  const hasProjectPermission =
    hasPermission('projects_all projects', 'write') && status ? status !== 'archived' : false

  const ProjectTicketDetailsPageBreadCrumbs: Array<PageLink> = [
    {
      title: 'Projects',
      path: `/projects/all-projects/`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `${toTitleCase(name)}`,
      path: `/projects/all-projects/${projectId}?view=agile-board`,
      isSeparator: false,
      isActive: true,
    },
  ]

  const contextValue = {
    projectId,
    ticketId,
    ticketIdentifier,
    projectName: name,
    projectStatus: status,
    projectDetails,
    hasProjectPermission,
    isLoading: isFetching,
  }

  return (
    <TicketDetailsContext.Provider value={contextValue}>
      <Navigation
        baseUrl=''
        breadcrumbs={ProjectTicketDetailsPageBreadCrumbs}
        pageTitle={`#${ticketIdentifier?.toUpperCase()}`}
        isLoading={isLoading}
        navigationData={[]}
      />
      <TicketDetails />
    </TicketDetailsContext.Provider>
  )
}

export default ProjectTicketDetailsPage
