import {CheckboxTypes} from './CheckBoxTypes'

function Checkbox({
  id,
  name,
  value,
  className = '',
  label,
  register,
  isRequired,
  onChange,
  onBlur,
  onKeyDown,
  checked,
  disabled = false,
  error,
  ...rest
}: CheckboxTypes) {
  return (
    <div className={`form-check form-check-sm form-check-custom ${className}`}>
      <input
        id={id}
        className={`form-check-input ${error ? 'is-invalid' : ''}`}
        type='checkbox'
        name={name}
        value={value}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        checked={checked}
        disabled={disabled}
        {...rest}
        {...register}
        onChange={onChange}
      />
      {label && <span className={`form-check-label ${isRequired ? 'required' : ''}`}>{label}</span>}
    </div>
  )
}
export default Checkbox
