import {useContext} from 'react'
import {TicketDetailsPageContext} from '../../context'
import {convertUTCToLocal} from '../../../../utils/date'
import {isEmpty} from '../../../../utils/common'
import {formattedActivities} from '../../utils'
import ActionButtons from '../../../../components/ActionButtons/ActionButtons'

export const ActivitiesTab = () => {
  const {ticketActivitiesHistory, isLoadingTicketActivitiesHistory, pagination, onLoadMore} =
    useContext(TicketDetailsPageContext)

  if (isEmpty(ticketActivitiesHistory)) {
    return (
      <p className='text-center fw-bold'>
        {isLoadingTicketActivitiesHistory
          ? 'Loading activity history...'
          : 'No activity history available at this time.'}
      </p>
    )
  }

  return (
    <div className='d-flex flex-column gap-8'>
      {ticketActivitiesHistory?.map((group: any, groupIndex: number) => (
        <div key={groupIndex} className='d-flex flex-column gap-7'>
          {group?.map((activity: any, index: number) => {
            const isFirstInUserGroup = index === 0
            return (
              <div key={activity.id} className='d-flex gap-3 position-relative'>
                <div className='position-relative d-flex justify-content-center align-items-start w-25px'>
                  {index !== group.length - 1 && <div className='project-log-tileline' />}
                  {isFirstInUserGroup ? (
                    <div className='w-25px h-25px d-flex align-items-center justify-content-center rounded-1 border border-1 border-secondary'>
                      <i className='fa-regular fa-clock text-gray-500'></i>
                    </div>
                  ) : (
                    <div className='rounded-circle bg-secondary w-10px h-10px' />
                  )}
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex gap-2 align-items-center lh-1 fs-7 mb-1'>
                    {isFirstInUserGroup && (
                      <>
                        <span
                          className='text-primary lh-1 fw-bold fs-6'
                          title={activity.created_by}
                        >
                          {activity.created_by_name}
                        </span>
                        <div
                          className='rounded-circle lh-1 bg-secondary d-flex align-items-center'
                          style={{
                            width: '7px',
                            height: '7px',
                            marginTop: '1px',
                          }}
                        />
                      </>
                    )}
                    <small className='text-muted'>
                      <div className='app-default-date-cell position-relative'>
                        <span className='app-default-date-area position-relative'>
                          {convertUTCToLocal(activity.created_at, true)}
                          <span className='popover app-default-date-tooltip fw-semibold lh-1 fs-8'>
                            {convertUTCToLocal(activity.created_at, false)}
                          </span>
                        </span>
                      </div>
                    </small>
                  </div>

                  <div className='d-flex gap-2 align-items-center text-gray-600 fs-7'>
                    {formattedActivities(activity)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ))}

      {pagination?.next_page && (
        <ActionButtons
          buttons={[
            {
              id: 'loadMore',
              label: 'More logs',
              className: 'btn-light-primary',
              size: 'sm',
              labelIcon: <i className='fa-solid fa-plus'></i>,
              loadingLabel: 'Loading...',
              isLoading: isLoadingTicketActivitiesHistory,
              onClick: () => onLoadMore(pagination?.next_page),
            },
          ]}
        />
      )}
    </div>
  )
}
