import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useContext} from 'react'
import {BoardSettingsPageContext} from '../../context'
import {AddBoardModuleModalTypes} from '../../interfaces'
import {AddBoardModuleSchema} from '../../validations'
import {AddBoardModuleDefaultValues} from '../../utils'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {SectionForm} from '../../../../components/SectionForm'

const AddBoardModuleModal = ({show, onClose, isEdit, moduleData}: AddBoardModuleModalTypes) => {
  const {isLoadingBoardModulesList, createModule, updateModule, isLoadingModulesOperation} =
    useContext(BoardSettingsPageContext)

  const {
    register,
    handleSubmit,
    formState: {dirtyFields, errors, isDirty, isValid},
    setError,
  } = useForm({
    defaultValues: AddBoardModuleDefaultValues(moduleData, isEdit),
    resolver: yupResolver(AddBoardModuleSchema),
  })

  const onSubmit = async (data: any) => {
    if (isEdit) {
      await updateModule(`${moduleData?.moduleId}`, data, dirtyFields, setError)
    } else {
      await createModule(data, setError)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Add'} Module`}
      onAction={handleSubmit(onSubmit)}
      actionName={`${isEdit ? 'Update' : 'Add'}`}
      actionBtnClass='btn-primary'
      isDataLoading={isLoadingBoardModulesList}
      isOperationLoading={isLoadingModulesOperation}
      isDisabled={!isDirty || !isValid || isLoadingModulesOperation || isLoadingBoardModulesList}
      body={
        <>
          <SectionForm
            id='add-board-modules'
            register={register}
            commonClassNames='mb-10'
            errors={errors}
            inputs={[
              {
                id: 'board-module-name',
                label: 'Module Name',
                placeholder: 'Enter module name',
                registerKey: 'name',
                required: true,
                maxLength: 30,
              },
              {
                id: 'board-module-description',
                isTextArea: true,
                label: 'Module Description',
                placeholder: 'Enter module description',
                registerKey: 'description',
                inputClass: 'resize-none',
                maxLength: 255,
              },
            ]}
          />
        </>
      }
    />
  )
}

export default AddBoardModuleModal
