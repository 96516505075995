import {Modal} from 'react-bootstrap'
import RuleForm from '../form/RuleForm'

const UpdateModal = ({
  show,
  onClose,
  sku,
  outOfDays,
  thresholdQty,
  promotion_id,
  promotion_disable_date,
  hide_out_of_stock_days,
  hide_category_id,
  hide_classification,
  hide_product,
  prefix,
  customer_rep,
  disable_promotion,
}: any) => {
  if (!show) {
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_create_navigation'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
      onHide={onClose}
    >
      <RuleForm
        onClose={onClose}
        isEdit={true}
        sku={sku}
        outOfDays={outOfDays}
        thresholdQty={thresholdQty}
        promotion_id={promotion_id}
        promotion_disable_date={promotion_disable_date}
        hide_out_of_stock_days={hide_out_of_stock_days}
        hide_category_id={hide_category_id}
        hide_classification={hide_classification}
        hide_product={hide_product}
        prefix={prefix}
        customer_rep={customer_rep}
        disable_promotion={disable_promotion}
      />
    </Modal>
  )
}

export default UpdateModal
