import {useContext, useState} from 'react'
import DndTable from '../../../../../components/dndTable/DndTable'
import AddBoardModuleModal from '../../modals/AddBoardModuleModal'
import {BoardSettingsPageContext} from '../../../context'
import ModulesTableRow from './ModulesTableRow'
import {boardModulesTableColumns} from '../../../utils'

export default function ModulesTable() {
  const [showModal, setShowModal] = useState(false)
  const {
    boardModulesList,
    isLoadingBoardModulesList,
    updateModuleTableSorting,
    hasBoardSettingsPermission,
  } = useContext(BoardSettingsPageContext)

  const handleUpdatedTableRows = (tableData: any) => {
    const moduleTableRowOrder = {
      module_ids: tableData.map((item: any) => item.moduleId),
    }
    updateModuleTableSorting(moduleTableRowOrder)
  }

  return (
    <div className='position-relative'>
      <div className='d-flex justify-content-between align-items-center mb-7'>
        <h4 className='fs-3 fw-bolder mb-0'>Modules</h4>
        {hasBoardSettingsPermission && (
          <div className='d-flex justify-content-end'>
            {!isLoadingBoardModulesList && (
              <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
                <i className='fa-solid fa-plus'></i>Add Module
              </button>
            )}
            {showModal && (
              <AddBoardModuleModal
                show={showModal}
                onClose={() => setShowModal(false)}
                isEdit={false}
              />
            )}
          </div>
        )}
      </div>

      <DndTable
        id='board-modules-table'
        data={boardModulesList}
        columns={boardModulesTableColumns}
        uniqueId='moduleId'
        isLoading={isLoadingBoardModulesList}
        onChange={handleUpdatedTableRows}
        hasWritePermission={hasBoardSettingsPermission}
        TableRow={ModulesTableRow}
        noDataText={
          <p>
            No modules have been added yet. <br /> Please click on 'Add Module' button to add
            modules.
          </p>
        }
      />
    </div>
  )
}
