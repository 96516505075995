import {useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import VariantRuleForm from './VariantRulesFrom'
import ProductRuleForm from './ProductRulesForm'
import useGetPRUpdateModalData from '../../hooks/useGetPRUpdateModalData'


const RuleForm = ({onClose, isEdit, sku, outOfDays, thresholdQty, promotion_id, promotion_disable_date, hide_out_of_stock_days, hide_category_id, hide_classification, hide_product, prefix, customer_rep, disable_promotion}: any) => {
  
  const location = useLocation()
  const {data} = useGetPRUpdateModalData()
  
  const [isChecked, setIsChecked] = useState(
    location.pathname === '/analytics/automation/rules/hideproducts' ? true : false
  )

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  
  return (
    <div className='modal-content'>
      <div className='modal-header'>
        <h4 className='modal-title'>Product Rule (Hide Product)</h4>

        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
        </div>
      </div>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <div className='modal-body'>
          <div>
            <div className='mb-5'>
              <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  name='notifications'
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label className='form-label form-label ms-3 mb-0'>Hide Parent Product</label>
              </div>
            </div>
          </div>
          <div className='mb-5'>
            <h4 className='fw-bold text-dark'>BigCommerce Setting</h4>
          </div>
          {!isChecked ? (
            <VariantRuleForm  onClose={onClose} isEdit={isEdit} sku={sku} outOfDays={outOfDays} hide_classification={hide_classification} prefix={prefix} modalData={data} />
            // <>
            //   <div className='mt-7'>
            //     <label className='form-label required'>Variant ID</label>
            //     <input
            //       type='text'
            //       id='variant_id'
            //       defaultValue={id || ''}
            //       disabled={isEdit}
            //       className={`form-control ${errors?.variant_id ? 'is-invalid' : ''}`}
            //       {...register('variant_id', {required: 'Variant ID is required'})}
            //     />
            //     {errors?.variant_id && (
            //       <div className='invalid-feedback'>{errors?.variant_id?.message}</div>
            //     )}
            //   </div>
            //   <div className='mt-7'>
            //     <label className='form-label required'>Out of Stock Days</label>
            //     <input
            //       type='text'
            //       id='out_of_stock_days'
            //       defaultValue={outOfDays || ''}
            //       className={`form-control ${errors?.out_of_stock_days ? 'is-invalid' : ''}`}
            //       {...register('out_of_stock_days', {required: 'Out of Stock Days is required'})}
            //     />
            //     {errors?.out_of_stock_days && (
            //       <div className='invalid-feedback'>{errors?.out_of_stock_days?.message}</div>
            //     )}
            //   </div>
            // </>
          ) : (
            <ProductRuleForm onClose={onClose} isEdit={isEdit} sku={sku}  thresholdQty={thresholdQty} promotion_id={promotion_id} promotion_disable_date={promotion_disable_date} hide_out_of_stock_days={hide_out_of_stock_days}  hide_category_id={hide_category_id}  hide_classification={hide_classification} hide_product={hide_product} prefix={prefix} modalData={data} customer_rep={customer_rep} disable_promotion={disable_promotion} /> 
            // <>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label required'>Parent Product ID</label>
            //       <input type='text' id='description' className='form-control' />
            //     </div>
            //   </div>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label required'>Promotion Threshold (Available qty)</label>
            //       <input type='text' id='description' className='form-control' />
            //     </div>
            //   </div>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label required'>Disable Promotion(BigCommerce)</label>
            //       <input type='text' id='description' className='form-control' />
            //     </div>
            //   </div>
            //   <div>
            //     <h4 className='fw-bold text-dark mb-5 mt-5'>Notify Sales Representative</h4>
            //   </div>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label required'>Representative Name</label>
            //       <input type='text' id='description' className='form-control' />
            //     </div>
            //   </div>
            //   <div style={{position: 'relative'}}>
            //     <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
            //       <input
            //         className='form-check-input'
            //         type='checkbox'
            //         value=''
            //         name='notifications'
            //         checked={isChecked}
            //         onChange={handleCheckboxChange}
            //       />
            //       <label className='form-label form-label ms-3'>Hide Parent Product</label>
            //     </div>
            //   </div>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label required'>Out of Stock Days</label>
            //       <input type='text' id='description' className='form-control' />
            //     </div>
            //   </div>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label '>Promotion ID</label>
            //       <input type='text' id='description' className='form-control' />
            //     </div>
            //   </div>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label '>Update Category ID</label>
            //       <input type='text' id='description' className='form-control' />
            //     </div>
            //   </div>
            //   <div>
            //     <h4 className='fw-bold text-dark mb-5 mt-5'>SKU Vault Setting</h4>
            //   </div>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label'>Classification</label>
            //       <select
            //         className='form-select'
            //         data-kt-select2='true'
            //         data-placeholder='Select option'
            //         data-allow-clear='true'
            //         defaultValue={'1'}
            //       >
            //         <option></option>
            //         <option value='1'>Select SKU</option>
            //       </select>
            //     </div>
            //   </div>
            //   <div>
            //     <div className='mt-7'>
            //       <label className='form-label'>Product Name Prefix</label>
            //       <input type='text' id='description' className='form-control' />
            //     </div>
            //   </div>
            // </>
          )}
        </div>
        {/* <div className='modal-footer'>
          <button type='button' className='btn btn-outline' onClick={onClose}>
            Cancel
          </button>
          <button type='submit' className='btn btn-primary'>
            {!isLoading ? (
              isEdit ? (
                <span className='indicator-label'>update</span>
              ) : (
                <span className='indicator-label'>Save</span>
              )
            ) : null}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div> */}
      {/* </form> */}
    </div>
  )
}

export default RuleForm
