import {useState} from 'react'
import {createUser, updateUserDetails, deleteAdminUser} from '../services'
import useToastify from '../../../hook/useToastify'
import useGetUserList from './useGetUserList'

export default function useUsers() {
  const [isLoading, setLoading] = useState(false)
  const {toastMessage} = useToastify()
  const {refetch} = useGetUserList(false)

  const addUser = async (data: any) => {
    try {
      setLoading(true)

      // convert username to small case while creating request.
      data['username'] = data['username'].toLowerCase()

      const res = await createUser(data)

      if (res.status === 200) {
        toastMessage('success', 'User created successfully.')
        refetch()
      } else {
        toastMessage('error', `${res.message}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {
      setLoading(false)
    }
  }

  const deleteUser = async (userId: string) => {
    
    try {
      setLoading(true)
      const res = await deleteAdminUser(userId)
      if (res.status === 200) {
        toastMessage('success', 'User deleted successfully.')
        refetch()
      } else {
        toastMessage('error', `${res.message}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {
      console.error(error)
      setLoading(false)
    }
  }

  const updateUser = async (data: any, userId: string) => {
    try {
      setLoading(true)
      const res = await updateUserDetails(data, userId)

      if (res.status === 200) {
        toastMessage('success', 'User updated successfully.')
        refetch()
      } else {
        toastMessage('error', `${res.data.data.message}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {
      setLoading(false)
    }
  }

  return {
    isLoading,
    addUser,
    updateUser,
    deleteUser,
  }
}
