/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

const Footer: FC = () => {
  return (
    <>
    <div className='footer p-0 d-flex flex-lg-column' id='kt_footer'>
    {/* begin::Container */}
    {/* end::Container */}
    </div>

    </>
  )
}

export {Footer}
