import useFilters from '../../../hook/useFilters'
import {useSearchParams} from 'react-router-dom'
import useApi from '../../../services/useApi'

export const useGetProjectsList = () => {
  const Api = useApi()
  const view = useSearchParams()[0].get('view')

  const initialFilters = {
    page: 1,
    limit: 20,
    search: true,
    is_archived: view === 'archived' ? 'true' : 'false',
    sort_by: 'updated_at/-1',
  }

  const {filters, onSortingChange, onPageChange, onSearch, searchValue} = useFilters(
    initialFilters,
    {
      sortType: 'number',
    }
  )

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery('/agile/projects', {
    queryId: 'projects-list',
    filters: filters,
  })

  return {
    projectsList: response?.data || [],
    isLoading: isFetching,
    pagination: response?.pagination || {},
    filters,
    onPageChange,
    onSearch,
    searchValue,
    refetchProjectsList: refetch,
    onSortingChange: onSortingChange,
  }
}
