export const dataToQueryParams = (filters: any) => {
  let params = ''
  let counter = 0

  Object.entries(filters).forEach((item): any => {
    let key = item[0]
    let value = item[1]

    if (value !== '') {
      if (counter > 0) {
        params = params + `&${key}=${value}`
      } else {
        params = params + `?${key}=${value}`
      }
      ++counter
    }
  })
  return params
}

export const titlePreFix = 'MW Business Central'

export const redirectTo = (input: string) => {
  const regex = /redirect_to=([^"]+)/
  const match = input.match(regex)

  if (match && match[1]) {
    return match[1]
  }

  return '/'
}
