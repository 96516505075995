import * as Yup from 'yup'

export const createProjectSchema = Yup.object().shape({
  name: Yup.string()
    .required('Project Name is required')
    .max(128)
    .test(
      'no-hyphen-prefix',
      'Project name cannot start with a "-"',
      (value) => !value?.startsWith('-')
    )
    .matches(
      /^[a-zA-Z0-9\s-]+$/,
      'Project name can only contain letters, numbers, spaces, and "-"'
    ),
  buId: Yup.number()
    .required('Business Unit is required')
    .test('is-valid', 'Business Unit is required', (value) => value !== 0),
  description: Yup.string().max(255, 'Description must contain at most 255 characters.'),
})
