export const CustomerTableColumns = [
  {
    key: 'name',
    label: 'Customer Name',
    headerStyle: 'min-w-250px',
    isSorted: true,
  },
  {
    key: 'rep_name',
    label: 'Customer Rep',
    headerStyle: 'min-w-150px',
    isSorted: true,
  },
  {
    key: 'tag_labels',
    label: 'Customer Tag',
    headerStyle: 'min-w-150px',
  },
  {
    key: 'order_count',
    label: 'Bulk Orders',
    headerStyle: 'min-w-150px ',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'order_total',
    label: 'Bo Revenue',
    headerStyle: 'min-w-150px',
    isSorted: true,
  },
  {
    key: 'lifetime_order_count',
    label: 'All Orders',
    headerStyle: 'min-w-150px ',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'lifetime_order_total',
    label: 'Total Revenue',
    headerStyle: 'min-w-175px ',
    isSorted: true,
  },
  {
    key: 'last_order_date',
    label: 'BO Last Order Date',
    headerStyle: 'min-w-200px',
    isSorted: true,
  },
]

export const SalesRepTableColumns = [
  {
    key: 'rep_name',
    label: 'Customer Rep',
    headerStyle: 'min-w-300px',
    isSorted: true,
  },
  {
    key: 'total_customers',
    label: 'Total Customers',
    headerStyle: 'min-w-175px',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'total_orders',
    label: 'Bulk Orders',
    headerStyle: 'min-w-100px',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'total_order_value',
    label: 'Revenue',
    headerStyle: 'min-w-100px',
    isSorted: true,
  },
  {
    key: 'last_order_date',
    label: 'BO Last Order Date',
    headerStyle: 'w-200px',
    isSorted: true,
  },
]

export const ProductsTableColumns = [
  {
    key: 'product_name',
    label: 'Product Name',
    headerStyle: 'min-w-400px',
    isSorted: true,
  },
  {key: 'bc_sku', label: 'SKU', headerStyle: 'min-w-150px', isSorted: true},
  {
    key: 'unique_customers',
    label: 'Unique Customers',
    headerStyle: 'min-w-200px',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'total_order_count',
    label: 'Bulk Orders',
    headerStyle: 'min-w-150px',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'order_total',
    label: 'Revenue',
    headerStyle: 'min-w-100px',
    isSorted: true,
  },
  {key: 'last_order_date', label: 'BO Last Order Date', headerStyle: 'min-w-200px', isSorted: true},
]

export const SuggestedCustomerTableColumns = [
  {
    key: 'name',
    label: 'Customer Name',
    headerStyle: 'min-w-250px',
    isSorted: true,
  },
  {
    key: 'state',
    label: 'state',
    headerStyle: 'min-w-100px',
    isSorted: true,
  },
  {
    key: 'rep_name',
    label: 'Customer Rep',
    headerStyle: 'min-w-150px',
    isSorted: true,
  },
  {
    key: 'lifetime_order_count',
    label: '2-Year Orders',
    headerStyle: 'min-w-150px ',
    style: 'justify-content-center',
    isSorted: true,
  },
  {
    key: 'lifetime_order_total',
    label: '2-Year Total Revenue',
    headerStyle: 'min-w-225px',
    isSorted: true,
  },
  {
    key: 'last_order_date',
    label: 'Last Order Date',
    headerStyle: 'min-w-175px',
    isSorted: true,
  },
  {
    key: 'action',
    label: 'Action',
    headerStyle: 'min-w-80px text-center',
    render: true,
  },
]
