import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import VariantContent from './automationHideProduct/components/VariantContent'
import HidePageNavigation from './automationHideProduct/HidePageNavigation'
import ProductContent from './automationHideProduct/components/ProductContent'

const analyticsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Automation',
    path: '/analytics/automation/rules',
    isSeparator: false,
    isActive: true,
  },
]
const AutomationPageBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Automation',
    path: '/analytics/automation/rules',
    isSeparator: false,
    isActive: true,
  },
]

const AutomationPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <HidePageNavigation />
            <Outlet />
          </>
        }
      >
        <Route
          path='/hidevariants'
          element={
            <>
              <PageTitle breadcrumbs={analyticsBreadCrumbs}>Variant Rules</PageTitle>
              <VariantContent />
            </>
          }
        />
        <Route
          path='/hideproducts'
          element={
            <>
              <PageTitle breadcrumbs={AutomationPageBreadCrumbs}>Product Rules</PageTitle>
              <ProductContent />
            </>
          }
        />
        <Route index element={<Navigate to='/analytics/automation/rules/hidevariants' />} />
      </Route>
    </Routes>
  )
}

export default AutomationPage
