/* eslint-disable */
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import useLayoutEffect from '../shared/useLayoutEffect'
import {$generateHtmlFromNodes} from '@lexical/html'
import {useRef, useState} from 'react'

export function OnChangePlugin({
  ignoreHistoryMergeTagChange = true,
  ignoreSelectionChange = false,
  onChange,
  setIsDirty,
  defaultValue,
}: {
  ignoreHistoryMergeTagChange?: boolean
  ignoreSelectionChange?: boolean
  onChange: (value: string) => void
  setIsDirty: (value: boolean) => void
  defaultValue: string | null
}): null {
  const [editor] = useLexicalComposerContext()
  const previousHtmlRef = useRef<string | null>(null)
  useLayoutEffect(() => {
    if (onChange) {
      return editor.registerUpdateListener(
        ({editorState, dirtyElements, dirtyLeaves, prevEditorState, tags}) => {
          // Ignore unnecessary updates
          if (
            (ignoreSelectionChange && dirtyElements.size === 0 && dirtyLeaves.size === 0) ||
            (ignoreHistoryMergeTagChange && tags.has('history-merge')) ||
            prevEditorState.isEmpty()
          ) {
            return
          }

          editor.read(() => {
            const htmlString = $generateHtmlFromNodes(editor, null)
            if (editorState.isEmpty()) {
              return
            } else if (htmlString !== defaultValue && htmlString !== previousHtmlRef.current) {
              onChange(htmlString)
              previousHtmlRef.current = htmlString
              setIsDirty(true)
            } else if (htmlString === defaultValue) {
              setIsDirty(false)
            }
          })
        }
      )
    }
  }, [editor, ignoreHistoryMergeTagChange, ignoreSelectionChange, onChange])

  return null
}
