import IconTick from '../../_metronic/layout/components/icon_ts/IconTick'
import {ToastModel} from './ToastModel'

export default function Toast({type, message}: ToastModel) {
  let iconSet: any = {
    success: {
      class: 'bc-toast-success',
      icon: <IconTick />,
    },
    warning: {
      class: 'bc-toast-warning',
      icon: <IconTick />,
    },
    info: {
      class: 'bc-toast-info',
      icon: <IconTick />,
    },
    error: {
      class: 'bc-toast-error',
      icon: <IconTick />,
    },
  }
  return (
    <div className={`bc-toast-message ${iconSet[type].class}`}>
      {/* {iconSet[type].icon} */}
      <span>{message}</span>
    </div>
  )
}
