import {useContext, useState} from 'react'
import {MembersReportPageContext} from '../../context'
import {useGetProjectsDropdownList} from '../../hooks/useGetProjectsDropdownList'
import {dataToSelectOptions} from '../../../../utils/common'
import {MultiSelect} from '../../../../components/MultiSelect/'
import {SideFilter} from '../../../../components/SideFilter'
import useSaveFilter from '../../hooks/useSaveFilter'

const MembersReportHeader = () => {
  const {
    onProjectChange,
    filtersData,
    refetch,
    isLoading: loading,
  } = useContext(MembersReportPageContext)
  const {isLoading, projectsDropdownList} = useGetProjectsDropdownList()
  const options = dataToSelectOptions(projectsDropdownList, 'name', 'id')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {isOperationLoading, onSaveFilter} = useSaveFilter(refetch)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div>
        <MultiSelect
          options={options}
          isLoading={isLoading}
          className='w-250px'
          handleSelected={(values: any) => {
            const extractedValues = values.map((item: {value: string}) => item.value).join(',')
            onProjectChange(extractedValues)
          }}
          placeholder='Select project'
          defaultSelectedAll={true}
          allValuesSelectedText={'Project: All'}
        />
      </div>
      <div>
        <SideFilter
          filterData={filtersData}
          isOperationLoading={isOperationLoading || loading}
          onApply={onSaveFilter}
          onCancel={() => setIsDrawerOpen(false)}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          filterType={'project_members_report'}
        />
      </div>
    </div>
  )
}

export default MembersReportHeader
