import {IActionButtons} from './ActionButtonsTypes'

const ActionButtons = ({
  className,
  buttons,
  show = true,
  disabled = false,
  setAction,
}: IActionButtons) => {
  if (!show) return null

  return (
    <div className={`d-flex ${className ? className : 'gap-2'}`}>
      {buttons.map((button) => {
        const buttonShow = button.show ?? true
        const buttonDisabled = button.disabled ?? false

        if (!buttonShow) return null

        return (
          <button
            key={button.id}
            id={button.id}
            className={`btn btn-${button?.size ? button?.size : 'md'} ${
              button?.className ? button?.className : 'btn-primary'
            }`}
            onClick={() => {
              button?.onClick?.()
              setAction?.(button?.id)
            }}
            disabled={disabled || buttonDisabled || button?.isLoading}
            type={button?.type ?? 'button'}
          >
            {button?.isLoading ? (
              <>
                {button?.loadingLabel}
                {button?.loadingIcon ? (
                  button?.loadingIcon
                ) : (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                )}
              </>
            ) : (
              <>
                {button?.labelIcon && <span className='me-1'>{button.labelIcon}</span>}
                {button?.label}
              </>
            )}
          </button>
        )
      })}
    </div>
  )
}

export default ActionButtons
