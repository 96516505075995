import {useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import UserActionDropdown from '../dropdown/UserActionDropdown'
import {DeleteUserConfirmationModal} from './DeleteUserConfirmationModal'
import UserModal from './UserModal'
import ResetPasswordModal from './ResetPasswordModal'

const OverlayModal = ({name, id,status}: any) => {
  const [openModal, setOpenModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const handleModalOpen = () => {
    setOpenModal(true)
    document.body.click()
  }
  const onEdit = () => {
    setModal(true)
    document.body.click()
  }
  const onClose = () => {
    setOpenModal(false)
    setModal(false)
    setResetPasswordModal(false)
  }
  const onResetPassword = () => {
    setResetPasswordModal(true)
    document.body.click()
  }
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <UserActionDropdown
            userName={name}
            onEdit={onEdit}
            handleModel={handleModalOpen}
            onResetPassword={onResetPassword}
            id={id}
            status={status}
          />
        }
        rootClose={true}
      />
      <UserModal show={modal} onClose={onClose} editId={id} />
      <DeleteUserConfirmationModal show={openModal} onClose={onClose} userId={id} />
      <ResetPasswordModal show={resetPasswordModal} onClose={onClose} userName={name} />
    </>
  )
}

export default OverlayModal
