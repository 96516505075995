import {createContext} from 'react'

export const SideMenuContext = createContext({
  type: '',
  permissionPath: '',
  defaultColumns: [],
  initialSelectedColumns: [],
  globalDefaultColumnsList: [],
  updatedDefaultColumn: [],
  selectedColumns: [],
  isLoadingGlobalDefaultColumnsList: false,
  isLoadingGlobalDefaultColumnOperation: false,
  refetch: () => {},
  setUpdatedDefaultColumn: (columns: any) => {},
  setSelectedColumns: (columns: any) => {},
  refetchGlobalDefaultColumnsList: () => {},
  updateGlobalDefaultColumnsTableSorting: (data: any) => {},
})
