import {useContext} from 'react'
import useApi from '../../../services/useApi'
import {TicketDetailsContext} from '../context'

const useGetProjectModules = () => {
  const Api = useApi()
  const {projectId} = useContext(TicketDetailsContext)
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/modules`)

  return {
    projectModules: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetProjectModules
