export const parseProductRowData = (row: any) => {
  return {
    productId: row?.id || '-',
    productName: row?.name || '-',
    sku: row?.sku || '-',
    minOrderQuantity: row?.order_quantity_minimum || 0,
    maxOrderQuantity: row?.order_quantity_maximum || '-',
    availabilityDescription: row?.availability_description || '-',
    bcId: row?.bc_id || '-',
    inventory: row?.inventory_level,
  }
}
