import useApi from '../../../services/useApi'

export const useGetProjectsDropdownList = () => {
  const Api = useApi()
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/dropdown`, {
    queryId: 'project-dropdown-list',
  })

  return {
    projectsDropdownList: response?.data || [],
    isLoading: isFetching,
  }
}
