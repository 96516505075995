import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getBoProductsDropdownList} from '../services'

export const useGetBoProductsDropdownList = (): any => {
  const {
    data: boProductsDropdownList,
    refetch,
    isFetching,
  } = useQuery(`get-bo-products-dropdown-list`, () => {
    return getBoProductsDropdownList()
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    boProductsDropdownList: boProductsDropdownList?.['data'] || [],
    isLoadingBoProductsDropdownList: isFetching,
    refetchBoProductsDropdownList: refetch,
  }
}
