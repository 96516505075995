import {Link} from 'react-router-dom'
import ProgressCircle from '../../components/ProgressCircle'

const TasksReportTableRow = ({row, actionComponent}: any) => {
  return (
    <tr>
      {/* Ticket Name Column */}
      <td className='pe-10'>
        <Link
          to={`/projects/all-projects/${row?.projectId}/ticket/${row.cardId}/${row.cardIdentifier}`}
        >
          {row.cardIdentifier} <span className='text-dark'> - {row.cardTitle}</span>
        </Link>
      </td>

      {/* Project Name Column */}
      <td>{row.projectName}</td>

      {/* Assignee Column */}
      <td>{row.assignee}</td>

      {/* Module Name Column */}
      <td>{row.moduleName}</td>

      {/* Estimation Column */}
      <td>{row.estimation}</td>

      {/* Spent Time Column */}
      <td className={` ${row.isOverEstimation ? ' text-danger' : ''}`}>{row.spentTime}</td>

      {/* Remaining Time Column */}
      <td>{row.remainingTime}</td>

      {/* Progress Column */}
      <td>
        <div className='d-flex justify-content-end align-items-end gap-2 me-5'>
          <span>{row.progressPercentage}</span>
          <ProgressCircle progress={parseFloat(row.progressPercentage)} />
        </div>
      </td>

      {/* Action Column */}
      {actionComponent && <td>{actionComponent(row)}</td>}
    </tr>
  )
}

export default TasksReportTableRow
