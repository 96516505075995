import {useEffect, useState, useRef} from 'react'
import Loading from '../../modules/loading'
import DynamicTableRow from './DynamicTableRow'
import NoDataFound from '../NoDataFound'
import {formateUppperCase} from '../../utils/common'
import {RenderBothArrow} from '../../utils/renderBothArrow'
import usePermission from '../../hook/usePermission'

const DynamicTable = ({
  id,
  data,
  sortableColumns,
  TableRow,
  onSortingChange,
  filters,
  loading,
  tableClass,
  noDataMessage,
  tableRowProps,
  error,
  setCheckedRows,
  permissionPath,
  hasPermission: hasWritePermission,
  checkedRowId,
  rowParsingFunction,
  requiredOutputKeys,
  searchValue,
  noSearchResultMessage = `No results found for '${searchValue}'.`,
  columns,
  tableFooter,
}: any) => {
  const {hasPermission} = usePermission()
  const [direction, setDirection] = useState('desc')
  const [sortBy, setSortBy] = useState(sortableColumns[0]?.key || '')
  const [checkedTableRow, setCheckedTableRow] = useState<any[]>([])
  const selectAllCheckboxRef = useRef<HTMLInputElement>(null)
  const isPermission = permissionPath
    ? hasPermission(permissionPath, 'write')
    : hasWritePermission
    ? hasWritePermission
    : false

  const getRowId = (row: any) => {
    return checkedRowId ? (row[checkedRowId] ? row[checkedRowId] : row.id) : row.id
  }

  useEffect(() => {
    if (filters) {
      const sortByFilter = filters['sort_by'] || ''
      let key = ''
      let dir = 'asc'

      if (sortByFilter.includes('/')) {
        const [sortKey, sortOrder] = sortByFilter.split('/')
        key = sortKey
        dir = sortOrder === '-1' ? 'desc' : 'asc'
      } else if (sortByFilter.includes(':')) {
        const [sortKey, sortOrder] = sortByFilter.split(':')
        key = sortKey
        dir = sortOrder || 'asc'
      } else {
        key = sortByFilter
        dir = filters['sort_order'] || 'asc'
      }

      setSortBy(key)
      setDirection(dir)
    }
  }, [filters])

  const extractKeys = (sortableColumns: any) => {
    return sortableColumns?.map((column: any) => column.key) || []
  }

  const keys = extractKeys(sortableColumns)

  const handleSortingChange = (key: string) => {
    if (data?.length > 1) {
      const newDirection = sortBy === key && direction === 'asc' ? 'desc' : 'asc'
      setSortBy(key)
      setDirection(newDirection)
      onSortingChange(key, newDirection)
    }
  }

  const handleCheckboxChange = (row: any) => {
    let updatedCheckedRows: any
    if (checkedTableRow.some((checkedRow) => getRowId(checkedRow) === getRowId(row))) {
      updatedCheckedRows = checkedTableRow.filter(
        (checkedRow) => getRowId(checkedRow) !== getRowId(row)
      )
    } else {
      updatedCheckedRows = [...checkedTableRow, row]
    }
    setCheckedTableRow(updatedCheckedRows)

    if (requiredOutputKeys && requiredOutputKeys.length > 1) {
      const outputObject = updatedCheckedRows.map((item: any) => {
        const filteredItem: any = {}
        requiredOutputKeys.forEach((key: any) => {
          if (key in item) {
            filteredItem[key] = item[key]
          }
        })
        return filteredItem
      })
      setCheckedRows?.(outputObject)
    } else {
      setCheckedRows?.(updatedCheckedRows.map((checkedRow: any) => getRowId(checkedRow)))
    }
  }

  useEffect(() => {
    setCheckedTableRow((prevCheckedRows: string[]) =>
      prevCheckedRows.filter((id) =>
        data.some((row: any) => getRowId(rowParsingFunction ? rowParsingFunction(row) : row) === id)
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      if (checkedTableRow.length === 0) {
        selectAllCheckboxRef.current.checked = false
        selectAllCheckboxRef.current.indeterminate = false
      } else if (checkedTableRow.length === data.length) {
        selectAllCheckboxRef.current.checked = true
        selectAllCheckboxRef.current.indeterminate = false
      } else {
        selectAllCheckboxRef.current.checked = false
        selectAllCheckboxRef.current.indeterminate = true
      }
    }
  }, [checkedTableRow, data])

  const handleSelectAll = () => {
    let updatedCheckedRows
    if (checkedTableRow.length === data.length) {
      updatedCheckedRows = []
    } else {
      updatedCheckedRows = data.map((row: any) =>
        rowParsingFunction ? rowParsingFunction(row) : row
      )
    }
    setCheckedTableRow(updatedCheckedRows)
    if (requiredOutputKeys && requiredOutputKeys.length > 1) {
      const outputObject = updatedCheckedRows.map((item: any) => {
        const filteredItem: any = {}
        requiredOutputKeys.forEach((key: any) => {
          if (key in item) {
            filteredItem[key] = item[key]
          }
        })
        return filteredItem
      })
      setCheckedRows?.(outputObject)
    } else {
      setCheckedRows?.(updatedCheckedRows.map((checkedRow: any) => getRowId(checkedRow)))
    }
  }

  const renderCheckBox = (row: any) => {
    return (
      checkedRowId && (
        <input
          type='checkbox'
          id={row.id}
          checked={checkedTableRow.some((checkedRow) => getRowId(checkedRow) === getRowId(row))}
          className='form-check-input'
          onChange={() => handleCheckboxChange(row)}
        />
      )
    )
  }

  const renderSortingIcons = (column: any) => {
    if (!column?.isSorted || data.length <= 1) {
      return null
    }
    return (
      <>
        {sortBy === column.key ? (
          direction === 'asc' ? (
            <i className='bi bi-caret-up-fill fs-8'></i>
          ) : (
            <i className='bi bi-caret-down-fill fs-8'></i>
          )
        ) : (
          <RenderBothArrow />
        )}
      </>
    )
  }

  return (
    <div className='card-body p-0' id={id}>
      <div className='table-responsive position-relative'>
        <div className={loading ? 'table-loader-wrapper' : ''}>
          <table
            className={`table ${
              tableClass || 'table-row-dashed table-row-gray-300 align-middle mb-15 gs-0 gy-2'
            }`}
          >
            <thead className='table-row-bordered'>
              <tr className='fs-6 fw-semibold text-muted'>
                {setCheckedRows && isPermission && (
                  <th>
                    <input
                      type='checkbox'
                      id='select-all'
                      ref={selectAllCheckboxRef}
                      className='form-check-input'
                      onChange={handleSelectAll}
                    />
                  </th>
                )}
                {sortableColumns
                  .filter((column: any) => column.key !== 'action')
                  .map((column: any, index: any) => (
                    <th
                      key={index}
                      onClick={() =>
                        column.isSorted &&
                        column.key &&
                        data.length > 1 &&
                        handleSortingChange(column.key)
                      }
                      className={`${column.headerStyle || ''} ${
                        column.key && column.isSorted && data.length > 1 ? 'cursor-pointer' : ''
                      }`}
                    >
                      <div
                        className={`d-flex align-items-center position-relative ${column.style}`}
                      >
                        <span
                          className='me-2'
                          dangerouslySetInnerHTML={{__html: formateUppperCase(column.label)}}
                        ></span>
                        {renderSortingIcons(column)}
                      </div>
                    </th>
                  ))}
                {isPermission &&
                  sortableColumns.find((column: any) => column.key === 'action')?.key && (
                    <th
                      className={`${
                        sortableColumns.find((column: any) => column.key === 'action')
                          ?.headerStyle || 'text-center w-80px'
                      }`}
                    >
                      {sortableColumns.find((column: any) => column.key === 'action')?.label &&
                        formateUppperCase(
                          sortableColumns.find((column: any) => column.key === 'action')?.label
                        )}
                    </th>
                  )}
              </tr>
            </thead>
            <tbody>
              {error && data?.length === 0 && (
                <tr>
                  <td colSpan={keys.length + 1}>
                    <span className='text-danger'>{error}</span>
                  </td>
                </tr>
              )}
              {data && data?.length > 0
                ? data?.map((row: any, index: any) =>
                    TableRow ? (
                      <TableRow
                        key={getRowId(rowParsingFunction ? rowParsingFunction(row) : row)}
                        index={index}
                        row={rowParsingFunction ? rowParsingFunction(row) : row}
                        actionComponent={
                          isPermission
                            ? sortableColumns.find((column: any) => column.key === 'action')?.render
                            : false
                        }
                        hasWritePermission={isPermission}
                        keys={keys}
                        checkBoxComponent={isPermission ? renderCheckBox : null}
                        columns={columns}
                        {...tableRowProps}
                      />
                    ) : (
                      <DynamicTableRow
                        row={rowParsingFunction ? rowParsingFunction(row) : row}
                        key={getRowId(rowParsingFunction ? rowParsingFunction(row) : row)}
                        keys={keys}
                        actionComponent={
                          isPermission
                            ? sortableColumns.find((column: any) => column.key === 'action')?.render
                            : false
                        }
                        checkBoxComponent={isPermission && setCheckedRows ? renderCheckBox : null}
                        columns={columns}
                        {...tableRowProps}
                      />
                    )
                  )
                : !loading &&
                  !error && (
                    <NoDataFound
                      colspan={keys.length + 1}
                      message={
                        searchValue && searchValue !== '' ? noSearchResultMessage : noDataMessage
                      }
                    />
                  )}
            </tbody>
            {tableFooter && tableFooter}
          </table>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  )
}

export default DynamicTable
