import {useContext} from 'react'
import {useForm} from 'react-hook-form'
import {ProjectsMembersPageContext} from '../../context'
import MultipleSelect from '../../../../components/MultiSelect/MultiSelect'
import {yupResolver} from '@hookform/resolvers/yup'
import {addGlobalProjectMembersSchema} from '../../validations'
import {GlobalProjectMembersModalTypes} from '../../interfaces'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'

const GlobalProjectMembersModal = ({show, onClose}: GlobalProjectMembersModalTypes) => {
  const {
    addGlobalProjectMember,
    isLoadingGlobalProjectsMembersOperation,
    isLoadingGlobalProjectsMembersList,
  } = useContext(ProjectsMembersPageContext)
  const {globalProjectsMembersDropdownList} = useContext(ProjectsMembersPageContext)

  const {
    handleSubmit,
    control,
    formState: {errors, isDirty, isValid},
  } = useForm({
    defaultValues: {
      usernames: [],
    },
    resolver: yupResolver(addGlobalProjectMembersSchema),
  })

  const onAddMember = (data: any) => {
    addGlobalProjectMember(data)
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title='Add Member'
      onAction={handleSubmit(onAddMember)}
      isDisabled={
        !isDirty ||
        !isValid ||
        isLoadingGlobalProjectsMembersOperation ||
        isLoadingGlobalProjectsMembersList
      }
      isOperationLoading={isLoadingGlobalProjectsMembersOperation}
      isDataLoading={isLoadingGlobalProjectsMembersList}
      actionName='Add'
      actionBtnClass='btn-primary'
      body={
        <MultipleSelect
          id='global-project-members'
          options={globalProjectsMembersDropdownList}
          label='Add members'
          control={control}
          registerKey='usernames'
          labelKey='name'
          valueKey='username'
          error={errors?.usernames}
        />
      }
    />
  )
}

export default GlobalProjectMembersModal
