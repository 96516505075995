import {Pagination} from '../../../utils/pagination'
import useMeta from '../../../hook/useMeta'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useGetAggregatedProductReport} from '../hooks/useGetAggregatedProductReport'
import {AnalyticsDashboardContext} from '../contexts'
import {AggregatedProductReportTable} from '../sections/table/AggregatedProductReportTable'

const AnonymouslyActiveCustomerBreadCrums: Array<PageLink> = [
  {
    title: 'Sold Products',
    path: `/analytics/sold-products/dashboard`,
    isSeparator: false,
    isActive: false,
  },
]

const AggregatedProductReportPage = () => {
  useMeta('Aggregated Product Report Page')

  const {
    onPageChange,
    AggregatedProductReportData,
    AggregatedProductReportMeta,
    isAggregatedProductReportDataLoading,
    pagination,
    filters,
    onSortingChange,
  } = useGetAggregatedProductReport(20)

  const contextValue = {
    dateRangeLable: '',
    AggregatedProductReportData,
    AggregatedProductReportMeta,
    isAggregatedProductReportDataLoading,
    AnalyticsOverviewData: [],
    TopProductsData: [],
    TopCustomerData: [],
    TopStatesData: [],
    isLoadingChartData: false,
    isTopCustomerLoading: false,
    isTopProductsLoading: false,
    isTopStatesLoading: false,
    topCustomer: '',
    topState: '',
    pagination,
    onPageChange,
    filters,
    onSortingChange,
  }

  return (
    <>
      <AnalyticsDashboardContext.Provider value={contextValue}>
        <PageTitle breadcrumbs={AnonymouslyActiveCustomerBreadCrums}>
          Aggregated Products Report
        </PageTitle>
        <AggregatedProductReportTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </AnalyticsDashboardContext.Provider>
    </>
  )
}

export default AggregatedProductReportPage
