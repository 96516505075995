import * as yup from 'yup'

export const hideVariantValidationSchema = yup.object().shape({
  variant_sku: yup.string().required('Please provide a variant sku'),
  out_of_stock_days: yup.string().required('Please provide a out of Stock Days'),
})


export const hideProductValidationSchema = yup.object().shape({
  product_sku: yup.string().required('Please provide a Product sku.'),
  // promotion_threshold_quantity: yup.string().required('Please provide a threshold quantity.'),
  // promotion_id: yup.string().required('Please select at least one promotion.'),           
})
