/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect} from 'react'
import {getInquiryBadgeColor} from '../../../../utils/badge'
import {InquiresPageListingContext} from '../../context'
import usePermission from '../../../../hook/usePermission'
import {trimMessage} from '../../utils'
import Initials from '../../../../components/Initials'
import Date from '../../../../components/Date/Date'
import {useLocation} from 'react-router-dom'

function InquiresPageTableRow({row, actionComponent, columns}: any) {
  const {setOpenModal, setClickedId} = useContext(InquiresPageListingContext)
  const {hasPermission} = usePermission()
  const isPermission = hasPermission('products_product inquiries', 'write')
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isOpen: string | null = searchParams.get('isOpen')
  const inquiryId: string | null = searchParams.get('id')

  useEffect(() => {
    if (isOpen && inquiryId && isPermission) {
      console.log('Opening modal for inquiry:', inquiryId);
      setOpenModal(true);
      setClickedId(Number(inquiryId));
    }
  }, [isOpen, inquiryId, isPermission, setOpenModal, setClickedId]);
  

  const handleOpen = () => {
    if (isPermission) {
      setOpenModal(true)
      setClickedId(row?.id)
    }
  }

  return (
    <tr>
      {columns.map((column: any) => {
        const [key] = Object.entries(column)[0]
        let cellContent

        // Render content based on the key
        switch (key) {
          case 'date_submitted':
            cellContent = (
              <div className='text-dark '>
                <Date date={row['date_submitted']} />
              </div>
            )
            break
          case 'product_name':
            cellContent = (
              <div onClick={handleOpen} className='cursor-pointer'>
                {row?.product_name || '-'}
              </div>
            )
            break
          case 'sku':
            cellContent = (
              <div onClick={handleOpen} className='cursor-pointer'>
                {row?.sku || '-'}
              </div>
            )
            break
          case 'message':
            cellContent = (
              <div onClick={handleOpen} className='cursor-pointer'>
                {trimMessage(row?.message)}
              </div>
            )
            break
          case 'customer_name':
            cellContent = (
              <div className='d-flex'>
                <div className='symbol symbol-circle symbol-35px me-3'>
                  <Initials text={row?.customer_name?.[0]} />
                </div>
                <div className='d-flex flex-column'>
                  <div className='text-dark '>
                    <span className='me-3'>{row?.customer_name}</span>
                    {row?.customer_name !== '' && row?.guest === true && (
                      <span className={`badge badge-light-primary badge-lg`}>{'Guest'}</span>
                    )}
                  </div>
                  <div className='align-items-center text-muted'>{row?.customer_email || '-'}</div>
                </div>
              </div>
            )
            break
          case 'rep_name':
            cellContent = (
              <div className='d-flex'>
                <div className='symbol symbol-circle symbol-35px me-3'>
                  <Initials text={row?.rep_name?.[0]} />
                </div>
                <div className='d-flex flex-column'>
                  <div className='text-dark '>{row?.rep_name || '-'} </div>
                  <div className='align-items-center text-muted'>{row?.rep_email || '-'}</div>
                </div>
              </div>
            )
            break
          case 'status':
            cellContent = (
              <span className={`badge ${getInquiryBadgeColor(row?.status, 'light')} badge-lg`}>
                <div className='align-items-center'>{row?.status || '-'}</div>
              </span>
            )
            break
          case 'action':
            cellContent = actionComponent ? actionComponent(row) : null
            break
          default:
            cellContent = null
        }

        return (
          <td key={key} className='text-hover-primary'>
            {cellContent}
          </td>
        )
      })}
    </tr>
  )
}

export default InquiresPageTableRow
