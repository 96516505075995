import {useContext, useState} from 'react'
import useApi from '../../../services/useApi'
import {TicketDetailsContext} from '../context'

function useCustomFieldsOperations() {
  const Api = useApi()
  const {projectId, ticketId} = useContext(TicketDetailsContext)
  const [isLoading, setLoading] = useState(false)
  const refetchAPIs = [
    `${ticketId}-additional-custom-fields`,
    `${ticketId}-details`,
    `${ticketId}-activities-history`,
  ]

  async function updateCustomField(payload: any) {
    return Api.post(`/agile/projects/${projectId}/${ticketId}/custom-fields`, payload, {
      setLoading: setLoading,
      refetch: refetchAPIs,
    })
  }

  return {
    updateCustomField,
    isLoading,
  }
}

export default useCustomFieldsOperations
