import {PageLink} from '../../_metronic/layout/core'
import useMeta from '../../hook/useMeta'
import ProjectsCustomFields from './layouts/ProjectsCustomFields'
import ProjectsDefaultColumns from './layouts/ProjectsDefaultColumns'
import ProjectsMembers from './layouts/ProjectsMembers'
import ProjectTeams from './layouts/ProjectTeams'
import Navigation from '../../components/Navigation/Navigation'

const ProjectSettingsPageCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/projects/all-projects',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Settings',
    path: '/projects/settings',
    isSeparator: false,
    isActive: false,
  },
]

function ProjectsSettingsPage() {
  useMeta('Projects Settings')

  return (
    <Navigation
      baseUrl='/projects/settings'
      breadcrumbs={ProjectSettingsPageCrumbs}
      navigationData={[
        {
          key: 'members',
          label: 'Members',
          component: <ProjectsMembers />,
        },
        {
          key: 'teams',
          label: 'Teams',
          component: <ProjectTeams />,
        },
        {
          key: 'custom-fields',
          label: 'Custom Fields',
          component: <ProjectsCustomFields />,
        },
        {
          key: 'default-columns',
          label: 'Default Columns',
          component: <ProjectsDefaultColumns />,
        },
      ]}
    />
  )
}

export default ProjectsSettingsPage
