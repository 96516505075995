import useApi from '../../../../services/useApi'
import {useQuery} from 'react-query'

const useGetProjectMappingDropdowns = () => {
  const Api = useApi()
  const {data}: any = useQuery(['projectMappingData']) || null
  const tableId = data?.tableId || null

  // Get project tables dropdown list
  const {data: projectTablesResponse, isFetching: isLoadingProjectTablesDropdownList} =
    Api.useGetQuery(`/agile/projects/project-mapping/tables`)

  // Get table columns dropdown list
  const {data: tableColumnsResponse, isFetching: isLoadingTableColumnsDropdownList} =
    Api.useGetQuery(
      `/agile/projects/dbtable/${tableId}/columns`,
      {filters: {column_mapping: true}},
      {enabled: !!tableId}
    )

  return {
    projectTablesDropdownList: projectTablesResponse?.data,
    isLoadingProjectTablesDropdownList,
    tableColumnsDropdownList: tableColumnsResponse?.data,
    isLoadingTableColumnsDropdownList,
  }
}

export default useGetProjectMappingDropdowns
