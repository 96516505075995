import Api from '../../../services/Api'

export async function createUser(data: any) {
  const url = `/settings/users`
  const response = await Api.post(url, data, null, null)
  return response.data
}

export const getUserLoginToken = (url: string, customerId: string): any => {
  return Api.post(
    `${url}`,
    {
      user_id: customerId,
    },
    null,
    null
  ).then((d: any) => {
    return d.data
  })
}

export async function updateUserDetails(data: any, userId: string) {
  const url = `/settings/users/${userId}`
  const response = await Api.put(url, data, null, null)
  return response
}

export const getUsers = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const getUserDetails = (username: string) => {
  const url = '/settings/users'
  return Api.get(`${url}/${username}`).then((d: any) => {
    return d?.data?.data
  })
}

export const deleteAdminUser = (userId: string) => {
  const url = `/settings/users/${userId}`

  return Api.delete(`${url}`, {}, {}, {}).then((d: any) => {
    return d
  })
}

export const changePassword = async (data: any) => {
  const url = `/settings/users/password`
  const response = await Api.post(url, data, null, null)
  return response
}

export const getRoles = (url: string) => {
  return Api.get(`${url}`).then((d: any) => d?.data?.data || [])
}

export const getRoleDetailsById = (role_id: string) => {
  return Api.get(`/settings/users/roles/${role_id}`, {}).then((d: any) => d?.data?.data || {})
}

export const getPermissions = (url: string) => {
  return Api.get(`${url}`).then((d: any) => d?.data || [])
}

export const createRole = (url: string, data: any) => {
  return Api.post(`${url}`, data, {}, {}).then((d: any) => d)
}

export const updateRolePermissions = (url: string, data: any, role_id: string) => {
  return Api.put(`${url}/${role_id}`, data, {}, {}).then((d: any) => d)
}

export const deleteUserRole = (url: string, roleId: string) => {
  return Api.delete(`${url}/${roleId}`, {}, {}, {}).then((d: any) => {
    return d
  })
}
