import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getProductRulesModalData} from '../services'
// import useFilters from '../../../../hook/useFilters'

export default function useGetPRUpdateModalData() {
  //   const {filters, setSingleFilter, setMultipleFilters} = useFilters({
  //     limit: 10,
  //     page: 1,
  //     // filterBy: 'name',
  //     search: '',
  //     sort_by: 'created_at',
  //     sort_order: '-1',
  //   })

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`get-product-rules-modal-data`, () => {
    return getProductRulesModalData(`/analytics/automation/rule-engine/products/rule/modal/data`)
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  //   const onSearch = (searchValue: string) => {
  //     setMultipleFilters({
  //       search: searchValue,
  //       page: 1,
  //     })
  //   }

  //   const onPageChange = (page_number: string | number | void) => {
  //     setSingleFilter('page', page_number)
  //   }

  //   const onSortingChange = (sortBy: string, sortDirection: any) => {
  //     const direction = sortDirection === 'asc' ? '1' : '-1'
  //     setMultipleFilters({
  //       sort_order: direction,
  //       sort_by: sortBy,
  //     })
  //   }

  return {
    data: response || [],
    refetch,
    isFetching: isFetching,
  }
}
