import {KTCard} from '../../../../_metronic/helpers'
import HideVariantRulesTable from './table/HideVariantRulesTable'
import useGetVariantRules from '../hooks/useGetVariantRules'
import {HideVariantRuleHeader} from './header/HideVariantRuleHeader'
import { HideVariantPagesContext } from '../contexts'
import useHideVariants from '../hooks/useHideVariants'
import { Pagination } from '../../../../utils/pagination'

function VariantContent() {
  const {addVariantRule, deleteVariantRule, updateVariantRule} = useHideVariants()

  const {variantRules, onPageChange, pagination, onSearch, onSortingChange, isFetching, filters, refetch} =
  useGetVariantRules()

  const contextValue = {
    variantRules: variantRules,
    onPageChange,
    onSearch,
    onSortingChange,
    addVariantRule,
    deleteVariantRule,
    updateVariantRule,
    pagination,
    refetch,    
    isFetching,
    filters,
  }

  return (
    <HideVariantPagesContext.Provider value={contextValue}>
      <KTCard>
        <HideVariantRuleHeader hide_variant_details={variantRules} />
        <HideVariantRulesTable />
        {/* <HideProductsPagination /> */}
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </KTCard>
    </HideVariantPagesContext.Provider>
  )
}

export default VariantContent
