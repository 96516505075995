import {DragStartEvent} from '@dnd-kit/core'

export function handleDragStart(
  event: DragStartEvent,
  tableData: any,
  setActiveRow: (activeRow: any) => void,
  setInitialIndex: Function,
  uniqueId: string
) {
  if (event.active.data.current?.type === 'Row') {
    setActiveRow(event.active.data.current.row)
    setInitialIndex(tableData.findIndex((item: any) => item[uniqueId] === event.active.id))
    return
  }
}
