import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useContext} from 'react'
import {BoardSettingsPageContext} from '../../context'
import {AddBoardColumnSchema} from '../../validations'
import {AddBoardColumnModalTypes} from '../../interfaces'
import {AddBoardColumnDefaultValues} from '../../utils'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {SectionForm} from '../../../../components/SectionForm'

const AddBoardColumnModal = ({show, onClose, isEdit, columnData}: AddBoardColumnModalTypes) => {
  const {createColumn, updateColumn, isLoadingColumnOperation, isLoadingBoardColumnsList} =
    useContext(BoardSettingsPageContext)

  const {
    register,
    handleSubmit,
    formState: {dirtyFields, errors, isDirty, isValid},
    setError,
  } = useForm({
    defaultValues: AddBoardColumnDefaultValues(columnData, isEdit),
    resolver: yupResolver(AddBoardColumnSchema),
  })

  const onSubmit = async (data: any) => {
    if (isEdit) {
      await updateColumn(`${columnData?.columnId}`, data, dirtyFields, setError)
    } else {
      await createColumn({...data, is_archived: false}, setError)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Add'} Column`}
      onAction={handleSubmit(onSubmit)}
      actionName={`${isEdit ? 'Update' : 'Add'}`}
      actionBtnClass='btn-primary'
      isOperationLoading={isLoadingColumnOperation}
      isDataLoading={isLoadingBoardColumnsList}
      isDisabled={!isDirty || !isValid || isLoadingColumnOperation || isLoadingBoardColumnsList}
      body={
        <>
          <SectionForm
            id='add-board-columns'
            register={register}
            commonClassNames='mb-10'
            errors={errors}
            inputs={[
              {
                id: 'board-column-name',
                label: 'Column Name',
                placeholder: 'Enter column name',
                registerKey: 'name',
                required: true,
                maxLength: 20,
              },
              {
                id: 'board-column-description',
                isTextArea: true,
                label: 'Column Description',
                placeholder: 'Enter column description',
                registerKey: 'description',
                inputClass: 'resize-none',
                maxLength: 255,
              },
              {
                isCheckBox: true,
                id: 'resolved',
                registerKey: 'is_resolved',
                label: 'Resolved',
              },
            ]}
          />
        </>
      }
    />
  )
}

export default AddBoardColumnModal
