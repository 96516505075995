import {useContext, useState} from 'react'
import {ProjectsTeamsPageContext} from '../../context'
import Search from '../../../../components/Search'
import ProjectTeamsModal from '../modals/ProjectTeamsModal'

const ProjectsTeamsPageHeader = () => {
  const [showModal, setShowModal] = useState(false)
  const {onSearch, hasProjectSettingsPermission} = useContext(ProjectsTeamsPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch && onSearch(searchTerm)
        }}
      />
      {hasProjectSettingsPermission && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Create Team
        </button>
      )}

      {showModal && (
        <ProjectTeamsModal
          projectTeamModalDetails={null}
          show={showModal}
          onClose={() => setShowModal(false)}
          isEdit={false}
        />
      )}
    </div>
  )
}

export default ProjectsTeamsPageHeader
