/* eslint-disable react-hooks/exhaustive-deps */
// import {SheetDataContext} from '../../../context'
import { useContext } from 'react'
import Search from '../../../../components/Search'
import { ExecutedProductRulesPageContext } from '../../contexts'

const ExecutedProductRuleSearch = () => {
  const {onSearch} = useContext(ExecutedProductRulesPageContext)

  return (
    <>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch && onSearch(searchTerm)
        }}
      />
    </>
  )
}

export {ExecutedProductRuleSearch}
