import {Pagination} from '../../../utils/pagination'
import {IdleCustomersDashboardContext} from '../context'
import useMeta from '../../../hook/useMeta'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useGetAnonymouslyActiveCustomers} from '../hooks/useGetAnonymouslyActiveCustomers'
import AnonymouslyActiveCustomerListing from '../sections/table/AnonymouslyActiveCustomerListing'

const AnonymouslyActiveCustomerBreadCrums: Array<PageLink> = [
  {
    title: 'Idle Customers',
    path: `/analytics/idle-customers`,
    isSeparator: false,
    isActive: false,
  },
]

const AnonymouslyActiveCustomerPage = () => {
  useMeta('Anonymously Active Customer')

  const {
    anonymousCustomers,
    isLoading: anonymousCustomersLoading,
    loadMore: loadMoreAnonymousCustomers,
    pagination,
    onPageChange,
    onAnonymouslyActiveCustomerClickSorting,
    AnonymouslyActiveCustomerFilters,
  }: any = useGetAnonymouslyActiveCustomers(30)

  const contextValue = {
    inactiveCustomers: [],
    inactiveCustomersLoading: false,
    loadMoreInactiveCustomers: () => {},
    totalInactiveCustomers: 0,
    recentlyInactiveCustomers: [],
    recentlyInactiveCustomersLoading: false,
    totalRecentlyInactiveCustomers: 0,
    loadMoreRecentlyInactiveCustomers: () => {},
    anonymousCustomers,
    anonymousCustomersLoading,
    totalAnonymousCustomers: 0,
    loadMoreAnonymousCustomers,
    onInactiveCustomerClickSorting: () => {},
    InactiveCustomerFilters: {},
    onRecentlyInactiveCustomerClickSorting: () => {},
    RecentlyInactiveCustomerFilters: {},
    onAnonymouslyActiveCustomerClickSorting,
    AnonymouslyActiveCustomerFilters,
  }

  return (
    <>
      <IdleCustomersDashboardContext.Provider value={contextValue}>
        <PageTitle breadcrumbs={AnonymouslyActiveCustomerBreadCrums}>
          Anonymous Activity (No Orders In Last 30 Days)
        </PageTitle>
        <AnonymouslyActiveCustomerListing />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </IdleCustomersDashboardContext.Provider>
    </>
  )
}

export default AnonymouslyActiveCustomerPage
