import React, {useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'
import {Link} from 'react-router-dom'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isToggleActive?: boolean
  firstChildRoute: string
  isScroll?: boolean // Flag to enable scroll to last child
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  isToggleActive,
  firstChildRoute,
  isScroll,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  const menuSubRef = useRef<HTMLDivElement>(null)

  const [toggleCounter, setToggleCounter] = useState(0)

  useEffect(() => {
    if (isScroll && menuSubRef.current) {
      const menuSubElement = menuSubRef.current
      setTimeout(() => {
        menuSubElement.scrollIntoView({behavior: 'smooth'})
      }, 250)
    }
  }, [isScroll, toggleCounter])

  const handleToggle = () => {
    setToggleCounter((prev) => prev + 1)
  }

  return (
    <>
      {isToggleActive ? (
        <div
          className={`menu-item test-happy menu-accordion ${isActive ? 'here show hiding' : ''}`}
          onClick={handleToggle}
        >
          <span className='menu-link test-happy'>
            <Link to={firstChildRoute}>
              {hasBullet && (
                <span className='menu-bullet'>
                  <span className='bullet bullet-dot'></span>
                </span>
              )}
              {icon && aside.menuIcon === 'svg' && (
                <span className='menu-icon'>
                  <KTSVG path={icon} className='svg-icon-2' />
                </span>
              )}
              {fontIcon && aside.menuIcon === 'font' && (
                <i className={clsx('bi fs-3', fontIcon)}></i>
              )}
            </Link>
          </span>
          <div
            className={`menu-sub menu-sub-accordion test-happy ms-14 ${
              isActive ? 'menu-active-bg' : ''
            }`}
            ref={menuSubRef}
          >
            {children}
          </div>
        </div>
      ) : (
        <div
          className={clsx('menu-item', {'here show': isActive && isToggleActive}, 'menu-accordion')}
          data-kt-menu-trigger='click'
          onClick={handleToggle}
        >
          <span className='menu-link'>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
            <span className='menu-title'>{title}</span>
          </span>
          <div
            className={clsx('menu-sub menu-sub-accordion ms-14', {'menu-active-bg': isActive})}
            ref={menuSubRef}
          >
            {children}
          </div>
        </div>
      )}
    </>
  )
}

export {AsideMenuItemWithSub}
