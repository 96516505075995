/* eslint-disable */
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {useEffect, useState, useCallback, useContext} from 'react'
import {$getRoot, $isElementNode, $isTextNode, LexicalNode, TextNode} from 'lexical'
import {$getSelection, $isRangeSelection, $setSelection} from 'lexical'
import {TextEditorContext} from '../../context/TextEditorContext'

type CharacterLimitPluginProps = {
  charset: 'UTF-8' | 'UTF-16'
  maxLength: number
}

function getLength(str: string, charset: 'UTF-8' | 'UTF-16'): number {
  if (charset === 'UTF-16') {
    return str.length
  }
  const encoder = new TextEncoder()
  return encoder.encode(str).length
}

function calculateCharacterCount(node: LexicalNode, charset: 'UTF-8' | 'UTF-16'): number {
  if ($isTextNode(node)) {
    return getLength(node.getTextContent(), charset)
  }

  if ($isElementNode(node)) {
    return node
      .getChildren()
      .reduce((count, child) => count + calculateCharacterCount(child, charset), 0)
  }

  return 0
}

function mergeStyles(existingStyle: string, newStyle: string): string {
  const existingStyles = existingStyle.split(';').filter(style => style.trim() && !style.includes('background-color'));
  const newStyles = newStyle.split(';').filter(style => style.trim());
  return [...existingStyles, ...newStyles].join(';');
}

export function CharacterLimitPlugin({
  charset,
  maxLength,
}: CharacterLimitPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  const [characterCount, setCharacterCount] = useState(0)
  const {setCharCount} = useContext(TextEditorContext)

  const updateCharacterCount = useCallback(() => {
    editor.getEditorState().read(() => {
      const root = $getRoot()
      const count = calculateCharacterCount(root, charset)
      setCharacterCount(count)
      setCharCount(count)
    })
  }, [editor, charset, setCharCount])

  useEffect(() => {
    return editor.registerUpdateListener(updateCharacterCount)
  }, [editor, updateCharacterCount])

  useEffect(() => {
    const applyStyleToExcessChars = () => {
      editor.update(() => {
        const root = $getRoot()
        let accumulatedCount = 0
        const selection = $getSelection()
        let selectionOffset = $isRangeSelection(selection) ? selection.anchor.offset : 0

        const applyStyleRecursively = (node: LexicalNode): void => {
          if ($isTextNode(node)) {
            const text = node.getTextContent()
            const nodeLength = getLength(text, charset)
            const existingStyle = node.getStyle()

            if (accumulatedCount < maxLength && accumulatedCount + nodeLength > maxLength) {
              const remainingChars = maxLength - accumulatedCount
              const splitPoint = getSplitPoint(text, remainingChars, charset)

              const firstPart = text.slice(0, splitPoint)
              const secondPart = text.slice(splitPoint)

              node.setTextContent(firstPart)
              node.setStyle(existingStyle)

              const newNode = new TextNode(secondPart)
              newNode.setStyle(mergeStyles(existingStyle, 'background-color: #FFBBBB; color: red'))
              node.insertAfter(newNode)

              if (selectionOffset > splitPoint) {
                const newSelection = selection?.clone()
                if (newSelection && $isRangeSelection(newSelection)) {
                  newSelection.anchor.set(newNode.getKey(), selectionOffset - splitPoint, 'text')
                  newSelection.focus.set(newNode.getKey(), selectionOffset - splitPoint, 'text')
                  $setSelection(newSelection)
                }
              }
            } else if (accumulatedCount >= maxLength) {
              node.setStyle(mergeStyles(existingStyle, 'background-color: #FFBBBB; color: red'))
            } else {
              node.setStyle(existingStyle)
            }

            accumulatedCount += nodeLength
          } else if ($isElementNode(node)) {
            node.getChildren().forEach(applyStyleRecursively)
          }
        }

        root.getChildren().forEach(applyStyleRecursively)
      })
    }

    applyStyleToExcessChars()
  }, [editor, maxLength, charset, characterCount])

  const remainingCharacters = maxLength - characterCount

  return (
    <div
      className='character-count'
      style={{
        position: 'absolute',
        bottom: '5px',
        left: '10px',
        fontSize: '12px',
        color: remainingCharacters < 0 ? 'red' : '#888',
      }}
    >
      {remainingCharacters >= 0
        ? `${remainingCharacters}`
        : `(${remainingCharacters}) Max limit reached`}
    </div>
  )
}

function getSplitPoint(text: string, targetLength: number, charset: 'UTF-8' | 'UTF-16'): number {
  if (charset === 'UTF-16') {
    return targetLength
  }

  const encoder = new TextEncoder()
  let currentLength = 0
  let splitPoint = 0

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i)
    const charLength = encoder.encode(char).length

    if (currentLength + charLength > targetLength) {
      break
    }

    currentLength += charLength
    splitPoint = i + 1
  }

  return splitPoint
}
