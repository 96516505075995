const TableRow = ({ row, keys, actionComponent, register, checkBoxComponent }: any) => {
  const filteredKeys = keys?.filter((key: any) => key !== 'action');

  return (
    <tr key={row?.id}>
      {checkBoxComponent && <td>{checkBoxComponent(row)}</td>}
      {filteredKeys?.map((key: any, index: any) => (
        <td key={index}>
          {row?.hasOwnProperty(key) ? (row[key] !== '' ? row[key] ?? '-' : '-') : '-'}
        </td>
      ))}
      {actionComponent && <td>{actionComponent(row, register)}</td>}
    </tr>
  );
};

export default TableRow;
