import React, {useContext} from 'react'
import Search from '../../../../components/Search'
import {SuggestedCustomerReportPageContext} from '../../contexts'
import {Select} from '../../../../components/Select/'

const SuggestedCustomerHeader = () => {
  const {onRepFilterChange, onSearch, Representatives} = useContext(
    SuggestedCustomerReportPageContext
  )

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex gap-5'>
        <div>
          <Search
            onSearch={(searchTerm: string) => {
              onSearch(searchTerm)
            }}
          />
        </div>
        <div className='w-200px'>
          <Select
            defaultValue=''
            onChange={(e: any) => onRepFilterChange(e.target.value)}
            options={[{label: 'Customer Rep : All', value: ''}, ...Representatives]}
            id={'reps'}
          />
        </div>
      </div>
    </div>
  )
}

export default SuggestedCustomerHeader
