import React from 'react'
import {Modal} from 'react-bootstrap'
import UserForm from '../form/UserForm'

const UserModal = ({Builds, show, onClose, editId}: any) => {
  if (!show) {
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_trigger_build'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
      onHide={onClose}
    >
      <div className='modal-content'>
        <UserForm onClose={onClose} editId={editId} />
      </div>
    </Modal>
  )
}

export default UserModal
