import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {
  $getRoot,
  $createTextNode,
  createCommand,
  LexicalCommand,
  $getSelection,
  $isRangeSelection,
} from 'lexical'
import {$createCodeNode} from '@lexical/code'
import {useEffect, useCallback} from 'react'
import {
  $generateHtmlFromNodes,
  $generateNodesFromDOM,
  minifyHtml,
} from '../../utils/htmlConversions'

const PRETTIER_OPTIONS: any = {
  parser: 'html',
  printWidth: 100,
  trailingComma: 'es5',
  tabWidth: 2,
  semi: false,
  singleQuote: true,
  bracketSpacing: false,
  jsxBracketSameLine: false,
  arrowParens: 'always',
  endOfLine: 'auto',
  jsxSingleQuote: true,
  quoteProps: 'as-needed',
  useTabs: false,
  htmlWhitespaceSensitivity: 'ignore',
}

// Update shortenBase64Urls function to truncate base64 URLs
const shortenBase64Urls = (html: string): string => {
  return html.replace(
    /(src=["'])(data:image\/[^;]+;base64,)([^"']+)(["'])/g,
    (match, prefix, mimePrefix, base64Data, suffix) => {
      const truncatedBase64 = base64Data.substring(0, 20) + '...'
      return `${prefix}${mimePrefix}${truncatedBase64}${suffix}`
    }
  )
}

async function loadPrettier(htmlContent: string) {
  try {
    const [{format}, {default: parserHtml}] = await Promise.all([
      import('prettier/standalone'),
      import('prettier/parser-html'),
    ])

    return format(htmlContent, {
      ...PRETTIER_OPTIONS,
      plugins: [parserHtml],
    })
  } catch (error) {
    console.error('Error loading Prettier:', error)
    return htmlContent
  }
}

export const HTML_CONVERT_COMMAND: LexicalCommand<'html' | 'richtext'> =
  createCommand('HTML_CONVERT_COMMAND')

export default function HtmlPlugin(): null {
  const [editor] = useLexicalComposerContext()

  const convertToHtml = useCallback(() => {
    let htmlString = ''
    editor.getEditorState().read(() => {
      htmlString = $generateHtmlFromNodes(editor)
    })

    const cleanHtml = htmlString
      .replace(/\s*\n\s*(<\/?span[^>]*>)\s*\n\s*/g, '$1')
      .replace(
        /(<\/(div|p|ul|ol|blockquote|section|article|header|footer|main|aside|nav|h[1-6])>)(?!\n\n)/g,
        '$1\n\n'
      )
      .replace(
        /(?<!^\s*)(<(div|p|ul|ol|blockquote|section|article|header|footer|main|aside|nav|h[1-6])[^>]*>)/g,
        '\n\n$1'
      )

    loadPrettier(cleanHtml).then((formattedHtml) => {
      editor.update(() => {
        const root = $getRoot()
        root.clear()
        const codeNode = $createCodeNode('html')
        const displayHtml = shortenBase64Urls(formattedHtml)
        const textNode = $createTextNode(displayHtml)
        codeNode.append(textNode)
        root.append(codeNode)
      })
    })
  }, [editor])

  const convertFromHtml = useCallback(() => {
    const root = $getRoot()
    const firstChild = root.getFirstChild()
    if (!firstChild) return

    const htmlContent = firstChild.getTextContent()

    if (!htmlContent) return

    loadPrettier(htmlContent).then((formattedHtml) => {
      editor.update(() => {
        try {
          const cleanHtml = formattedHtml.replace(/\s*\n\s*(<\/?span[^>]*>)\s*\n\s*/g, '$1')
          const minifiedHtml = minifyHtml(cleanHtml)
          const parser = new DOMParser()
          const dom = parser.parseFromString(minifiedHtml, 'text/html')

          // Preserve layout container attributes and structure
          dom.querySelectorAll('[data-lexical-layout-container]').forEach(container => {
            if (container instanceof HTMLElement) {
              // Ensure grid styles are preserved
              if (!container.style.display) {
                container.style.display = 'grid'
              }
              if (!container.style.gap) {
                container.style.gap = '10px'
              }

              // Ensure layout items are properly wrapped
              const children = Array.from(container.children)
              children.forEach(child => {
                if (!child.classList.contains('textEditor_layoutItem')) {
                  const layoutItem = document.createElement('div')
                  layoutItem.className = 'textEditor_layoutItem'
                  child.replaceWith(layoutItem)
                  layoutItem.appendChild(child)
                }
              })
            }
          })

          const root = $getRoot()
          root.clear()
          const nodes = $generateNodesFromDOM(editor, dom)
          if (nodes) {
            root.append(...nodes)
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              selection.anchor.set(nodes[0].getKey(), 0, 'element')
              selection.focus.set(nodes[0].getKey(), 0, 'element')
            }
          }
        } catch (error) {
          console.error('Error parsing HTML:', error)
        }
      })
    })
  }, [editor])

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const clipboardData = event.clipboardData
      if (!clipboardData) return

      const text = clipboardData.getData('text/plain')
      if (text.trim().startsWith('<') && text.trim().endsWith('>')) {
        event.preventDefault()

        loadPrettier(text).then((formattedHtml) => {
          editor.update(() => {
            const root = $getRoot()
            const hasHtmlContent = root.getChildren().some((child) => child.getType() === 'code')
            if (!hasHtmlContent) {
              root.clear()
            }
            const codeNode = $createCodeNode('html')
            codeNode.append($createTextNode(formattedHtml))
            root.append(codeNode)
          })
        })
      }
    }

    editor.getRootElement()?.addEventListener('paste', handlePaste)
    return () => {
      editor.getRootElement()?.removeEventListener('paste', handlePaste)
    }
  }, [editor])

  useEffect(() => {
    return editor.registerCommand<'html' | 'richtext'>(
      HTML_CONVERT_COMMAND,
      (payload) => {
        if (payload === 'html') {
          convertToHtml()
        } else {
          convertFromHtml()
        }
        return true
      },
      1
    )
  }, [editor, convertToHtml, convertFromHtml])

  return null
}
