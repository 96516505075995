import usePermission from '../../../hook/usePermission'
import { Pagination } from '../../../utils/pagination'
import {TasksReportPageContext} from '../context'
import useGetTasksReportsData from '../hooks/TasksReports/useGetTasksReportsData'
import TasksReportHeader from '../sections/headers/TasksReportHeader'
import TasksReportTable from '../sections/tables/TasksReportTable'

const TasksReport = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {
    isLoading,
    tasksReportsData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onPageChange,
    pagination,
    onProjectChange,
    filtersData,
    refetch
  } = useGetTasksReportsData()

  const contextValue = {
    hasProjectSettingsPermission,
    isLoading,
    tasksReportsData,
    onSortingChange,
    onProjectChange,
    onSearch,
    searchValue,
    filters,
    filtersData,
    refetch
  }

  return (
    <>
      <TasksReportPageContext.Provider value={contextValue}>
        <TasksReportHeader />
        <TasksReportTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </TasksReportPageContext.Provider>
    </>
  )
}

export default TasksReport
