import {useEffect, useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import isEqual from 'lodash/isEqual'

interface MultipleSelectProps {
  options: any
  defaultValues?: any
  placeholder?: string | undefined
  isDisabled?: boolean
  handleSelected?: (values: any) => void
  className?: string
  inputClass?: string
  hasSelectAll?: boolean
  error?: any
  label?: string
  isRequired?: boolean
  formLabel?: string
  control?: any
}

function MultipleSelect({
  options,
  inputClass,
  defaultValues,
  placeholder = 'Select',
  isDisabled,
  handleSelected,
  className,
  hasSelectAll = true,
  error,
  label,
  isRequired,
  formLabel,
}: MultipleSelectProps) {
  const [selected, setSelected] = useState(defaultValues || [])

  useEffect(() => {
    handleSelected?.(selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const handleChange = (values: any) => {
    setSelected(values)
  }

  useEffect(() => {
    if (defaultValues && !isEqual(defaultValues, selected)) {
      setSelected(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  return (
    <div className={className}>
      {label && (
        <label className={`form-label ${isRequired ? 'required' : ''}`} form-label={formLabel}>
          {label}
        </label>
      )}
      <MultiSelect
        options={options}
        value={selected}
        onChange={(values: any) => handleChange(values)}
        labelledBy={placeholder}
        disabled={isDisabled}
        className={inputClass}
        hasSelectAll={hasSelectAll}
        overrideStrings={{
          selectSomeItems: placeholder,
        }}
      />
      {error && error?.message && (
        <div className='form-error invalid-feedback'>{error.message}</div>
      )}
    </div>
  )
}

export default MultipleSelect
