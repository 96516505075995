import {useEffect, useState, useRef} from 'react'
import DndDynamicTable from './components/DndDynamicTable'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {createPortal} from 'react-dom'
import {handleDragEnd} from './utils/handleDragEnd'
import {handleDragOver} from './utils/handleDragOver'
import {handleDragStart} from './utils/handleDragStart'
import {CustomPointerSensor} from './utils/customPointerSensor'
import usePermission from '../../hook/usePermission'
import {DndTableProps} from './dndTableTypes'

function DndTable({
  id,
  data,
  columns,
  isLoading = false,
  onChange,
  readOnly = false,
  uniqueId,
  TableRow,
  noDataText,
  permissionPath,
  hasWritePermission: hasActionPermission = true,
  searchValue,
  noSearchResultMessage = `No results found for '${searchValue}'.`,
  tableRowProps,
}: DndTableProps) {
  const {hasPermission} = usePermission()
  const [tableData, setTableData] = useState(data)
  const [activeRow, setActiveRow] = useState<any>(null)
  const [initialIndex, setInitialIndex] = useState<number | null>(null)
  const prevDataRef = useRef<string>('')

  const hasWritePermission = permissionPath
    ? hasPermission(permissionPath, 'write')
    : hasActionPermission

  const tableColumns = hasWritePermission
    ? columns.filter((column: any) => column.show !== false)
    : columns.filter((column: any) => column.key !== 'action' && column.show !== false)

  useEffect(() => {
    const currentDataString = JSON.stringify(data)
    if (prevDataRef.current !== currentDataString) {
      prevDataRef.current = currentDataString
      setTableData(data)
    }
  }, [data])

  const sensors = useSensors(
    useSensor(CustomPointerSensor, {
      activationConstraint: {
        distance: 0,
      },
    })
  )

  function onDragStart(event: DragStartEvent) {
    handleDragStart(event, tableData, setActiveRow, setInitialIndex, uniqueId)
  }

  function onDragOver(event: DragOverEvent) {
    handleDragOver(event, setTableData, uniqueId)
  }

  function onDragEnd(event: DragEndEvent) {
    handleDragEnd(event, onChange, tableData, setActiveRow, initialIndex, setInitialIndex, uniqueId)
  }

  const tableContent = (
    <DndDynamicTable
      tableData={tableData}
      tableColumns={tableColumns}
      isLoading={isLoading}
      readOnly={readOnly}
      uniqueId={uniqueId}
      TableRow={TableRow}
      noDataText={noDataText}
      hasWritePermission={hasWritePermission}
      searchValue={searchValue}
      noSearchResultMessage={noSearchResultMessage}
      tableRowProps={tableRowProps}
    />
  )

  const dragOverlay = createPortal(
    <DragOverlay>
      {activeRow && (
        <table className='w-100'>
          <thead>
            <tr>
              {tableColumns.map((column: any, index: number) => (
                <th key={index} className={column.headerClass}></th>
              ))}
            </tr>
          </thead>
          <tbody>
            <TableRow
              dndSettings={{}}
              row={activeRow}
              hasWritePermission={hasWritePermission}
              {...tableRowProps}
              dndImage={
                hasWritePermission ? (
                  <img src='/media/svg/general/Group556.svg' className='me-3' alt='img' />
                ) : null
              }
            />
          </tbody>
        </table>
      )}
    </DragOverlay>,
    document.body
  )

  return (
    <div dnd-table-area='true' id={id}>
      {readOnly || !hasWritePermission ? (
        tableContent
      ) : (
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        >
          {tableContent}
          {dragOverlay}
        </DndContext>
      )}
    </div>
  )
}

export default DndTable
