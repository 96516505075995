import useApi from '../../../services/useApi'
import useFilters from '../../../hook/useFilters'
import {convertToLabelValueFormat} from '../../../utils/common'

export const useGetAllStates = () => {
  const Api = useApi()
  const {filters} = useFilters(
    {
      product_id: '',
      start_date: '',
      end_date: '',
    },
    {
      sortType: 'number',
    }
  )

  const {data: response, isFetching} = Api.useGetQuery(
    `/analytics/sold-products/state-wise-stats/allstates`,
    {
      queryId: 'all-states',
      filters: filters,
    }
  )

  return {
    AllStateData: convertToLabelValueFormat(response?.data?.states),
    AllStateCount: response?.data?.state_count || 0,
    isLoadingStates: isFetching,
  }
}
