/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import Papa from 'papaparse'
import Import from '../../images/Import.png'
import {ConfirmationModal} from '../ConfirmationModal'

// Helper function to format file size.
const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1024) return `${sizeInBytes} bytes`
  if (sizeInBytes < 1024 * 1024) return `${(sizeInBytes / 1024).toFixed(2)} KB`
  if (sizeInBytes < 1024 * 1024 * 1024) return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`
  return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`
}

function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

// Helper function to merge custom errors
function mergeDeep(defaultErrors: any, customErrors: any) {
  if (!isObject(defaultErrors) || !isObject(customErrors)) {
    return customErrors
  }

  Object.keys(customErrors).forEach((key) => {
    const sourceValue = customErrors[key]
    const targetValue = defaultErrors[key]

    if (typeof sourceValue === 'function') {
      defaultErrors[key] = sourceValue
    } else if (isObject(sourceValue)) {
      defaultErrors[key] = mergeDeep(targetValue ?? {}, sourceValue)
    } else {
      defaultErrors[key] = sourceValue
    }
  })

  return defaultErrors
}

// Helper function to manage error messages with dynamic placeholders.
const createErrorMessages = (fileSize: number, maxFileSize: number, customErrors?: any) => {
  const formattedFileSize = formatFileSize(fileSize)
  const formattedMaxFileSize = formatFileSize(maxFileSize)

  const defaultErrors = {
    delimiterMismatch:
      'The delimiter in the file does not match the expected delimiter for import.',
    parsingErrors: (errors: string[]) => `Parsing errors encountered: ${errors.join(', ')}`,
    fileSizeLimit: `The uploaded file size (${formattedFileSize}) exceeds the maximum allowed limit (${formattedMaxFileSize}). Please reduce the file size.`,
    maxRowsLimit: (rowNumbers: number, maxRowsLimit: number) => (
      <span>
        The CSV file exceeds the maximum allowable number of rows <strong>{maxRowsLimit}</strong>.
        Please reduce the number of rows and try again.
      </span>
    ),
    headerMismatch: (missingHeaders: string, expectedHeaders: string, errorHeadersCount: number) =>
      `The CSV file has an incorrect or missing ${
        errorHeadersCount > 1 ? 'headers' : 'header'
      } (${missingHeaders}). Expected ${
        errorHeadersCount > 1 ? 'headers' : 'header'
      }: ${expectedHeaders}.`,
    extraHeaders: (extraHeaders: string) =>
      `The CSV file contains unexpected extra header: ${extraHeaders}. Please remove these headers and try again.`,
    emptyCell: (rowIndex: number, headerName: string) =>
      `Empty cell found at Row No: ${rowIndex}, Column: ${headerName}.`,
    emptyFile: 'The CSV file is empty or contains no valid data rows.',
    invalidFileType: (fileType: string) =>
      `Invalid file type (${fileType}). Only CSV files are allowed for import. Please upload a valid CSV file.`,
    duplicateRecords: (rowNumbers: number[]) =>
      `Duplicate rows in the file (rows: ${rowNumbers.join(
        ', '
      )}) can cause data inconsistencies. Remove duplicate entries before import.`,
    duplicateData: (headerName: string, rowNumbers: number[]) =>
      `Duplicate ${headerName} found in rows: ${rowNumbers.join(', ')}`,
    duplicateWithDefault: (existingValues: string[], rowNumbers: number[], headerName: string) =>
      `${headerName} (${existingValues.join(', ')}) already exists of ${
        rowNumbers.length > 1 ? 'rows' : 'row'
      }: ${rowNumbers.join(', ')}.`,
    combinationLimitExceeded: (rowNumbers: number, combinationLimit: number) =>
      `The CSV file exceeds the maximum allowed combination limit of ${combinationLimit}.`,
    invalidDataType: (headerName: string, rows: string, expectedType: string, actualValue: any) =>
      `Invalid data type for ${headerName} at row ${rows}. Expected ${expectedType}, but found ${typeof actualValue}.`,
    maxLengthExceeded: (
      headerName: string,
      rowNumber: number,
      currentLength: number,
      maxLength: number
    ) =>
      `Max length exceeded for ${headerName} at row ${rowNumber}. Current length: ${currentLength}, Max allowed: ${maxLength}.`,
  }

  return customErrors ? mergeDeep(defaultErrors, customErrors) : defaultErrors
}

// Helper function to filter data based on specified fields.
function filterData(data: any[], fields: any[]) {
  return data
    .map((item: any) => {
      let filteredItem: any = {}
      fields.forEach((field) => {
        if (item.hasOwnProperty(field)) {
          filteredItem[field] = item[field]
        }
      })
      return filteredItem
    })
    .filter((item: any) => {
      return fields.some((field) => item[field] !== '')
    })
}

// Main Helper Function to handle the CSV import and validate the data.
const importCSV = async (
  file: any,
  config: any,
  setErrors: (errors: {existingDataErrors: string[]; otherErrors: string[]}) => void,
  setShowModal: (value: boolean) => void,
  onError?: any,
  existingData?: any[]
) => {
  const {
    maxFileSize = 10 * 1024 * 1024,
    maxRows = Infinity,
    requiredHeaders = [],
    transformKeys = {},
    transformData = {},
    duplicates = {},
    isNullable = true,
    isValidateHeadersDataOnly = false,
    allowDuplicateRow = false,
    isCaseSensitiveHeader = false,
    combinationLimit,
    customErrors,
    types,
    validationRules = {},
  } = config
  const processedHeaders = isCaseSensitiveHeader
    ? requiredHeaders
    : requiredHeaders.map((header: string) => header.toLowerCase())

  const errorMessages = createErrorMessages(file.size, maxFileSize, customErrors)
  const existingDataErrors: string[] = []
  const otherErrors: string[] = []

  // helper function to get actual header string using processed header string
  function getActualHeader(processedHeader: string): string[] {
    return requiredHeaders?.filter((header: string) => header.toLowerCase() === processedHeader)
  }

  try {
    // Check file type.
    if (file.type !== 'text/csv') {
      otherErrors.push(errorMessages.invalidFileType(file.type))
      setErrors({existingDataErrors, otherErrors})
      setShowModal(true)
      return {data: null, otherErrors, existingDataErrors}
    }

    // Check file size limit.
    if (file.size > maxFileSize) {
      otherErrors.push(errorMessages.fileSizeLimit)
    }

    // Read and parse file.
    const text = await file.text()
    const {data, errors} = Papa.parse(text, {header: true, skipEmptyLines: true})

    // Process parsed data headers based on case sensitivity, trim values, and filter empty rows
    const processedData = data
      .map((row: any) => {
        const processedRow: any = {}
        Object.keys(row).forEach((key) => {
          const processedKey = isCaseSensitiveHeader ? key : key.toLowerCase()
          processedRow[processedKey] = typeof row[key] === 'string' ? row[key].trim() : row[key]
        })
        return processedRow
      })
      .filter((row: any) => Object.values(row).some((value) => value !== ''))

    const dataToValidate = isValidateHeadersDataOnly
      ? await filterData(processedData, processedHeaders)
      : processedData

    // Check if file is empty.
    if (dataToValidate.length === 0) {
      otherErrors.push(errorMessages.emptyFile)
      setErrors({existingDataErrors, otherErrors})
      setShowModal(true)
      return {data: null, otherErrors, existingDataErrors}
    }

    // Check for max rows limit.
    if (dataToValidate.length > maxRows) {
      otherErrors.push(errorMessages.maxRowsLimit(dataToValidate.length, maxRows))
    }

    // Check if combinations exceed the limit of 600.
    if (combinationLimit && dataToValidate.length > combinationLimit) {
      otherErrors.push(
        errorMessages.combinationLimitExceeded(dataToValidate.length, combinationLimit)
      )
    }

    // Check parsing errors.
    if (errors.length > 0) {
      otherErrors.push(errorMessages.parsingErrors(errors.map((e) => e.message)))
    }

    // Check for missing or incorrect headers.
    const CSVHeaders = Object.keys(data[0] || {})

    const missingHeaders = requiredHeaders.filter(
      (requiredHeader: any) =>
        !CSVHeaders.some((header) =>
          isCaseSensitiveHeader
            ? header === requiredHeader
            : header.toLowerCase() === requiredHeader.toLowerCase()
        )
    )

    if (
      missingHeaders.length > 0 ||
      (!isValidateHeadersDataOnly && CSVHeaders.length > processedHeaders.length)
    ) {
      if (missingHeaders.length > 0) {
        const missingHeadersString = missingHeaders.join(', ')
        const expectedHeadersString = requiredHeaders.join(', ')
        otherErrors.push(
          errorMessages.headerMismatch(
            missingHeadersString,
            expectedHeadersString,
            missingHeaders.length
          )
        )
      }

      if (!isValidateHeadersDataOnly && CSVHeaders.length > processedHeaders.length) {
        const extraHeaders = CSVHeaders.filter(
          (header) =>
            !processedHeaders.includes(isCaseSensitiveHeader ? header : header.toLowerCase())
        )
        if (extraHeaders.length > 0) {
          const extraHeadersString = extraHeaders.join(', ')
          otherErrors.push(errorMessages.extraHeaders(extraHeadersString))
        }
      }
    }

    const headers = Object.keys(dataToValidate[0] || {})

    // Check for empty cells.
    if (!isNullable && processedHeaders.every((header: any) => headers.includes(header))) {
      dataToValidate.forEach((row, rowIndex) => {
        processedHeaders.forEach((header: any) => {
          if (!row[header] || row[header].trim() === '') {
            otherErrors.push(errorMessages.emptyCell(rowIndex + 2, getActualHeader(header)))
          }
        })
      })
    }

    // check for duplicate rows
    const checkDuplicateRows = (dataToValidate: any[]) => {
      const duplicates: any = []
      const seenRows = new Map()
      dataToValidate.forEach((row, index) => {
        const rowData = JSON.stringify(row)
        if (seenRows.has(rowData)) {
          duplicates.push(seenRows.get(rowData))
          duplicates.push(index + 2)
        } else {
          seenRows.set(rowData, index + 2)
        }
      })
      return Array.from(new Set(duplicates)).sort((a: any, b: any) => a - b)
    }
    if (!duplicates?.rows || duplicates?.rows === false) {
      const duplicateRows: any = checkDuplicateRows(dataToValidate)
      if (!allowDuplicateRow && duplicateRows.length > 0) {
        otherErrors.push(errorMessages.duplicateRecords(duplicateRows))
      }
    }

    // Check for duplicates based on specific fields.
    const dynamicDuplicateCheck = (dataToValidate: any[], key: string) => {
      const uniqueValues = new Set()
      const duplicateRows: number[] = []

      dataToValidate.forEach((item, index) => {
        if (uniqueValues.has(item[key])) {
          duplicateRows.push(index + 2)
        }
        uniqueValues.add(item[key])
      })

      return duplicateRows
    }
    if (processedHeaders.every((header: any) => headers.includes(header))) {
      for (const key of Object.keys(duplicates)) {
        if (key !== 'rows' && duplicates[key] === false) {
          const duplicateIndices = dynamicDuplicateCheck(dataToValidate, key)
          if (duplicateIndices.length > 0) {
            otherErrors.push(errorMessages.duplicateData(getActualHeader(key), duplicateIndices))
          }
        }
      }
    }

    // Check for duplicates with existing Data
    if (
      existingData &&
      existingData.length > 0 &&
      config.isReplaceable &&
      config.replacePartially
    ) {
      for (const key of Object.keys(duplicates)) {
        if (key !== 'rows' && duplicates[key] === false) {
          const existingValues = new Set(existingData.map((item) => item[key]))

          dataToValidate.forEach((item, index) => {
            if (existingValues.has(item[key])) {
              existingDataErrors.push(
                errorMessages.duplicateWithDefault([item[key]], [index + 2], getActualHeader(key))
              )
            }
          })
        }
      }
    }

    // check for types of values
    if (types) {
      processedHeaders.forEach((header: any) => {
        const invalidRows: number[] = []

        dataToValidate.forEach((row, rowIndex) => {
          const actualValue = row[header]
          const expectedType = types[header]

          if (expectedType) {
            let isInvalid = false

            if (expectedType === 'integer') {
              if (!Number.isInteger(Number(actualValue))) {
                isInvalid = true
              }
            } else if (expectedType === 'float') {
              if (isNaN(parseFloat(actualValue)) || Number.isInteger(Number(actualValue))) {
                isInvalid = true
              }
            } else if (expectedType === 'number') {
              if (isNaN(Number(actualValue))) {
                isInvalid = true
              }
            } else if (typeof actualValue !== expectedType) {
              isInvalid = true
            }

            if (isInvalid) {
              invalidRows.push(rowIndex + 1)
            }
          }
        })
        // If there are invalid rows for this header, add an error message
        if (invalidRows.length > 0) {
          otherErrors.push(
            errorMessages.invalidDataType(
              getActualHeader(header),
              invalidRows.join(', '),
              types[header],
              'Invalid values detected'
            )
          )
        }
      })
    }

    // Add this validation after parsing the CSV data
    if (processedHeaders.every((header: any) => headers.includes(header))) {
      dataToValidate.forEach((row, rowIndex) => {
        processedHeaders.forEach((header: any) => {
          const value = row[header]
          const rule = validationRules[header]
          if (rule && rule.maxLength && value && value.length > rule.maxLength) {
            otherErrors.push(
              errorMessages.maxLengthExceeded(
                getActualHeader(header),
                rowIndex + 2,
                value.length,
                rule.maxLength
              )
            )
          }
        })
      })
    }

    // Apply transformations.
    let transformedData: any[] = [...dataToValidate]

    if (transformData?.uniqueKey && transformData?.groupedKey) {
      const result: any[] = []

      transformedData.forEach((item) => {
        const uniqueKeyValues = transformData.uniqueKey.map((uniqueKey: any) => {
          const key = Object.keys(uniqueKey)[0]
          return {key: uniqueKey[key], value: item[key]}
        })

        let existingItem = result.find((entry) =>
          uniqueKeyValues.every(({key, value}: any) => entry[key] === value)
        )

        if (!existingItem) {
          existingItem = {}
          uniqueKeyValues.forEach(({key, value}: any) => {
            existingItem[key] = value
          })
          result.push(existingItem)
        }

        transformData.groupedKey.forEach((group: any) => {
          const groupedKey = Object.keys(group)[0]
          const groupedKeyOutput = group[groupedKey]

          if (!existingItem[groupedKeyOutput]) {
            existingItem[groupedKeyOutput] = []
          }

          if (transformData.specialGroupKey && item[groupedKey]) {
            const splitValues = item[groupedKey].split(transformData.specialGroupKey)
            existingItem[groupedKeyOutput].push(...splitValues)
          } else {
            existingItem[groupedKeyOutput].push(item[groupedKey])
          }
        })
      })

      transformedData = result
    }

    // Transform keys.
    if (Object.keys(transformKeys).length > 0) {
      transformedData = transformedData.map((row: any) => {
        const transformedRow: any = {}
        Object.keys(row).forEach((key) => {
          transformedRow[transformKeys[key] || key] = row[key]
        })
        return transformedRow
      })
    }

    // Update error handling
    if (existingDataErrors.length + otherErrors.length > 0) {
      setErrors({existingDataErrors, otherErrors})
      setShowModal(true)
      if (config?.isReplaceable && config?.replacePartially && otherErrors.length === 0) {
        return {
          data: transformedData,
          otherErrors: otherErrors,
          existingDataErrors: existingDataErrors,
        }
      }
      throw new Error([...existingDataErrors, ...otherErrors].join(' | '))
    }

    // Return final data.
    return {data: transformedData, otherErrors: otherErrors, existingDataErrors: existingDataErrors}
  } catch (error) {
    setErrors({existingDataErrors, otherErrors})
    onError?.([...existingDataErrors, ...otherErrors])
    return {
      data: null,
      otherErrors: otherErrors,
      existingDataErrors: existingDataErrors,
    }
  }
}

// Component starts from here...
const CSVImporter = ({
  id,
  config,
  onSuccess,
  onError,
  existingData,
  isLoading = false,
  type = 'import',
}: any) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [errors, setErrors] = useState<{existingDataErrors: string[]; otherErrors: string[]}>({
    existingDataErrors: [],
    otherErrors: [],
  })
  const [parsedData, setParsedData] = useState<any[]>([])

  useEffect(() => {
    if (config.mode === 'onChange') {
      onSuccess(existingData)
    }
  }, [existingData])

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0]
    if (file) {
      importCSV(file, config, setErrors, setShowModal, onError, existingData).then(
        ({data, otherErrors, existingDataErrors}: any) => {
          if (data) {
            if (
              existingData &&
              existingData.length > 0 &&
              ((config.isReplaceable && !config.replacePartially) ||
                (config.isReplaceable &&
                  config.replacePartially &&
                  otherErrors.length + existingDataErrors.length > 0))
            ) {
              setSelectedFile(file)
              setParsedData(data)
              setShowModal(true)
            } else if (errors.otherErrors.length === 0) {
              onSuccess(!config?.isReplaceable && existingData ? [...existingData, ...data] : data)
              onError?.([])
              setErrors({existingDataErrors: [], otherErrors: []})
            }
          }
        }
      )
    }
  }

  const handleConfirmImport = () => {
    if (selectedFile && parsedData.length > 0) {
      onSuccess(parsedData)
      setShowModal(false)
      setSelectedFile(null)
      setParsedData([])
    }
  }

  const handleCancelImport = () => {
    setShowModal(false)
    setSelectedFile(null)
    setParsedData([])
  }

  return (
    <>
      <label htmlFor={`csvImportInput-${id}`} className='btn btn-outline'>
        <div className='d-flex align-items-center'>
          <img src={Import} alt='Import File' className='me-2 import-icon' />
          <div className='d-flex align-items-center'>
            {isLoading
              ? type === 'upload'
                ? 'Uploading...'
                : 'Importing...'
              : type === 'upload'
              ? 'Upload CSV'
              : 'Import CSV'}
            {isLoading && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
          </div>
        </div>
        <input
          id={`csvImportInput-${id}`}
          type='file'
          className='d-none'
          onChange={handleFileChange}
          onClick={(e: any) => {
            e.target.value = ''
            setErrors({existingDataErrors: [], otherErrors: []})
          }}
          accept='.csv'
        />
      </label>
      {showModal && (
        <ConfirmationModal
          show={showModal}
          actionBtnClass='btn-primary'
          okayBtnClass='btn-outline'
          title={
            !config.isReplaceable
              ? errors?.otherErrors?.length + errors?.existingDataErrors?.length > 0
                ? 'Import Error!'
                : 'Are you sure?'
              : errors?.otherErrors?.length > 0
              ? 'Import Error!'
              : 'Are you sure?'
          }
          body={
            <>
              {config.isReplaceable &&
              config?.replacePartially &&
              errors.otherErrors.length === 0 &&
              errors.existingDataErrors.length > 0
                ? config?.customErrors?.replacePartially
                  ? config?.customErrors?.replacePartially
                  : 'The displayed list shows the values that already exist in the system, Importing this CSV will replace all the existing data. Please ensure you want to proceed?'
                : null}
              {errors.existingDataErrors.length + errors.otherErrors.length > 0 ? (
                <>
                  <div className='position-relative'>
                    <ul>
                      {((config.isReplaceable &&
                        config?.replacePartially &&
                        errors.otherErrors.length === 0 &&
                        errors.existingDataErrors.length > 0) ||
                        !config?.isReplaceable) &&
                        errors.existingDataErrors.map((error, index) => (
                          <li key={index} className=' mt-5'>
                            {typeof error === 'string' ? (
                              <span dangerouslySetInnerHTML={{__html: error}} />
                            ) : (
                              error
                            )}
                          </li>
                        ))}
                      {errors.otherErrors.map((error, index) => (
                        <li key={index} className=' mt-5'>
                          {typeof error === 'string' ? (
                            <span dangerouslySetInnerHTML={{__html: error}} />
                          ) : (
                            error
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                'Importing this CSV will replace all the existing data. Please ensure you want to proceed?'
              )}
            </>
          }
          onAction={handleConfirmImport}
          onClose={handleCancelImport}
          disableAction={
            config?.isReplaceable
              ? config.replacePartially
                ? errors.otherErrors.length > 0
                : false
              : !config.isReplaceable
              ? errors.existingDataErrors.length + errors.otherErrors.length > 0
              : errors.otherErrors.length > 0
          }
        />
      )}
    </>
  )
}

export default CSVImporter
