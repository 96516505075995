import store from '../../../../redux/store'
import {ProjectsListPageContext} from '../../context'
import {useContext} from 'react'

const ProjectsTableActionDropdown = ({
  setShowModal,
  setShowArchiveModal,
  setDeleteModal,
  projectDetails,
}: any) => {
  const {isActiveProjects} = useContext(ProjectsListPageContext)
  const user: any = store.getState()?.auth?.user

  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleArchiveProject = () => {
    setShowArchiveModal(true)
    document.body.click()
  }

  const handleDeleteProject = () => {
    setDeleteModal(true)
    document.body.click()
  }
  return (
    <>
      <div className='px-2 py-1'>
        <span className='text-dark fw-bold text-hover-primary fs-6' onClick={handleEditClick}>
          <label>Edit</label>
        </span>
      </div>
      <div className='px-2 py-1'>
        <span
          className={`text-dark fw-bold text-hover-primary fs-6 text-hover-${
            isActiveProjects ? 'danger' : 'primary'
          } fs-6`}
          onClick={handleArchiveProject}
        >
          <label>{isActiveProjects ? 'Archive' : 'Unarchive'}</label>
        </span>
      </div>
      {!isActiveProjects && user?.username === projectDetails?.ownerUsername && (
        <div className='px-2 py-1'>
          <span className='text-dark fw-bold text-hover-danger fs-6' onClick={handleDeleteProject}>
            <label>Delete</label>
          </span>
        </div>
      )}
    </>
  )
}

export default ProjectsTableActionDropdown
