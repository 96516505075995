/* eslint-disable jsx-a11y/anchor-is-valid */
import {logout, reset, setRedirect} from '../../../../feature/authSlice'
import {useAppDispatch, useAppSelector} from '../../../../redux/useTypedSelector'
import useToastify from '../../../../hook/useToastify'
// import {Languages} from '../header-menus/Languages'
import store from '../../../../redux/store'

const UserMenu = () => {
  const {user} = useAppSelector((state) => state.auth)
  const {toastMessage} = useToastify()
  const dispatch = useAppDispatch()
  const redirect_url: any = '/dashboard'

  const logoutHandler = () => {
    store.dispatch(setRedirect(redirect_url))
    dispatch(logout(user?.accessToken))
    dispatch(reset())
    toastMessage('success', 'Logout Successfully')
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <a onClick={logoutHandler} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {UserMenu}
