import Date from '../../../../../components/Date/Date'
import usePermission from '../../../../../hook/usePermission'
import {getBadgeColor} from '../../../../../utils/badge'
import { parseHideProductsRules } from '../../services/hideProducts'
import OverlayModal from '../modal/OverlayModal'

function ProductRuleTableRow({row, key}: any) {
  const product = parseHideProductsRules(row)
  const {hasPermission} = usePermission()

  return (
    <>
      <tr key={key}>
        <td>
          <div className='align-items-center'>{product['product_name']}</div>
        </td>
       
        <td>
          <div className='align-items-center'>{product['product_sku']}</div>
        </td>
        <td>
          <div className='align-items-center'>{product['promotion_threshold_quantity']}</div>
        </td>       
         <td>
          <div className='align-items-center'>{product['disable_promotion_triggered']}</div>
        </td> 
        <td>
          <div className='align-items-center'>{product['promotion_id']}</div>
        </td>       
        <td>
          <div className='align-items-center'>{product['hide_out_of_stock_days']}</div>
        </td>
        <td>
          <div className='align-items-center'>{product['hide_category_id']}</div>
        </td>
        <td>
          <div className='align-items-center'>{product['hide_classification']}</div>
        </td>        
        <td>
          <Date date={row['created_at']} />
        </td>
        <td>
          <span className={`badge ${getBadgeColor(product['is_active'], 'light')} badge-lg`}>
            <div className='align-items-center'>{product['is_active']}</div>
          </span>
        </td>
        {hasPermission('analytics_automation', 'write') && (

        <td>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <OverlayModal is_product={true} sku={product['product_sku']} outOfDays={product['hide_out_of_stock_days']} thresholdQty={product['promotion_threshold_quantity']} promotion_id={product['promotion_id']} promotion_disable_date={product['promotion_disable_date']} hide_out_of_stock_days={product['hide_out_of_stock_days']}  hide_category_id={product['hide_category_id']}  hide_classification={product['hide_classification']} hide_product={product['hide_product']} prefix={product['hide_product_name_prefix']} customer_rep={product['customer_rep']} disable_promotion={product['disable_promotion']} />
          </div>
        </td>
        )}
      </tr>
    </>
  )
}

export default ProductRuleTableRow
