/* eslint-disable react-hooks/exhaustive-deps */
// import {SheetDataContext} from '../../../context'
import {useContext} from 'react'
import Search from '../../../../../components/Search'
import {HideProductPagesContext} from '../../contexts'

const ProductRuleSearch = ({productRules}: any) => {
  const {onSearch} = useContext(HideProductPagesContext)

  return (
    <>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch && onSearch(searchTerm)
        }}
      />
    </>
  )
}

export {ProductRuleSearch}
