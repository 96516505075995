import {Select} from '../../../../components/Select/'
import {MatrixReportPageContext} from '../../context'
import {useContext,  useState} from 'react'
import {matrixAxisOptions} from '../../utils'

const MatrixHeader = () => {
  const {onXAxisChange, onYAxisChange} = useContext(MatrixReportPageContext)

  const [selectedXAxis, setSelectedXAxis] = useState('Module')
  const [selectedYAxis, setSelectedYAxis] = useState('Assignee')


  const handleXAxisChange = (e: any) => {
    const value = e.target.value
    setSelectedXAxis(value)
    onXAxisChange(value)
  }

  const handleYAxisChange = (e: any) => {
    const value = e.target.value
    setSelectedYAxis(value)
    onYAxisChange(value)
  }

  const getAxisOptions = (selectedValue: string) => {
    return matrixAxisOptions.map((option) => ({
      ...option,
      isDisabled: option.value === selectedValue,
    }))
  }

  return (
    <div className='mb-10'>
      <div className='d-flex gap-5'>
        <Select
          id='x-axis'
          className='d-flex align-items-center'
          label='X-Axis:'
          labelClass='me-2'
          disabledKey={'isDisabled'}
          options={getAxisOptions(selectedYAxis)}
          onChange={handleXAxisChange}
          value={selectedXAxis}
        />
        <Select
          id='y-axis'
          className='d-flex align-items-center'
          labelClass='me-2'
          label='Y-Axis:'
          disabledKey={'isDisabled'}
          options={getAxisOptions(selectedXAxis)}
          onChange={handleYAxisChange}
          value={selectedYAxis}
        />
      </div>
    </div>
  )
}

export default MatrixHeader
