export function parseNumber(value: string | number, returnValue: any = 0): any {
  if (value === '' || value === null || value === undefined || value === '-') {
    return returnValue
  }

  // Check if the value is already a number
  if (typeof value === 'number') {
    return Number.isInteger(value) ? value : parseFloat(value.toFixed(2))
  } else if (typeof value === 'string') {
    // If it's a string, parse it
    const parsedValue = parseFloat(value)
    if (Number.isNaN(parsedValue)) {
      return returnValue
    } else {
      return Number.isInteger(parsedValue) ? parsedValue : parseFloat(value).toFixed(2)
    }
  }
  return
}
