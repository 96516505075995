import {useEffect} from 'react'
import {titlePreFix} from '../utils/url'

function useMeta(postFix: string) {
  useEffect(() => {
    document.title = postFix ? `${titlePreFix} - ${postFix}` : `${titlePreFix}`
  }, [postFix])
}

export default useMeta
