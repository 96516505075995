import {dataToRoles} from '../../utils'
import usePermission from '../../../../hook/usePermission'
import OverlayModalRoles from '../modals/OverlayModalRoles'
import Date from '../../../../components/Date/Date'

const RoleTableRow = ({row}: any) => {
  const role = dataToRoles(row)
  const {hasPermission} = usePermission()

  return (
    <tr>
      <td>
        <div className='align-items-center'>{role['role']}</div>
      </td>
      <td>
        <span className='text-dark fs-6'>
          <div className='text-center'>{role['totalUsers']}</div>
        </span>
      </td>
      <td>
        <span className='text-dark fs-6'>
          <Date date={row?.['created_at']} />
        </span>
      </td>
      {hasPermission('settings_users', 'write') ? (
        <td>
          <OverlayModalRoles id={row['id']} users={role['totalUsers']} />
        </td>
      ) : null}
    </tr>
  )
}

export default RoleTableRow
