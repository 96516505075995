import type {Klass, LexicalNode} from 'lexical'
import {HorizontalRuleNode} from '@lexical/react/LexicalHorizontalRuleNode'
import {HeadingNode, QuoteNode} from '@lexical/rich-text'
import {ListItemNode, ListNode} from '@lexical/list'
import {CodeHighlightNode, CodeNode} from '@lexical/code'
import {HashtagNode} from '@lexical/hashtag'
import {OverflowNode} from '@lexical/overflow'
import {MarkNode} from '@lexical/mark'
import {MentionNode} from './MentionNode'
import {KeywordNode} from './KeywordNode'
import {LayoutContainerNode} from './LayoutContainerNode'
import {LayoutItemNode} from './LayoutItemNode'
import {ImageNode} from './ImageNode'
import {InlineImageNode} from './InlineImageNode'
import {AutoLinkNode, LinkNode} from '@lexical/link'
import {TableCellNode, TableNode, TableRowNode} from '@lexical/table'
import {CollapsibleContainerNode} from '../plugins/CollapsiblePlugin/CollapsibleContainerNode'
import {CollapsibleTitleNode} from '../plugins/CollapsiblePlugin/CollapsibleTitleNode'
import {CollapsibleContentNode} from '../plugins/CollapsiblePlugin/CollapsibleContentNode'

const TextEditorNodes: Array<Klass<LexicalNode>> = [
  HeadingNode,
  MentionNode,
  KeywordNode,
  LayoutContainerNode,
  LayoutItemNode,
  ImageNode,
  InlineImageNode,
  LinkNode,
  TableNode,
  CollapsibleContainerNode,
  CollapsibleTitleNode,
  CollapsibleContentNode,
  HorizontalRuleNode,
  TableCellNode,
  TableRowNode,
  AutoLinkNode,
  QuoteNode,
  ListNode,
  ListItemNode,
  CodeNode,
  CodeHighlightNode,
  HashtagNode,
  OverflowNode,
  MarkNode,
]

export default TextEditorNodes
