import type {
  BaseSelection,
  DOMChildConversion,
  DOMConversion,
  DOMConversionFn,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
} from 'lexical'

import {$sliceSelectedTextNodeContent} from '@lexical/selection'
import {isBlockDomNode, isHTMLElement} from '@lexical/utils'
import {
  $cloneWithProperties,
  $createLineBreakNode,
  $createParagraphNode,
  $getRoot,
  $isBlockElementNode,
  $isElementNode,
  $isRootOrShadowRoot,
  $isTextNode,
  ArtificialNode__DO_NOT_USE,
  ElementNode,
  isDocumentFragment,
  isInlineDomNode,
} from 'lexical'
import {$createInlineImageNode, Position} from '../nodes/InlineImageNode'
import {$isInlineImageNode, InlineImageNode} from '../nodes/InlineImageNode'
import {$createImageNode, $isImageNode, ImageNode} from '../nodes/ImageNode/index'
import {$createLayoutContainerNode, $isLayoutContainerNode} from '../nodes/LayoutContainerNode'
import {$createLayoutItemNode, $isLayoutItemNode} from '../nodes/LayoutItemNode'
import {$createFigmaNode} from '../nodes/FigmaNode'
import { $findMatchingParent } from '@lexical/utils'
import {
  $isTableNode,
  TableNode,
} from '@lexical/table'

export function $generateNodesFromDOM(editor: LexicalEditor, dom: Document): Array<LexicalNode> {
  const elements = dom.body ? dom.body.childNodes : []
  let lexicalNodes: Array<LexicalNode> = []
  const allArtificialNodes: Array<ArtificialNode__DO_NOT_USE> = []
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i]
    if (!IGNORE_TAGS.has(element.nodeName)) {
      const lexicalNode = $createNodesFromDOM(element, editor, allArtificialNodes, false)
      if (lexicalNode !== null) {
        lexicalNodes = lexicalNodes.concat(lexicalNode)
      }
    }
  }
  $unwrapArtificalNodes(allArtificialNodes)

  return lexicalNodes
}

export function $generateHtmlFromNodes(
  editor: LexicalEditor,
  selection?: BaseSelection | null
): string {
  if (
    typeof document === 'undefined' ||
    (typeof window === 'undefined' && typeof global.window === 'undefined')
  ) {
    throw new Error(
      'To use $generateHtmlFromNodes in headless mode please initialize a headless browser implementation such as JSDom before calling this function.'
    )
  }

  const container = document.createElement('div')
  const root = $getRoot()
  const topLevelChildren = root.getChildren()

  for (let i = 0; i < topLevelChildren.length; i++) {
    const topLevelNode = topLevelChildren[i]
    $appendNodesToHTML(editor, topLevelNode, container, selection)
  }

  return container.innerHTML
}

function $appendNodesToHTML(
  editor: LexicalEditor,
  currentNode: LexicalNode,
  parentElement: HTMLElement | DocumentFragment,
  selection: BaseSelection | null = null
): boolean {
  let shouldInclude = selection !== null ? currentNode.isSelected(selection) : true
  const shouldExclude = $isElementNode(currentNode) && currentNode.excludeFromCopy('html')
  let target = currentNode

  if (selection !== null) {
    let clone = $cloneWithProperties(currentNode)
    clone =
      $isTextNode(clone) && selection !== null
        ? $sliceSelectedTextNodeContent(selection, clone)
        : clone
    target = clone
  }
  const children = $isElementNode(target) ? target.getChildren() : []
  const registeredNode = editor._nodes.get(target.getType())
  let exportOutput

  // Use HTMLConfig overrides, if available.
  if (registeredNode && registeredNode.exportDOM !== undefined) {
    exportOutput = registeredNode.exportDOM(editor, target)
  } else {
    exportOutput = target.exportDOM(editor)
  }

  const {element, after} = exportOutput

  if (!element) {
    return false
  }

  // Preserve image attributes and styles, replacing position with float if position is not falsie
  if (element instanceof HTMLImageElement && $isInlineImageNode(target)) {
    const imageNode = target as InlineImageNode
    const position = imageNode.__position
    const styleHeight = parseInt(
      imageNode.__style?.match(/height\s*:\s*([\d.]+)\s*px/i)?.[1] || '0',
      10
    )
    const styleWidth = parseInt(
      imageNode.__style?.match(/width\s*:\s*([\d.]+)\s*px/i)?.[1] || '0',
      10
    )
    const height = (styleHeight ? styleHeight : parseInt(imageNode.__height.toString(), 10)) || null
    const width = (styleWidth ? styleWidth : parseInt(imageNode.__width.toString(), 10)) || null

    if (position || height || width) {
      // Parse existing style properties
      const styleProps = imageNode?.__style
        ?.split(';')
        .filter(Boolean)
        .reduce((acc: Record<string, string>, prop) => {
          const [key, value] = prop.split(':').map((s) => s.trim())
          acc[key] = value
          return acc
        }, {})

      // Build the new style string
      const newStyles: Record<string, string> = {}

      if (position) {
        newStyles.float = position
      }
      if (height) {
        newStyles.height = `${height}px`
        element.height = height
      }
      if (width) {
        newStyles.width = `${width}px`
        element.width = width
      }

      // Combine existing styles with new styles
      const combinedStyles = {...styleProps, ...newStyles}

      // Convert to CSS string
      const styleString = Object.entries(combinedStyles)
        .map(([key, value]) => `${key}: ${value}`)
        .join('; ')

      if (styleString) {
        element.style.cssText = styleString
      }
    }
  }

  if (element instanceof HTMLImageElement && $isImageNode(target)) {
    const imageNode = target as ImageNode
    const styleHeight = parseInt(
      imageNode.__style?.match(/height\s*:\s*([\d.]+)\s*px/i)?.[1] || '0',
      10
    )
    const styleWidth = parseInt(
      imageNode.__style?.match(/width\s*:\s*([\d.]+)\s*px/i)?.[1] || '0',
      10
    )
    const height = (styleHeight ? styleHeight : parseInt(imageNode.__height.toString(), 10)) || null
    const width = (styleWidth ? styleWidth : parseInt(imageNode.__width.toString(), 10)) || null

    if (height || width) {
      // Parse existing style properties
      const styleProps = imageNode?.__style
        ?.split(';')
        .filter(Boolean)
        .reduce((acc: Record<string, string>, prop) => {
          const [key, value] = prop.split(':').map((s) => s.trim())
          acc[key] = value
          return acc
        }, {})

      // Build the new style string
      const newStyles: Record<string, string> = {}

      if (height) {
        newStyles.height = `${height}px`
        element.height = height
      }
      if (width) {
        newStyles.width = `${width}px`
        element.width = width
      }

      // Combine existing styles with new styles
      const combinedStyles = {...styleProps, ...newStyles}

      // Convert to CSS string
      const styleString = Object.entries(combinedStyles)
        .map(([key, value]) => `${key}: ${value}`)
        .join('; ')

      if (styleString) {
        element.style.cssText = styleString
      }
    }
  }

  if ($isLayoutContainerNode(currentNode)) {
    const layoutElement = document.createElement('div')
    layoutElement.setAttribute('data-lexical-layout-container', 'true')
    layoutElement.style.gridTemplateColumns = currentNode.getTemplateColumns()
    layoutElement.style.display = 'grid'
    layoutElement.style.gap = '10px'

    // Process children (layout items)
    const children = currentNode.getChildren()
    for (let i = 0; i < children.length; i++) {
      const childNode = children[i]
      if ($isLayoutItemNode(childNode)) {
        const layoutItemElement = document.createElement('div')
        layoutItemElement.className = 'textEditor_layoutItem'
        $appendNodesToHTML(editor, childNode, layoutItemElement, selection)
        layoutElement.appendChild(layoutItemElement)
      }
    }

    parentElement.appendChild(layoutElement)
    return true
  }

  if ($isLayoutItemNode(currentNode)) {
    const children = currentNode.getChildren()
    for (let i = 0; i < children.length; i++) {
      const childNode = children[i]
      $appendNodesToHTML(editor, childNode, parentElement, selection)
    }
    return true
  }

  const fragment = document.createDocumentFragment()

  for (let i = 0; i < children.length; i++) {
    const childNode = children[i]
    const shouldIncludeChild = $appendNodesToHTML(editor, childNode, fragment, selection)

    if (
      !shouldInclude &&
      $isElementNode(currentNode) &&
      shouldIncludeChild &&
      currentNode.extractWithChild(childNode, selection, 'html')
    ) {
      shouldInclude = true
    }
  }

  if (shouldInclude && !shouldExclude) {
    if (isHTMLElement(element) || isDocumentFragment(element)) {
      element.append(fragment)
    }
    parentElement.append(element)

    if (after) {
      const newElement = after.call(target, element)
      if (newElement) {
        if (isDocumentFragment(element)) {
          element.replaceChildren(newElement)
        } else {
          element.replaceWith(newElement)
        }
      }
    }
  } else {
    parentElement.append(fragment)
  }

  return shouldInclude
}

function getConversionFunction(domNode: Node, editor: LexicalEditor): DOMConversionFn | null {
  const {nodeName} = domNode

  const cachedConversions = editor._htmlConversions.get(nodeName.toLowerCase())

  let currentConversion: DOMConversion | null = null

  if (cachedConversions !== undefined) {
    for (const cachedConversion of cachedConversions) {
      const domConversion = cachedConversion(domNode)
      if (
        domConversion !== null &&
        (currentConversion === null ||
          (currentConversion.priority || 0) < (domConversion.priority || 0))
      ) {
        currentConversion = domConversion
      }
    }
  }

  return currentConversion !== null ? currentConversion.conversion : null
}

const IGNORE_TAGS = new Set(['STYLE', 'SCRIPT'])

function isInlineImage(imgElement: HTMLImageElement): boolean {
  const display = imgElement.style.getPropertyValue('display')
  const float = imgElement.style.getPropertyValue('float')
  const isInline = imgElement.getAttribute('data-inline') === 'true'

  return display === 'inline' || display === 'inline-block' || !!float || isInline
}

function $createNodesFromDOM(
  node: Node,
  editor: LexicalEditor,
  allArtificialNodes: Array<ArtificialNode__DO_NOT_USE>,
  hasBlockAncestorLexicalNode: boolean,
  forChildMap: Map<string, DOMChildConversion> = new Map(),
  parentLexicalNode?: LexicalNode | null | undefined
): Array<LexicalNode> {
  let lexicalNodes: Array<LexicalNode> = []

  if (IGNORE_TAGS.has(node.nodeName)) {
    return lexicalNodes
  }

  if (node.nodeName === 'IMG') {
    const imgElement = node as HTMLImageElement
    const src = imgElement.getAttribute('src')
    const altText = imgElement.getAttribute('alt') || ''
    const width = imgElement.width
    const height = imgElement.height
    const style = imgElement.style.cssText

    if (src) {
      if (isInlineImage(imgElement)) {
        const position = (imgElement.style.getPropertyValue('float') as Position) || 'left'
        const imageNode = $createInlineImageNode({
          src,
          altText,
          width,
          height,
          position,
          style,
        })
        return [imageNode]
      } else {
        const imageNode = $createImageNode({
          src,
          altText,
          width,
          height,
          style,
        })
        return [imageNode]
      }
    }
    return lexicalNodes
  }

  if (node.nodeName === 'COLGROUP') {
    const colNodes = Array.from(node.childNodes).filter(
      child => child.nodeName === 'COL'
    ) as HTMLTableColElement[]

    // Simply get width from style or width attribute, defaulting to 92
    const colWidths = colNodes.map(col => {
      const width = col.style.width || col.getAttribute('width') || '92px'
      return parseInt(width, 10) || 92
    })

    editor.update(() => {
      const tableNode = $findMatchingParent(
        parentLexicalNode as LexicalNode,
        $isTableNode
      ) as TableNode

      if (tableNode) {
        tableNode.setColWidths(colWidths)
      }
    })
  }

  if (node instanceof HTMLElement && node.getAttribute('data-lexical-layout-container')) {
    const templateColumns = node.style.getPropertyValue('grid-template-columns')
    if (templateColumns) {
      const layoutContainer = $createLayoutContainerNode(templateColumns)

      // Process children (layout items)
      const children = node.childNodes
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        if (child instanceof HTMLElement && child.classList.contains('textEditor_layoutItem')) {
          // Create layout item node
          const layoutItem = $createLayoutItemNode()

          // Process layout item children
          const itemChildren = child.childNodes
          for (let j = 0; j < itemChildren.length; j++) {
            const itemChildNodes = $createNodesFromDOM(
              itemChildren[j],
              editor,
              allArtificialNodes,
              false,
              new Map(forChildMap),
              layoutItem
            )
            layoutItem.append(...itemChildNodes)
          }

          layoutContainer.append(layoutItem)
        }
      }

      return [layoutContainer]
    }
  }

  if (node instanceof HTMLElement && node.hasAttribute('data-lexical-figma-embed')) {
    const iframeHTML = node.innerHTML
    if (iframeHTML) {
      const figmaNode = $createFigmaNode(iframeHTML)
      return [figmaNode]
    }
    return lexicalNodes
  }

  let currentLexicalNode: any = null
  const transformFunction = getConversionFunction(node, editor)
  const transformOutput = transformFunction ? transformFunction(node as HTMLElement) : null
  let postTransform = null

  if (transformOutput !== null) {
    postTransform = transformOutput.after
    const transformNodes = transformOutput.node
    currentLexicalNode = Array.isArray(transformNodes)
      ? transformNodes[transformNodes.length - 1]
      : transformNodes

    if (currentLexicalNode !== null) {
      forChildMap.forEach((forChildFunction) => {
        currentLexicalNode = forChildFunction(currentLexicalNode, parentLexicalNode)

        if (!currentLexicalNode) {
          return
        }
      })

      if (currentLexicalNode) {
        lexicalNodes.push(
          ...(Array.isArray(transformNodes) ? transformNodes : [currentLexicalNode])
        )
      }
    }

    if (transformOutput.forChild != null) {
      forChildMap.set(node.nodeName, transformOutput.forChild)
    }
  }

  const children = node.childNodes
  let childLexicalNodes = []

  const hasBlockAncestorLexicalNodeForChildren =
    currentLexicalNode != null && $isRootOrShadowRoot(currentLexicalNode)
      ? false
      : (currentLexicalNode != null && $isBlockElementNode(currentLexicalNode)) ||
        hasBlockAncestorLexicalNode

  for (let i = 0; i < children.length; i++) {
    childLexicalNodes.push(
      ...$createNodesFromDOM(
        children[i],
        editor,
        allArtificialNodes,
        hasBlockAncestorLexicalNodeForChildren,
        new Map(forChildMap),
        currentLexicalNode
      )
    )
  }

  if (postTransform != null) {
    childLexicalNodes = postTransform(childLexicalNodes)
  }

  if (isBlockDomNode(node)) {
    if (!hasBlockAncestorLexicalNodeForChildren) {
      childLexicalNodes = wrapContinuousInlines(node, childLexicalNodes, $createParagraphNode)
    } else {
      childLexicalNodes = wrapContinuousInlines(node, childLexicalNodes, () => {
        const artificialNode = new ArtificialNode__DO_NOT_USE()
        allArtificialNodes.push(artificialNode)
        return artificialNode
      })
    }
  }

  if (currentLexicalNode == null) {
    if (childLexicalNodes.length > 0) {
      // If it hasn't been converted to a LexicalNode, we hoist its children
      // up to the same level as it.
      lexicalNodes = lexicalNodes.concat(childLexicalNodes)
    } else {
      if (isBlockDomNode(node) && isDomNodeBetweenTwoInlineNodes(node)) {
        // Empty block dom node that hasnt been converted, we replace it with a linebreak if its between inline nodes
        lexicalNodes = lexicalNodes.concat($createLineBreakNode())
      }
    }
  } else {
    if ($isElementNode(currentLexicalNode)) {
      // If the current node is a ElementNode after conversion,
      // we can append all the children to it.
      currentLexicalNode.append(...childLexicalNodes)
    }
  }

  return lexicalNodes
}

function wrapContinuousInlines(
  domNode: Node,
  nodes: Array<LexicalNode>,
  createWrapperFn: () => ElementNode
): Array<LexicalNode> {
  const textAlign = (domNode as HTMLElement).style.textAlign as ElementFormatType
  const out: Array<LexicalNode> = []
  let continuousInlines: Array<LexicalNode> = []
  // wrap contiguous inline child nodes in para
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if ($isBlockElementNode(node)) {
      if (textAlign && !node.getFormat()) {
        node.setFormat(textAlign)
      }
      out.push(node)
    } else {
      continuousInlines.push(node)
      if (i === nodes.length - 1 || (i < nodes.length - 1 && $isBlockElementNode(nodes[i + 1]))) {
        const wrapper = createWrapperFn()
        wrapper.setFormat(textAlign)
        wrapper.append(...continuousInlines)
        out.push(wrapper)
        continuousInlines = []
      }
    }
  }
  return out
}

function $unwrapArtificalNodes(allArtificialNodes: Array<ArtificialNode__DO_NOT_USE>) {
  for (const node of allArtificialNodes) {
    if (node.getNextSibling() instanceof ArtificialNode__DO_NOT_USE) {
      node.insertAfter($createLineBreakNode())
    }
  }
  // Replace artificial node with it's children
  for (const node of allArtificialNodes) {
    const children = node.getChildren()
    for (const child of children) {
      node.insertBefore(child)
    }
    node.remove()
  }
}

function isDomNodeBetweenTwoInlineNodes(node: Node): boolean {
  if (node.nextSibling == null || node.previousSibling == null) {
    return false
  }
  return isInlineDomNode(node.nextSibling) && isInlineDomNode(node.previousSibling)
}

export function minifyHtml(html: string): string {
  return html
    .replace(/\s+/g, ' ') // Replace multiple spaces with single space
    .replace(/>\s+</g, '><') // Remove spaces between tags
    .replace(/<!--.*?-->/g, '') // Remove comments
    .replace(/^\s+|\s+$/g, '') // Trim start and end
    .trim()
}
