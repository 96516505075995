import usePermission from '../../../hook/usePermission'
import {Pagination} from '../../../utils/pagination'
import useGetCustomerReportsData from '../hooks/useGetCustomerReportsData'
import CustomerHeader from '../sections/headers/CustomerHeader'
import CustomerTable from '../sections/tabels/ByCustomer/CustomerTable'
import {CustomerReportPageContext} from '../contexts'
import useGetCustomerTagsDropdown from '../../customers/hooks/useGetCustomerTagsDropdown'
import { useGetRepresantetives } from '../../inquires/hooks/useGetRepresantetives'

const Customers = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {
    isLoading,
    customerReportsData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onPageChange,
    pagination,
    onTagFilterChange,
    onRepFilterChange,
    onDateChange,
    refetch,
  } = useGetCustomerReportsData()
  const {Representatives} = useGetRepresantetives()
  const {customerTags} = useGetCustomerTagsDropdown()

  const contextValue = {
    hasProjectSettingsPermission,
    isLoading,
    customerReportsData,
    onSortingChange,
    onTagFilterChange,
    onRepFilterChange,
    onSearch,
    searchValue,
    filters,
    onDateChange,
    refetch,
    Representatives,
    customerTags
  }

  return (
    <CustomerReportPageContext.Provider value={contextValue}>
      <CustomerHeader />
      <CustomerTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </CustomerReportPageContext.Provider>
  )
}

export default Customers
