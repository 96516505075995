import {Link} from 'react-router-dom'
import {parseVendorsTableRowData} from '../../utils'

const TopConsignmentVendorsTableRow = ({row: rowData, key}: any) => {
  const row = parseVendorsTableRowData(rowData)

  return (
    <tr key={key}>
      <td>
        <Link
          to={`/customers/all-customer/details?id=${row?.vendorId}&active=consignment`}
          className='text-dark text-hover-primary align-items-center'
        >
          {row.vendorName}
        </Link>
      </td>
      <td>
        <div className='align-items-center'>{row.email}</div>
      </td>
      <td>
        <div className='align-items-center'>{row.representative}</div>
      </td>
      <td>
        <div className='align-items-center'>${row.orderAmount}</div>
      </td>
      <td>
        <div className='align-items-center'>${row.pendingAmount}</div>
      </td>
    </tr>
  )
}

export default TopConsignmentVendorsTableRow
