import {useContext} from 'react'
import {ProjectsTeamsPageContext} from '../../context'
import ProjectTeamsTableRow from './ProjectTeamsTableRow'
import {DynamicTable} from '../../../../components/DynamicTable'
import {ProjectTeamsTableColumns} from '../../utils'

export default function ProjectTeamsTable() {
  const {
    ProjectsTeamsList,
    isLoadingProjectsTeamsList,
    filters,
    onSortingChange,
    searchValue,
  } = useContext(ProjectsTeamsPageContext)

  return (
    <DynamicTable
      id={'project-teams'}
      data={ProjectsTeamsList}
      sortableColumns={ProjectTeamsTableColumns}
      TableRow={ProjectTeamsTableRow}
      permissionPath={'projects_settings'}
      loading={isLoadingProjectsTeamsList}
      filters={filters}
      onSortingChange={onSortingChange}
      searchValue={searchValue}
      noDataMessage={
        <p>
          No project teams have been added yet. <br /> Please click on 'Create Teams' button to
          Create Teams.
        </p>
      }
    />
  )
}
