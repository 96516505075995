import {GanttChart} from '../../../../components/GanttChart'
import {useContext} from 'react'
import {GanttChartPageContext, ProjectDetailsContext} from '../../context'
import {ganttChartConfig} from '../../utils'

const Chart = () => {
  const {projectId} = useContext(ProjectDetailsContext)
  const {ganttChartData, isLoading} = useContext(GanttChartPageContext)

  const {dataMappingConfig, tableColumns, features, zoomConfig} = ganttChartConfig(projectId)

  return (
    <GanttChart
      data={ganttChartData.data}
      dataMappingConfig={dataMappingConfig}
      tableColumns={tableColumns}
      isLoading={isLoading}
      taskDrag={features.taskDrag}
      taskResize={features.taskResize}
      columnResize={features.columnResize}
      sort={features.sort}
      filter={features.filter}
      taskTemplate={features.taskTemplate}
      fullScreen={features.fullScreen}
      highlightWeekend={features.highlightWeekend}
      zoom={zoomConfig}
    />
  )
}
export default Chart
