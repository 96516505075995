import {useContext, useState} from 'react'
import {AgileBoardPageContext} from '../../context'
import CreateTicketModal from '../modals/CreateTicketModal'
import Search from '../../../../components/Search'
import {SideFilter} from '../../../../components/SideFilter'
import useSaveFilter from '../../../projectReports/hooks/useSaveFilter'

const AgileBoardPageHeader = () => {
  const [showModal, setShowModal] = useState(false)
  const {
    hasBoardPermission,
    onSearch,
    filtersData,
    refetchAgileBoardData,
    isLoadingAgileBoardData,
  } = useContext(AgileBoardPageContext)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {isOperationLoading, onSaveFilter} = useSaveFilter(refetchAgileBoardData)

  return (
    <div className='d-flex justify-content-between align-items-center mb-8'>
      <Search onSearch={onSearch} />
      <div>
        <SideFilter
          filterData={filtersData}
          onApply={onSaveFilter}
          isOperationLoading={isOperationLoading || isLoadingAgileBoardData}
          onCancel={() => setIsDrawerOpen(false)}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          filterType={'project_agile_board'}
        />
        {hasBoardPermission && (
          <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
            <i className='fa-solid fa-plus'></i>Create Ticket
          </button>
        )}
      </div>
      {showModal && <CreateTicketModal show={showModal} onClose={() => setShowModal(false)} />}
    </div>
  )
}

export {AgileBoardPageHeader}
