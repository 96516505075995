import {getUserLoginToken} from '../services'

function useUserLogin() {
  const userLogin = async (customerId: string) => {
    await getUserLoginToken(`/settings/users/login`, customerId).then((res: any) => {
      if (res?.data?.token !== '') {
        const url = `/user-login?token=${res?.data?.token}&tenant_id=${res?.data?.tenant_id}`
        window.open(url, '_blank', '')
      }
    })
  }
  return {
    userLogin,
  }
}

export default useUserLogin
