import {useContext} from 'react'
import {Pagination} from '../../../utils/pagination'
import TasksReportTable from '../sections/tables/TasksReportTable'
import {MembersDetailsReportPageContext} from '../contexts'
import ProjectMembersDetailsHeader from '../sections/headers/ProjectMembersDetailsHeader'

const Tasks = () => {
  const {onPageChange, pagination} = useContext(MembersDetailsReportPageContext)
  return (
    <>
      <ProjectMembersDetailsHeader />
      <TasksReportTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </>
  )
}

export default Tasks
