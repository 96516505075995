import ProjectsCustomFieldsHeader from '../sections/headers/ProjectsCustomFieldsHeader'
import {ProjectsCustomFieldsPageContext} from '../context'
import useGetGlobalCustomFieldsList from '../hooks/customFields/useGetGlobalCustomFieldsList'
import useGlobalCustomFieldsOperations from '../hooks/customFields/useGlobalCustomFieldsOperations'
import GlobalProjectsCustomFieldsTable from '../sections/tables/GlobalProjectsCustomFieldsTable'
import usePermission from '../../../hook/usePermission'

export default function ProjectsCustomFields() {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')
  const {
    globalCustomFieldsList,
    isLoading: isLoadingGlobalCustomFieldList,
    onSearch,
    searchValue,
  } = useGetGlobalCustomFieldsList()
  const {
    createGlobalCustomField,
    updateGlobalCustomField,
    updateGlobalCustomFieldTableSorting,
    deleteGlobalCustomField,
    isLoadingGlobalCustomFieldOperation,
  } = useGlobalCustomFieldsOperations()

  const contextValue = {
    hasProjectSettingsPermission,
    globalCustomFieldsList: globalCustomFieldsList,
    isLoadingGlobalCustomFieldList: isLoadingGlobalCustomFieldList,
    onSearch,
    searchValue,

    createGlobalCustomField: createGlobalCustomField,
    updateGlobalCustomField: updateGlobalCustomField,
    updateGlobalCustomFieldTableSorting: updateGlobalCustomFieldTableSorting,
    deleteGlobalCustomField: deleteGlobalCustomField,
    isLoadingGlobalCustomFieldOperation: isLoadingGlobalCustomFieldOperation,
  }

  return (
    <>
      <ProjectsCustomFieldsPageContext.Provider value={contextValue}>
        <ProjectsCustomFieldsHeader />
        <GlobalProjectsCustomFieldsTable />
      </ProjectsCustomFieldsPageContext.Provider>
    </>
  )
}
