import useApi from '../../../services/useApi'
import {useContext, useState} from 'react'
import {TicketDetailsContext} from '../context'

const useSpentTimeHistoryOperations = () => {
  const Api = useApi()
  const {ticketId} = useContext(TicketDetailsContext)
  const [isLoading, setLoading] = useState(false)
  const refetchAPIs = [
    `${ticketId}-details`,
    `${ticketId}-activities-history`,
    `${ticketId}-spentTime-time-log`,
  ]

  async function updateSpentTime(data: any, dirtyFields: any, setError: Function, timeLogId: any) {
    return await Api.patch(`/agile/projects/${ticketId}/time-log/${timeLogId}`, data, {
      dirtyFields: dirtyFields,
      setLoading: setLoading,
      refetch: refetchAPIs,
      successMessage: 'Timelog update successfully',
      setUseFormError: setError,
    })
  }

  async function deleteSpentTime(timeLogId: any) {
    return await Api.delete(
      `/agile/projects/${ticketId}/time-log/${timeLogId}`,
      {},
      {
        setLoading: setLoading,
        successMessage: 'Timelog deleted successfully',
        refetch: refetchAPIs,
      }
    )
  }

  return {
    updateSpentTime,
    deleteSpentTime,
    isLoading,
  }
}

export default useSpentTimeHistoryOperations
