//import {useGoogleLogin} from '@react-oauth/google'
import {useFormik} from 'formik'
import {useState} from 'react'
import '../../../_metronic/assets/sass/layout/login.scss'
// import LoginImage from '../../../_metronic/assets/images/login.jpg'
import * as Yup from 'yup'
import {useAppDispatch} from '../../../redux/useTypedSelector'
import {login, getUserGoogleLogin} from '../../../feature/authSlice'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useGoogleLogin} from '@react-oauth/google'

const initialValues = {
  email: '',
  password: '',
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export default function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await dispatch(login(values))
      setLoading(false)
    },
  })

  const googleLogin = useGoogleLogin({
    onSuccess: async (res: any) => {
      if (res) {
        const data = {
          username: res?.hd,
          accessToken: res?.access_token,
        }
        await dispatch(getUserGoogleLogin(data))
        setLoading(false)
      }
    },
    onError: async (res: any) => {
      console.error(res)
    },
  })

  return (
    <div className='login-root ad-login-page'>
      <div className='form-wrapper'>
        <div className='form-main'>
          <div className='login-form-outer'>
            <div className='login-form-inner'>
              <div className='text-center'>
                <h1 className='fs-1 mb-10 fw-bolder'>Midwest App</h1>
                <h4 className='sign fs-4'>Sign In</h4>
              </div>
              {/* Social Login start */}
              <div className='social-login'>
                <div className='social-wrapper'>
                  {/* begin::Google link */}

                  <button
                    onClick={() => googleLogin()}
                    className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'
                  >
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                      className='h-20px me-3'
                    />
                    Google Login
                  </button>

                  {/* end::Google link */}

                  {/* begin::Google link */}
                  {/* <button className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                  className='h-20px me-3'
                />
                Facebook Login
              </button> */}
                </div>
                {/* end::Google link */}
              </div>
              {/* Social login end */}
              {/* <!--begin::Separator--> */}
              <div className='separator separator-content my-10'>
                <span className='w-300px text-gray-500 fw-semibold fs-7'>Or with email</span>
              </div>
              {/* <!--end::Separator--> */}
              <div className='input-root'>
                <form onSubmit={formik.handleSubmit}>
                  <div className='input-wrapper'>
                    <input
                      name='email'
                      type='email'
                      placeholder='EMAIL'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className='form-input'
                    />
                  </div>
                  <div className='input-wrapper'>
                    <input
                      name='password'
                      type='password'
                      placeholder='PASSWORD'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className='form-input'
                    />
                  </div>
                  <div>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-lg btn-primary w-100 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Continue</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='image-main'>
          <img src={LoginImage} alt='login' className='login-image' />
        </div> */}
      </div>
    </div>
  )
}
