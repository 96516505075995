import {capitalizeFirstLetter} from '../../utils/string'

export function flattenErrorRefs(
  errorRefs: Record<string, Record<string, Record<number, HTMLElement>>>
): HTMLElement[] {
  const flattenedRefs: HTMLElement[] = []

  // Traverse the nested structure
  for (const indexKey in errorRefs) {
    if (errorRefs.hasOwnProperty(indexKey)) {
      const colObject = errorRefs[indexKey]

      for (const colKey in colObject) {
        if (colObject.hasOwnProperty(colKey)) {
          const valueIndexObject = colObject[colKey]

          for (const valueIndex in valueIndexObject) {
            if (valueIndexObject.hasOwnProperty(valueIndex)) {
              const element = valueIndexObject[valueIndex]

              // Check if the element is not null or falsy and has the 'is-invalid' class
              if (element && element.classList.contains('is-invalid')) {
                flattenedRefs.push(element)
              }
            }
          }
        }
      }
    }
  }

  return flattenedRefs
}

type ErrorObject = {
  [rowIndex: number]: {
    [columnKey: string]: {
      [valueIndex: number]: {message: string}
    }
  }
}

export function validateCustomFields(
  customFields: any[],
  config: any,
  onError: Function = () => {},
  setFieldErrors: Function,
  onChange: Function = () => {},
  setShowMaxLimitMessage: Function
): ErrorObject {
  const errors: ErrorObject = {}
  const fieldValueMap = new Map<string, Map<string, number[]>>() // Track duplicate values per field
  const rowMap = new Map<string, number>() // Track serialized rows for duplicate row error

  // Extract settings from the config
  const {tableConfig, isDuplicateRow = true} = config
  const fieldsConfig = tableConfig.data

  // Serialize a row based on case sensitivity
  function serializeRow(row: Record<string, any>, fieldsConfig: any[]): string {
    return JSON.stringify(
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          const fieldConfig = fieldsConfig.find((field: any) => field.key === key)
          if (fieldConfig) {
            const {isCaseSensitive} = fieldConfig
            if (Array.isArray(value)) {
              return [key, value.map((val: string) => (isCaseSensitive ? val : val.toLowerCase()))]
            }
            return [
              key,
              typeof value === 'string' ? (isCaseSensitive ? value : value.toLowerCase()) : value,
            ]
          }
          return [key, value]
        })
      )
    )
  }

  customFields.forEach((row, rowIndex) => {
    const filteredRow = {...row}
    const keys = Object.keys(filteredRow)

    // Remove empty strings from array values
    Object.keys(filteredRow).forEach((key) => {
      if (Array.isArray(filteredRow[key])) {
        filteredRow[key] = filteredRow[key].filter((val: any) => val !== '')
      }
    })

    const hasValidValues = keys.every((key) => filteredRow[key] !== null && row[key] !== '')

    // Duplicate row check
    if (!isDuplicateRow && hasValidValues) {
      const serializedRow = serializeRow(filteredRow, fieldsConfig)

      if (rowMap.has(serializedRow)) {
        const duplicateRowIndex = rowMap.get(serializedRow)!
        keys.forEach((key, index) => {
          if (!errors[duplicateRowIndex]) errors[duplicateRowIndex] = {}
          if (!errors[rowIndex]) errors[rowIndex] = {}

          // Mark first key of duplicate row
          if (index === 0) {
            addError(duplicateRowIndex, key, 0, 'Duplicate row found.')
            addError(rowIndex, key, 0, 'Duplicate row found.')
          } else {
            addError(duplicateRowIndex, key, 0, ' ')
            addError(rowIndex, key, 0, ' ')
          }
        })
      } else if (Object.keys(filteredRow).length > 0) {
        rowMap.set(serializedRow, rowIndex)
      }
    }

    // Iterate over each key for validation
    keys.forEach((key) => {
      const value = row[key]
      const fieldConfig = fieldsConfig.find((field: any) => field.key === key)

      if (!fieldConfig) return

      const {
        isRequired = false,
        isDuplicate = true,
        isCaseSensitive = false,
        isMultiple = false,
        duplicateAcrossRows = true,
      } = fieldConfig

      // Required field check
      if (isRequired && (!value || (Array.isArray(value) && value.length === 0))) {
        addError(rowIndex, key, 0, `${capitalizeFirstLetter(key)} is required.`)
        return
      }

      if (!fieldValueMap.has(key)) {
        fieldValueMap.set(key, new Map<string, number[]>())
      }

      // Validate array values for duplicates when `isMultiple: true`
      if (isMultiple && Array.isArray(value)) {
        const rowValueMap = new Map<string, number>()

        value.forEach((val, valueIndex) => {
          const lowercaseVal = isCaseSensitive ? val : val.toLowerCase()

          if (val === '' || val === null || val === undefined) {
            if (isRequired) {
              addError(rowIndex, key, valueIndex, `${capitalizeFirstLetter(key)} is required.`)
            }
            return
          }

          // Duplicate within the same row
          if (!isDuplicate && rowValueMap.has(lowercaseVal)) {
            const duplicateIndex = rowValueMap.get(lowercaseVal)
            addError(rowIndex, key, duplicateIndex!, 'Duplicate field found.')
            addError(rowIndex, key, valueIndex, 'Duplicate field found.')
          } else {
            rowValueMap.set(lowercaseVal, valueIndex)
          }

          // Duplicate across rows when `isMultiple` and `duplicateAcrossRows: false`
          if (isMultiple && !duplicateAcrossRows) {
            const fieldMap = fieldValueMap.get(key)!
            if (fieldMap.has(lowercaseVal)) {
              const existingRows = fieldMap.get(lowercaseVal)!
              existingRows.forEach((existingRowIndex) => {
                addError(existingRowIndex, key, valueIndex, 'Duplicate field found.')
              })
              addError(rowIndex, key, valueIndex, 'Duplicate field found.')
              fieldMap.get(lowercaseVal)?.push(rowIndex)
            } else {
              fieldMap.set(lowercaseVal, [rowIndex])
            }
          }
        })
      } else if (typeof value === 'string') {
        const lowercaseVal = isCaseSensitive ? value : value.toLowerCase()

        if (value === '' || value === null || value === undefined) {
          if (isRequired) {
            addError(rowIndex, key, 0, `${capitalizeFirstLetter(key)} is required.`)
          }
          return
        }

        // Duplicate check for non-multiple values, always allow duplicates across rows
        if (!isDuplicate && !isMultiple) {
          const fieldMap = fieldValueMap.get(key)!
          if (fieldMap.has(lowercaseVal)) {
            const existingRows = fieldMap.get(lowercaseVal)!
            existingRows.forEach((existingRowIndex) => {
              addError(existingRowIndex, key, 0, 'Duplicate field found.')
            })
            addError(rowIndex, key, 0, 'Duplicate field found.')
            fieldMap.get(lowercaseVal)?.push(rowIndex)
          } else {
            fieldMap.set(lowercaseVal, [rowIndex])
          }
        }
      }
    })
  })

  function addError(row: number, column: string, valueIndex: number, message: string) {
    if (!errors[row]) {
      errors[row] = {}
    }
    if (!errors[row][column]) {
      errors[row][column] = {}
    }
    errors[row][column][valueIndex] = {message}
  }

  onError?.(errors)
  setFieldErrors(errors)
  onChange?.(customFields)
  if (customFields.length < (config?.maxFields ?? Infinity)) {
    setShowMaxLimitMessage(false)
  }
  return errors
}
