import useApi from '../../../../services/useApi'

export const useGetGlobalProjectsMembersDropdownList = () => {
  const Api = useApi()
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/permitted/members`, {
    queryId: 'global-project-members-dropdown-list',
  })

  return {
    globalProjectsMembersDropdownList: response?.data || [],
    isLoading: isFetching,
  }
}
