import {Link, useLocation} from 'react-router-dom'

function HidePageNavigation() {
  const location = useLocation()

  return (
    <div className=''>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10'>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/analytics/automation/rules/hidevariants' && 'active')
            }
            to='/analytics/automation/rules/hidevariants'
          >
            Variant Rule
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/analytics/automation/rules/hideproducts' && 'active')
            }
            to='/analytics/automation/rules/hideproducts'
          >
            Product Rule
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default HidePageNavigation
