import BarChart from '../../../components/charts/BarChart'
import {useParams} from 'react-router-dom'
import {dataToBarChart, extractChartData} from '../utils'
import {useGetNewCustomerReport} from '../hooks/useGetNewCustomerReport'

const NewCustomerReport = () => {
  const params = useParams<any>()
  const productId = params?.id as string
  const {NewCustomerReportData, TotalPercentage} = useGetNewCustomerReport(productId)
  const {labels, chartData} = extractChartData(NewCustomerReportData, TotalPercentage)

  const {CustomerReportBarChartOption, ChartData} = dataToBarChart(
    chartData,
    labels,
    TotalPercentage
  )

  return (
    <div className='card border'>
      <div className='card-body p-7'>
        <div className='pb-5 mb-5'>
          <div className='row d-flex align-items-center'>
            <div className='col-xl-9 col-md-8 d-flex flex-wrap align-items-center'>
              <h2 className='text-dark fw-bolder me-5 mb-5'>New Customer Report</h2>
            </div>
          </div>
        </div>

        <BarChart data={ChartData} options={CustomerReportBarChartOption} />
      </div>
    </div>
  )
}

export default NewCustomerReport
