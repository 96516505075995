import useFilters from '../../../hook/useFilters'
import useApi from '../../../services/useApi'

const initialFilters = {
  page: 1,
  limit: 50,
  sort_by: 'lifetime_order_count/1',
  search: '',
  customer_rep: '',
}

const useGetSuggestedCustomerData = () => {
  const Api = useApi()

  const {
    filters,
    onSearch,
    onSortingChange,
    searchValue,
    onPageChange,
    setMultipleFilters,
  } = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(`/bulkOrders/reports/suggested/customers`, {
    queryId: 'suggested-customer-reports',
    filters: filters,
  })

  const onRepFilterChange = (rep: string) => {
    setMultipleFilters({customer_rep: rep, page: 1})
  }

  return {
    suggestedCustomersData: response?.data || [],
    pagination: response?.meta?.pagination || {},
    filters,
    isLoading: isFetching,
    onSortingChange,
    onSearch,
    searchValue,
    onPageChange,
    onRepFilterChange,
    refetch,
  }
}

export default useGetSuggestedCustomerData
