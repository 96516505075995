import {useContext} from 'react'
import useGetInquiriesDetails from '../hooks/useGetInquiresDetails'
import {InquiresPageListingContext} from '../context'

export function DropdownListing({status, handleModel, inquiryId}: any) {
  const {onUpdateStatus} = useGetInquiriesDetails()
  const {refetch, setClickedId} = useContext(InquiresPageListingContext)

  const onChangeStatus = async () => {
    const newStatus = status === 'Archived' ? 'New' : 'Archived'
    const res: any = await onUpdateStatus(inquiryId, newStatus)
    if (res?.status === 200) {
      refetch()
    }
    document.body.click()
  }

  const handleEdit = () => {
    handleModel()
    setClickedId(inquiryId)
  }

  return (
    <div>
      <div className='px-2 py-1'>
        <span onClick={handleEdit} className='text-dark fw-bold text-hover-primary fs-6'>
          <label>Edit</label>
        </span>
      </div>

      <div className='px-2 py-1'>
        <span
          onClick={() => {
            onChangeStatus()
          }}
          className='text-dark fw-bold text-hover-primary fs-6'
        >
          <label>{status === 'Archived' ? 'Unarchive' : 'Archive'}</label>
        </span>
      </div>
    </div>
  )
}
