import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html'
import {LexicalEditor, $getRoot, $getSelection, $isRangeSelection} from 'lexical'

// Function to convert from HTML string to Lexical nodes
export function convertHTMLToLexical(editor: LexicalEditor, html: string) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const nodes = $generateNodesFromDOM(editor, doc)
  nodes.forEach((node) => editor.update(() => $getRoot().append(node)))
}

// Function to convert Lexical nodes to HTML string
export function convertLexicalToHTML(editor: LexicalEditor) {
  return $generateHtmlFromNodes(editor)
}

// Helper to merge refs
export function mergeRefs<T>(...refs: React.Ref<T>[]) {
  return (instance: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance)
      } else if (ref && typeof ref === 'object') {
        ;(ref as React.MutableRefObject<T | null>).current = instance
      }
    })
  }
}

// Function to get the cursor position
export function getCursorPosition(editor: LexicalEditor) {
  editor.getEditorState().read(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const {anchor} = selection
      const cursorPosition = {
        nodeKey: anchor.key,
        offset: anchor.offset,
        type: anchor.type,
      }
      return cursorPosition
    }

    return null
  })
}
