import {useQuery} from 'react-query'
import {getReorderRateReport} from '../services'

export const useGetReorderRateReport = (productId: any) => {
  const {data, isFetching} = useQuery(
    ['get-ReorderRateReport'],
    () => {
      return getReorderRateReport(
        `/analytics/sold-products/reorder-rate-report?product_id=${productId}`
      )
    },
    {
      enabled: !!productId,
    }
  )

  return {
    ReorderRateReportData: data?.data || [],
    TotalPercentage: data?.total_percentage || {},
    isLoading: isFetching,
    ProductName: data?.product_name || '',
  }
}
