import { GlobalMemberActionsDropdownTypes } from "../../interfaces"

const GlobalMemberActionsDropdown = ({setShowDeleteModal}: GlobalMemberActionsDropdownTypes) => {
  const handleRemove = () => {
    setShowDeleteModal(true)
    document.body.click()
  }

  return (
    <div className='d-flex align-items-start flex-column'>
      <span onClick={handleRemove} className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'>
        Remove
      </span>
    </div>
  )
}
export default GlobalMemberActionsDropdown
