import {useContext} from 'react'
import {ListViewPageContext} from '../../../context'
import {DynamicTable} from '../../../../../components/DynamicTable'
import ListViewTableRow from './ListViewTableRow'
import {agileBoardTicketListData} from '../../../parsings'
import {listViewTicketColumns} from '../../../utils'

const ListViewTable = () => {
  const {
    agileBoardTicketList,
    isLoadingAgileBoardTicketList,
    filters,
    onSearch,
    onSortingChange,
    isLoadingTicketOperation,
  } = useContext(ListViewPageContext)

  return (
    <DynamicTable
      sortableColumns={listViewTicketColumns}
      data={agileBoardTicketList}
      TableRow={ListViewTableRow}
      rowParsingFunction={agileBoardTicketListData}
      loading={isLoadingAgileBoardTicketList || isLoadingTicketOperation}
      filters={filters}
      onSearch={onSearch}
      onSortingChange={onSortingChange}
    />
  )
}

export default ListViewTable
