/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {convertFilesToJson, isEmpty} from '../../utils/common'

export interface DropzoneProps {
  id: string | number
  maxFileSize?: number
  totalFileSize?: number
  onUploadImage?: (imgUrls?: any) => void
  maxFileUploads?: number
  accept?: string[] | 'All'
  convertToBase64?: boolean
  defaultFile?: string | []
  isLabel?: boolean
  onDelete?: (imageName: string) => void
  customStyles?: string
  notification?: (message: string, type: 'success' | 'error') => void
  isReplaceable?: boolean
  Component?: any
  componentProps?: any
  isDisabled?: boolean
  apiFunction?: (images: any) => void
}

const Dropzone = ({
  id,
  maxFileSize,
  totalFileSize,
  maxFileUploads = Infinity,
  onUploadImage,
  accept = ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
  convertToBase64 = false,
  defaultFile,
  onDelete,
  customStyles = '',
  notification,
  isReplaceable = false,
  Component,
  componentProps,
  isDisabled = false,
  apiFunction,
}: DropzoneProps) => {
  const maximumFileSize = maxFileSize ? maxFileSize * 1024 * 1024 : Infinity
  const maximumTotalFileSize = totalFileSize ? totalFileSize * 1024 * 1024 : Infinity
  const [images, setImages] = useState<any>([])
  const [apiImages, setApiImages] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isDefaultValueSet, setIsDefaultValueSet] = useState(false)

  useEffect(() => {
    const initializeImages = () => {
      const imageObjects = (Array.isArray(defaultFile) ? defaultFile : [defaultFile])
        .map((data: any) => ({
          file: data?.name || '',
          url: data?.image || '',
          source: 'cloud',
        }))
        .filter((image) => image.file || image.url)

      if (imageObjects.length > 0) {
        setImages((prevImages: any) => {
          const newImages = [...prevImages, ...imageObjects].filter(
            (image, index, self) => index === self.findIndex((i) => i.file === image.file)
          )
          return maxFileUploads ? newImages.slice(0, maxFileUploads) : newImages
        })
      }

      if (defaultFile && defaultFile.length > 0) {
        setApiImages(defaultFile)
      }

      setIsDefaultValueSet(true)
    }
    if (!isDefaultValueSet && !isEmpty(defaultFile)) {
      setImages([])
      setApiImages([])
      initializeImages()
    }
  }, [defaultFile, maxFileUploads])

  const getTotalSize = (files: {file: File; source: string}[]) => {
    return files.reduce((acc, curr) => acc + curr.file.size, 0)
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    setIsLoading(true)
    const newFiles = Array.from(event.target.files || [])
    const validFiles: {file: File; source: string}[] = []
    let hasError = false

    newFiles.forEach((file) => {
      const fileType = file.type
      if (!Array.isArray(accept) || !accept.includes(fileType)) {
        const formattedAcceptMessage =
          Array.isArray(accept) &&
          accept.map((type: any) => type.split('/').pop().toUpperCase()).join(', ')
        setError(
          `File type not supported. Please upload a file of type: ${formattedAcceptMessage}.`
        )
        notification?.(
          `File type not supported. Please upload a file of type: ${formattedAcceptMessage}.`,
          'error'
        )
        hasError = true
      } else if (maximumFileSize !== Infinity && file.size > maximumFileSize) {
        setError(
          `The file exceeds the maximum allowed size of ${maxFileSize}MB. Please choose a smaller image.`
        )
        notification?.(
          `The file exceeds the maximum allowed size of ${maxFileSize}MB. Please choose a smaller image.`,
          'error'
        )
        hasError = true
      } else if (
        getTotalSize([...images, ...validFiles, {file, source: 'manual'}]) > maximumTotalFileSize
      ) {
        setError(
          `The total file size exceeds the maximum allowed limit of ${totalFileSize}MB. Please choose smaller images.`
        )
        notification?.(
          `The total file size exceeds the maximum allowed limit of ${totalFileSize}MB. Please choose smaller images.`,
          'error'
        )
        hasError = true
      } else if (!images.some((existingImage: any) => existingImage.file.name === file.name)) {
        validFiles.push({file, source: 'manual'})
      }
    })

    if (hasError) {
      setIsLoading(false)
      return // Exit early if there are errors
    }

    const previousImages = images.slice()
    const updatedImages = isReplaceable
      ? validFiles
      : maxFileUploads
      ? [...images, ...validFiles].slice(0, maxFileUploads)
      : [...images, ...validFiles].slice(0, 1)

    if (isReplaceable) {
      previousImages
        .filter((img: any) => img.source === 'cloud')
        .forEach((img: any) => onDelete?.(img.file.name))
    }

    const manualImages = updatedImages
      .filter((img) => img.source === 'manual')
      .map((img) => img.file)

    if (convertToBase64) {
      // Convert images to JSON format and handle both callbacks
      const imageData = await convertFilesToJson(manualImages, maxFileUploads ? true : false)
      onUploadImage?.(imageData)
    } else {
      onUploadImage?.(manualImages)
      const res: any = await apiFunction?.(newFiles)
      if (res.status < 300) {
        setApiImages((prevApiImages: any[]) => [...prevApiImages, ...res?.data?.files])
      }
    }

    setImages(updatedImages)

    setIsLoading(false)
    notification?.(`Uploaded ${validFiles.length} file(s).`, 'success')
  }

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    setError(null)
    setIsLoading(true)
    event.preventDefault()
    const newFiles = Array.from(event.dataTransfer.files || [])
    const validFiles: {file: File; source: string}[] = []
    let hasError = false

    newFiles.forEach((file) => {
      const fileType = file.type
      if (!Array.isArray(accept) || !accept.includes(fileType)) {
        const formattedAcceptMessage =
          Array.isArray(accept) &&
          accept.map((type: any) => type.split('/').pop().toUpperCase()).join(', ')
        setError(
          `File type not supported. Please upload a file of type: ${formattedAcceptMessage}.`
        )
        notification?.(
          `File type not supported. Please upload a file of type: ${formattedAcceptMessage}.`,
          'error'
        )
        hasError = true
      } else if (maximumFileSize !== Infinity && file.size > maximumFileSize) {
        setError(
          `The file exceeds the maximum allowed size of ${maxFileSize}MB. Please choose a smaller image.`
        )
        notification?.(
          `The file exceeds the maximum allowed size of ${maxFileSize}MB. Please choose a smaller image.`,
          'error'
        )
        hasError = true
      } else if (
        getTotalSize([...images, ...validFiles, {file, source: 'manual'}]) > maximumTotalFileSize
      ) {
        setError(
          `The total file size exceeds the maximum allowed limit of ${totalFileSize}MB. Please choose smaller images.`
        )
        notification?.(
          `The total file size exceeds the maximum allowed limit of ${totalFileSize}MB. Please choose smaller images.`,
          'error'
        )
        hasError = true
      } else if (!images.some((existingImage: any) => existingImage.file.name === file.name)) {
        validFiles.push({file, source: 'manual'})
      }
    })

    if (hasError) {
      setIsLoading(false)
      return // Exit early if there are errors
    }

    const previousImages = images.slice()
    const updatedImages = isReplaceable
      ? validFiles
      : maxFileUploads
      ? [...images, ...validFiles].slice(0, maxFileUploads)
      : [...images, ...validFiles].slice(0, 1)

    if (isReplaceable) {
      previousImages
        .filter((img: any) => img.source === 'cloud')
        .forEach((img: any) => onDelete?.(img.file.name))
    }

    const manualImages = updatedImages
      .filter((img) => img.source === 'manual')
      .map((img) => img.file)

    if (convertToBase64) {
      // Convert images to JSON format and handle both callbacks
      const imageData = await convertFilesToJson(manualImages, maxFileUploads ? true : false)
      onUploadImage?.(imageData)
      apiFunction?.(imageData)
    } else {
      onUploadImage?.(manualImages)
      const res: any = await apiFunction?.(newFiles)
      if (res.status < 300) {
        setApiImages((prevApiImages: any[]) => [...prevApiImages, ...res?.data?.files])
      }
    }

    setImages(updatedImages)

    setIsLoading(false)
    notification?.(`Uploaded ${validFiles.length} file(s) from drag and drop.`, 'success')
  }

  const handleDeleteImage = (index: number) => {
    if (!isEmpty(images)) {
      const updatedImages = [...images]
      const removedImage = updatedImages.splice(index, 1)[0]
      setImages(updatedImages)

      if (removedImage.source === 'cloud' && typeof removedImage?.file === 'object') {
        onDelete?.(removedImage.file.name)
      } else if (removedImage.source === 'cloud' && typeof removedImage?.file === 'string') {
        onDelete?.(removedImage.file)
      }
    }
    if (!isEmpty(apiImages)) {
      setApiImages((prevApiImages: any[]) => prevApiImages.filter((_, i) => i !== index))
    }

    setError(null)
  }

  const renderAcceptedTypesMessage = () => {
    if (accept === 'All' || accept.length === 0) {
      return `All file types are accepted.`
    } else {
      return `${accept
        .map((type) => type.split('/')[1].toUpperCase())
        .join(', ')} files are accepted`
    }
  }

  const handleDeleteAllImages = async () => {
    if (!isEmpty(apiImages)) {
      images
        .filter((img: any) => img.source === 'cloud')
        .forEach((img: any) => onDelete?.(img.file.name))

      setImages([])
      setError(null)

      if (convertToBase64) {
        onUploadImage?.(await convertFilesToJson([], maxFileUploads ? true : false))
      } else {
        onUploadImage?.([])
      }
    }
    if (!isEmpty(apiImages)) {
      setApiImages([])
    }
  }

  return (
    <>
      {!isDisabled && (
        <>
          <div onDrop={handleDrop} onDragOver={(event) => event.preventDefault()}>
            <label
              htmlFor={`${id}-upload`}
              className={`dropzone ad-dropzone ${customStyles} d-flex align-items-center justify-content-between`}
            >
              <input
                type='file'
                id={`${id}-upload`}
                className='d-none'
                accept={accept === 'All' ? undefined : accept.join(',')}
                multiple={maxFileUploads !== 1 ? true : false}
                onChange={handleFileChange}
                onClick={(e: any) => (e.target.value = '')}
              />
              <div className='d-flex align-items-center'>
                <span className='download-icon me-7'>
                  <i className='bi bi-cloud-upload text-gray-700'></i>
                </span>
                <div>
                  <p className='fw-bold mb-2 text-start'>Select a file or drag and drop here</p>
                  <p className='mb-0 text-gray-600'>
                    {renderAcceptedTypesMessage()}{' '}
                    {maxFileSize && totalFileSize
                      ? `, No more than ${maxFileSize}MB, Total size no more than ${totalFileSize}MB`
                      : maxFileSize
                      ? `, No more than ${maxFileSize}MB`
                      : totalFileSize
                      ? `, Total size no more than ${totalFileSize}MB`
                      : null}
                  </p>
                </div>
              </div>
              <div id={`${id}-drop`} className='d-flex flex-column'>
                <label
                  htmlFor={`${id}-upload`}
                  className='btn btn-outline btn-active-light-primary text-center flex-grow-1'
                >
                  {isLoading ? (
                    <>
                      <i className='bi bi-cloud-upload me-2'></i> Uploading...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </>
                  ) : (
                    <>
                      <i className='bi bi-cloud-upload me-2'></i> Upload Image
                    </>
                  )}
                </label>
              </div>
            </label>
          </div>
          {error && <div className='alert alert-danger mt-2'>{error}</div>}
          {(images?.length > 1 || apiImages?.length > 1) && maxFileUploads > 1 ? (
            <span>
              <label
                className='text-dark text-hover-primary text-decoration-underline mt-3 fw-bold cursor-pointer'
                onClick={handleDeleteAllImages}
              >
                Remove all Images
              </label>
            </span>
          ) : null}
        </>
      )}

      <div className='mt-5'>
        {Component ? (
          <Component
            images={images}
            apiImages={apiImages}
            handleDeleteImage={handleDeleteImage}
            setApiImages={setApiImages}
            {...componentProps}
          />
        ) : (
          <div className='row d-flex flex-wrap justify-content-start align-items-center'>
            {images.map((image: any, index: any) => (
              <div key={index} className='col col-md-2 mb-5'>
                {isLoading && 'Loading...'}
                {typeof image?.file === 'object' ? (
                  <div className='w-100 h-100px overflow-hidden position-relative'>
                    <img
                      src={URL.createObjectURL(image.file)}
                      alt=''
                      className='rounded w-100 h-100 object-fit-cover'
                    />
                    <i
                      className='bi bi-x-lg text-gray-600 rounded cursor-pointer bg-light media-kit-remove-link'
                      onClick={() => handleDeleteImage(index)}
                    ></i>
                  </div>
                ) : image?.url !== '' ? (
                  <div className='w-100 h-100px overflow-hidden position-relative'>
                    <img src={image.url} alt='' className='rounded w-100 h-100 object-fit-cover' />
                    <i
                      className='bi bi-x-lg text-gray-600 rounded cursor-pointer bg-light media-kit-remove-link'
                      onClick={() => handleDeleteImage(index)}
                    ></i>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default Dropzone
