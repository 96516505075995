import Api from '../../../services/Api'

export const getInquiries= (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const updateVisibility = (url: string, filters: any) => {
  return Api.post(`${url}`, {}, filters, {}).then((d: any) => {
    return d?.data
  })
}

export const getInquiryDetails = (url: string) => {
  return Api.get(`${url}`, {}, {}).then((d: any) => {
    return d.data
  })
}

export const updateInquiryStatus = (url: string, data: any) => {
  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function updateDefaultColumnsTableSorting(data: any) {
  const url = `/settings/columns`

  return Api.patch(url, data, null, null).then((d: any) => {
    return d
  })
}

export function getAllDefaultColumns(filters: any) {
  const url = `/settings/columns?type=product_inquiries`
  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}