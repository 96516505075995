import * as yup from 'yup'

export const customFieldsSchema = yup.object().shape({
  name: yup.string().required('Custom Field Name is required'),
  datatype: yup.string().required('Custom field type is required'),
  group_values: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('Option is required').notOneOf([''], 'Option cannot be empty'),
      })
    )
    .when('datatype', {
      is: (datatype: string) => datatype === 'select' || datatype === 'multi select',
      then: (schema) => schema.min(1, 'At least one option is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
})

export const defaultColumnsSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
})

export const addGlobalProjectMembersSchema = yup.object().shape({
  usernames: yup.array().min(1, 'Members is required'),
})

export const editProjectTeamsSchema = yup.object().shape({
  teamId: yup.string().required('Please select at least one.'),
  usernames: yup
    .array()
    .min(1, 'At least one member must be selected')
    .of(yup.string().required('Each member must be selected')),
})

export const addProjectTeamsSchema = yup.object().shape({
  name: yup.string().required('Team name is required'),
  usernames: yup
    .array()
    .min(1, 'At least one member must be selected')
    .of(yup.string().required('Each member must be selected')),
})
