import {Link, useLocation} from 'react-router-dom'

function InquiresNavigation() {
  const location = useLocation()

  return (
    <div className=''>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10'>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/products/inquiries/all-inquiries' && 'active')
            }
            to='/products/inquiries/all-inquiries'
          >
            All Inquiries
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname === '/products/inquiries/archived' && 'active')
            }
            to='/products/inquiries/archived'
          >
            Archived
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default InquiresNavigation
