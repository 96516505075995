import useApi from '../../../../services/useApi'
import {useContext} from 'react'
import {ProjectDetailsContext} from '../../context'

export const useGetProjectMembersDropdownList = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)

  const {data: response, isFetching} = Api.useGetQuery(`agile/projects/${projectId}/members`, {
    queryId: 'project-members-list',
  })

  return {
    projectMembersDropdownList:
      response?.data?.filter((member: any) => member.name !== 'Unassigned') || [],
    isLoading: isFetching,
  }
}
