import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getInActiveCustomers} from '../services'
import useFilters from '../../../hook/useFilters'
import {dataToInactiveCustomerListing} from '../utils'


export const useGetInactiveCustomers = (limit:number): any => {
  const initialFilters = {
    page: 1,
    limit: limit,
    sort_by: 'date_created/1',
  }
  const {filters, setSingleFilter, setMultipleFilters} = useFilters(initialFilters)

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`no-${limit}-orders-customers`, () => {
    return getInActiveCustomers(`/analytics/customers/no-orders`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onPageChange = (page_number: number) => {
    setSingleFilter('page', page_number)
  }

  const onClickSorting = (sortBy: string, sortDirection: any) => {
    const direction = sortDirection === 'asc' ? 1 : -1
    const sortFlag = sortBy + '/' + direction
    setMultipleFilters({
      sort_by: sortFlag,
      page: 1,
    })
  }

  return {
    inactiveCustomers: response?.data ? dataToInactiveCustomerListing(response.data) : [],
    isLoading: isFetching,
    totalInactiveCustomers: response?.meta?.pagination?.total || 0,
    pagination: response?.['meta']?.['pagination'] || {},
    onPageChange,
    onInactiveCustomerClickSorting: onClickSorting,
    InactiveCustomerFilters: filters,
  }
}
