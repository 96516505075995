import {useQuery} from 'react-query'
import {getRoles} from '../services'

export default function useGetRoles() {
  const {
    data: roles,
    refetch,
    isFetching,
  } = useQuery(`roles`, () => {
    return getRoles(`/settings/users/roles`)
  })

  return {
    roles: roles || [],
    refetch,
    isFetching,
  }
}
