import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../helpers'
import { storePermissions } from '../../../../feature/authSlice'
import store from '../../../../redux/store'
import { useAppDispatch } from '../../../../redux/useTypedSelector'


function StoreListModal({show, onClose, stores, setActiveStoreName}: any) {
  const dispatch = useAppDispatch()
  const activeStoreId = localStorage.getItem('x-store-id')
  const user: any = store.getState()?.auth?.user

  async function handleClick(storeId: string) {
    localStorage.setItem('x-store-id', storeId)
    setActiveStoreName(stores.find((store: any) => store['storeId'] === storeId)['name'])
    const token = user?.['access_token']
    const tenantId = user?.['tenant_id']
    await dispatch(storePermissions({ token: token, storeId: storeId, tenantId: tenantId })) 
    window.location.reload()
    window.location.href = '/dashboard';
  }

  if (!show) {
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_create_navigation'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title'>Select Workspace</h4>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={onClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body'>
          {/* <div className='card border p-5 pt-0 pb-5'>
            {stores?.map((store: any) => {
              return (
                <div
                  key={store.admin_url}
                  className='menu-item menu-item-parent'
                  onClick={() => handleClick(store['storeId'])}
                >
                  <div>
                    <div
                      className={`fs-6 fw-bold mt-5 ${
                        activeStoreId === store['storeId'] ? 'text-primary' : 'text-dark'
                      }`}
                    >
                      {store['name']}
                    </div>
                    <div className='fs-6 text-gray-500'>
                      {store['AdminURL'].replace('https://', '')}
                    </div>
                  </div>
                </div>
              )
            })}
          </div> */}

          <div className=''>
            {stores?.map((store: any) => {
              return (
                <div
                  key={store.admin_url}
                  className={`cursor-pointer rounded-2 border mb-2 p-4 px-5 d-flex align-items-center justify-content-between ${
                    activeStoreId === store['storeId'] ? 'store-active' : 'store-not-active'
                  }`}
                  onClick={() => handleClick(store['storeId'])}
                >
                  <div>
                    <div
                      className={`fs-5 fw-bold ${
                        activeStoreId === store['storeId'] ? 'text-primary' : 'text-gray-900'
                      }`}
                    >
                      {store['name']}
                    </div>
                    <div className='fs-7 text-gray-500'>
                      {store['AdminURL'].replace('https://', '')}
                    </div>
                  </div>

                  <div className='symbol symbol-30px me-5'>
                    <span className='symbol-label'>
                      <i className={`bi bi-check-circle-fill fs-3 ${activeStoreId === store['storeId'] ? 'text-primary' : ''}`}></i>
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StoreListModal
