import ProjectsTeamsPageHeader from '../sections/headers/ProjectsTeamsPageHeader'
import {Pagination} from '../../../utils/pagination'
import {ProjectsTeamsPageContext} from '../context'
import ProjectTeamsTable from '../sections/tables/ProjectTeamsTable'
import {useGetProjectsTeamsList} from '../hooks/teams/useGetProjectsTeamsList'
import useGetProjectsTeamsOperations from '../hooks/teams/useGetProjectsTeamsOperations'
import usePermission from '../../../hook/usePermission'
import { useGetProjectsMembersTeamsDropdownList } from '../hooks/teams/useGetProjectsMembersTeamsDropdownList'

export default function ProjectTeams() {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {globalProjectsMembersDropdownList, isLoading: isLoadingGlobalProjectsMembersDropdownList} =
  useGetProjectsMembersTeamsDropdownList()

  const {
    ProjectsTeamsList,
    isLoading: isLoadingProjectsTeamsList,
    pagination,
    onPageChange,
    onSearch,
    searchValue,
    filters,
    onSortingChange,
  } = useGetProjectsTeamsList('')
  const {addProjectTeams, isLoadingProjectsTeamsOperation, removeProjectTeams, updateProjectTeam} =
    useGetProjectsTeamsOperations()

  const contextValue = {
    hasProjectSettingsPermission,
    ProjectsTeamsDropdownList: [],
    isLoadingProjectsTeamsDropdownList: false,

    ProjectsTeamsList: ProjectsTeamsList,
    isLoadingProjectsTeamsList: isLoadingProjectsTeamsList,
    onSearch: onSearch,
    searchValue,
    filters,
    onSortingChange,

    addProjectTeams: addProjectTeams,
    removeProjectTeams: removeProjectTeams,
    updateProjectTeam: updateProjectTeam,
    isLoadingProjectsTeamsOperation: isLoadingProjectsTeamsOperation,

    globalProjectsMembersDropdownList,
    isLoadingGlobalProjectsMembersDropdownList,
  }

  return (
    <ProjectsTeamsPageContext.Provider value={contextValue}>
      <ProjectsTeamsPageHeader />
      <ProjectTeamsTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </ProjectsTeamsPageContext.Provider>
  )
}
