import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {authenticationSlice} from '../feature/authSlice'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import filterReducer from './ReplenishmentFilterHandler'

const persistConfig = {
  key: 'adminRoot',
  storage,
}

const rootReducer = combineReducers({
  auth: authenticationSlice.reducer,
  filters: filterReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),  
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)
export default store
