import Api from '../../../services/Api'

/**
 * Retrieves a list of inactive customers based on the provided URL and filters.
 * @param url - The URL to fetch the inactive customers from.
 * @param filters - The filters to apply to the request.
 * @returns A Promise that resolves to the data of the inactive customers.
 */
// same function used in anonymousCustomerListing, recentlyInactiveCustomerListing, inactiveCustomerListing ...
export const getInActiveCustomers = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return {
      data: d?.data?.data,
      meta: d?.data?.meta,
    }
  })
}
