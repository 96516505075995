import {DynamicTable} from '../../../../components/DynamicTable'
import LinkedTicketsTableRow from './LinkedTicketsTableRow'
import {parseLinkedTicketData} from '../../parsing'

const LinkedTicketsTable = ({data}: {data: any}) => {
  return (
    <DynamicTable
      data={data}
      sortableColumns={[]}
      TableRow={LinkedTicketsTableRow}
      rowParsingFunction={parseLinkedTicketData}
    />
  )
}

export default LinkedTicketsTable
