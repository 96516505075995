import Api from '../../../services/Api'

export async function createUnhideProductsRule(data: any) {
  const url = `/analytics/automation/rule-engine/unhideproducts`
  const response = await Api.post(url, data, null, null)  
  return response
}

export const getUnhideProductRules = (url: string, filters: any) => {
  return Api.get(`${url}`, filters, {}).then((d: any) => {
    return d.data
  })
}

export const deleteUnhideProductsRule = (product_id: string) => {
  const url = `/analytics/automation/rule-engine/unhideproducts`
  return Api.delete(`${url}?product_id=${product_id}`, {}, {}, {}).then((d: any) => {
    return d
  })
}
