import usePermission from '../../../hook/usePermission'
import { Pagination } from '../../../utils/pagination'
import { ProductsReportPageContext } from '../contexts'
import useGetProductsReportsData from '../hooks/useGetProductsReportsData'
import ProductsHeader from '../sections/headers/ProductsHeader'
import ProductsTable from '../sections/tabels/ByProducts/ProductsTable'

const Products = () => {
  const { hasPermission } = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {
    isLoading,
    productsReportsData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onPageChange,
    pagination,
    refetch,
  } = useGetProductsReportsData()

  const contextValue = {
    hasProjectSettingsPermission,
    isLoading,
    productsReportsData,
    onSortingChange,
    onSearch,
    searchValue,
    filters,
    refetch,
  }

  return (
    <ProductsReportPageContext.Provider value={contextValue}>
      <ProductsHeader />
      <ProductsTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </ProductsReportPageContext.Provider>
  )
}

export default Products
