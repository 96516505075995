import {toCamelCase} from './string'

export const formatErrors = (error: any, isDisableSneckCase: any) => {
  const status = error?.response?.status

  // Handling specific error messages for other status codes
  if (
    error?.response?.data?.detail ||
    error?.response?.data?.message ||
    error?.response?.data?.data
  ) {
    const detail =
      error?.response?.data?.detail || error?.response?.data?.message || error?.response?.data?.data
    const colonIndex = typeof detail === 'string' ? detail.indexOf(':') : -1

    if (Array.isArray(detail)) {
      return detail
        .map((item: string) => {
          if (typeof item === 'string') {
            const colonIndex = item.indexOf(':')
            if (colonIndex !== -1) {
              const key = item.substring(0, colonIndex).trim().toLowerCase()
              const camelCaseKey = toCamelCase(key)
              const message = item.substring(colonIndex + 1).trim()
              const valueMatch = message.match(/'([^']*)'/)
              const value = valueMatch ? valueMatch[1] : null
              const errorObject = {
                key: isDisableSneckCase ? key : camelCaseKey,
                message: message,
                value: value,
              }
              return errorObject
            } else {
              return {
                key: null,
                message: item.trim(),
                value: null,
              }
            }
          }
          return null
        })
        .filter((item) => item !== null)
    }

    if (typeof detail === 'object' && detail !== null) {
      return detail?.title || detail
    }

    if (colonIndex === -1) {
      // No colon found, return the message directly
      return `${detail}`
    } else {
      // Colon found, extract key and message
      const key = detail.substring(0, colonIndex).trim().toLowerCase()
      const camelCaseKey = toCamelCase(key)
      const message = detail.substring(colonIndex + 1).trim()
      const valueMatch = message.match(/'([^']*)'/)
      const value = valueMatch ? valueMatch[1] : null
      const errorObject = {
        key: isDisableSneckCase ? key : camelCaseKey,
        message: message,
        value: value,
      }

      return errorObject
    }
  } else if ((status === 409 && error?.response?.data?.errors) || error?.response?.data?.success) {
    return error.response.data
  }
}
