/* eslint-disable */
import {useFieldArray, useForm} from 'react-hook-form'
import {BoardSettingsPageContext} from '../../context'
import {useContext, useEffect} from 'react'
import {AddBoardCustomFieldsModalTypes} from '../../interfaces'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {SectionForm} from '../../../../components/SectionForm'
import {yupResolver} from '@hookform/resolvers/yup'
import {AddBoardCustomFieldsSchema} from '../../validations'
import {InputText} from '../../../../components/InputText'
import {
  addBoardCustomFieldsData,
  customFieldTypesDropdownList,
  formatBoardCustomFieldSubmitData,
} from '../../utils'

const AddBoardCustomFieldsModal = ({
  show,
  onClose,
  isEdit,
  customFieldData,
}: AddBoardCustomFieldsModalTypes) => {
  const {
    createCustomField,
    updateCustomField,
    isLoadingBoardCustomFieldsList,
    isLoadingCustomFieldOperation,
    globalCustomFieldsList,
    isLoadingGlobalCustomFieldsList,
  } = useContext(BoardSettingsPageContext)
  const {
    handleSubmit,
    control,
    register,
    formState: {dirtyFields, errors, isDirty, isValid},
    setError,
    setValue,
    watch,
    reset,
  }: any = useForm<any>({
    defaultValues: addBoardCustomFieldsData(customFieldData, isEdit),
    resolver: yupResolver(AddBoardCustomFieldsSchema(isEdit)),
  })

  const {type} = watch()
  const hasGlobalCustomFields = globalCustomFieldsList?.length > 0

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'groupValues',
  })

  const handleGroupTypeChange = (e: any) => {
    const isGroupType = ['select', 'multi select'].includes(e.target.value)
    if (isGroupType && fields.length === 0) {
      append({value: ''})
    } else if (!isGroupType) {
      setValue('groupValues', [])
    }
  }

  const onSubmit = (data: any) => {
    const formattedData = formatBoardCustomFieldSubmitData(data, isEdit)
    if (!isEdit) {
      createCustomField(formattedData, setError)
    } else {
      updateCustomField(`${customFieldData?.id}`, formattedData, dirtyFields, setError)
    }
  }

  useEffect(() => {
    !hasGlobalCustomFields && setValue('type', 'custom')
  }, [hasGlobalCustomFields])

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Add'} Field`}
      onAction={handleSubmit(onSubmit)}
      actionName={`${isEdit ? 'Update' : 'Add'}`}
      actionBtnClass='btn-primary'
      isDataLoading={isLoadingBoardCustomFieldsList}
      isOperationLoading={isLoadingCustomFieldOperation}
      isDisabled={
        !isValid || !isDirty || isLoadingCustomFieldOperation || isLoadingBoardCustomFieldsList
      }
      body={
        <>
          <SectionForm
            id='add-board-custom-field-type'
            register={register}
            control={control}
            errors={errors}
            commonClassNames='mb-8'
            inputs={[
              {
                id: 'fieldType',
                isRadio: true,
                class: 'd-flex gap-3',
                name: 'fieldType',
                registerKey: 'type',
                onChange: (e: any) => reset({type: e.target.value}),
                disabled: isEdit,
                show: hasGlobalCustomFields && !isEdit,
                children: [
                  {
                    id: 'globalRadio',
                    label: 'Global',
                    value: 'global',
                  },
                  {
                    id: 'customRadio',
                    label: 'Custom',
                    value: 'custom',
                  },
                ],
              },
              {
                id: 'project-custom-field-name',
                label: 'Field Name',
                registerKey: 'name',
                required: true,
                placeholder: 'Enter field name',
                show: isEdit || type === 'custom',
                maxLength: 30,
              },
            ]}
          />

          {/* Conditional Rendering Ui of global and custom based on type */}
          {type === 'global' ? (
            <SectionForm
              id='add-project-custom-fields'
              register={register}
              control={control}
              commonClassNames='mb-8'
              errors={errors}
              inputs={[
                {
                  id: 'project-custom-fields',
                  isMultiSelect: true,
                  label: 'Custom Fields',
                  required: true,
                  registerKey: 'customfieldMetaIds',
                  options: globalCustomFieldsList,
                  isLoading: isLoadingGlobalCustomFieldsList,
                  labelKey: 'custom_field_name',
                  valueKey: 'custom_field_id',
                  disabledKey: 'is_disabled',
                  valueType: 'number',
                  show: !isEdit,
                },
              ]}
            />
          ) : (
            <>
              <SectionForm
                id='add-global-projects-custom-fields'
                register={register}
                control={control}
                errors={errors}
                commonClassNames='mb-8'
                inputs={[
                  {
                    id: 'global-custom-field-type',
                    label: 'Field Type',
                    isSelect: true,
                    options: customFieldTypesDropdownList,
                    placeholder: 'Select field type',
                    disabled: isEdit,
                    registerKey: 'datatype',
                    required: true,
                    onChange: handleGroupTypeChange,
                    disabledKey: 'disabled',
                  },
                ]}
              />

              {(watch('datatype') === 'select' || watch('datatype') === 'multi select') && (
                <>
                  <label className='form-label required mb-5'>Options</label>
                  {fields.map((field, index) => (
                    <div key={field.id} className='d-flex align-items-center gap-3 mb-8'>
                      <InputText
                        id={`option-${index}`}
                        register={register(`groupValues.${index}.value`)}
                        placeholder={`Option ${index + 1}`}
                        className='w-100'
                        inputClass='bg-light'
                        error={errors?.groupValues?.[index]?.value}
                      />

                      {fields?.length > 1 && (
                        <button type='button' onClick={() => remove(index)} className='btn p-0 '>
                          <span className='fa-solid fa-circle-minus text-danger'></span>
                        </button>
                      )}
                    </div>
                  ))}

                  <button
                    type='button'
                    onClick={() => append({value: ''})}
                    className='btn btn-primary'
                  >
                    + Add Option
                  </button>
                </>
              )}
            </>
          )}
        </>
      }
    />
  )
}

export default AddBoardCustomFieldsModal
