import {isEmpty, isEmptyObj} from '../../../utils/common'

export const reorderColumns = (defaultCols: any[], globalCols: any[]) => {
  const reordered: any = []

  // Add matching columns from globalDefaultColumnsList in the same order
  globalCols.forEach((globalCol) => {
    const key = Object.keys(globalCol)[0] // get the key
    const matchedCol = defaultCols.find((col) => col.hasOwnProperty(key))
    if (matchedCol) {
      reordered.push(matchedCol)
    }
  })

  // Add the remaining columns from defaultColumns that were not in globalDefaultColumnsList
  defaultCols.forEach((defaultCol) => {
    const key = Object.keys(defaultCol)[0]
    const isAlreadyAdded = reordered.some((col: any) => col.hasOwnProperty(key))
    if (!isAlreadyAdded) {
      reordered.push(defaultCol)
    }
  })

  return reordered
}

// format data for dnd table
export const formatForDndData = (data: any[]) => {
  if (isEmpty(data)) return []
  return data.map((item) => {
    const key = Object.keys(item).find((k) => k !== 'default')!
    const transformed = {
      label: item[key] as string,
      value: key,
    }

    // Add "default: true" if it is found
    if (item.default === true) {
      return {...transformed, default: true}
    }

    return transformed
  })
}

// remove column from data
export const removeColumn = (data: any[], columnKey: string) => {
  return data.filter((item: any) => !item.hasOwnProperty(columnKey))
}

export const trimMessage = (message: any) => {
  if (message.length > 150) {
    return message.substring(0, 150) + '...'
  }
  return message
}

// initialize columns
export const initializeColumns = (
  globalDefaultColumnsList: any,
  defaultColumns: any,
  setUpdatedDefaultColumn: any,
  setSelectedColumns: any,
  selectedColumns: any,
  setInitialSelectedColumns: any
) => {
  const columns = !isEmptyObj(globalDefaultColumnsList) ? globalDefaultColumnsList : defaultColumns
  setInitialSelectedColumns([...selectedColumns])
  if (!columns) return

  const reorderedColumns = reorderColumns(defaultColumns, globalDefaultColumnsList)
  const reorderedKeys = reorderedColumns.map((column: any) => Object.keys(column)[0])
  sessionStorage.setItem('sortedColumnIds', JSON.stringify(reorderedKeys))

  const updatedColumn = removeColumn(reorderedColumns, 'action')
  setUpdatedDefaultColumn(formatForDndData(updatedColumn))
  setSelectedColumns(
    columns.map((column: any) => ({key: Object.keys(column)[0], title: Object.values(column)[0]}))
  )
}
