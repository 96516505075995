import useApi from '../../../services/useApi'

export const useGetBusinessUnitsList = () => {
  const Api = useApi()
  const {data: response, isFetching} = Api.useGetQuery(`agile/businessunits`)

  return {
    businessUnitsList: response?.data || [],
    isLoadingBusinessUnits: isFetching,
  }
}
