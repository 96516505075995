/* eslint-disable */
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {FORMAT_TEXT_COMMAND, REDO_COMMAND, UNDO_COMMAND} from 'lexical'
import {Dispatch} from 'react'
import DropdownColorPicker from '../../ui/DropdownColorPicker'
import {IS_APPLE} from '../../utils/environment'
import {ContentFormatDropdown} from './Components/ContentFormatDropdown'
import {Divider} from '../../ui/Divider'
import {FontDropDown} from './Components/FontDropDown'
import {BlockFormatDropDown} from './Components/BlockFormatDropDown'
import {CodeLanguageDropDown} from './Components/CodeLanguageDropDown'
import {TextFormatDropDown} from './Components/TextFormatDropDown'
import {InsertDropDown} from './Components/InsertDropDown'
import useToolbarFunctions from './hooks/useToolbarFunctions'
import CustomFontSize from './Components/CustomFontSize'
import useToolbarEffects from './hooks/useToolbarEffects'
import {CODE_BLOCK_MODE_TOOLS, TOOLBAR_SETTINGS} from '../../editorSettings'

export default function ToolbarPlugin({
  setIsLinkEditMode,
}: {
  setIsLinkEditMode: Dispatch<boolean>
}): JSX.Element {
  const [editor] = useLexicalComposerContext()
  const {
    activeEditor,
    isEditable,
    canUndo,
    canRedo,
    isLink,
    isMarkdown,
    blockType,
    codeLanguage,
    fontFamily,
    fontSize,
    fontColor,
    bgColor,
    elementFormat,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    isSubscript,
    isSuperscript,
    isCode,
    modal,
    isHtmlMode,
    isRTL,
    showModal,
    insertLink,
    onCodeLanguageSelect,
    onFontColorSelect,
    onBgColorSelect,
    clearFormatting,
    handleMarkdownToggle,
    setActiveEditor,
    setIsMarkdown,
    setIsEditable,
    setCanUndo,
    setCanRedo,
    $updateToolbar,
    handleHtmlToggle,
    setIsHtmlMode,
  } = useToolbarFunctions(editor, setIsLinkEditMode)

  useToolbarEffects(
    editor,
    activeEditor,
    isLink,
    setIsLinkEditMode,
    setActiveEditor,
    setIsMarkdown,
    setIsEditable,
    setCanUndo,
    setCanRedo,
    $updateToolbar,
    setIsHtmlMode
  )

  const toolbarItems: Record<string, () => JSX.Element | null> = {
    undo: () => (
      <button
        disabled={!canUndo || !isEditable}
        onClick={() => activeEditor.dispatchCommand(UNDO_COMMAND, undefined)}
        title={IS_APPLE ? 'Undo (⌘Z)' : 'Undo (Ctrl+Z)'}
        type='button'
        className='toolbar-item spaced'
        aria-label='Undo'
      >
        <i className='format undo' />
      </button>
    ),

    redo: () => (
      <button
        disabled={!canRedo || !isEditable}
        onClick={() => activeEditor.dispatchCommand(REDO_COMMAND, undefined)}
        title={IS_APPLE ? 'Redo (⇧⌘Z)' : 'Redo (Ctrl+Y)'}
        type='button'
        className='toolbar-item'
        aria-label='Redo'
      >
        <i className='format redo' />
      </button>
    ),

    blockFormattingDropdown: () => (
      <BlockFormatDropDown editor={activeEditor} blockType={blockType} disabled={!isEditable} />
    ),

    fontFamilyDropdown: () => (
      <FontDropDown
        disabled={!isEditable}
        style={'font-family'}
        value={fontFamily}
        editor={activeEditor}
      />
    ),

    codeLanguageDropDown: () => (
      <CodeLanguageDropDown
        disabled={!isEditable}
        codeLanguage={codeLanguage}
        onCodeLanguageSelect={onCodeLanguageSelect}
      />
    ),

    fontSizeDropdown: () => (
      <CustomFontSize
        selectionFontSize={fontSize.slice(0, -2)}
        editor={activeEditor}
        disabled={!isEditable}
      />
    ),

    bold: () => (
      <button
        disabled={!isEditable}
        onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        title={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}
        type='button'
        aria-label={`Format text as bold. Shortcut: ${IS_APPLE ? '⌘B' : 'Ctrl+B'}`}
      >
        <i className='format bold' />
      </button>
    ),

    italic: () => (
      <button
        disabled={!isEditable}
        onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
        className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
        title={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}
        type='button'
        aria-label={`Format text as italics. Shortcut: ${IS_APPLE ? '⌘I' : 'Ctrl+I'}`}
      >
        <i className='format italic' />
      </button>
    ),

    underline: () => (
      <button
        disabled={!isEditable}
        onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
        title={IS_APPLE ? 'Underline (⌘U)' : 'Underline (Ctrl+U)'}
        type='button'
        aria-label={`Format text to underlined. Shortcut: ${IS_APPLE ? '⌘U' : 'Ctrl+U'}`}
      >
        <i className='format underline' />
      </button>
    ),

    insertCode: () => (
      <button
        disabled={!isEditable}
        onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')}
        className={'toolbar-item spaced ' + (isCode ? 'active' : '')}
        title='Insert code block'
        type='button'
        aria-label='Insert code block'
      >
        <i className='format code' />
      </button>
    ),

    link: () => (
      <button
        disabled={!isEditable}
        onClick={insertLink}
        className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
        aria-label='Insert link'
        title='Insert link'
        type='button'
      >
        <i className='format link' />
      </button>
    ),

    fontColorPicker: () => (
      <DropdownColorPicker
        disabled={!isEditable}
        buttonClassName='toolbar-item color-picker'
        buttonAriaLabel='Formatting text color'
        buttonIconClassName='icon font-color'
        color={fontColor}
        onChange={onFontColorSelect}
        title='text color'
      />
    ),

    bgColorPicker: () => (
      <DropdownColorPicker
        disabled={!isEditable}
        buttonClassName='toolbar-item color-picker'
        buttonAriaLabel='Formatting background color'
        buttonIconClassName='icon bg-color'
        color={bgColor}
        onChange={onBgColorSelect}
        title='bg color'
      />
    ),

    textFormattingDropdown: () => (
      <TextFormatDropDown
        disabled={!isEditable}
        isStrikethrough={isStrikethrough}
        isSubscript={isSubscript}
        isSuperscript={isSuperscript}
        editor={activeEditor}
        clearFormatting={clearFormatting}
      />
    ),

    insertElementsDropdown: () => (
      <InsertDropDown disabled={!isEditable} activeEditor={activeEditor} showModal={showModal} />
    ),

    alignmentDropdown: () => (
      <ContentFormatDropdown
        disabled={!isEditable}
        value={elementFormat}
        editor={activeEditor}
        isRTL={isRTL}
      />
    ),

    markdownToggle: () => {
      if (blockType === 'code' && codeLanguage !== 'markdown') return null
      return (
        <button
          disabled={!isEditable}
          onClick={handleMarkdownToggle}
          className={`toolbar-item spaced action-button ${isMarkdown ? 'active' : ''}`}
          aria-label='markdown mode'
          title='Markdown Mode'
          type='button'
        >
          <i className='format markdown' />
        </button>
      )
    },

    htmlToggle: () => {
      if (blockType === 'code' && codeLanguage !== 'html') return null
      return (
        <button
          disabled={!isEditable || (blockType === 'code' && !isHtmlMode)}
          onClick={handleHtmlToggle}
          className={`toolbar-item spaced action-button ${isHtmlMode ? 'active' : ''}`}
          aria-label='html mode'
          title='Html Mode'
          type='button'
        >
          <i className='format html-view' />
        </button>
      )
    },
  }

  // Toolbar function to render items based on settings
  const renderToolbarItems = () => {
    if (blockType === 'code') {
      return CODE_BLOCK_MODE_TOOLS?.map(({key, visible}: any) => {
        if (key === 'divider') {
          return <Divider />
        } else if (visible) {
          return toolbarItems[key as keyof typeof toolbarItems]?.() || null
        }
      })
    } else {
      return TOOLBAR_SETTINGS.map(({key, visible}: any) => {
        if (key === 'divider') {
          return <Divider />
        } else if (visible) {
          return toolbarItems[key as keyof typeof toolbarItems]?.() || null
        }
        return null
      })
    }
  }

  return (
    <div className='toolbar'>
      {renderToolbarItems()}
      {modal}
    </div>
  )
}
