import {useContext} from 'react'
import {BoardSettingsPageContext} from '../../context'

const BoardColumnsActionDropdown = ({setShowModal, setDeleteModal, columnData}: any) => {
  const {boardColumnsList} = useContext(BoardSettingsPageContext)
  const {updateColumn} = useContext(BoardSettingsPageContext)

  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setDeleteModal(true)
    document.body.click()
  }

  const handleColumnVisibility = () => {
    updateColumn(columnData?.columnId, {isVisible: !columnData?.isVisible})
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={handleEditClick}
      >
        Edit
      </span>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={handleColumnVisibility}
      >
        {columnData?.isVisible ? 'Inactive' : 'Active'}
      </span>
      {columnData?.cards === 0 && boardColumnsList?.length > 1 && (
        <span
          onClick={handleDeleteClick}
          className='text-dark fw-bold text-hover-danger fs-6 px-2 py-1 cursor-pointer'
        >
          Delete
        </span>
      )}
    </div>
  )
}

export default BoardColumnsActionDropdown
