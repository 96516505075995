/* eslint-disable */
import {Dispatch, useCallback} from 'react'
import {$createCodeNode, $isCodeNode} from '@lexical/code'
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link'
import {$isListNode, ListNode} from '@lexical/list'
import {$isDecoratorBlockNode} from '@lexical/react/LexicalDecoratorBlockNode'
import {$isHeadingNode, $isQuoteNode} from '@lexical/rich-text'
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
} from '@lexical/selection'
import {$isTableNode, $isTableSelection} from '@lexical/table'
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  $isEditorIsNestedEditor,
} from '@lexical/utils'
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  LexicalEditor,
} from 'lexical'
import {getSelectedNode} from '../../../utils/getSelectedNode'
import {sanitizeUrl} from '../../../utils/url'
import {PLAYGROUND_TRANSFORMERS} from '../../MarkdownPlugin/MarkdownTransformers'
import {$convertFromMarkdownString, $convertToMarkdownString} from '@lexical/markdown'
import useToolbarStates from './useToolbarStates'
import {blockTypeToBlockName} from '../utils'
import {useSettings} from '../../../context/SettingsContext'
import {CODE_LANGUAGES} from '../../../editorSettings'
import {HTML_CONVERT_COMMAND} from '../../HtmlPlugin'

const useToolbarFunctions = (editor: LexicalEditor, setIsLinkEditMode: Dispatch<boolean>) => {
  const {
    blockType,
    rootType,
    selectedElementKey,
    fontSize,
    fontColor,
    bgColor,
    fontFamily,
    elementFormat,
    isLink,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    isSubscript,
    isSuperscript,
    isCode,
    canUndo,
    canRedo,
    isRTL,
    codeLanguage,
    isImageCaption,
    isMarkdown,
    isEditable,
    activeEditor,
    modal,
    isHtmlMode,
    showModal,
    setBlockType,
    setRootType,
    setSelectedElementKey,
    setFontSize,
    setFontColor,
    setBgColor,
    setFontFamily,
    setElementFormat,
    setIsLink,
    setIsBold,
    setIsItalic,
    setIsUnderline,
    setIsStrikethrough,
    setIsSubscript,
    setIsSuperscript,
    setIsCode,
    setCanUndo,
    setCanRedo,
    setIsRTL,
    setCodeLanguage,
    setIsImageCaption,
    setIsEditable,
    setIsMarkdown,
    setActiveEditor,
    setIsHtmlMode,
  } = useToolbarStates(editor)

  const {
    settings: {shouldPreserveNewLinesInMarkdown},
  } = useSettings()

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
        const rootElement = activeEditor.getRootElement()
        setIsImageCaption(
          !!rootElement?.parentElement?.classList.contains('image-caption-container')
        )
      } else {
        setIsImageCaption(false)
      }

      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      setIsRTL($isParentElementRTL(selection))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      const tableNode = $findMatchingParent(node, $isTableNode)
      if ($isTableNode(tableNode)) {
        setRootType('table')
      } else {
        setRootType('root')
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName)
          }
          if ($isCodeNode(element)) {
            const language: any = element.getLanguage()
            setCodeLanguage(language ? CODE_LANGUAGES[language]?.key || language : 'plain')
            return
          }
        }
      }
      // Handle buttons
      setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'))
      setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'))
      setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'))
      let matchingParent
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        )
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || 'left'
      )
    }
    if ($isRangeSelection(selection)) {
      // Update text format for range selection
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      if (selection) {
        setIsSubscript(selection.hasFormat('subscript'))
        setIsSuperscript(selection.hasFormat('superscript'))
        setIsCode(selection.hasFormat('code'))
        setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'))
      }
    }

    editor.update(() => {
      const firstChild = $getRoot().getFirstChild()
      if ($isCodeNode(firstChild) && firstChild.getLanguage() === 'html') {
        setIsHtmlMode(true)
        setCodeLanguage('html')
      } else if (!$isCodeNode(firstChild) || firstChild.getLanguage() !== 'html') {
        setIsHtmlMode(false)
      }
    })
  }, [activeEditor, editor])

  const handleMarkdownToggle = useCallback(() => {
    editor.update(() => {
      const root = $getRoot()
      const firstChild = root.getFirstChild()
      if ($isCodeNode(firstChild) && firstChild.getLanguage() === 'markdown') {
        $convertFromMarkdownString(
          firstChild.getTextContent(),
          PLAYGROUND_TRANSFORMERS,
          undefined,
          shouldPreserveNewLinesInMarkdown
        )
        setIsMarkdown(false)
      } else {
        const markdown = $convertToMarkdownString(
          PLAYGROUND_TRANSFORMERS,
          undefined,
          shouldPreserveNewLinesInMarkdown
        )
        const codeNode = $createCodeNode('markdown')
        codeNode.append($createTextNode(markdown))
        root.clear().append(codeNode)
        if (markdown.length === 0) {
          codeNode.select()
        }
        setIsMarkdown(true)
      }
    })
  }, [editor, shouldPreserveNewLinesInMarkdown])

  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      activeEditor.update(
        () => {
          const selection = $getSelection()
          if (selection !== null) {
            $patchStyleText(selection, styles)
          }
        },
        skipHistoryStack ? {tag: 'historic'} : {}
      )
    },
    [activeEditor]
  )

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const nodes = selection.getNodes()
        const extractedNodes = selection.extract()

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection, So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode
            }
            // We need this in case the selected text only has one format
            const extractedTextNode = extractedNodes[0]
            if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
              textNode = extractedTextNode
            }

            if (textNode.__style !== '') {
              textNode.setStyle('')
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0)
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat('')
            }
            node = textNode
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true)
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('')
          }
        })
      }
    })
  }, [activeEditor])

  const onFontColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({color: value}, skipHistoryStack)
    },
    [applyStyleText]
  )

  const onBgColorSelect = useCallback(
    (value: string, skipHistoryStack: boolean) => {
      applyStyleText({'background-color': value}, skipHistoryStack)
    },
    [applyStyleText]
  )

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true)
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
    } else {
      setIsLinkEditMode(false)
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [activeEditor, isLink, setIsLinkEditMode])

  const onCodeLanguageSelect = useCallback(
    (value: string) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey)
          if ($isCodeNode(node)) {
            node.setLanguage(value)
          }
        }
      })
    },
    [activeEditor, selectedElementKey]
  )

  const handleHtmlToggle = useCallback(() => {
    editor.update(() => {
      const root = $getRoot()
      const firstChild = root.getFirstChild()
      if (($isCodeNode(firstChild) && firstChild.getLanguage() === 'html') || isHtmlMode) {
        activeEditor.dispatchCommand(HTML_CONVERT_COMMAND, 'richtext')
        setIsHtmlMode(false)
        $updateToolbar()
      } else {
        activeEditor.dispatchCommand(HTML_CONVERT_COMMAND, 'html')
        setIsHtmlMode(true)
      }
    })
  }, [editor, activeEditor, setIsHtmlMode, $updateToolbar, isHtmlMode])

  return {
    blockType,
    rootType,
    selectedElementKey,
    fontSize,
    fontColor,
    bgColor,
    fontFamily,
    elementFormat,
    isLink,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    isSubscript,
    isSuperscript,
    isCode,
    canUndo,
    canRedo,
    isRTL,
    codeLanguage,
    isImageCaption,
    isMarkdown,
    isEditable,
    activeEditor,
    modal,
    isHtmlMode,
    showModal,
    setBlockType,
    setRootType,
    setSelectedElementKey,
    setFontSize,
    setFontColor,
    setBgColor,
    setFontFamily,
    setElementFormat,
    setIsLink,
    setIsBold,
    setIsItalic,
    setIsUnderline,
    setIsStrikethrough,
    setIsSubscript,
    setIsSuperscript,
    setIsCode,
    setCanUndo,
    setCanRedo,
    setIsRTL,
    setCodeLanguage,
    setIsImageCaption,
    setIsEditable,
    setIsMarkdown,
    setActiveEditor,
    setIsHtmlMode,
    $updateToolbar,
    onFontColorSelect,
    onBgColorSelect,
    insertLink,
    onCodeLanguageSelect,
    clearFormatting,
    handleMarkdownToggle,
    handleHtmlToggle,
  }
}

export default useToolbarFunctions
