import usePermission from '../../../hook/usePermission'
import {Pagination} from '../../../utils/pagination'
import {GlobalProductsContext} from '../contexts'
import {useGetRepresantetives} from '../../inquires/hooks/useGetRepresantetives'
import GlobalProductsTable from '../sections/tabels/GlobalProducts/GlobalProductsTable'
import GlobalProductsHeader from '../sections/headers/GlobalProductsHeader'
import useUpdateCustomerTag from '../hooks/useUpdateCustomerTag'
import {useGetBoProductsDropdownList} from '../../poRequest/hooks/useGetBoProductsDropdownList'
import {useGetAllStates} from '../hooks/useGetAllStates'
import useGetGlobalProductsData from '../hooks/useGetGlobalProductsData'

const GlobalProducts = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {
    isLoading,
    GlobalProductsDataData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onPageChange,
    pagination,
    onRepFilterChange,
    refetch,
    onApplySortingOnProduct,
    onProductFilterChange,
    onStateFilterChange,
    isFetchingCsvData,
    onDownloadCsv,
  } = useGetGlobalProductsData()
  const {Representatives} = useGetRepresantetives()
  const {updateCustomerTags} = useUpdateCustomerTag()
  const {boProductsDropdownList, isLoadingBoProductsDropdownList} = useGetBoProductsDropdownList()
  const {AllStateData, isLoadingStates} = useGetAllStates()

  const contextValue = {
    hasProjectSettingsPermission,
    isLoading,
    GlobalProductsData: GlobalProductsDataData,
    onSortingChange,
    onRepFilterChange,
    onSearch,
    searchValue,
    filters,
    refetch,
    Representatives,
    updateCustomerTags,
    boProductsDropdownList,
    isLoadingBoProductsDropdownList,
    handleProductOptionChange:onProductFilterChange,
    AllStateData,
    isLoadingStates,
    onStateFilterChange,
    onDownloadCsv,
    isFetchingCsvData,
    onApplySortingOnProduct,
  }

  return (
    <GlobalProductsContext.Provider value={contextValue}>
      <GlobalProductsHeader />
      <GlobalProductsTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </GlobalProductsContext.Provider>
  )
}

export default GlobalProducts
