export function handleDragStart(
  event: any,
  setUpdatedTaskDetails: any,
  setActiveTask: any,
) {
  const {active} = event
  const activeDataType = active?.data?.current?.type
  const initialSorting = active?.data?.current?.sortable?.items

  if (activeDataType === 'Task') {
    const activeTask = active?.data?.current?.task
    const activeTaskID = activeTask?.ticketID
    const initialModuleID = activeTask?.moduleID
    const initialcID = activeTask?.cID

    setActiveTask(activeTask)

    setUpdatedTaskDetails({
      activeTaskID,
      prevModuleID: initialModuleID,
      prevCID: initialcID,
      prevColumnSorting: initialSorting,
    })
  }
}
