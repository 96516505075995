import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import AddBoardModuleModal from '../modals/AddBoardModuleModal'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import BoardModulesActionDropdown from '../actionDropdowns/BoardModulesActionDropdown'
import {BoardSettingsPageContext} from '../../context'

const ModuleActionsOverlay = ({moduleData}: any) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const {deleteModule, isLoadingModulesOperation, isLoadingBoardModulesList, projectName} =
    useContext(BoardSettingsPageContext)

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <BoardModulesActionDropdown
            setShowModal={setShowModal}
            setDeleteModal={setDeleteModal}
            moduleData={moduleData}
          />
        }
      />

      {showModal && (
        <AddBoardModuleModal
          show={showModal}
          isEdit={true}
          moduleData={moduleData}
          onClose={() => setShowModal(false)}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={() => setDeleteModal(false)}
          onAction={() => deleteModule(moduleData?.moduleId)}
          isOperationLoading={isLoadingModulesOperation}
          isDataLoading={isLoadingBoardModulesList}
          body={
            <p>
              Do you really want to remove '<strong>{moduleData?.moduleName}</strong>' module from '
              <strong>{projectName}</strong>' project?
            </p>
          }
        />
      )}
    </>
  )
}

export default ModuleActionsOverlay
