import {ReactNode} from 'react'
import {Dropdown} from 'rsuite'
import type {TypeAttributes} from 'rsuite/esm/@types/common'
import 'rsuite/dist/rsuite.min.css'
import './reachMenu.scss'

interface MenuItemProps {
  eventKey: string
  icon?: React.ReactElement
  label: string
  disabled?: boolean
  className?: string
  style?: any
  show?: boolean
  onClick?: ({
    event,
    eventKey,
    selectedItem,
  }: {
    event?: React.SyntheticEvent
    eventKey?: string
    selectedItem?: MenuItemProps
  }) => void
}

type ButtonVariants =
  | 'light'
  | 'primary'
  | 'danger'
  | 'outline'
  | 'outline-dashed'
  | 'info'
  | 'success'
  | 'warning'
  | 'light-primary'
  | 'light-danger'
  | 'light-success'
  | 'light-warning'
  | 'light-info'
  | 'link'
  | 'body'

interface MenuProps {
  title: ReactNode
  items: MenuItemProps[]
  placement?: TypeAttributes.Placement
  onSelect?: ({
    eventKey,
    event,
    selectedItem,
  }: {
    eventKey?: string | null
    event?: React.SyntheticEvent
    selectedItem?: MenuItemProps
  }) => void
  className?: string
  disabled?: boolean
  noCaret?: boolean
  toggleClassName?: string
  toggleVariant?: ButtonVariants
  toggleSize?: 'sm' | 'md' | 'lg'
  activeVariant?: ButtonVariants
  show?: boolean
}

const DropdownMenu = ({
  title,
  items,
  placement = 'auto',
  onSelect,
  className = '',
  disabled = false,
  noCaret = false,
  toggleClassName = 'px-3',
  toggleVariant = 'body',
  toggleSize = 'sm',
  activeVariant = 'light',
  show = true,
}: MenuProps) => {
  if (!show) return null

  const handleItemSelect = (eventKey: string, event: React.SyntheticEvent) => {
    const selectedItem = items.find((item) => item.eventKey === eventKey)

    if (selectedItem?.onClick) {
      selectedItem.onClick({event, eventKey, selectedItem})
    }

    if (onSelect) {
      onSelect({eventKey, event, selectedItem})
    }
  }

  return (
    <Dropdown<any>
      title={title}
      placement={placement}
      onSelect={handleItemSelect}
      className={className}
      disabled={disabled}
      noCaret={noCaret}
      renderToggle={(props: any) => {
        return (
          <button
            className={`btn btn-${toggleVariant} btn-${toggleSize} btn-active-${activeVariant} ${toggleClassName}`}
            {...props}
          >
            {title}
          </button>
        )
      }}
    >
      {items
        .filter((item) => item.show !== false)
        .map((item) => (
          <Dropdown.Item
            key={item.eventKey}
            eventKey={item.eventKey}
            icon={<>{item.icon}</>}
            disabled={item.disabled}
            className={`${item.className || ''} d-flex align-items-center gap-2`}
            style={item.style}
          >
            {item.label}
          </Dropdown.Item>
        ))}
    </Dropdown>
  )
}

export default DropdownMenu
