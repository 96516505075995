/* eslint-disable */
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {useEffect, useState, useCallback} from 'react'
import {$getRoot, $isElementNode, $isTextNode, LexicalNode, TextNode} from 'lexical'
import {$getSelection, $isRangeSelection, $setSelection} from 'lexical'

type CharacterLimitPluginProps = {
  charset: 'UTF-8' | 'UTF-16'
  maxLength: number
  setCharacterCount: (count: number) => void
}

function getLength(str: string, charset: 'UTF-8' | 'UTF-16'): number {
  return charset === 'UTF-16' ? str.length : new Blob([str]).size
}

function calculateCharacterCount(node: LexicalNode, charset: 'UTF-8' | 'UTF-16'): number {
  if ($isTextNode(node)) {
    return getLength(node.getTextContent(), charset)
  }

  if ($isElementNode(node)) {
    return node
      .getChildren()
      .reduce((count, child) => count + calculateCharacterCount(child, charset), 0)
  }

  return 0
}

export function CharacterLimitPlugin({
  charset,
  maxLength,
  setCharacterCount: setCharCounter,
}: CharacterLimitPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  const [characterCount, setCharacterCount] = useState(0)

  const updateCharacterCount = useCallback(() => {
    editor.getEditorState().read(() => {
      const root = $getRoot()
      const count = root
        .getChildren()
        .reduce((acc, child) => acc + calculateCharacterCount(child, charset), 0)
      setCharacterCount(count)
      setCharCounter(count)
    })
  }, [editor, charset])

  useEffect(() => {
    return editor.registerUpdateListener(updateCharacterCount)
  }, [editor, updateCharacterCount])

  useEffect(() => {
    const applyStyleToExcessChars = () => {
      editor.update(() => {
        const root = $getRoot()
        let currentCount = 0
        const selection = $getSelection()
        let selectionOffset = $isRangeSelection(selection) ? selection.anchor.offset : 0

        const applyStyleRecursively = (node: LexicalNode): void => {
          if ($isTextNode(node)) {
            const text = node.getTextContent()
            const nodeLength = getLength(text, charset)
            const startIndex = currentCount
            const endIndex = currentCount + nodeLength

            if (startIndex < maxLength && endIndex > maxLength) {
              const splitIndex = maxLength - startIndex
              const firstPart = text.slice(0, splitIndex)
              const secondPart = text.slice(splitIndex)

              node.setTextContent(firstPart)
              node.setStyle('')

              const newNode = new TextNode(secondPart).setStyle('background-color: #FFBBBB')
              node.insertAfter(newNode)

              if (selectionOffset > splitIndex) {
                const newSelection = selection?.clone()
                if (newSelection && $isRangeSelection(newSelection)) {
                  newSelection.anchor.set(newNode.getKey(), selectionOffset - splitIndex, 'text')
                  newSelection.focus.set(newNode.getKey(), selectionOffset - splitIndex, 'text')
                  $setSelection(newSelection)
                }
              }
            } else if (startIndex >= maxLength) {
              node.setStyle('background-color: #FFBBBB')
            } else {
              node.setStyle('')
            }

            currentCount += nodeLength
          } else if ($isElementNode(node)) {
            node.getChildren().forEach(applyStyleRecursively)
          }
        }

        root.getChildren().forEach(applyStyleRecursively)
      })
    }

    applyStyleToExcessChars()
  }, [editor, maxLength, charset, characterCount])

  const remainingCharacters = maxLength - characterCount

  return (
    <div
      className='character-count'
      style={{
        position: 'absolute',
        bottom: '5px',
        left: '10px',
        fontSize: '12px',
        color: remainingCharacters < 0 ? 'red' : '#888',
      }}
    >
      {remainingCharacters >= 0
        ? `${remainingCharacters}`
        : `(${remainingCharacters}) Max limit reached`}
    </div>
  )
}
