/* eslint-disable */
import {useForm} from 'react-hook-form'
import {SectionForm} from '../../../components/SectionForm'
import {BoardSettingsPageContext, ProjectDetailsContext} from '../context'
import {useContext} from 'react'
import {projectMappingDefaultValues, useChangeProjectMappingValues} from '../utils'

const ProjectMappingSection = () => {
  const {
    hasBoardSettingsPermission,
    projectTablesDropdownList,
    isLoadingProjectTablesDropdownList,
    tableColumnsDropdownList,
    isLoadingTableColumnsDropdownList,
    projectMembers,
    isLoadingProjectMembers,
    updateProjectMapping,
    isLoadingProjectMappingOperations,
  } = useContext(BoardSettingsPageContext)
  const {projectMappingDetails, isLoading} = useContext(ProjectDetailsContext)

  const {
    register,
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: {isDirty},
  } = useForm<any>({
    defaultValues: projectMappingDefaultValues(projectMappingDetails),
  })

  const {projectType, tableId, columnKey} = watch()
  const isCustomProject = projectType === 'custom'
  const isProjectSelected = !!tableId
  const isColumnSelected = !!columnKey

  const onSubmit = (data: any) => {
    updateProjectMapping(data)
  }

  useChangeProjectMappingValues(reset, watch(), isDirty)

  return (
    <div className='d-flex flex-column gap-7'>
      <SectionForm
        id='project-mapping'
        title='Project Mapping'
        titleClass='mb-7'
        className='row col-md-6'
        commonClassNames='d-flex gap-3 mb-5 align-items-center'
        commonLabelClass='col-md-3 mb-0'
        commonInputClass='col-md-4'
        register={register}
        control={control}
        isDisabled={isLoading || !hasBoardSettingsPermission || isLoadingProjectMappingOperations}
        inputs={[
          {
            id: 'project-type',
            label: 'Project Type',
            isRadio: true,
            registerKey: 'projectType',
            isLoading: isLoading,
            children: [
              {
                id: 'project-type-standard',
                label: 'Standard',
                value: 'standard',
                name: 'projectType',
              },
              {
                id: 'project-type-custom',
                label: 'Custom',
                value: 'custom',
                name: 'projectType',
                colClass: 'me-5',
              },
            ],
          },
          {
            id: 'project-table',
            label: 'Project Table',
            isReachSelect: true,
            isNullable: true,
            show: isCustomProject,
            options: projectTablesDropdownList,
            isLoading: isLoadingProjectTablesDropdownList,
            registerKey: 'tableId',
            labelKey: 'lable',
            valueKey: 'id',
            placeholderValue: 0,
            valueType: 'number',
            onChange: () => {
              setValue('columnKey', '')
            },
          },
          {
            id: 'select-project-column',
            label: 'Select Column',
            isReachSelect: true,
            isNullable: true,
            show: isProjectSelected,
            options: tableColumnsDropdownList,
            labelKey: 'column_label',
            valueKey: 'column_key',
            registerKey: 'columnKey',
            isLoading: isLoadingTableColumnsDropdownList,
            disabled: isLoadingTableColumnsDropdownList,
          },
        ]}
      />

      {isColumnSelected && (
        <SectionForm
          id='ticket-setting'
          title='Ticket Setting'
          titleClass='mb-7'
          className='row col-md-6'
          commonClassNames='d-flex gap-3 mb-5 align-items-center'
          commonLabelClass='col-md-3 mb-0'
          commonInputClass='col-md-4'
          control={control}
          isDisabled={isLoading || !hasBoardSettingsPermission || isLoadingProjectMappingOperations}
          inputs={[
            {
              id: 'ticket-name',
              label: 'Ticket Name',
              isReachSelect: true,
              options: tableColumnsDropdownList,
              labelKey: 'column_label',
              valueKey: 'column_key',
              disabled: isLoadingTableColumnsDropdownList,
              registerKey: 'ticketName',
              isNullable: true,
            },
            {
              id: 'default-assignee',
              label: 'Default Assignee',
              isReachSelect: true,
              options: projectMembers,
              labelKey: 'name',
              valueKey: 'username',
              disabled: isLoadingProjectMembers,
              registerKey: 'defaultAssignee',
              isNullable: true,
            },
          ]}
        />
      )}

      {hasBoardSettingsPermission &&
        ((isDirty && isColumnSelected) ||
          (!isDirty && projectMappingDetails?.projectType !== projectType)) && (
          <div className='row'>
            <div className='d-flex mt-4 border-top col-md-4 pt-5'>
              <button
                className='btn btn-primary'
                disabled={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {!isLoadingProjectMappingOperations && 'Save Changes'}
                {isLoadingProjectMappingOperations && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Saving...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        )}
    </div>
  )
}

export default ProjectMappingSection
