import {createContext} from 'react'

export const ProjectsListPageContext = createContext({
  hasProjectsPermission: false,
  isActiveProjects: true,
  projectsList: [],
  isLoading: false,
  isOperationLoading: false,
  onSearch: (e: string) => {},
  searchValue: '',
  filters: {},
  businessUnitsList: [],
  isLoadingBusinessUnits: false,
  createNewProjectDetails: (projectDetails: any, setError: Function) => {},
  updateProjectDetails: (
    updatedProjectDetails: any,
    projectId: number,
    dirtyFields?: any,
    setError?: Function
  ) => {},
  refetchProjectsList: () => {},
  onSortingChange: (sortBy: string, sortDirection: any) => {},
  addProjectToFavorite: (projectId: string) => {},
  deleteProject: (projectId: string) => {},
})
