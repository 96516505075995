import axios from 'axios'
import store from '../redux/store'

class ApiV2 {
  instance: any
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP__AD_API_URL,
    })

    this.instance.interceptors.request.use(
      function (req: any) {
        const user: any = store.getState()?.auth?.user
        const access_token: string = user?.['access_token']
        const storeId = localStorage.getItem('x-store-id')

        if (access_token) {
          req.headers['X-AUTH-TOKEN'] = access_token
          req.headers['HTTP_ORIGIN'] = process.env.REACT_APP_HTTP_ORIGIN
          req.headers['X-STORE-ID'] = storeId
          req.headers['X-TENANT-ID'] = user['tenant_id']
        }
        return req
      },
      function (error: any) {
        return Promise.reject(error)
      }
    )

    this.instance.interceptors.response.use(
      function (res: any) {
        return res
      },
      function (error: any) {
        if (error?.response?.status === 403) {
          window.location.replace('/error/403')
        }

        return Promise.reject(error)
      }
    )
  }

  get(endpoint: string, params: any = {}, config: any = {}) {
    return this.instance
      .get(endpoint, {
        ...config,
        params,
      })
      .catch((error: any) => {
        console.error(error, config)
        return error.response
      })
  }

  delete(endpoint: string, data: any, params: any = {}, config: any) {
    return this.instance.delete(endpoint, {...config, data, params}).catch((error: any) => {
      console.error(error, config)
      return error.response
    })
  }

  post(endpoint: string, data: any, params: any = {}, config: any) {
    return this.instance.post(endpoint, data, config).catch((error: any) => {
      console.error(error, config)
      return error.response
    })
  }

  put(endpoint: string, data: any, params: any = {}, config: any) {
    return this.instance.put(endpoint, data, config).catch((error: any) => {
      console.error(error, config)
      return error.response
    })
  }

  patch(endpoint: string, data: any, params: any = {}, config: any) {
    return this.instance.patch(endpoint, data, config).catch((error: any) => {
      console.error(error, config)
      return error.response
    })
  }
}

// Assign the class instance to a variable
const apiV2 = new ApiV2()

export default apiV2
