import {useState, useEffect} from 'react'

const AgileBoardAccordion = ({items, defaultOpen, isLoading}: any) => {
  const [activeIds, setActiveIds] = useState<string[]>([])

  useEffect(() => {
    if (!items || items.length === 0) {
      return
    }

    if (defaultOpen === 'All') {
      setActiveIds(items.map((item: any) => item.id))
    } else if (defaultOpen === false) {
      setActiveIds([])
    } else if (Array.isArray(defaultOpen)) {
      setActiveIds(defaultOpen.map(String))
    }
  }, [defaultOpen, items])

  const handleToggle = (id: string) => {
    if (activeIds.includes(id)) {
      setActiveIds(activeIds.filter((i) => i !== id))
    } else {
      setActiveIds([...activeIds, id])
    }
  }

  return (
    <div className='d-flex flex-column accordion'>
      {(!items || items.length === 0) && !isLoading ? (
        <div className='fw-bold text-center'>No data found...</div>
      ) : (
        items.map((item: any) => (
          <div key={item.id} className='board-section-item mb-7 pb-7 border-bottom border-gray-300'>
            <h2 className='accordion-header'>
              <button
                className={`accordion-buttton d-flex w-100 rounded bg-white rounded-1 py-5 px-0 border border-1 border-white text-dark fs-5 fw-bolder ${
                  activeIds.includes(item.id) ? '' : 'collapsed'
                }`}
                type='button'
                onClick={() => handleToggle(item.id)}
              >
                <span>
                  {activeIds.includes(item.id) ? (
                    <i className='fa-solid fa-angle-up fs-4'></i>
                  ) : (
                    <i className='fa-solid fa-angle-down fs-4'></i>
                  )}
                </span>
                <span className='ms-3'>{item.title}</span>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${activeIds.includes(item.id) ? 'show' : ''}`}
            >
              <div className='accordion-body p-0'>{item.content}</div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default AgileBoardAccordion
