/* eslint-disable react-hooks/exhaustive-deps */
import {useContext} from 'react'
import {InquiresPageListingContext} from '../context'
import Search from '../../../components/Search'
import {defaultColumns} from '../utils'
import {TableSettingsMenu} from '../../../components/tableSettingsMenu'
import {useAppSelector} from '../../../redux/useTypedSelector'

function InquiresPageListingHeader() {
  const {user} = useAppSelector((state) => state.auth)
  const {onSearch, Representatives, onChangeCustomerFilter, refetch} = useContext(
    InquiresPageListingContext
  )

  function hasAccess() {
    const unrestrictedUsers = [
      'umair@midwestgoods.com',
      'naseer@midwestgoods.com',
      'dan.c@midwestgoods.com',
      'imran@midwestgoods.com',
      'luket@atlantixdigital.com',
    ]

    const emailIds = Representatives?.map((item: any) => item.value)?.filter(
      (email: any) => email !== null
    )

    const canViewAllTickets =
      unrestrictedUsers.includes(user.username) || !emailIds?.includes(user.username)

    if (user.role === 'Customer Representative' && !canViewAllTickets) {
      return {
        canViewAllTickets: false,
        isFilterReadOnly: true,
        repEmail: user.username,
      }
    }

    if (canViewAllTickets) {
      return {
        canViewAllTickets: true,
        isFilterReadOnly: false,
        repEmail: '',
      }
    }

    return {
      canViewAllTickets: false,
      isFilterReadOnly: false,
      repEmail: emailIds?.includes(user.username) ? user.username : '',
    }
  }

  const accessPermissions = hasAccess()

  const handleChange = (e: any) => {
    onChangeCustomerFilter(e.target.value)
  }

  return (
    <div className='d-flex mb-10 justify-content-between'>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch(searchTerm)
        }}
      />
      <div className='d-flex align-items-center'>
        <div className='me-5'>
          <select
            className='form-select'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            defaultValue={accessPermissions?.repEmail || ''}
            onChange={handleChange}
            disabled={accessPermissions.repEmail}
          >
            <option value=''>Representative: All</option>
            {Representatives &&
              Representatives.map((item: any, index: number) => (
                <option
                  key={index}
                  value={item.value}
                  selected={accessPermissions.repEmail === item.value}
                >
                  {item.label}
                </option>
              ))}
          </select>
        </div>
        <TableSettingsMenu
          defaultColumns={defaultColumns}
          permissionPath='products_product inquiries'
          refetch={refetch}
          type={'product_inquiries'}
        />
      </div>
    </div>
  )
}

export default InquiresPageListingHeader
