/* eslint-disable */
import {useContext, useEffect} from 'react'
import { TextEditorContext } from '../../context/TextEditorContext'

const CheckValidationsPlugin = () => {
  const {charCount, setIsValid, maxLength} = useContext(TextEditorContext)

  useEffect(() => {
    maxLength && charCount > maxLength ? setIsValid(false) : setIsValid(true)
  }, [charCount, maxLength])

  return null
}

export default CheckValidationsPlugin
