/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from 'react'
import {GlobalProductsContext} from '../../../contexts'
import {toggleSortingOrder} from '../../../../../utils/common'
import {RenderBothArrow} from '../../../../../utils/renderBothArrow'
import NoDataFound from '../../../../../components/NoDataFound'
import Loading from '../../../../loading'
import GlobalProductsTableRow from './GlobalProductsTableRow'

const GlobalProductsTable = () => {
  const {filters, onSortingChange, isLoading, GlobalProductsData, onApplySortingOnProduct} =
    useContext(GlobalProductsContext)
  const [direction, setDirection] = useState<any>('asc')
  const [sortBy, setSortBy] = useState('')
  const [currentColumnIndex, setCurrentColumnIndex] = useState(null)

  useEffect(() => {
    if (filters) {
      const array = filters['sort_by']?.split('/')
      setSortBy(array[0])
      if (array[1] === '1') {
        setDirection('asc')
      } else {
        setDirection('desc')
      }
    }
  }, [filters])

  return (
    <div className='card-body p-0'>
      <div className='table-modes '>
        <div className='table-responsive-wrapper'>
          <div className='table-responsive position-relative'>
            <div className='table-loader-wrapper'>
              <table
                id='kt_datatable_vertical_scroll'
                className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 mb-15'
              >
                <thead className='table-row-bordered'>
                  <tr className='fs-6 fw-semibold text-muted border-bottom-0'>
                    <td colSpan={5}></td>
                    {GlobalProductsData?.[0]?.products?.map((product: any, index: any) => (
                      <td colSpan={3} className='text-start border-start'>
                        <div className='position-relative'>
                          <span className='app-default-tooltip-area position-relative w-100'>
                            {product?.product_name?.length > 60
                              ? `BOP #${product?.bop_id} : ${product?.product_name.slice(0, 60)}...`
                              : product?.product_name}
                            <span className='popover app-default-tooltip fw-semibold lh-2 fs-7 text-dark text-start mw-300px text-wrap'>
                              BOP #{product?.bop_id} : {product.product_name}
                            </span>
                          </span>
                        </div>
                      </td>
                    ))}
                  </tr>

                  <tr className='fs-6 fw-semibold text-muted text-uppercase'>
                    <th
                      onClick={() => {
                        onSortingChange('name', toggleSortingOrder(direction))
                        onApplySortingOnProduct('')
                      }}
                      className='min-w-250px'
                    >
                      <div className='d-flex align-items-center'>
                        <span className='me-2'>Customer Name</span>
                        {direction === 'asc' && sortBy === 'name' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'name' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => {
                        onSortingChange('company_name', toggleSortingOrder(direction))
                        onApplySortingOnProduct('')
                      }}
                      className='min-w-200px'
                    >
                      <div className='d-flex align-items-center'>
                        <span className='me-2'>Company Name</span>
                        {direction === 'asc' && sortBy === 'company_name' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'company_name' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => {
                        onSortingChange('state', toggleSortingOrder(direction))
                        onApplySortingOnProduct('')
                      }}
                      className='min-w-120px'
                    >
                      <div className='d-flex align-items-center'>
                        <span className='me-2'>State</span>
                        {direction === 'asc' && sortBy === 'state' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'state' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => {
                        onSortingChange('rep_name', toggleSortingOrder(direction))
                        onApplySortingOnProduct('')
                      }}
                      className='min-w-150px'
                    >
                      <div className='d-flex align-items-center'>
                        <span className='me-2'>Customer Rep</span>
                        {direction === 'asc' && sortBy === 'rep_name' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'rep_name' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => {
                        onSortingChange('lifetime_order_total', toggleSortingOrder(direction))
                        onApplySortingOnProduct('')
                      }}
                      className='min-w-150px'
                    >
                      <div className='d-flex align-items-center'>
                        <div>
                          <span className='app-default-tooltip-area position-relative w-100 '>
                            <span className='me-2'> CLV</span>
                            <span className='popover app-default-tooltip fw-semibold lh-2 fs-7 text-dark text-start mw-300px text-wrap'>
                              Customer Lifetime Revenue
                            </span>
                          </span>
                        </div>
                        {direction === 'asc' && sortBy === 'lifetime_order_total' ? (
                          <i className='bi bi-caret-up-fill fs-8'></i>
                        ) : direction === 'desc' && sortBy === 'lifetime_order_total' ? (
                          <i className='bi bi-caret-down-fill fs-8'></i>
                        ) : (
                          <RenderBothArrow />
                        )}
                      </div>
                    </th>
                    {GlobalProductsData?.[0]?.products?.map((product: any, index: any) => (
                      <>
                        <th
                          key={`orders-${index}`}
                          onClick={() => {
                            onSortingChange(`product_order_count`, toggleSortingOrder(direction))
                            onApplySortingOnProduct(product?.bop_id)
                            setCurrentColumnIndex(index)
                          }}
                          className='min-w-100px border-start'
                        >
                          <div className='d-flex align-items-center justify-content-center'>
                            <span className='me-2'>Orders</span>
                            {sortBy === `product_order_count` && currentColumnIndex === index ? (
                              direction === 'asc' ? (
                                <i className='bi bi-caret-up-fill fs-8'></i>
                              ) : (
                                <i className='bi bi-caret-down-fill fs-8'></i>
                              )
                            ) : (
                              <RenderBothArrow />
                            )}
                          </div>
                        </th>
                        <th
                          key={`revenue-${index}`}
                          onClick={() => {
                            onSortingChange(`product_order_total`, toggleSortingOrder(direction))
                            onApplySortingOnProduct(product?.bop_id)
                            setCurrentColumnIndex(index)
                          }}
                          className='min-w-100px'
                        >
                          <div className='d-flex align-items-center'>
                            <span className='me-2'>Revenue</span>
                            {sortBy === `product_order_total` && currentColumnIndex === index ? (
                              direction === 'asc' ? (
                                <i className='bi bi-caret-up-fill fs-8'></i>
                              ) : (
                                <i className='bi bi-caret-down-fill fs-8'></i>
                              )
                            ) : (
                              <RenderBothArrow />
                            )}
                          </div>
                        </th>
                        <th
                          key={`product_last_order_date-${index}`}
                          onClick={() => {
                            onSortingChange(
                              `product_last_order_date`,
                              toggleSortingOrder(direction)
                            )
                            onApplySortingOnProduct(product?.bop_id)
                            setCurrentColumnIndex(index)
                          }}
                          className='min-w-175px'
                        >
                          <div className='d-flex align-items-center'>
                            <span className='me-2'>Last Order Date</span>
                            {sortBy === `product_last_order_date` &&
                            currentColumnIndex === index ? (
                              direction === 'asc' ? (
                                <i className='bi bi-caret-up-fill fs-8'></i>
                              ) : (
                                <i className='bi bi-caret-down-fill fs-8'></i>
                              )
                            ) : (
                              <RenderBothArrow />
                            )}
                          </div>
                        </th>
                      </>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {GlobalProductsData && GlobalProductsData?.length > 0
                    ? GlobalProductsData?.map((row: any) => (
                        <GlobalProductsTableRow
                          row={row}
                          key={row['id']}
                          meta={GlobalProductsData?.[0]}
                        />
                      ))
                    : !isLoading && <NoDataFound colspan={5} />}
                  {isLoading && <Loading />}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GlobalProductsTable
