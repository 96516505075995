import {useContext, useState} from 'react'
import {TimeSheetReportPageContext} from '../../context'
import {SideFilter} from '../../../../components/SideFilter'
import useSaveFilter from '../../hooks/useSaveFilter'
import PreDefinedDateRange from '../../../../components/DateRangePicker/PreDefinedDateRange'

const TimeSheetHeader = () => {
  const {onDateChange, filtersData, refetch, isLoadingTimeSheetData} = useContext(
    TimeSheetReportPageContext
  )
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {isOperationLoading, onSaveFilter} = useSaveFilter(refetch)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div></div>
      <div className='d-flex align-items-center gap-5'>
        <PreDefinedDateRange
          cb={onDateChange}
          exclude={['Current Year', 'Today', 'Yesterday', 'Last 7 Days']}
          maxRange={90}
          defaultValue={'Last 30 Days'}
          includeCurrantDay={true}
        />
        <div>
          <SideFilter
            filterData={filtersData}
            onApply={onSaveFilter}
            isOperationLoading={isOperationLoading || isLoadingTimeSheetData}
            onCancel={() => setIsDrawerOpen(false)}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            filterType={'individual_project_timesheet_report'}
          />
        </div>
      </div>
    </div>
  )
}

export default TimeSheetHeader
