import Chart from '../components/GanttChart'
import {GanttChartPageContext} from '../context'
import useGetGanttChartData from '../hooks/ganttChart/useGetGanttChartData'

const GanttChart = () => {
  const {ganttChartData, isLoading} = useGetGanttChartData()

  const contextValue = {
    ganttChartData,
    isLoading,
  }

  return (
    <GanttChartPageContext.Provider value={contextValue}>
      <Chart />
    </GanttChartPageContext.Provider>
  )
}

export default GanttChart
