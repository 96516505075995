import React from 'react'
import {Link} from 'react-router-dom'
import Initials from '../../../../../components/Initials'
import {formatPrice} from '../../../../../utils/common'
import ActionsOverlay from '../../overlayModalDropdowns/ActionsOverlay'
import Date from '../../../../../components/Date/Date'

const GlobalProductsTableRow = ({row, meta, actionComponent}: any) => {
  return (
    <tr>
      {/* Customer Name */}
      <td>
        <Link
          to={`/customers/all-customer/details?id=${row.customer_id}`}
          state={{
            id: row.customer_id,
          }}
          className='d-flex align-items-center flex-grow-1 text-start cursor-pointer'
        >
          <div className='symbol symbol-circle symbol-35px me-3'>
            <Initials text={row?.name?.[0]} />
          </div>
          <div className='d-flex flex-column'>
            <div className='align-items-center text-dark fw-semibold'>{row.name}</div>
            <div className='align-items-center text-muted'>{row.email}</div>
          </div>
        </Link>
      </td>

      {/* Company Name */}
      <td>{row.company_name || '-'}</td>

      {/* State */}
      <td>{row.state || '-'}</td>

      {/* Customer Representative */}
      <td>{row.rep_name || '-'}</td>

      {/* CLV */}
      <td>{formatPrice(row.lifetime_order_total || 0, false)}</td>

      {/* Dynamic Product Columns */}
      {meta?.products?.map((product: any, index: any) => (

        <>
          <td className='text-center border-start'>{row.products?.[index]?.product_order_count || '-'}</td>
          <td>{formatPrice(row.products?.[index]?.product_order_total || 0, false)}</td>
          <td>
            {row.products?.[index]?.product_last_order_date ? (
              <Date date={row.products?.[index]?.product_last_order_date} />
            ) : (
              '-'
            )}
          </td>
        </>
      ))}

      {/* Actions Column */}
      {actionComponent && (
        <td>
          <ActionsOverlay customerId={row?.customer_id} />
        </td>
      )}
    </tr>
  )
}

export default GlobalProductsTableRow
