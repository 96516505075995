import {useState} from 'react'
import useApi from '../../../../services/useApi'

export default function useGetProjectsTeamsOperations() {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  async function addProjectTeams(globalProjectTeamsDetails: any, setError: any) {
    return Api.post(`/agile/projects/teams`, globalProjectTeamsDetails, {
      successMessage: 'Team Created successfully',
      setUseFormError: setError,
      setLoading: setIsLoading,
      refetch: ['projects-teams'],
    })
  }

  async function updateProjectTeam(teamId: number, updateData: any, setError: any) {
    return Api.patch(`/agile/projects/teams/${teamId}`, updateData, {
      successMessage: 'Team updated successfully',
      setUseFormError: setError,
      setLoading: setIsLoading,
      refetch: ['projects-teams'],
    })
  }

  async function removeProjectTeams(teamId: string) {
    return Api.delete(
      `/agile/projects/teams/${teamId}`,
      {},
      {
        successMessage: 'Team removed successfully',
        setLoading: setIsLoading,
        refetch: ['projects-teams'],
      }
    )
  }

    return {
      addProjectTeams,
      removeProjectTeams,
      updateProjectTeam,
      isLoadingProjectsTeamsOperation: isLoading,
    }
}
