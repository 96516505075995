import React from 'react'
import {simulateEvent} from '../utils/form'

interface SelectOption {
  label: string
  value: any
}

interface SelectProps {
  id?: string
  name: string
  value: any
  label: string
  options?: SelectOption[] | undefined
  isRequired?: Boolean
  disabled?: boolean | undefined
  formLabel?: string
  touched?: any
  errors?: any
  props?: any
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

function Select({
  id,
  name,
  value,
  label,
  onChange,
  disabled = false,
  isRequired = false,
  options,
  formLabel = '',
  errors,
  touched,
  ...props
}: SelectProps) {
  const handleChange = (e: any) => {
    if (name) {
      onChange && onChange(simulateEvent(name, e.target.value))
    }
  }

  return (
    <>
      <label className={`form-label ${isRequired ? 'required' : ''}`} form-label={formLabel}>
        {label}
      </label>

      <select
        className={`form-select ${errors && errors[name] ? 'is-invalid' : ''}`}
        data-control='select2'
        data-placeholder='Select an option'
        data-hide-search='true'
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        {...props}
      >
        {options &&
          options.length &&
          options.map((option: any) => {
            return (
              <option key={option['label']} value={option.value}>
                {option.label}
              </option>
            )
          })}
      </select>

      {errors && errors[name] && errors[name]['message'] ? (
        <div className='form-error invalid-feedback'>{errors[name]['message']}</div>
      ) : null}
    </>
  )
}

export default Select
