import {useState} from 'react'
import OverlayComponent from '../../../../../_metronic/layout/components/Popover'
// import {UpdateBlogModal} from './UpdateBlogModal'
// import DeleteConfirmationModal from './DeleteConfirmationModal'
import HideProductActionDropdown from '../dropdown/HideProductActionDropdown'
import { DeleteUserConfirmationModal } from './DeleteUserConfirmationModal'
import UpdateModal from './UpdateModal'

const OverlayModal = ({is_product, sku, outOfDays, thresholdQty, promotion_id, promotion_disable_date, hide_out_of_stock_days, hide_category_id, hide_classification, hide_product, prefix, customer_rep, disable_promotion}: any) => {
    const [openModal, setOpenModal] = useState(false)
    const [modal, setModal] = useState(false)
  const handleModalOpen = () => {
    setOpenModal(true)
    document.body.click()
  }

  const onEdit = () => {
    setModal(true)
    document.body.click()
  }

  const onClose = () => {
    setModal(false)
    setOpenModal(false)
  }
  
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <HideProductActionDropdown onEdit={onEdit} handleModel={handleModalOpen} />
        }
        rootClose={true}
      />
       <UpdateModal show={modal} onClose={onClose} sku={sku} outOfDays={outOfDays} thresholdQty={thresholdQty} promotion_id={promotion_id} promotion_disable_date={promotion_disable_date} hide_out_of_stock_days={hide_out_of_stock_days}  hide_category_id={hide_category_id}  hide_classification={hide_classification} hide_product={hide_product} prefix={prefix} customer_rep={customer_rep} disable_promotion={disable_promotion} />
      <DeleteUserConfirmationModal show={openModal} onClose={onClose} sku={sku} is_product={is_product} />
    </>
  )
}

export default OverlayModal
