import {useContext} from 'react'
import {CommentsTab} from '../sections/tabs/CommentsTab'
import {ActivitiesTab} from '../sections/tabs/ActivitiesTab'
import SpentTimeTab from '../sections/tabs/SpentTimeTab'
import {TicketDetailsPageContext} from '../context'
import Navigation from '../../../components/Navigation/Navigation'

function CommentSectionNavigation() {
  const {ticketSpentTimeHistory} = useContext(TicketDetailsPageContext)

  return (
    <Navigation
      baseUrl={window.location.pathname}
      className='mt-10 lh-2 fs-6'
      navigationData={[
        {
          key: 'comments',
          label: 'Comments',
          component: <CommentsTab />,
        },
        {
          key: 'spent-time',
          label: 'Spent Time',
          show: ticketSpentTimeHistory?.length > 0,
          component: <SpentTimeTab />,
        },
        {
          key: 'history',
          label: 'History',
          component: <ActivitiesTab />,
        },
      ]}
    />
  )
}

export default CommentSectionNavigation
