import Date from '../../../../components/Date/Date'
import { getBadgeColor } from '../../../../utils/badge'
import parseUnHideProductRules from '../../services/UnhideProducts'
// import OverlayModal from '../modal/OverlayModal'

function ExecutedRuleTableRow({row, key}: any) {
  const product = parseUnHideProductRules(row)
  return (
    <>
      <tr key={key}>
        <td>
          <div className='align-items-center'>{product['product_name']}</div>
        </td>
       
        <td>
          <div className='align-items-center'>{product['product_id']}</div>
        </td>
       
        <td>
          <Date date={row['executed_date']} />
        </td>
        <td>
          <span className={`badge ${getBadgeColor(product['is_executed'], 'light')} badge-lg`}>
            <div className='align-items-center'>{product['is_executed']}</div>
          </span>
        </td>

        {/* <td>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <OverlayModal id={product['product_id']} />
          </div>
        </td> */}
      </tr>
    </>
  )
}

export default ExecutedRuleTableRow
