import {Link, useLocation} from 'react-router-dom'
import TopConsignmentVendorsTableRow from './TopConsignmentVendorsTableRow'
import {DynamicTable} from '../../../../components/DynamicTable'
import {useContext} from 'react'
import {AggregatedVendorsTablePageContext} from '../../contexts'

const TopConsignmentVendorsTable = ({vendorsList, isLoading}: any) => {
  const {filters, onSortingChange} = useContext(AggregatedVendorsTablePageContext)
  const location = useLocation()
  const length = location?.pathname?.split('/')?.length
  const isTableView = location?.pathname?.split('/')?.[length - 1] !== 'dashboard'

  const columns = [
    {key: 'name', label: 'Name', headerStyle: 'min-w-250px'},
    {key: 'email', label: 'Email', headerStyle: 'min-w-200px'},
    {key: 'rep_name', label: 'Representative', headerStyle: 'min-w-150px'},
    {
      key: 'total_amount',
      label: 'Order Amount',
      headerStyle: 'min-w-150px',
      isSorted: isTableView ? true : false,
    },
    {
      key: 'total_due_amount',
      label: 'Pending Amount',
      headerStyle: 'min-w-100px',
      isSorted: isTableView ? true : false,
    },
  ]

  return (
    <div className={`${isTableView ? '' : 'card-body  card card-flush  border p-7'}`}>
      <div
        className={`d-flex align-items-center mb-10 ${
          isTableView ? 'justify-content-end' : 'justify-content-between'
        }`}
      >
        {!isTableView && <h2 className='text-dark fw-bolder'>Top 10 Vendors</h2>}
      </div>

      <DynamicTable
        data={
          vendorsList && vendorsList.length > 0
            ? vendorsList.slice(0, isTableView ? vendorsList.length : 10)
            : []
        }
        sortableColumns={columns}
        TableRow={TopConsignmentVendorsTableRow}
        loading={isLoading}
        filters={filters}
        onSortingChange={onSortingChange}
      />
      {!isTableView && (
        <>
          <div className='mt-2'>
            <Link
              to='/analytics/consignments/vendors'
              className='btn btn-link btn-color-black btn-active-color-primary text-primary text-hover-dark text-decoration-underline'
            >
              View More
            </Link>
          </div>
        </>
      )}
    </div>
  )
}

export {TopConsignmentVendorsTable}
