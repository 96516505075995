import {Pagination} from '../../../utils/pagination'
import useMeta from '../../../hook/useMeta'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import useGetConsignmentVendorsListing from '../hooks/useGetConsignmentVendorsListing'
import {TopConsignmentVendorsTable} from '../components/table/TopConsignmentVendorsTable'
import {AggregatedVendorsTablePageContext} from '../contexts'

const AggregatedVendorsTablePageBreadCrumb: Array<PageLink> = [
  {
    title: 'Consignments',
    path: `/analytics/consignments/dashboard`,
    isSeparator: false,
    isActive: false,
  },
]

const AggregatedVendorsTablePage = () => {
  useMeta('All Consignment Vendors')

  const {vendorsList, isLoading, filters, pagination, onPageChange, onSortingChange} =
    useGetConsignmentVendorsListing()

  const contextValue = {
    filters,
    onSortingChange,
  }

  return (
    <AggregatedVendorsTablePageContext.Provider value={contextValue}>
      <PageTitle breadcrumbs={AggregatedVendorsTablePageBreadCrumb}>Vendors</PageTitle>
      <TopConsignmentVendorsTable vendorsList={vendorsList} isLoading={isLoading} />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </AggregatedVendorsTablePageContext.Provider>
  )
}

export default AggregatedVendorsTablePage
