import { KTCard } from '../../../_metronic/helpers'
import ExecutedRuleTable from './table/ExecutedRuleTable'
import { ExecutedProductRuleHeader } from './header/ExecutedProductRuleHeader'
import { ExecutedProductRulesPageContext } from '../contexts'
import useGetExecutedProductRules from '../hooks/useGetExecutedProductRules'
import { Pagination } from '../../../utils/pagination'

function ExecutedRuleContent() {

  const {executedRules, onPageChange, pagination, onSearch, onSortingChange, isFetching, filters, refetch} = useGetExecutedProductRules()

  const contextValue = {
    executedRules: executedRules,
    onPageChange,
    onSearch,
    onSortingChange,    
    pagination,
    refetch,    
    isFetching,
    filters,
  }

  return (
    <ExecutedProductRulesPageContext.Provider value={contextValue}>
      <KTCard>
        <ExecutedProductRuleHeader />
        <ExecutedRuleTable />        
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </KTCard>
    </ExecutedProductRulesPageContext.Provider>
  )
}

export default ExecutedRuleContent
