const Loading = () => {
  return (
    <div className='table-overlay theme-custom-loading'>
      <div className='table-loading-inner'>
        <span className='spinner-border text-primary' role='status'></span>
        <span className='text-gray-800 fs-6 fw-semibold mt-5'>Loading...</span>
      </div>
    </div>
  )
}

export default Loading
