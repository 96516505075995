import {Bar} from 'react-chartjs-2'

interface BarChartProps {
  options: object
  data: any
}

function BarChart(props: BarChartProps) {
  const {data, options} = props

  return <Bar id='chart' data={data} options={options} />
}

export default BarChart
