import {useState} from 'react'
import {createRole, deleteUserRole, updateRolePermissions} from '../services'
import useGetRoles from './useGetRoles'
import useToastify from '../../../hook/useToastify'

const roleUrl = '/settings/users/roles'

export default function useRoles() {
  const [isLoading, setLoading] = useState(false)
  const {refetch ,isFetching} = useGetRoles()
  const {toastMessage} = useToastify()

  const createNewRole = async (data: any) => {
    setLoading(true)
    const res = await createRole(roleUrl, data)
    setLoading(false)

    if (res.status === 201) {
      refetch()
      toastMessage('success', 'Role added successfully.')
    }

    if (res.status === 409) {
      toastMessage('error', res.data.message)
    }
    return res
  }

  const updateRole = async (data: any, role_id: string) => {
    setLoading(true)
    data['id'] = role_id
    const res = await updateRolePermissions(roleUrl, data, role_id)
    if (res) {
      refetch()
      toastMessage('success', 'Role updated successfully.')
    }
    setLoading(false)
    return res
  }

  const deleteRole = async (roleId: string) => {
    try {
      setLoading(true)
      const res = await deleteUserRole(roleUrl, roleId)
      if (res.status === 200) {
        toastMessage('success', 'Role deleted successfully.')
        refetch()
      } else {
        toastMessage('error', `${res.message}`)
      }
      setLoading(false)

      return res
    } catch (error: any) {
      console.error(error)
      setLoading(false)
    }
  }

  return {
    isLoading,
    updateRole,
    createNewRole,
    deleteRole,
    isFetching
  }
}
