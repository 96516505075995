export const convertInputToHoursAndMinutes = (input: string, days:any, hours:any) => {
    const regex = /(\d+)([wdhm]?)/g
    let totalMinutes = 0
    let match
    while ((match = regex.exec(input)) !== null) {
      const value = parseInt(match[1])
      const unit = match[2] || 'h'

      switch (unit) {
        case 'w':
          totalMinutes += value * days * hours * 60
          break
        case 'd':
          totalMinutes += value * hours * 60
          break
        case 'h':
          totalMinutes += value * 60
          break
        case 'm':
          totalMinutes += value
          break
        default:
          break
      }
    }

    const hoursy = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    const hoursString = hoursy > 0 ? `${hoursy}h` : ''
    const minutesString = minutes > 0 || hoursy === 0 ? `${minutes}m` : ''
    return hoursString + minutesString
  }