import {createContext} from 'react'

export const ProductLimitReportContext = createContext({
  products: [],
  isLoading: false,
  onChangeCustomerFilter:(e: string) => {},
  onSortingChange: (key: string, value: string) => {},
  onSearch: (searchValue: string) => {},
  filters: {},
})
