import {useContext} from 'react'
import {ReachSelect} from '../../../../components/reachSelect'
import {ProjectMembersPageContext} from '../../context'
import {useForm} from 'react-hook-form'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {TransferOwnershipModalTypes} from '../../interfaces'

const TransferOwnershipModal = ({showManageModal, setManageModal}: TransferOwnershipModalTypes) => {
  const {
    projectMembersDropdownList,
    isLoadingProjectMembersDropdownList,
    transferProjectOwnership,
    isLoadingOperation,
  } = useContext(ProjectMembersPageContext)

  const {
    control,
    handleSubmit,
    formState: {isDirty},
  } = useForm({
    defaultValues: {
      username: '',
    },
  })

  const activeProjectMembersList = projectMembersDropdownList.filter(
    (member: any) => member.is_active && !member.is_owner
  )

  const onSubmit = (data: any) => {
    transferProjectOwnership(data)
  }

  return (
    <ConfirmationModal
      show={showManageModal}
      title='Transfer Project Ownership'
      onClose={() => setManageModal(false)}
      onAction={handleSubmit(onSubmit)}
      actionName='Save'
      actionBtnClass='btn-primary'
      isDisabled={!isDirty || isLoadingOperation}
      isOperationLoading={isLoadingOperation}
      isDataLoading={isLoadingProjectMembersDropdownList}
      body={
        <ReachSelect
          id='project-owner'
          options={activeProjectMembersList}
          labelKey='name'
          valueKey='username'
          disabledKey='!is_active'
          control={control}
          registerKey='username'
          isNullable={true}
        />
      }
    />
  )
}

export default TransferOwnershipModal
