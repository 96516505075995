import {$createParagraphNode, $getSelection, $isRangeSelection, LexicalEditor} from 'lexical'
import {$setBlocksType} from '@lexical/selection'
import {$createHeadingNode, $createQuoteNode, HeadingTagType} from '@lexical/rich-text'
import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list'
import {$createCodeNode} from '@lexical/code'
import DropDown, {DropDownItem} from '../../../ui/DropDown'
import {blockTypeToBlockName} from '../utils'

export function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}: {
  editor: LexicalEditor
  blockType: keyof typeof blockTypeToBlockName
  disabled?: boolean
}): JSX.Element {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createHeadingNode(headingSize))
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      formatParagraph()
    }
  }

  const formatCheckList = () => {
    if (blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined)
    } else {
      formatParagraph()
    }
  }

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      formatParagraph()
    }
  }

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createQuoteNode())
      })
    }
  }

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection()

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode())
          } else {
            const textContent = selection.getTextContent()
            const codeNode = $createCodeNode()
            selection.insertNodes([codeNode])
            selection = $getSelection()
            if ($isRangeSelection(selection)) {
              selection.insertRawText(textContent)
            }
          }
        }
      })
    }
  }

  return (
    <DropDown
      disabled={disabled}
      buttonClassName='toolbar-item block-controls'
      buttonIconClassName={'icon block-type ' + blockType}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel='Formatting options for text style'
    >
      <DropDownItem
        className={`item ${blockType === 'paragraph' ? 'active dropdown-item-active' : ''}`}
        onClick={formatParagraph}
      >
        <i className='icon paragraph' />
        <span className='text'>Normal</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'h1' ? 'active dropdown-item-active' : ''}`}
        onClick={() => formatHeading('h1')}
      >
        <i className='icon h1' />
        <span className='text'>Heading 1</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'h2' ? 'active dropdown-item-active' : ''}`}
        onClick={() => formatHeading('h2')}
      >
        <i className='icon h2' />
        <span className='text'>Heading 2</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'h3' ? 'active dropdown-item-active' : ''}`}
        onClick={() => formatHeading('h3')}
      >
        <i className='icon h3' />
        <span className='text'>Heading 3</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'h4' ? 'active dropdown-item-active' : ''}`}
        onClick={() => formatHeading('h4')}
      >
        <i className='icon h4' />
        <span className='text'>Heading 4</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'h5' ? 'active dropdown-item-active' : ''}`}
        onClick={() => formatHeading('h5')}
      >
        <i className='icon h5' />
        <span className='text'>Heading 5</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'h6' ? 'active dropdown-item-active' : ''}`}
        onClick={() => formatHeading('h6')}
      >
        <i className='icon h6' />
        <span className='text'>Heading 6</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'bullet' ? 'active dropdown-item-active' : ''}`}
        onClick={formatBulletList}
      >
        <i className='icon bullet-list' />
        <span className='text'>Bullet List</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'number' ? 'active dropdown-item-active' : ''}`}
        onClick={formatNumberedList}
      >
        <i className='icon numbered-list' />
        <span className='text'>Numbered List</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'check' ? 'active dropdown-item-active' : ''}`}
        onClick={formatCheckList}
      >
        <i className='icon check-list' />
        <span className='text'>Check List</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'quote' ? 'active dropdown-item-active' : ''}`}
        onClick={formatQuote}
      >
        <i className='icon quote' />
        <span className='text'>Quote</span>
      </DropDownItem>
      <DropDownItem
        className={`item ${blockType === 'code' ? 'active dropdown-item-active' : ''}`}
        onClick={formatCode}
      >
        <i className='icon code' />
        <span className='text'>Code Block</span>
      </DropDownItem>
    </DropDown>
  )
}
