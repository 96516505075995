import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectsTeamsPageContext} from '../../context'
import {TeamsActionsOverlayTypes} from '../../interfaces'
import TeamsActionsDropdown from '../actionsDropdowns/TeamsActionsDropdown'
import ProjectTeamsModal from '../modals/ProjectTeamsModal'

const TeamsActionsOverlay = ({TeamsData}: TeamsActionsOverlayTypes) => {
  const {removeProjectTeams, isLoadingProjectsTeamsOperation, isLoadingProjectsTeamsList} =
    useContext(ProjectsTeamsPageContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const onRemove = async (teamId: any) => {
    await removeProjectTeams(teamId)
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <TeamsActionsDropdown
            data={TeamsData}
            setShowDeleteModal={setShowDeleteModal}
            setShowModal={setShowModal}
          />
        }
      />

      {showModal && (
        <ProjectTeamsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          projectTeamModalDetails={TeamsData}
          isEdit={true}
        />
      )}

      {showDeleteModal && (
        <>
          <ConfirmationModal
            uniqueID={TeamsData?.id}
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onAction={onRemove}
            isOperationLoading={isLoadingProjectsTeamsOperation}
            isDataLoading={isLoadingProjectsTeamsList}
            body={
              <p>
                Do you really want to remove '<strong>{TeamsData?.name}</strong>' from Teams?
              </p>
            }
          />
        </>
      )}
    </>
  )
}

export default TeamsActionsOverlay
