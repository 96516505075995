import {useContext} from 'react'
import {GanttChartPageContext} from '../../context'
import Search from '../../../../components/Search'
import {formatDate} from '../../../../utils/date'
import {RadioGroup} from '../../../../components/RadioGroup'
import {isEmpty} from '../../../../utils/common'

const GanttChartPageHeader = () => {
  const {ganttChartData, chartView, setChartView} = useContext(GanttChartPageContext)

  return (
    <div className='mb-10'>
      <div className='d-flex justify-content-between mb-8'>
        <Search onSearch={() => {}} />
        <div className='d-flex align-items-center gap-5'>
          <RadioGroup
            id='gantt-chart-view'
            name='gantt-chart-view'
            label='Month View'
            value='month'
            checked={chartView === 'month'}
            onChange={(e: any) => setChartView(e.target.value)}
          />
          <RadioGroup
            id='gantt-chart-view'
            name='gantt-chart-view'
            label='Week View'
            value='week'
            checked={chartView === 'week'}
            onChange={(e: any) => setChartView(e.target.value)}
          />
        </div>
      </div>

      {!isEmpty(ganttChartData) && (
        <div className='d-flex gap-10'>
          <div className='d-flex gap-3'>
            <span className='fw-bold'>Start Date:</span>
            <span className='text-gray-600'>
              {formatDate(ganttChartData?.lowest_start_date, true)}
            </span>
          </div>
          <div className='d-flex gap-3'>
            <span className='fw-bold'>End Date:</span>
            <span className='text-gray-600'>
              {formatDate(ganttChartData?.highest_due_date, true)}
            </span>
          </div>
          <div className='d-flex gap-3'>
            <span className='fw-bold'>Duration:</span>
            <span className='text-gray-600'>{ganttChartData?.total_duration}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default GanttChartPageHeader
