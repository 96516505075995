/* eslint-disable react-hooks/exhaustive-deps */
import useGetPermissions from '../../hooks/useGetPermissions'
import {useContext, useEffect, useState} from 'react'
import {roleValidationSchema as schema} from '../../validations'
import {
  filterPermissionsWithOperations,
  formatCheckboxValues,
  handleCheckboxChange,
  setDefaultValues,
} from '../../utils'
import Loading from '../../../loading'
import {useForm} from 'react-hook-form'
import {KTSVG} from '../../../../_metronic/helpers'
import {UserRoleContext} from '../../context'
import {yupResolver} from '@hookform/resolvers/yup'
import {InputText} from '../../../../components/InputText'
import RoleItem from './RoleItem'
import useGetRoleById from '../../hooks/useGetRoleById'
import {CheckBox} from '../../../../components/CheckBox copy'

const RoleForm = ({isEdit, editId, onClose}: any) => {
  const {
    register,
    setValue,
    reset,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema), mode: 'onChange'})

  const {permissions, isFetching: isPermissionsLoading} = useGetPermissions()
  const {createNewRole, updateRole, isLoading: isOperationLoading} = useContext(UserRoleContext)
  const {getRoleDetails, isLoadingDetails} = useGetRoleById()

  const [checkboxValues, setCheckboxValues] = useState<Record<string, any>>({})

  const onSubmit = async (data:any) => {
    checkboxValues['role'] = data?.role
    const filteredData = filterPermissionsWithOperations(checkboxValues, permissions)
    
    try {
      const res: any = editId
        ? await updateRole(filteredData, editId)
        : await createNewRole(filteredData)
      if (res?.status === 201 || res?.status === 200) {
        onCancel()
      } else {
        setError(res?.key, {type: 'focus', message: res?.message}, {shouldFocus: true})
      }
    } catch (error) {
      setError(
        'general',
        {type: 'manual', message: 'An unexpected error occurred.'},
        {shouldFocus: true}
      )
    }
  }

  const onCancel = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    if (Object.keys(checkboxValues).length > 0) {
      formatCheckboxValues(checkboxValues, setValue)
    }
  }, [checkboxValues, setValue])

  useEffect(() => {
    if (isEdit && editId) {
      const loadRoleDetails = async () => {
        try {
          const roleDetails = await getRoleDetails(editId)
          setCheckboxValues(setDefaultValues(roleDetails, permissions, isEdit))
          setValue('role', roleDetails?.role)
          setValue('isAdmin', roleDetails?.is_administrator_access)
          setValue('isSuperAdmin', roleDetails?.is_super_admin)
        } catch (error) {
          // Handle error (e.g., show notification)
        }
      }
      loadRoleDetails()
    } else if (!isEdit && permissions?.permissions) {
      setCheckboxValues(setDefaultValues({}, permissions, isEdit))
    }
  }, [editId, permissions])

  const onSelectCheckbox = (permissionId: string, operationType: string) => {
    handleCheckboxChange(permissionId, operationType, setCheckboxValues)
  }

    useEffect(() => {
      const anyFalsePermission = Object.keys(checkboxValues).some((id) => {
        if (id !== 'isAdmin') {
          const permission = checkboxValues[id]
          return permission.read === false || permission.write === false
        }
        return false
      })
      setValue('isAdmin', !anyFalsePermission)
      setValue('isSuperAdmin', !anyFalsePermission)
    }, [checkboxValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='modal-header'>
        <h4 className='modal-title'>{editId ? 'Edit' : 'Add'} a Role</h4>
        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
        </div>
      </div>
      <div className='modal-body role-modal-content'>
        <div className='mb-8'>
          <InputText
            id='role'
            label='Role Name'
            name='role'
            placeholder='Enter Role Name'
            isRequired={true}
            register={register('role')}
            error={errors?.role}
            maxLength={30}
          />
        </div>

        <h6 className='form-label required'>Role Permissions</h6>

        <div className='form-error text-danger fs-7 mb-8'>
          {errors?.permissions?.message && <>{errors?.permissions?.message}</>}
        </div>

        <div className='role-item d-flex justify-content-between flex-nowrap mb-5'>
          <p className='role-title mw-400px mb-0'>Super Admin</p>

          <div className='role-action d-flex w-250px justify-content-end'>
            <div className='form-check form-check-custom form-check-solid form-check-sm w-100px'>
              <CheckBox
                id='is-super-admin'
                name='isSuperAdmin'
                onChange={() => onSelectCheckbox('is_super_admin', 'super_administrator')}
                register={register('isSuperAdmin')}
                label='Select All'
                htmlFor='flexCheckDefault'
                error={errors?.isSuperAdmin}
                disabled={isPermissionsLoading}
              />
            </div>
            <div className='w-100px'></div>
          </div>
        </div>

        <div className='role-item d-flex justify-content-between flex-nowrap mb-5'>
          <p className='role-title mw-400px mb-0'>Administrator Access</p>

          <div className='role-action d-flex w-250px justify-content-end'>
            <div className='form-check form-check-custom form-check-solid form-check-sm w-100px'>
              <CheckBox
                id='is-admin'
                name='isAdmin'
                onChange={() => onSelectCheckbox('is_administrator_access', 'administrator')}
                register={register('isAdmin')}
                label='Select All'
                htmlFor='flexCheckDefault'
                error={errors?.isAdmin}
                disabled={isPermissionsLoading}
              />
            </div>
            <div className='w-100px'></div>
          </div>
        </div>

        <div className='role-list-section position-relative min-h-350px'>
          {Object.keys(checkboxValues).length > 0 &&
          (isEdit ? !isPermissionsLoading && !isLoadingDetails : !isPermissionsLoading)
            ? Object.values(permissions.permissions).map((permission: any) => (
                <RoleItem
                  key={permission?.id}
                  permission={permission}
                  onSelectCheckbox={onSelectCheckbox}
                  checkboxValues={checkboxValues}
                />
              ))
            : (isEdit ? !isPermissionsLoading && !isLoadingDetails : !isPermissionsLoading) && (
                <p className='text-danger text-center pt-20 fw-bold'>Data not found...</p>
              )}
          {isPermissionsLoading && isLoadingDetails && <Loading />}
      </div>
      </div>
      <div className='modal-footer'>
        <button
          type='button'
          className='btn btn-outline btn-active-light-primary'
          onClick={onClose}
        >
          Cancel
        </button>
        {isOperationLoading ? (
          <button type='button' className='btn btn-primary' disabled>
            {editId ? 'Save' : 'Add Role'}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </button>
        ) : (
          <button type='submit' className='btn btn-primary'>
            {editId ? 'Save' : 'Add Role'}
          </button>
        )}
      </div>
    </form>
  )
}

export default RoleForm
