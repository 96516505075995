import {TextAreaTypes} from './TextAreaTypes'

function TextArea({
  id,
  name,
  placeholder,
  textAreaRef,
  error,
  label,
  register,
  isRequired,
  disabled = false,
  className = '',
  inputClass = '',
  maxLength,
  onChange,
  onBlur,
  rows = 5,
  isReadOnly = false,
  value,
  rest,
  labelClass = '',
  onKeyDown,
}: TextAreaTypes) {
  const {
    name: registerName = name,
    onChange: registerOnChange = onChange,
    onBlur: registerOnBlur = onBlur,
    ref: registerRef = textAreaRef,
  } = register && register.name ? register : {}

  const handleChange = (e: any) => {
    if (onChange) onChange(e)
    if (registerOnChange) registerOnChange(e)
  }

  const handleBlur = (e: any) => {
    if (onBlur) onBlur(e)
    if (registerOnBlur) registerOnBlur(e)
  }

  const handleRef = (e: any) => {
    if (textAreaRef) (textAreaRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = e
    if (registerRef) registerRef(e)
  }

  return (
    <div className={className}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <textarea
        id={id}
        name={registerName || name}
        ref={handleRef}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        className={`form-control ${inputClass} ${error ? 'is-invalid' : ''} ${
          disabled ? 'text-gray-600' : ''
        }`}
        maxLength={maxLength}
        disabled={disabled}
        rows={rows}
        onKeyDown={onKeyDown}
        readOnly={isReadOnly}
        {...(value ? {value: value} : {})}
        {...rest}
      />
      {error && error.message && <div className='invalid-feedback'>{error.message}</div>}
    </div>
  )
}

export default TextArea
