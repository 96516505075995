import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import AddBoardColumnModal from '../modals/AddBoardColumnModal'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {BoardSettingsPageContext} from '../../context'
import BoardColumnsActionDropdown from '../actionDropdowns/BoardColumnsActionDropdown'

const ColumnActionsOverlay = ({columnData}: any) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const {deleteColumn, isLoadingColumnOperation, isLoadingBoardColumnsList, projectName} =
    useContext(BoardSettingsPageContext)

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <BoardColumnsActionDropdown
            setShowModal={setShowModal}
            setDeleteModal={setDeleteModal}
            columnData={columnData}
          />
        }
      />

      {showModal && (
        <AddBoardColumnModal
          show={showModal}
          isEdit={true}
          columnData={columnData}
          onClose={() => setShowModal(false)}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={() => setDeleteModal(false)}
          onAction={() => deleteColumn(columnData?.columnId)}
          isOperationLoading={isLoadingColumnOperation}
          isDataLoading={isLoadingBoardColumnsList}
          body={
            <p>
              Do you really want to remove '<strong>{columnData?.columnName}</strong>' column from '
              <strong>{projectName}</strong>' project?
            </p>
          }
        />
      )}
    </>
  )
}

export default ColumnActionsOverlay
