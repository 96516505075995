import type {EditorThemeClasses} from 'lexical'

import './textEditorThemeStyles.css'

const textEditorTheme: EditorThemeClasses = {
  blockCursor: 'texteditor_blockCursor',
  characterLimit: 'texteditor_characterLimit',
  code: 'texteditor_code',
  codeHighlight: {
    atrule: 'texteditor_tokenAttr',
    attr: 'texteditor_tokenAttr',
    boolean: 'texteditor_tokenProperty',
    builtin: 'texteditor_tokenSelector',
    cdata: 'texteditor_tokenComment',
    char: 'texteditor_tokenSelector',
    class: 'texteditor_tokenFunction',
    'class-name': 'texteditor_tokenFunction',
    comment: 'texteditor_tokenComment',
    constant: 'texteditor_tokenProperty',
    deleted: 'texteditor_tokenProperty',
    doctype: 'texteditor_tokenComment',
    entity: 'texteditor_tokenOperator',
    function: 'texteditor_tokenFunction',
    important: 'texteditor_tokenVariable',
    inserted: 'texteditor_tokenSelector',
    keyword: 'texteditor_tokenAttr',
    namespace: 'texteditor_tokenVariable',
    number: 'texteditor_tokenProperty',
    operator: 'texteditor_tokenOperator',
    prolog: 'texteditor_tokenComment',
    property: 'texteditor_tokenProperty',
    punctuation: 'texteditor_tokenPunctuation',
    regex: 'texteditor_tokenVariable',
    selector: 'texteditor_tokenSelector',
    string: 'texteditor_tokenSelector',
    symbol: 'texteditor_tokenProperty',
    tag: 'texteditor_tokenProperty',
    url: 'texteditor_tokenOperator',
    variable: 'texteditor_tokenVariable',
  },
  embedBlock: {
    base: 'texteditor_embedBlock',
    focus: 'texteditor_embedBlockFocus',
  },
  hashtag: 'texteditor_hashtag',
  heading: {
    h1: 'texteditor_h1',
    h2: 'texteditor_h2',
    h3: 'texteditor_h3',
    h4: 'texteditor_h4',
    h5: 'texteditor_h5',
    h6: 'texteditor_h6',
  },
  image: 'editor-image',
  indent: 'texteditor_indent',
  inlineImage: 'inline-editor-image',
  layoutContainer: 'texteditor_layoutContaner',
  layoutItem: 'texteditor_layoutItem',
  link: 'texteditor_link',
  list: {
    listitem: 'texteditor_listItem',
    listitemChecked: 'texteditor_listItemChecked',
    listitemUnchecked: 'texteditor_listItemUnchecked',
    nested: {
      listitem: 'texteditor_nestedListItem',
    },
    olDepth: [
      'texteditor_ol1',
      'texteditor_ol2',
      'texteditor_ol3',
      'texteditor_ol4',
      'texteditor_ol5',
    ],
    ul: 'texteditor_ul',
  },
  ltr: 'texteditor_ltr',
  mark: 'texteditor_mark',
  markOverlap: 'texteditor_markOverlap',
  paragraph: 'texteditor_paragraph',
  quote: 'texteditor_quote',
  rtl: 'texteditor_rtl',
  table: 'texteditor_table',
  tableAddColumns: 'texteditor_tableAddColumns',
  tableAddRows: 'texteditor_tableAddRows',
  tableCell: 'texteditor_tableCell',
  tableCellActionButton: 'texteditor_tableCellActionButton',
  tableCellActionButtonContainer: 'texteditor_tableCellActionButtonContainer',
  tableCellEditing: 'texteditor_tableCellEditing',
  tableCellHeader: 'texteditor_tableCellHeader',
  tableCellPrimarySelected: 'texteditor_tableCellPrimarySelected',
  tableCellResizer: 'texteditor_tableCellResizer',
  tableCellSelected: 'texteditor_tableCellSelected',
  tableCellSortedIndicator: 'texteditor_tableCellSortedIndicator',
  tableResizeRuler: 'texteditor_tableCellResizeRuler',
  tableSelected: 'texteditor_tableSelected',
  tableSelection: 'texteditor_tableSelection',
  text: {
    bold: 'texteditor_textBold',
    code: 'texteditor_textCode',
    italic: 'texteditor_textItalic',
    strikethrough: 'texteditor_textStrikethrough',
    subscript: 'texteditor_textSubscript',
    superscript: 'texteditor_textSuperscript',
    underline: 'texteditor_textUnderline',
    underlineStrikethrough: 'texteditor_textUnderlineStrikethrough',
  },
}

export default textEditorTheme
