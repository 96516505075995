import useApi from '../../../../services/useApi'

export const useGetProjectsMembersTeamsDropdownList = () => {
  const Api = useApi()
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/members?sort_by=name/1`, {
    queryId: 'global-project-members-dropdown-list',
  })

  return {
    globalProjectsMembersDropdownList: response?.data?.members || [],
    isLoading: isFetching,
  }
}
