import React from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

import {KTSVG} from '../../../../_metronic/helpers'

import { resetPasswordValidationSchema as resetSchema } from '../../validations'
import useChangePassword from '../../hooks/useChangePassword'

const defaultValues = { 
  password: '',
  confirm_password: '' 
}

function ResetPasswordForm({onClose, userName}: any) {
  const {
    register,
    handleSubmit,    
    formState: {errors},
    reset,    
  } = useForm({
    defaultValues,
    resolver: yupResolver(resetSchema),
  })
  
  const {onChangePassword, isLoading} = useChangePassword()  



  const onSubmit = async (data: any) => {        
    data['username'] = userName         
    await onChangePassword(data)    
    onCancle()
  }

  const onCancle = () => {
    reset()
    onClose()
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='modal-header'>
        <h4 className='modal-title'>Reset Password</h4>

        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onCancle}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
        </div>
      </div>

      <div className='modal-body'>               
        
        <div className='mt-7'>
          <label className='form-label required form-label'>Password</label>
          <input
            type='password'
            className='form-control '
            id='password'
            {...register('password')}
          />
          <div className='form-error'>{errors.password?.message}</div>
        </div>         
       
        <div className='mt-7'>
          <label className='form-label required form-label'>Confirm Password</label>
          <input
            type='password'
            className='form-control '
            id='confirm-password'
            {...register('confirm_password')}
          />
          <div className='form-error'>{errors.confirm_password?.message}</div>
        </div>         

        

        
      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-outline btn-active-light-primary' onClick={onClose}>
          Cancel
        </button>
        <button type='submit' className='btn btn-primary'>
          {!isLoading ? <span className='indicator-label'>Save</span> : null}

          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export default ResetPasswordForm
