import {FORMAT_TEXT_COMMAND, LexicalEditor} from 'lexical'
import DropDown, {DropDownItem} from '../../../ui/DropDown'

export function TextFormatDropDown({
  disabled,
  isStrikethrough,
  isSubscript,
  isSuperscript,
  editor,
  clearFormatting,
}: {
  disabled: boolean
  isStrikethrough: boolean
  isSubscript: boolean
  isSuperscript: boolean
  editor: LexicalEditor
  clearFormatting: () => void
}) {
  return (
    <DropDown
      disabled={disabled}
      buttonClassName='toolbar-item spaced'
      buttonLabel=''
      buttonAriaLabel='Formatting options for additional text styles'
      buttonIconClassName='icon dropdown-more'
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
        className={`item ${isStrikethrough ? 'active dropdown-item-active' : ''}`}
        title='Strikethrough'
        aria-label='Format text with a strikethrough'
      >
        <i className='icon strikethrough' />
        <span className='text'>Strikethrough</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript')
        }}
        className={`item ${isSubscript ? 'active dropdown-item-active' : ''}`}
        title='Subscript'
        aria-label='Format text with a subscript'
      >
        <i className='icon subscript' />
        <span className='text'>Subscript</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript')
        }}
        className={`item ${isSuperscript ? 'active dropdown-item-active' : ''}`}
        title='Superscript'
        aria-label='Format text with a superscript'
      >
        <i className='icon superscript' />
        <span className='text'>Superscript</span>
      </DropDownItem>
      <DropDownItem
        onClick={clearFormatting}
        className='item'
        title='Clear text formatting'
        aria-label='Clear all text formatting'
      >
        <i className='icon clear' />
        <span className='text'>Clear Formatting</span>
      </DropDownItem>
    </DropDown>
  )
}
