import {useState} from 'react'
import {changePassword} from '../services'
import useToastify from '../../../hook/useToastify'

export default function useChangePassword() {
  const [isLoading, setLoading] = useState(false)
  const {toastMessage} = useToastify()
  const onChangePassword = async (data: any) => {
    try {
      setLoading(true)
      const res = await changePassword(data)      
      if (res.status === 200) {
        toastMessage('success', `${res?.data?.message}`)       
      } else {
        toastMessage('error', 'Password updating failed.')
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    isLoading,
    onChangePassword,
  }
}
