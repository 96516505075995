import {useContext} from 'react'
import {TasksReportPageContext} from '../../context'
import TasksReportTableRow from './TasksReportTableRow'
import {DynamicTable} from '../../../../components/DynamicTable'
import {TasksReportTableColumns} from '../../utils'
import { dataToTasksReportRow } from '../../parsings'

export default function TasksReportTable() {
  const {isLoading, tasksReportsData, onSortingChange, searchValue, filters} =
    useContext(TasksReportPageContext)

  return (
    <DynamicTable
      id={'tasks-report'}
      data={tasksReportsData}
      sortableColumns={TasksReportTableColumns}
      TableRow={TasksReportTableRow}
      permissionPath={'projects_settings'}
      loading={isLoading}
      tableClass="table-row-dashed table-row-gray-300 align-middle mb-15 gs-0 gy-3"
      filters={filters}
      onSortingChange={onSortingChange}
      searchValue={searchValue}
      rowParsingFunction={dataToTasksReportRow}
    />
  )
}
