import OverlayComponent from '../../../_metronic/layout/components/Popover'
import {OverlayDropdownsProps} from '../interfaces'

export const OverlayDropdowns = ({
  triggerIcon,
  children,
  className,
  hasValue,
  disabled,
}: OverlayDropdownsProps) => {
  return (
    <OverlayComponent
      btnIcon={triggerIcon}
      placement='bottom-start'
      className={hasValue ? 'w-auto' : 'btn btn-light rounded-circle w-30px h-30px ' + className}
      popoverBodyClass='p-3'
      popoverClass='border border-2 border-gray-300'
      onClick={(e: React.MouseEvent) => e.preventDefault()}
      outerDivClass='justify-content-start'
      disabled={disabled}
    >
      {children}
    </OverlayComponent>
  )
}
