/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
// import {useAuth} from '../../../../modules/auth'
import {KTSVG} from '../../../helpers'
import {UserMenu} from '../user-menu/UserMenu'
import {useAppSelector} from '../../../../redux/useTypedSelector'

const AsideUserMenu: FC = () => {
  // const {currentUser} = useAuth()
  const {user, isLoading} = useAppSelector((state) => state.auth)  
  return (
    <>
      {!isLoading && (
        <div className='d-flex flex-stack aside-footer-inner'>
          {/* begin::Wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Avatar */}
            <div className='symbol symbol-circle symbol-30px'>
              <div
                className={`symbol-label fs-5 fw-semibold bg-primary bg-opacity-50 text-secondary`}
              >
                {user?.name?.charAt(0)?.toUpperCase()}
              </div>
            </div>
            {/* end::Avatar */}
            {/* begin::User info */}
            <div className='ms-2 hide-sidebar-tggle overflow-hidden w-175px'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold lh-1'>
                {user?.name}
              </a>
              <span className='fw-bold d-block fs-8 lh-1 text-muted '>{user?.username}</span>
            </div>
            {/* end::User info */}
          </div>
          {/* end::Wrapper */}

          {/* begin::User menu */}
          <div className='hide-sidebar-tggle'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary position-relative w-30px h-30px'
              data-kt-menu-trigger='click'
              data-kt-menu-overflow='false'
              data-kt-menu-placement='top-end'
            >
              <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
            </div>
            <UserMenu />
          </div>
          {/* end::User menu */}
        </div>
      )}
    </>
  )
}

export {AsideUserMenu}
