import Api from '../../../services/Api'

export const getCustomers = (url: string, filters: any) => {
  return Api.get(`${url}`, filters).then((d: any) => {
    return d.data
  })
}

export const getRepresantetives = (url: string) => {
  return Api.get(`${url}`).then((d: any) => {
    return d.data
  })
}

export const getCustomerLoginToken = (url: string, customerId: string): any => {
  return Api.post(
    `${url}`,
    {
      customer_id: +customerId,
    },
    null,
    null
  ).then((d: any) => {
    return d.data
  })
}
