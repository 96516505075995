import {Modal} from 'react-bootstrap'
import store from '../../../redux/store'
import {logout, reset, setRedirect} from '../../../feature/authSlice'
import {useLocation, useNavigate} from 'react-router-dom'

function LogoutModal({show}: any) {
  const navigate = useNavigate()
  const location = useLocation()

  const user: any = store.getState()?.auth?.user
  const _token: string = user?.['accessToken']
  const redirect_url: any = location.pathname

  const handleLogout = () => {
    store.dispatch(setRedirect(redirect_url))
    store.dispatch(logout(_token))
    store.dispatch(reset())
    navigate('/login')
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_trigger_build'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2'></div>
        </div>
        <div className='modal-body'>
          <div className='alert alert-danger d-flex items-center p-5 m-0'>
            <div className='d-flex flex-column'>
              <h4 className='mb-1 text-danger'>Please Login again</h4>
              <span>Your session has expired. Please log in again to continue.</span>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button type='submit' className='btn btn-primary' onClick={() => handleLogout()}>
            <span style={{display: 'block'}}>Login</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default LogoutModal
