import {useState} from 'react'
import useApi from '../../../services/useApi'

const useUpdateCustomerTag = () => {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const updateCustomerTags = (payload: any, customerId: any) => {
    return Api.post(`/customers/tags/${customerId}`, payload, {
      setLoading: setIsLoading,
      successMessage: 'Customer tags updated successfully.',
      refetch: ['suggested-customer-reports'],
      isDisableSneckCase: true,
    })
  }

  return {
    updateCustomerTags: updateCustomerTags,
    isLoading: isLoading,
  }
}

export default useUpdateCustomerTag
