import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {getHideProductList} from '../services'
import useFilters from '../../../../hook/useFilters'

export default function useGetVariantRules() {
  const {filters, setSingleFilter, setMultipleFilters} = useFilters({
    limit: 10,
    page: 1,
    // filterBy: 'name',
    search: '',
    sort_by: 'created_at',
    sort_order: '-1',
  })

  const {
    data: response,
    refetch,
    isFetching,
  } = useQuery(`get-hide-product`, () => {
    return getHideProductList(`/analytics/automation/rule-engine/variants`, filters)
  })

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const onSearch = (searchValue: string) => {
    setMultipleFilters({
      search: searchValue,
      page: 1,
    })
  }

  const onPageChange = (page_number: string | number | void) => {
    setSingleFilter('page', page_number)
  }

  const onSortingChange = (sortBy: string, sortDirection: any) => {
    const direction = sortDirection === 'asc' ? '1' : '-1'
    setMultipleFilters({
      sort_order: direction,
      sort_by: sortBy,
    })
  }

  return {
    variantRules: response?.data || [],
    refetch,
    isFetching: isFetching,
    onSearch,
    pagination: response?.['meta']?.['pagination'] || [],
    onPageChange,
    onSortingChange,
    filters,
  }
}
