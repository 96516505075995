import {PageLink} from '../../_metronic/layout/core'
import useMeta from '../../hook/useMeta'
import Navigation from '../../components/Navigation/Navigation'
import Customers from './layouts/Customers'
import SalesRep from './layouts/SalesRep'
import Products from './layouts/Products'
import SuggestedCustomers from './layouts/SuggestedCustomers'
import GlobalProducts from './layouts/GlobalProducts'

const BOBrandsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Orders',
    path: '/orders/all-orders/all',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Bulk Orders',
    path: `/orders/bo/manage/products`,
    isSeparator: false,
    isActive: false,
  },
]

const BoReportsPage = () => {
  useMeta('BoReportsPage')

  return (
    <Navigation
      baseUrl={`/orders/bo/reports`}
      pageTitle={'Reports'}
      breadcrumbs={BOBrandsBreadCrumbs}
      navigationData={[
        {
          key: 'by-customer',
          label: 'By Customer',
          component: <Customers />,
        },
        {
          key: 'by-sales-rep',
          label: 'By Sales Rep',
          component: <SalesRep />,
        },
        {
          key: 'by-products',
          label: 'By Products',
          component: <Products />,
        },
        {
          key: 'suggested-customer',
          label: 'Suggested Customer',
          component: <SuggestedCustomers />,
        },
        {
          key: 'global-products',
          label: 'Global Products',
          component: <GlobalProducts />,
        },
      ]}
    />
  )
}

export default BoReportsPage
