import useApi from '../../../services/useApi'
import {useParams} from 'react-router-dom'

const useGetProjectDetails = () => {
  const Api = useApi()
  const {projectId} = useParams()

  const {data: response, isLoading, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}`)

  return {
    projectDetails: response?.data?.[0] || {},
    isLoading,
    isFetching,
  }
}

export default useGetProjectDetails
