import {LexicalComposer} from '@lexical/react/LexicalComposer'
import PlaygroundNodes from './nodes/PlaygroundNodes'
import TextEditorTheme from './themes/TextEditorTheme'
import EditorComponent from './ui/EditorComponent'
import {Controller} from 'react-hook-form'
import {useEffect, useState} from 'react'
import {TextEditorTypes} from './TextEditorTypes'
import {$getRoot} from 'lexical'
import {convertHTMLToLexical} from './utils/custom'
import {isEmpty} from '../../utils/common'
import {TextEditorContext} from './context/TextEditorContext'
import '../newTextEditor/textEditorStyles.css'
import '../newTextEditor/textEditorThemeStyles.css'

const TextEditor = ({
  className = '',
  inputClass = '',
  editorClass = '',
  labelClass = '',
  errorClass,
  onChange,
  control,
  registerKey,
  label,
  isRequired,
  error,
  errorMessageRef,
  disabled = false,
  value,
  defaultValue = null,
  textEditorRef,
  maxLength,
  id,
  rules,
  isChangeOnSave = false,
  isLoadingOperation = undefined,
  isLoadingData = undefined,
  isReadOnly = false,
  isClearChanges = false,
  placeholder,
  onBlur,
  setIsValid,
}: TextEditorTypes) => {
  const [editorKey, setEditorKey] = useState<number>(0)
  const [charCount, setCharCount] = useState<number>(0)

  const initialConfig = {
    namespace: 'AD Text Editor',
    nodes: PlaygroundNodes,
    onError: (error: Error) => {
      throw error
    },
    theme: TextEditorTheme,
    editorState: (editor: any) =>
      editor.update(() => {
        $getRoot().clear()
        convertHTMLToLexical(editor, defaultValue)
      }),
  }

  useEffect(() => {
    if (!isEmpty(defaultValue)) {
      setEditorKey((prevKey) => prevKey + 1)
    }
  }, [defaultValue])

  useEffect(() => {
    if (!isReadOnly) {
      setEditorKey((prevKey) => prevKey + 1)
    }
  }, [isReadOnly])

  const contextValue = {
    inputClass,
    editorClass,
    disabled,
    value,
    defaultValue,
    textEditorRef,
    maxLength,
    isChangeOnSave,
    isLoadingOperation,
    isLoadingData,
    isReadOnly,
    isClearChanges,
    placeholder,
    onBlur,
    setIsValid,
    charCount,
    setCharCount,
  }

  return (
    <TextEditorContext.Provider value={contextValue}>
      <div className={className} id={id}>
        <LexicalComposer initialConfig={initialConfig} key={editorKey}>
          {label && (
            <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
              {label}
            </label>
          )}
          {control && registerKey ? (
            <Controller
              name={registerKey}
              control={control}
              rules={rules}
              render={({field: {onChange: registerOnChange, value: registeredValue}}) => (
                <>
                  <EditorComponent
                    id={id}
                    setEditorKey={setEditorKey}
                    onChange={(value: any) => {
                      registerOnChange(value)
                      onChange?.(value)
                    }}
                    inputClass={error?.message ? 'border border-danger' + inputClass : inputClass}
                    disabled={disabled}
                    value={value ?? registeredValue}
                    defaultValue={defaultValue ?? registeredValue}
                    textEditorRef={textEditorRef}
                    maxLength={maxLength}
                    isChangeOnSave={isChangeOnSave}
                    isLoadingOperation={isLoadingOperation}
                    isLoadingData={isLoadingData}
                    isReadOnly={isReadOnly}
                    editorClass={editorClass}
                    isClearChanges={isClearChanges}
                    placeholderText={placeholder}
                    onBlur={onBlur}
                    registerKey={registerKey}
                  />
                  {error?.message && (
                    <>
                      <div className='is-invalid d-none'></div>
                      <p
                        className={errorClass ? errorClass : 'invalid-feedback'}
                        ref={errorMessageRef}
                      >
                        {error?.message}
                      </p>
                    </>
                  )}
                </>
              )}
            />
          ) : (
            <EditorComponent
              id={id}
              setEditorKey={setEditorKey}
              onChange={onChange}
              inputClass={inputClass}
              disabled={disabled}
              value={value}
              defaultValue={defaultValue}
              textEditorRef={textEditorRef}
              maxLength={maxLength}
              isChangeOnSave={isChangeOnSave}
              isLoadingOperation={isLoadingOperation}
              isLoadingData={isLoadingData}
              isReadOnly={isReadOnly}
              editorClass={editorClass}
              isClearChanges={isClearChanges}
              placeholderText={placeholder}
              onBlur={onBlur}
              registerKey={registerKey}
            />
          )}
        </LexicalComposer>
      </div>
    </TextEditorContext.Provider>
  )
}

export default TextEditor
