import apiV2 from '../services/ApiV2'
import {xStoreId} from '../utils/xStoreId'

//login api call
const login = async (data: any) => {
  const url = `${process.env.REACT_APP__AD_API_URL}/v2/global/login`
  const username = data?.email
  const password = data?.password
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      HTTP_ORIGIN: `${process.env.REACT_APP_HTTP_ORIGIN}`,
      Authorization: `Basic ${btoa(username + ':' + password)}`,
      redirect: 'follow',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((res) => res.json())
  return response
}

//user login api call
const userLogin = async (payload: any) => {
  const url = `${process.env.REACT_APP__AD_API_URL}/v2/global/admin/user/login`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      HTTP_ORIGIN: `${process.env.REACT_APP_HTTP_ORIGIN}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-store-id': `${process.env.REACT_APP_STORE_ID}`,
    },
    body: JSON.stringify(payload),
  }).then((res) => res.json())

  return response
}

//logout api call
const logOut = async (token: string) => {
  const url = `${process.env.REACT_APP__AD_API_URL}/v2/global/logout`

  const response = await logOutUser(url)

  if(response?.status === 200){
    localStorage.clear()
  }

  function logOutUser(url: string) {
    return apiV2.post(`${url}`, {}, {}, {}).then((d: any) => {
      return d
    })
  }

  return response
}

const googleLogin = async (data: any) => {
  const storeId = xStoreId()
  const url = `${process.env.REACT_APP__AD_API_URL}/v2/global/login/google`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      HTTP_ORIGIN: `${process.env.REACT_APP_HTTP_ORIGIN}`,
      redirect: 'follow',
      'Content-Type': 'application/json',
      'x-store-id': `${storeId}`,
    },
    body: JSON.stringify(data),
  }).then((res) => res.json())
  return response
}

const storePermissions = async (token: string, storeId: string, tenantId: string) => {
  const url = `${process.env.REACT_APP__AD_API_URL}/v2/global/permissions`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      HTTP_ORIGIN: `${process.env.REACT_APP_HTTP_ORIGIN}`,
      'X-AUTH-TOKEN': token,
      'X-STORE-ID': storeId,
      'X-TENANT-ID': tenantId,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((res) => res.json())
  return response?.data
}

export const authService = {login, logOut, googleLogin, storePermissions, userLogin}

export default authService
