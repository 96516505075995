import {useInfiniteQuery} from 'react-query'
import {getInActiveCustomers} from '../services'
import useFilters from '../../../hook/useFilters'
import {dataToCustomerIPInformationList} from '../utils'

const initialFilters = {
  page: 1,
  limit: 20,
}

export default function useGetCustomerIPList(customerID: string | number) {
  const {filters} = useFilters(initialFilters)

  const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = useInfiniteQuery(
    `customer-${customerID}-ip-info`,
    ({pageParam = initialFilters.page}) => {
      return getInActiveCustomers(`/analytics/customers/${customerID}/ip-info`, {
        ...filters,
        page: pageParam,
      })
    },
    {
      getNextPageParam: (res) => {
        return res?.meta?.pagination?.next_page ? res?.meta?.pagination?.next_page : undefined
      },
    }
  )

  const onPageChange = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  return {
    customerIPInformations:
      data?.pages?.flatMap((pageData) => dataToCustomerIPInformationList(pageData['data'])) || [],
    isLoading: isFetchingNextPage,
    loadMore: onPageChange,
  }
}
