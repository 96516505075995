export function simulateEvent(name: any, value: any) {
  return {target: {name, value}} as React.ChangeEvent<HTMLSelectElement>
}

export function getFormError(errors: any, name: string) {
  return (errors && errors[name] && errors[name].message) || ''
}

export function requiredMsg(name: string = 'field') {
  return `Please enter a ${name}`
}

export function selectMsg(name: string = 'field') {
  return `Please select ${name}`
}

export function emailMsg() {
  return 'Please enter a valid email'
}

export function emailRegMsg() {
  return "Email should not include capital letters and '"
}
