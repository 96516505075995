import {useContext} from 'react'
import {MembersDetailsReportPageContext} from '../../contexts'
import Search from '../../../../components/Search'

const ProjectMembersDetailsHeader = () => {
  const {onSearch} = useContext(MembersDetailsReportPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch(searchTerm)
        }}
      />
    </div>
  )
}

export default ProjectMembersDetailsHeader
