import {useContext} from 'react'
import {Pagination} from '../../../utils/pagination'
import {useGetMembersList} from '../hooks/members/useGetMembersList'
import {ProjectDetailsContext, ProjectMembersPageContext} from '../context/index'
import {MemberPageHeader} from '../sections/headers/MemberPageHeader'
import {useGetDropdownMembers} from '../hooks/members/useGetDropdownMembers'
import MemberPageTable from '../sections/tables/memberTables/MemberPageTable'
import useProjectMembersOperations from '../hooks/members/useProjectMembersOperations'
import { useGetProjectMembersDropdownList } from '../hooks/members/useGetProjectMembersDropdownList'
import {useGetProjectsTeamsList} from '../../projectsSettings/hooks/teams/useGetProjectsTeamsList'

function Members() {
  const {projectId, projectName, projectStatus, hasProjectPermission} =
    useContext(ProjectDetailsContext)
  const hasMembersPermission = hasProjectPermission && projectStatus !== 'archived'
  const {
    projectMembersList,
    isLoading: isLoadingProjectMembersList,
    filters,
    pagination,
    onSearch,
    onPageChange,
    onSortingChange,
  }: any = useGetMembersList()
  const {
    addProjectMembers,
    addProjectTeams,
    removeProjectMember,
    transferProjectOwnership,
    isLoading: isLoadingOperation,
  } = useProjectMembersOperations()
  const {membersList, isLoading: isLoadingMembersList} = useGetDropdownMembers()
  const {projectMembersDropdownList, isLoading: isLoadingProjectMembersDropdownList} =
    useGetProjectMembersDropdownList()
  const {ProjectsTeamsList, isLoading: isLoadingProjectsTeamsList} = useGetProjectsTeamsList('active')

  const contextValue = {
    hasMembersPermission,

    projectId,
    projectName,
    projectStatus,

    projectMembersList,
    isLoadingProjectMembersList,
    filters,
    onSearch,
    onSortingChange,

    membersList,
    isLoadingMembersList,

    addProjectMembers,
    addProjectTeams,
    removeProjectMember,
    transferProjectOwnership,
    isLoadingOperation,

    projectMembersDropdownList,
    isLoadingProjectMembersDropdownList,

    isLoadingProjectsTeamsList,
    ProjectsTeamsList,
  }

  return (
    <>
      <ProjectMembersPageContext.Provider value={contextValue}>
        <MemberPageHeader />
        <MemberPageTable />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </ProjectMembersPageContext.Provider>
    </>
  )
}

export default Members
