import {createContext} from 'react'

export const MembersDetailsReportPageContext = createContext({
  hasProjectSettingsPermission: false,
  tasksReportsData: [] as any,
  isLoading: false,
  onSortingChange: (key: string, value: string) => {},
  onSearch: (e: string) => {},
  filters: {},
  searchValue: '',
  refetch: () => {},
  onPageChange: (number: any) => {},
  pagination: {},
})
