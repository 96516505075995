import useApi from '../../../../services/useApi'
import {boardCustomFieldsData} from '../../parsings'
import {useQuery} from 'react-query'
import {useContext, useState} from 'react'
import {ProjectDetailsContext} from '../../context'

const useGetBoardCustomFieldsList = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const {data}: any = useQuery(['projectMappingData']) || null
  const tableId = data?.tableId || null
  const [isInitialDropdownListFetched, setIsInitialDropdownListFetched] = useState(false)

  // get custom fields listing
  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/${projectId}/custom-fields`,
    {
      queryId: 'board-custom-fields',
      isErrorPageRedirection: false,
    }
  )

  // dropdown list for custom field mapping
  const {data: dropdownListResponse, isFetching: isLoadingCustomFieldMappingDropdownList} =
    Api.useGetQuery(
      `/agile/projects/dbtable/${tableId}/columns`,
      {
        queryId: `${tableId}-board-custom-fields-table-columns`,
        filters: {column_mapping: false},
        onSuccess: () => setIsInitialDropdownListFetched(true),
      },
      {
        enabled: !!tableId && !isInitialDropdownListFetched,
        cacheTime: 0,
        staleTime: 0,
      }
    )

  return {
    boardCustomFieldsList: boardCustomFieldsData(response?.data) || [],
    customFieldMappingDropdownList: dropdownListResponse?.data || [],
    isLoading: isFetching,
    isLoadingCustomFieldMappingDropdownList: isLoadingCustomFieldMappingDropdownList,
  }
}

export default useGetBoardCustomFieldsList
