import {useContext} from 'react'
import {InquiresPageListingContext} from '../../context'
import InquiresPageListingHeader from '../InquiresPageListingHeader'
import {DynamicTable} from '../../../../components/DynamicTable'
import InquiresPageTableRow from './InquiresPageTableRow'
import OverlayModal from '../OverlayModal'
import {dataToSortableColumns} from '../../utils'

function InquiresPageTable() {
  const {InquiresData, isLoading, onSortingChange, columns ,filters} = useContext(InquiresPageListingContext)

  return (
    <>
      <InquiresPageListingHeader />

      <div className='card-body p-0'>
        <DynamicTable
          id='inquires-management-table'
          data={InquiresData || []}
          sortableColumns={dataToSortableColumns(columns, (row: any) => (
            <div className='d-flex justify-content-center flex-shrink-0'>
              <OverlayModal row={row} />
            </div>
          ))}
          TableRow={InquiresPageTableRow}
          onSortingChange={onSortingChange}
          loading={isLoading}
          permissionPath='products_product inquiries'
          columns={columns}
          filters={filters}
        />
      </div>
    </>
  )
}

export default InquiresPageTable