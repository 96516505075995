import {useContext, useState, useRef, useEffect} from 'react'
import {TicketDetailsPageContext} from '../../context'
import {TextArea} from '../../../../components/TextArea'
import HtmlPlaceholder from '../../components/HtmlPlaceholder'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import useDynamicDate from '../../../../hook/useDynamicDate'
import {convertUTCToLocal} from '../../../../utils/date'
import {ticketActionMenuActions, ticketTitleTextareaHeight} from '../../utils'
import LinkTicketModal from '../modals/LinkTicketModal'
import {DropdownMenu} from '../../../../components/DropdownMenu'

const TicketDetailsPageHeader = ({register, onSave, ticketTitle, errors}: any) => {
  const {
    ticketDetails,
    isTicketEditable,
    setIsTicketEditable,
    deleteTicket,
    isLoadingTicketOperation,
    isLoadingTicketDetails,
    projectName,
    hasTicketWritePermission,
  } = useContext(TicketDetailsPageContext)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const {dynamicTimestamp: dynamicCreatedAt} = useDynamicDate(ticketDetails?.created_at)
  const {dynamicTimestamp: dynamicUpdatedAt} = useDynamicDate(ticketDetails?.updated_at)
  const hasTicketCreatorOrUpdater = ticketDetails?.ticket_creator || ticketDetails?.ticket_updater
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false)
  const [menuEvent, setMenuEvent] = useState('')

  useEffect(() => {
    if (textareaRef.current) {
      ticketTitleTextareaHeight(textareaRef)
      if (isTicketEditable) {
        textareaRef.current.focus()
      }
    }
  }, [isTicketEditable, ticketTitle])

  const onEnterInTitle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      onSave()
      e.preventDefault()
    }
  }

  const onDeleteTicket = () => {
    deleteTicket()
  }

  const handleMenuSelect = ({eventKey}: any) => {
    ticketActionMenuActions(
      eventKey,
      setMenuEvent,
      setIsTicketEditable,
      setShowCreateTicketModal,
      setShowDeleteModal
    )
  }

  return (
    <div className={`ticket-header-sticky mt-5 bg-white ${isTicketEditable ? 'mb-4' : 'mb-10'}`}>
      <div className='d-flex justify-content-between gap-3'>
        <div className='w-100 d-flex gap-4 align-items-start'>
          {isTicketEditable ? (
            <TextArea
              id='edit-ticket-title'
              register={register('title')}
              onKeyDown={onEnterInTitle}
              className='w-100'
              rows={1}
              value={ticketTitle}
              inputClass='px-0 pt-2 pb-0 text-dark border-bottom border-top-0 border-start-0 border-end-0 resize-none rounded-0 overflow-hidden fs-2 fw-bolder lh-base p-0 m-0'
              error={errors?.title}
              textAreaRef={textareaRef}
              maxLength={255}
            />
          ) : (
            <h1
              className={`mb-0 fs-2 fw-bolder lh-base ${
                hasTicketWritePermission ? 'cursor-pointer' : ''
              }`}
              onClick={() => hasTicketWritePermission && setIsTicketEditable(true)}
            >
              <HtmlPlaceholder className='w-200px'>{ticketTitle}</HtmlPlaceholder>
            </h1>
          )}
        </div>

        <DropdownMenu
          title={<i className='fa-solid fa-ellipsis-vertical fs-5' />}
          noCaret={true}
          placement='bottomEnd'
          onSelect={handleMenuSelect}
          show={hasTicketWritePermission}
          items={ticketActionMenuOptions}
        />
      </div>

      {ticketDetails?.length !== 0 && !isTicketEditable && hasTicketCreatorOrUpdater && (
        <div className='mt-4 fs-7 fw-semibold'>
          <span className='text-muted'>
            Created by{' '}
            <span className='text-dark cursor-pointer fw-bolder' title={ticketDetails?.created_by}>
              {ticketDetails?.ticket_creator}
            </span>{' '}
            <span className='app-default-date-cell position-relative'>
              <span className='app-default-date-area position-relative'>
                {dynamicCreatedAt}
                <span className='popover app-default-date-tooltip fw-semibold lh-1 fs-8'>
                  {convertUTCToLocal(ticketDetails?.created_at)}
                </span>
              </span>
            </span>
          </span>
          {ticketDetails?.ticket_updater && (
            <span className='text-muted border-start ps-2 ms-2'>
              Updated by{' '}
              <span
                className='text-dark cursor-pointer fw-bolder'
                title={ticketDetails?.updated_by}
              >
                {ticketDetails?.ticket_updater}
              </span>{' '}
              <span className='app-default-date-cell position-relative'>
                <span className='app-default-date-area position-relative'>
                  {dynamicUpdatedAt}
                  <span className='popover app-default-date-tooltip fw-semibold lh-1 fs-8'>
                    {convertUTCToLocal(ticketDetails?.updated_at)}
                  </span>
                </span>
              </span>
            </span>
          )}
        </div>
      )}

      {showCreateTicketModal && (
        <LinkTicketModal
          show={showCreateTicketModal}
          isCreateFromBoard={true}
          onClose={() => setShowCreateTicketModal(false)}
          menuEvent={menuEvent}
        />
      )}

      {hasTicketWritePermission && showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onAction={onDeleteTicket}
          isDataLoading={isLoadingTicketDetails}
          isOperationLoading={isLoadingTicketOperation}
          body={
            <p>
              Do you really want to remove '<strong>{ticketTitle}</strong>' ticket from '
              <strong>{projectName}</strong>' project?
            </p>
          }
        />
      )}
    </div>
  )
}

const ticketActionMenuOptions = [
  {
    eventKey: 'edit',
    label: 'Edit',
    icon: <i className='fa-regular fa-pen-to-square me-2' />,
  },
  {
    eventKey: 'parent-ticket',
    label: 'Assign parent ticket',
    icon: <i className='fa-solid fa-circle-up me-2' />,
  },
  {
    eventKey: 'sub-ticket',
    label: 'Assign sub ticket',
    icon: <i className='fa-solid fa-circle-down me-2' />,
  },
  {
    eventKey: 'delete',
    label: 'Delete',
    icon: <i className='fa-regular fa-trash-can me-2 text-danger active-text-white' />,
    className: 'text-danger active-bg-danger active-text-white',
  },
]

export default TicketDetailsPageHeader
