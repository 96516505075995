export const highlightTime = (d: any, u: any) => {
  const isDayOff = (date: any) => {
    const d = date.getDay()
    return d === 0 || d === 6
  }

  const isHourOff = (date: any) => {
    const h = date.getHours()
    return h < 8 || h === 12 || h > 17
  }

  if (u === 'day' && isDayOff(d)) return 'wx-weekend'
  if (u === 'hour' && (isDayOff(d) || isHourOff(d))) return 'wx-weekend'
  return ''
}
