import usePermission from '../../../hook/usePermission'
import {MatrixReportPageContext} from '../context'
import useGetMatrixData from '../hooks/MatrixReport/useGetMatrixData'
import {useGetProjectsDropdownList} from '../hooks/useGetProjectsDropdownList'
import MatrixHeader from '../sections/headers/MatrixHeader'
import MatrixTable from '../sections/tables/MatrixTable'

const Matrix = () => {
  const {hasPermission} = usePermission()
  const hasProjectSettingsPermission = hasPermission('projects_settings', 'write')

  const {
    isLoading: isLoadingMatrixData,
    xAxisMatrixData,
    yAxisMatrixData,
    onSearch,
    filters,
    searchValue,
    onSortingChange,
    onProjectChange,
    onXAxisChange,
    onYAxisChange,
    onBothChange
  } = useGetMatrixData()

  const {isLoading: isLoadingProjectDropdownList, projectsDropdownList} =
    useGetProjectsDropdownList()

  const contextValue = {
    projectsDropdownList,
    isLoadingProjectDropdownList,

    hasProjectSettingsPermission,
    isLoadingMatrixData,
    xAxisMatrixData,
    yAxisMatrixData,
    onSortingChange,
    onSearch,
    searchValue,
    filters,
    onProjectChange,
    onXAxisChange,
    onYAxisChange,
    onBothChange
  }

  return (
    <MatrixReportPageContext.Provider value={contextValue}>
      <MatrixHeader />
      <MatrixTable />
    </MatrixReportPageContext.Provider>
  )
}

export default Matrix
