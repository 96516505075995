import {Select} from '../../../../components/Select/'
import {dataToSelectOptions, isEmpty} from '../../../../utils/common'
import {MatrixReportPageContext} from '../../context'
import {useContext, useEffect, useState} from 'react'
import {matrixAxisOptions} from '../../utils'

const MatrixHeader = () => {
  const {
    projectsDropdownList,
    isLoadingProjectDropdownList,
    onProjectChange,
    onXAxisChange,
    onYAxisChange,
  } = useContext(MatrixReportPageContext)

  const [selectedProject, setSelectedProject] = useState('')
  const [selectedXAxis, setSelectedXAxis] = useState('Module')
  const [selectedYAxis, setSelectedYAxis] = useState('Assignee')

  useEffect(() => {
    if (!isEmpty(projectsDropdownList) && !isLoadingProjectDropdownList && !selectedProject) {
      setSelectedProject(projectsDropdownList[0].id)
      onProjectChange(projectsDropdownList[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsDropdownList, isLoadingProjectDropdownList, selectedProject])

  const handleProjectChange = (e: any) => {
    setSelectedProject(e.target.value)
    onProjectChange(e.target.value)
  }

  const handleXAxisChange = (e: any) => {
    const value = e.target.value
    setSelectedXAxis(value)
    onXAxisChange(value)
  }

  const handleYAxisChange = (e: any) => {
    const value = e.target.value
    setSelectedYAxis(value)
    onYAxisChange(value)
  }

  const getAxisOptions = (selectedValue: string) => {
    return matrixAxisOptions.map((option) => ({
      ...option,
      isDisabled: option.value === selectedValue,
    }))
  }

  return (
    <div className='mb-10'>
      <div className='mb-7'>
        <div className='w-250px'>
          <Select
            id='product-status'
            options={dataToSelectOptions(projectsDropdownList, 'name', 'id')}
            onChange={handleProjectChange}
            isLoading={isLoadingProjectDropdownList}
            value={selectedProject}
          />
        </div>
      </div>
      <div className='d-flex gap-5'>
        <Select
          id='x-axis'
          className='d-flex align-items-center'
          label='X-Axis:'
          labelClass='me-2'
          disabledKey={'isDisabled'}
          options={getAxisOptions(selectedYAxis)}
          onChange={handleXAxisChange}
          value={selectedXAxis}
        />
        <Select
          id='y-axis'
          className='d-flex align-items-center'
          labelClass='me-2'
          label='Y-Axis:'
          disabledKey={'isDisabled'}
          options={getAxisOptions(selectedXAxis)}
          onChange={handleYAxisChange}
          value={selectedYAxis}
        />
      </div>
    </div>
  )
}

export default MatrixHeader
