import {Link} from 'react-router-dom'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import Initials from '../../../../components/Initials'
import InfiniteScrollWrapper from '../../../../components/InfiniteScrollWrapper'
import useGetCustomers from '../../hooks/useGetCustomers'
import Loading from '../../../loading'

const CostumersListModal = ({show, onClose, repEmail}: any) => {
  const {customers, loadMore, isLoading} = useGetCustomers(repEmail)

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={'Customers'}
      bodyClass='mh-550px overflow-auto'
      body={
        <InfiniteScrollWrapper name='ip' loadMore={loadMore} data={customers} height={300}>
          <div className='table-responsive position-relative'>
            <div className='table-loader-wrapper'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
                <thead className='table-row-bordered'>
                  <tr className='fs-6 fw-semibold text-muted'>
                    <th className='min-w-150px'>{'CUSTOMER NAME'}</th>
                  </tr>
                </thead>
                <tbody>
                  {customers?.map((row: any) => (
                    <tr>
                      <Link
                        to={`/customers/all-customer/details?id=${row['customer_id']}`}
                        state={{
                          id: row['customer_id'],
                        }}
                        className='d-flex align-items-center flex-grow-1 text-start cursor-pointer'
                      >
                        <div className='symbol symbol-circle symbol-35px me-3'>
                          <Initials text={row['name']?.[0]} />
                        </div>
                        <div className='d-flex flex-column'>
                          <div className='align-items-center text-dark fw-semibold'>
                            {row['name']}
                          </div>
                          <div className='align-items-center text-muted'>{row['email']}</div>
                        </div>
                      </Link>
                    </tr>
                  ))}
                </tbody>
              </table>
              {isLoading && <Loading />}
            </div>
          </div>
        </InfiniteScrollWrapper>
      }
    />
  )
}

export default CostumersListModal
