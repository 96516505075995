import {useContext, useState} from 'react'
import useApi from '../../../../services/useApi'
import {ProjectDetailsContext} from '../../context'

const useProjectMappingOperations = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const [isLoading, setLoading] = useState(false)

  const updateProjectMapping = (payload: any) => {
    return Api.post(`/agile/projects/${projectId}/dbtable`, payload, {
      setLoading: setLoading,
      refetch: [
        'project-details',
        `${payload?.tableId}-${payload?.columnKey}-status-dropdown-list`,
        'board-columns',
        `${payload?.tableId}-board-custom-fields-table-columns`,
        'board-custom-fields',
      ],
    })
  }

  return {
    updateProjectMapping,
    isLoading,
  }
}

export default useProjectMappingOperations
