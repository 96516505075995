import {isEmpty} from '../../../utils/common'

const parseTimeStringToMinutes = (timeString: string) => {
  const timeRegex = /(\d+)\s*h|\s*(\d+)\s*m/g;
  let totalMinutes = 0;
  let match;

  while ((match = timeRegex.exec(timeString)) !== null) {
    if (match[1]) {
      totalMinutes += parseInt(match[1], 10) * 60;
    }
    if (match[2]) {
      totalMinutes += parseInt(match[2], 10);
    }
  }

  return totalMinutes;
}


// parsing for members table
export function dataToMembersReportRow(row: any) {
  const totalEstimationInMinutes = parseTimeStringToMinutes(row['total_estimation'])
  const totalSpentInMinutes = parseTimeStringToMinutes(row['total_spent_time'])
  const isOverEstimation = totalSpentInMinutes > totalEstimationInMinutes

  return {
    id: row['id'] || '',
    assignee: row['assignee'] || '-',
    totalCards: row['total_cards'] || 0,
    totalEstimation: row['total_estimation'] || '-',
    totalSpentTime: row['total_spent_time'] || '-',
    totalRemainingTime: row['total_remaining_time'] || '-',
    progressPercentage: row['progress_percentage'],
    email: row['username'] || 'Unassigned',
    isOverEstimation,
  }
}

// parsing for tasks table
export function dataToTasksReportRow(row: any) {
  const totalEstimationInMinutes = parseTimeStringToMinutes(row['estimation'])
  const totalSpentInMinutes = parseTimeStringToMinutes(row['spent_time'])
  const isOverEstimation = totalSpentInMinutes > totalEstimationInMinutes

  return {
    id: row['id'] || '',
    cardIdentifier: row['card_identifier'] || '-',
    cardTitle: row['card_title'] || '-',
    cardId: row['card_id'] || '',
    projectId: row['project_id'] || '',
    projectName: row['project_name'] || '-',
    assignee: row['assignee'] || '-',
    moduleName: row['module_name'] || '-',
    estimation: row['estimation'] || '-',
    spentTime: row['spent_time'] || '-',
    remainingTime: row['remaining_time'] || '-',
    progressPercentage: row['progress'],
    resolved: row['is_resolved'],
    isOverEstimation,
  }
}

// parsing for time sheet table
export const timeSheetTableRowData = (data: any) => {
  if (isEmpty(data)) return {}

  return {}
}
