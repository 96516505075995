import Api from '../../../services/Api'

export function updateDefaultColumnsTableSorting(data: any) {
  const url = `/settings/columns`

  return Api.post(url, data, null, null).then((d: any) => {
    return d
  })
}

export function getAllDefaultColumns(filters: any) {
  const url = `/settings/columns`
  return Api.get(url, filters).then((d: any) => {
    return d.data
  })
}
