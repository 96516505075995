import { makeSemicolonSepratedValues } from "../../../../utils/common"
import { format24Hour } from "../../../../utils/date"

export const formDataToSave= (data: any) => {
    return {
        product_sku: data?.['product_sku'],
        disable_promotion: data?.['disable_promotion'],
        promotion_threshold_quantity: data?.['disable_promotion'] ? parseInt(data?.['promotion_threshold_quantity']) : 0,
        promotion_id: data?.['disable_promotion'] ? parseInt(data?.['promotion_id']) : 0,
        promotion_disable_date: data?.['disable_promotion'] ? format24Hour(data?.['promotion_disable_date'], true) : '',
        hide_product: data?.['hide_product'],
        customer_rep: data?.['hide_product'] ? makeSemicolonSepratedValues(data?.['hide_customer_rep']) : '',
        hide_out_of_stock_days: data?.['hide_product'] ? (parseInt(data?.['hide_out_of_stock_days']) || 0) : 0,
        hide_product_name_prefix: data?.['hide_product'] ? (data?.['hide_product_name_prefix'] || '') : '',
        hide_category_id: data?.['hide_product'] ? makeSemicolonSepratedValues(data?.['hide_category_id']) : '',
        classification: data?.['hide_product'] ? data?.['hide_classification'] : '',      
    }
  }

  export const variantFormDataToSave= (data: any) => {
    return {
        variant_sku: data?.['variant_sku'],               
        out_of_stock_days: parseInt(data?.['out_of_stock_days']) || 0,
        hide_product_name_prefix: data?.['hide_product_name_prefix'] || '',        
        hide_classification: data?.['hide_classification'],      
    }
  }

  export const dataToMultiSelectCategory = (data: any, type: string) => {
    const res: any = []
  
    if (data?.[type]?.length) {
      data[type].forEach((i: any) => {        
        res.push({
          value: i?.id,
          label: i?.name,
        })
      })
    }
  
    return res
  }