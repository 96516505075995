import {Link, useLocation} from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import clsx from 'clsx'
import RightArrow from '../images/right-arrow.png'
import LeftArrow from '../images/left-arrow.png'

// Iterate through all the elements and relacing labels ...
const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

export const Pagination = ({pagination, onPageChange, scrollDivRef}: any) => {
  const location = useLocation()
  const pathAndQuery = location.pathname + location.search

  const handlePageChange = (nextPage: any) => {
    onPageChange(nextPage)
    // Scroll to the top of the page
    if (scrollDivRef && scrollDivRef.current) {
      scrollDivRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
    } else {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }

  return (
    <div className='mt-5'>
      <div className='d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {!isEmpty(pagination) &&
              pagination.links
                ?.map((link: any) => {
                  return {...link, label: mappedLabel(link.label)}
                })
                ?.map((link: any) => (
                  <li
                    key={link.label}
                    className={clsx('page-item', {
                      active: link.active,
                      disabled: false,
                      previous: link.label === 'Previous',
                      next: link.label === 'Next',
                      first: link.label === 'First',
                      last: link.label === 'last',
                    })}
                  >
                    <Link
                      className={clsx('page-link', {
                        'page-text':
                          link.label === 'Previous' ||
                          link.label === 'Next' ||
                          link.label === 'First' ||
                          link.label === 'Last',
                        '': link.label === 'Previous',
                      })}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        if (link['label'] === 'Next') {
                          handlePageChange(pagination['next_page'])
                        } else if (link['label'] === 'Previous') {
                          handlePageChange(pagination['prev_page'])
                        } else if (link['label'] === 'First') {
                          handlePageChange(pagination['first_page'])
                        } else if (link['label'] === 'Last') {
                          handlePageChange(pagination['last_page'])
                        } else {
                          handlePageChange(link['label'])
                        }
                      }}
                      // to={link?.['url'] === 'javascript:void(0)' ? `${link['url']}` : `${location.pathname}${link['url']}`}
                      to={`${pathAndQuery}`}
                    >
                      {link.label === 'Previous' || link.label === 'Next' ? (
                        link.label === 'Previous' ? (
                          <img src={LeftArrow} alt='' />
                        ) : (
                          <img src={RightArrow} alt='' />
                        )
                      ) : (
                        mappedLabel(link.label)
                      )}
                    </Link>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
