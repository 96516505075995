import { ExecutedProductRuleSearch } from './ExecutedProductRuleSearch'

const ExecutedProductRuleHeader = () => {
//   const [modal, setModal] = useState(false)

//   const toggleModal = () => {
//     setModal(!modal)
//   }
//   const onClose = () => {
//     setModal(false)
//   }

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex'>
        <ExecutedProductRuleSearch />
      </div>
      {/* <div>
        <button onClick={toggleModal} className='btn btn-primary'>
          + Create Rule
        </button>
      </div>
      {modal && <CreateRule show={modal} onClose={onClose}/>} */}
    </div>
  )
}

export {ExecutedProductRuleHeader}
