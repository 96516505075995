import React, {useContext, useEffect, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {ProjectsTeamsPageContext} from '../../context'
import {Select} from '../../../../components/Select/'
import {addProjectTeamsSchema, editProjectTeamsSchema} from '../../validations'
import {ProjectTeamsModalTypes} from '../../interfaces'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {RadioGroup} from '../../../../components/RadioGroup'
import MultipleSelect from '../../../../components/MultiSelect/MultiSelect'
import {InputText} from '../../../../components/InputText'
import {useGetTeamMemberDetailsById} from '../../hooks/teams/useGetProjectsTeamMembersById'
import {dataToSelectOptions} from '../../../../utils/common'

const ProjectTeamsModal = ({
  show,
  onClose,
  isEdit,
  projectTeamModalDetails,
}: ProjectTeamsModalTypes) => {
  const {
    addProjectTeams,
    isLoadingProjectsTeamsOperation,
    globalProjectsMembersDropdownList,
    updateProjectTeam,
    ProjectsTeamsList,
    isLoadingProjectsTeamsList,
  } = useContext(ProjectsTeamsPageContext)

  const [teamType, setTeamType] = useState('new')
  const [teamMembers, setTeamMembers] = useState<any[]>([])
  const [isClearDefaultValue, setIsClearDefaultValue] = useState(false)
  const isComponentMounted = useRef(false)

  const {
    handleSubmit,
    control,
    watch,
    formState: {errors, isDirty, isValid, dirtyFields},
    reset,
    setFocus,
    setError,
  } = useForm({
    defaultValues: {
      teamType: 'new',
      teamId: '',
      name: projectTeamModalDetails?.name || '',
      usernames: [],
    },
    resolver: yupResolver(
      teamType === 'existing' && !isEdit ? editProjectTeamsSchema : addProjectTeamsSchema
    ),
  })

  const formData = watch()
  const {teamMemberDetails, isLoading} = useGetTeamMemberDetailsById(
    formData?.teamId || projectTeamModalDetails?.id
  )
  useEffect(() => {
    if (teamMemberDetails.length) {
      setTeamMembers(teamMemberDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (isComponentMounted.current) {
      setIsClearDefaultValue(true)
      reset({
        teamType: teamType,
        teamId: '',
        name: '',
        usernames: [],
      })
      setTeamMembers([])
    } else {
      isComponentMounted.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamType])

  useEffect(() => {
    if (show) {
      setFocus('name')
    }
  }, [show, setFocus])

  useEffect(() => {
    if (isClearDefaultValue) {
      setIsClearDefaultValue(false)
    }
  }, [isClearDefaultValue])

  useEffect(() => {
    if (projectTeamModalDetails) {
      reset({
        teamType: 'new',
        teamId: projectTeamModalDetails?.id,
        name: projectTeamModalDetails?.name || '',
        usernames: teamMembers.map((member: any) => member.username),
      })
    }
  }, [projectTeamModalDetails, reset, teamMembers])

  const onAddTeam = (data: any) => {
    const getUpdatedFields = (currentData: any, dirtyFields: any) => {
      return Object.keys(dirtyFields).reduce((acc: any, key) => {
        if (key !== 'teamId') {
          acc[key] = currentData[key]
        }
        return acc
      }, {})
    }
    if (isEdit || teamType === 'existing') {
      const updateData = getUpdatedFields(data, dirtyFields)
      if (Object.keys(updateData).length > 0) {
        const res: any = updateProjectTeam(data.teamId, updateData, setError)
        if (res?.status === 200) {
          setIsClearDefaultValue(true)
          reset({
            teamType: teamType,
            teamId: '',
            name: '',
            usernames: [],
          })
          setTeamMembers([])
        }
      }
    } else {
      const addData = {
        name: data.name,
        usernames: data.usernames,
      }
      const res: any = addProjectTeams(addData, setError)
      if (res?.status === 200) {
        setIsClearDefaultValue(true)
        reset({
          teamType: teamType,
          teamId: '',
          name: '',
          usernames: [],
        })
        setTeamMembers([])
      }
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={isEdit ? 'Edit Team' : 'Create Team'}
      onAction={handleSubmit(onAddTeam)}
      isDisabled={!isDirty || !isValid || isLoadingProjectsTeamsOperation}
      isOperationLoading={isLoadingProjectsTeamsOperation}
      actionName={isEdit || teamType === 'existing' ? 'Save' : 'Create'}
      actionBtnClass='btn-primary'
      isDataLoading={isLoadingProjectsTeamsList}
      body={
        <form>
          {!isEdit && ProjectsTeamsList?.length !== 0 && (
            <div className='mb-8'>
              <div className='d-flex'>
                <RadioGroup
                  id='newTeam'
                  className='me-10'
                  name='teamType'
                  label='New'
                  value='new'
                  checked={teamType === 'new'}
                  onChange={() => setTeamType('new')}
                />
                <RadioGroup
                  id='existingTeam'
                  name='teamType'
                  label='Existing'
                  value='existing'
                  checked={teamType === 'existing'}
                  onChange={() => setTeamType('existing')}
                />
              </div>
            </div>
          )}

          {!isEdit && teamType === 'existing' ? (
            <div className='mb-4'>
              <Controller
                name='teamId'
                control={control}
                render={({field: {name, value, onChange}}) => (
                  <Select
                    id={'teamId'}
                    isRequired={true}
                    label='Team'
                    name={name}
                    options={dataToSelectOptions(ProjectsTeamsList, 'name', 'id')}
                    placeholder='Select Team'
                    error={errors.teamId}
                    value={value}
                    onChange={(e: any) => onChange(e.target.value)}
                  />
                )}
              />
            </div>
          ) : (
            <div className='mb-8'>
              <Controller
                name='name'
                control={control}
                render={({field}) => (
                  <InputText
                    id={`name`}
                    label='Team Name'
                    isRequired={true}
                    placeholder='Enter team name'
                    className='w-100'
                    maxLength={50}
                    error={errors?.name}
                    {...field}
                  />
                )}
              />
            </div>
          )}

          <div className=''>
            <Controller
              name='usernames'
              control={control}
              render={({field: {name, value, onChange}}) => (
                <MultipleSelect
                  id='global-project-members'
                  options={dataToSelectOptions(
                    globalProjectsMembersDropdownList,
                    'name',
                    'username'
                  )}
                  label='Select Member'
                  isRequired={true}
                  control={control}
                  registerKey='usernames'
                  error={errors?.usernames}
                  selectedValues={dataToSelectOptions(teamMembers, 'name', 'username')}
                  defaultValues={
                    teamType === 'existing'
                      ? dataToSelectOptions(teamMembers, 'name', 'username')
                      : null
                  }
                  disabled={teamType === 'existing' && !formData?.teamId}
                  isClearDefaultValue={isClearDefaultValue}
                  isLoading={isLoading}
                />
              )}
            />
          </div>
        </form>
      }
    />
  )
}

export default ProjectTeamsModal
