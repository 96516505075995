const ProgressCircle = ({progress}: {progress: number}) => {
    return (
      <svg width='20' height='20' viewBox='-2 -2 40 40'>
        <circle cx='18' cy='18' r='16' fill='none' stroke='#f3f4f6' strokeWidth='6' />
        <circle
          cx='18'
          cy='18'
          r='16'
          fill='none'
          stroke='#466AEC'
          strokeWidth='6'
          strokeDasharray={`${progress} 100`}
          transform='rotate(-90 18 18)'
        />
      </svg>
    )
  }

  export default ProgressCircle
