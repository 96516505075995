import {useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import RoleActionDropdown from '../dropdown/RoleActionDropdown'
import DeleteRoleConfirmationModal from './DeleteRoleConfirmationModal'
import AddRoleModal from './AddRoleModal'

const OverlayModal = ({id,users}: any) => {
  const [openModal, setOpenModal] = useState(false)
  const [modal, setModal] = useState(false)
  const handleModalOpen = () => {
    setOpenModal(true)
    document.body.click()
  }
  const onEdit = () => {
    setModal(true)
    document.body.click()
  }
  const onClose = () => {
    setOpenModal(false)
    setModal(false)
  }
  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x' data-kt-menu-trigger='click'></i>}
        children={<RoleActionDropdown roleId={id} onEdit={onEdit} handleModel={handleModalOpen} users={users}/>}
      />
      <AddRoleModal isEdit={true} show={modal} onClose={onClose} editId={id} />
      <DeleteRoleConfirmationModal show={openModal} onClose={onClose} roleId={id} />
    </>
  )
}

export default OverlayModal
