import {PointerSensor} from '@dnd-kit/core'

// Custom PointerSensor to allow drag events only on elements with dnd-board-area
export class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({nativeEvent}: {nativeEvent: PointerEvent}) => {
        const target = nativeEvent.target as HTMLElement
        // Allow drag start only if the target or its parent has dnd-board-area attribute
        if (target.closest('[dnd-board-area]')) {
          return true
        }
        // Block drag for other elements
        return false
      },
    },
  ]
}
