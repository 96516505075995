import {useQuery} from 'react-query'
import Api from '../../../services/Api'

async function getCustomerTags(url: string) {
  const response = await Api.get(url, {}, {})
  return response?.data
}
const useGetCustomerTagsDropdown = () => {
  const url = `/tags?entity_type=customer`

  const {data: customerTags, isLoading} = useQuery(`get-customer-Tags-data`, () => {
    return getCustomerTags(url)
  })
  return {customerTags, isLoading}
}

export default useGetCustomerTagsDropdown
