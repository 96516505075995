import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../_metronic/layout/core'
import {WithChildren} from '../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import UserPage from '../modules/user/Users'
import Automation from '../modules/analyticsAutomation/Automation'
import UnhideProductRules from '../modules/analyticsUnhideProducts/UnhideProductRulesPage'
import ProjectPage from '../modules/project/ProjectPage'
import ProjectsPage from '../modules/projects/ProjectsPage'
import ProjectsSettingsPage from '../modules/projectsSettings/ProjectsSettingsPage'
import InactiveCustomerPage from '../modules/idealCustomerAnalysis/page/InactiveCustomerPage'
import AnonymouslyActiveCustomerPage from '../modules/idealCustomerAnalysis/page/AnonymouslyActiveCustomerPage'
import RecentlyInactiveCustomerPage from '../modules/idealCustomerAnalysis/page/RecentlyInactiveCustomerPage'
import AggregatedProductReportPage from '../modules/soldProductDashboard/page/AggregatedProductReportPage'
import AggregatedVendorsTablePage from '../modules/consignmentDashboard/pages/AggregatedVendorsTablePage'
import CustomersReport from '../modules/analyticDetails/pages/CustomersReport'
import usePermission from '../hook/usePermission'
import InquiresPage from '../modules/inquires/InquiresPage'
import ProjectTicketDetailsPage from '../modules/projectTicketDetails/ProjectTicketDetailsPage'
import ProjectReportsPage from '../modules/projectReports/ProjectReportsPage'
import BoReportsPage from '../modules/boReports/BoReportsPage'
import ProjectReportsDetailsPage from '../modules/projectReportsDetails/ProjectReportsDetailsPage'
import ProductLimitPage from '../modules/analyticsProductLimitReport/ProductLimitReport'

// -----------------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------------

const PrivateRoutes = () => {
  const {hasPermission} = usePermission()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  // products ...

  // orders ...
  const OrderPage = lazy(() => import('../modules/orders/OrderPage'))
  const OrderDetailsPage = lazy(() => import('../modules/order/OrderDetailsPage'))
  const ManageProduct = lazy(() => import('../modules/manageBlukOrders/ManageProduct'))
  const ProductInfo = lazy(() => import('../modules/manageBlukOrders/layouts/ProductInfo'))
  const BulkOrderProductsList = lazy(
    () => import('../modules/manageBlukOrders/BulkOrderProductsList')
  )
  const PendingPoRequest = lazy(() => import('../modules/poRequest/PendingPoRequest'))
  const CompletedPoRequest = lazy(() => import('../modules/poRequest/CompletedPoRequest'))
  const DeletedPoRequest = lazy(() => import('../modules/poRequest/DeletedPoRequest'))
  const PoRequestDetails = lazy(() => import('../modules/poRequest/PoRequestDetails'))
  const BOBrands = lazy(() => import('../modules/boBrands/BOBrands'))
  const BlockOrders = lazy(() => import('../modules/blockOrders/BlockOrders'))
  const BlockedOrderDetails = lazy(() => import('../modules/blockedOrder/BlockedOrder'))
  // prodcuts ...
  const ProductsPage = lazy(() => import('../modules/products/ProductsPage'))
  const ProductsDetailsPage = lazy(() => import('../modules/product/ProductsDetailsPage'))
  const PriceListPage = lazy(() => import('../modules/priceList/PriceListPage'))
  const MyProductDetailsPage = lazy(
    () => import('../modules/myProductDetails/MyProductDetailsPage')
  )

  // CategoriesPage
  const CategoriesPage = lazy(() => import('../modules/productCategories/CategoriesPage'))
  const CategoryDetailsPage = lazy(() => import('../modules/productCategory/ProductCategoryPage'))

  // BrandsPage
  const BrandsPage = lazy(() => import('../modules/productBrands/BrandsPage'))
  const BrandDetailsPage = lazy(() => import('../modules/productBrand/ProductBrandPage'))

  // customers ...
  const CustomerPage = lazy(() => import('../modules/customers/CustomerPage'))
  const CustomerDetailsPage = lazy(() => import('../modules/customer/CustomerDetailsPage'))

  // navigations ...
  const NavigationPage = lazy(() => import('../modules/navigations/NavigationPage'))
  const NavigationDetailsPage = lazy(() => import('../modules/navigation/NavigationDetailsPage'))

  // build ...
  const BuildsPage = lazy(() => import('../modules/builds/BuildsPage'))

  // site setting
  const SiteSetting = lazy(() => import('../modules/setting/SiteSetting'))

  // page builder
  const PageBuilder = lazy(() => import('../modules/webpages/WebPage'))
  const PageBuilderDetailsPage = lazy(() => import('../modules/pageBuilder/pageBuilder'))

  const AnalyticsDashboard = lazy(
    () => import('../modules/soldProductDashboard/AnalyticsDashboardPage')
  )

  const AnalyticsPage = lazy(() => import('../modules/analytics/AnalyticsPage'))
  const AnalyticsCartsPage = lazy(() => import('../modules/analyticsCarts/analyticsCartsPage'))
  const AnalyticsCartDetailsPage = lazy(
    () => import('../modules/analyticsCartDetails/analyticsCartDetailsPage')
  )
  const PDAPage = lazy(() => import('../modules/analyticDetails/PDAPage'))
  const ConsignmentsPage = lazy(() => import('../modules/consignmentDetails/ConsignmentsPage'))
  const ConsignmentProducts = lazy(() => import('../modules/consignment/ConsignmentsPage'))

  const AnalyticConsignmentsPage = lazy(
    () => import('../modules/consignmentDashboard/AnalyticConsignmentsPage')
  )

  const ReplenishmentPage = lazy(
    () => import('../modules/analyticsReplenishment/ReplenishmentPage')
  )

  const InventoryReportPage = lazy(() => import('../modules/inventoryReport/InventoryReportPage'))

  const CustomQueryDataPage = lazy(
    () => import('../modules/analyticsCustomQueryData/CustomQueryDataPage')
  )

  const IdleCustomerAnalysis = lazy(
    () => import('../modules/idealCustomerAnalysis/IdleCustomerAnalysis')
  )

  // utility setting
  const UtilitySetting = lazy(() => import('../modules/utility/UtilitySetting'))
  const UtilityPage = lazy(() => import('../modules/utilities/UtilityPage'))
  const ApiTokenPage = lazy(() => import('../modules/api-tokens/ApiTokensPage'))

  // blogs
  const Blogs = lazy(() => import('../modules/blogs/BlogsPage'))
  const BlogDetailsPage = lazy(() => import('../modules/blog/BlogDetails'))

  // appBuilder
  const AppBuilder = lazy(() => import('../modules/appBuilder/BuilderPage'))
  const AppDetailsPage = lazy(() => import('../modules/app/AppDetailsPage'))
  const LeafDetailsPage = lazy(() => import('../modules/appChild/LeafDetailsPage'))
  // const BlogDetailsPage = lazy(() => import('../modules/blog/BlogDetails'))

  // Loyalty app
  const LoyaltyApp = lazy(() => import('../modules/loyaltyApp/LoyaltyAppPage'))

  // mail templates
  const MailTemplatePage = lazy(() => import('../modules/mailTemplates/MailTemplatesPage'))
  const JobsPage = lazy(() => import('../modules/jobs/JobsPage'))
  const JobDetailsPage = lazy(() => import('../modules/jobDetails/JobDetailsPage'))

  //Footer Data
  const StoreInfoPage = lazy(() => import('../modules/storeInfo/StoreInfoPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        <Route path='login/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* Lazy Modules */}
        {/* Orders Routes Starts ... */}
        <Route
          path='/orders/all-orders/*'
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />
        <Route
          path='/user-login/*'
          element={
            <SuspensedView>
              <DashboardWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/all-orders/summary'
          element={
            <SuspensedView>
              <OrderDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/all-orders/history'
          element={
            <SuspensedView>
              <OrderDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/manage/products'
          element={
            <SuspensedView>
              <BulkOrderProductsList />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/manage/products/create'
          element={
            <SuspensedView>
              <ProductInfo />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/manage/products/:status/:bop_id/*'
          element={
            <SuspensedView>
              <ManageProduct />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/po-request/pending'
          element={
            <SuspensedView>
              <PendingPoRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/po-request/completed'
          element={
            <SuspensedView>
              <CompletedPoRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/po-request/deleted'
          element={
            <SuspensedView>
              <DeletedPoRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/po-request/:status/:po_id/*'
          element={
            <SuspensedView>
              <PoRequestDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/brands/*'
          element={
            <SuspensedView>
              <BOBrands />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/bo/reports/*'
          element={
            <SuspensedView>
              <BoReportsPage />
            </SuspensedView>
          }
        />

        <Route
          path='/orders/blocked-orders/*'
          element={
            <SuspensedView>
              <BlockOrders />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/blocked-orders/logs'
          element={
            <SuspensedView>
              <BlockedOrderDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/blocked-orders/summary'
          element={
            <SuspensedView>
              <BlockedOrderDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/carts/*'
          element={
            <SuspensedView>
              <AnalyticsCartsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/orders/carts/:tab/:channel/:customerId'
          element={
            <SuspensedView>
              <AnalyticsCartDetailsPage />
            </SuspensedView>
          }
        />
        {/* Orders Routes Ends ... */}
        <Route
          path='/products/all-products/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/all-products/:id/:name'
          element={
            <SuspensedView>
              <ProductsDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/price-list*'
          element={
            <SuspensedView>
              <PriceListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/all-products/my-products/:mode/*'
          element={
            <SuspensedView>
              <MyProductDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/all-products/my-products/:mode/:id/*'
          element={
            <SuspensedView>
              <MyProductDetailsPage />
            </SuspensedView>
          }
        />
        {/* CategoriesPage Routes Starts ... */}
        <Route
          path='/products/categories/*'
          element={
            <SuspensedView>
              <CategoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/categories/details/:id'
          element={
            <SuspensedView>
              <CategoryDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/inquiries/*'
          element={
            <SuspensedView>
              <InquiresPage />
            </SuspensedView>
          }
        />
        {/* CategoriesPage Routes Ends ... */}
        {/* Brands Page Rouets Starts */}
        <Route
          path='/products/brands/*'
          element={
            <SuspensedView>
              <BrandsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/products/brands/details/:id'
          element={
            <SuspensedView>
              <BrandDetailsPage />
            </SuspensedView>
          }
        />
        {/* Customers Routes Starts ... */}
        <Route
          path='/customers/all-customer/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='/customers/all-customer/details'
          element={
            <SuspensedView>
              <CustomerDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/customers/all-customer/addresses'
          element={
            <SuspensedView>
              <CustomerDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/customers/all-customer/rewards'
          element={
            <SuspensedView>
              <CustomerDetailsPage />
            </SuspensedView>
          }
        />
        {/* Customers Routes Ends ... */}
        {/* Loyalty App Routes Starts ... */}
        <Route
          path='/customers/loyalty-points/*'
          element={
            <SuspensedView>
              <LoyaltyApp />
            </SuspensedView>
          }
        />
        {/* Loyalty App Routes Ends ... */}
        <Route
          path='/site-setting/*'
          element={
            <SuspensedView>
              <SiteSetting />
            </SuspensedView>
          }
        />
        {/* Utility Mapping Routes Starts ... */}
        <Route
          path='/utility/*'
          element={
            <SuspensedView>
              <UtilityPage />
            </SuspensedView>
          }
        />
        <Route
          path='/utility/:id'
          element={
            <SuspensedView>
              <UtilitySetting />
            </SuspensedView>
          }
        />
        {/* Utility Mapping Routes Ends ... */}
        {/* Navigations Routes Starts ... */}
        <Route
          path='/storefronts/navigations'
          element={
            <SuspensedView>
              <NavigationPage />
            </SuspensedView>
          }
        />
        <Route
          path='/storefronts/navigations/:id'
          element={
            <SuspensedView>
              <NavigationDetailsPage />
            </SuspensedView>
          }
        />
        {/* Navigations Routes Ends ... */}
        <Route
          path='/storefronts/builds/*'
          element={
            <SuspensedView>
              <BuildsPage />
            </SuspensedView>
          }
        />
        {/* Store Information Routes Starts ... */}
        <Route
          path='/storefronts/store-info/*'
          element={
            <SuspensedView>
              <StoreInfoPage />
            </SuspensedView>
          }
        />
        {/* Store Information Routes Ends ... */}
        {/* Page-builder Routes Starts ... */}
        <Route
          path='/cms/webpages'
          element={
            <SuspensedView>
              <PageBuilder />
            </SuspensedView>
          }
        />
        <Route
          path='/cms/webpages/:id/*'
          element={
            <SuspensedView>
              <PageBuilderDetailsPage />
            </SuspensedView>
          }
        />
        {/* Page-builder Routes Ends ... */}
        {/* Blogs Routes Starts ... */}
        <Route
          path='/cms/blogs/*'
          element={
            <SuspensedView>
              <Blogs />
            </SuspensedView>
          }
        />
        <Route
          path='/cms/blogs/all-blogs/:id/*'
          element={
            <SuspensedView>
              <BlogDetailsPage />
            </SuspensedView>
          }
        />
        {/* Blogs Routes Ends ... */}
        {/* Projects Routes Starts ... */}
        {hasPermission('projects_all projects', 'read') && (
          <Route
            path='/projects/all-projects'
            element={
              <SuspensedView>
                <ProjectsPage />
              </SuspensedView>
            }
          />
        )}
        {hasPermission('projects_all projects', 'read') && (
          <Route
            path='/projects/all-projects/:projectId'
            element={
              <SuspensedView>
                <ProjectPage />
              </SuspensedView>
            }
          />
        )}
        {hasPermission('projects_all projects', 'read') && (
          <Route
            path='/projects/all-projects/:projectId/ticket/:ticketId/:ticketIdentifier/*'
            element={
              <SuspensedView>
                <ProjectTicketDetailsPage />
              </SuspensedView>
            }
          />
        )}
        {hasPermission('projects_settings', 'read') && (
          <Route
            path='/projects/settings/*'
            element={
              <SuspensedView>
                <ProjectsSettingsPage />
              </SuspensedView>
            }
          />
        )}
        {/* {hasPermission('projects_settings', 'read') && ( */}
        <Route
          path='/projects/reports'
          element={
            <SuspensedView>
              <ProjectReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/projects/reports/member-task/*'
          element={
            <SuspensedView>
              <ProjectReportsDetailsPage />
            </SuspensedView>
          }
        />

        {/* )} */}
        {/* Agile Board Routes Ends ... */}
        {/* App Builder Routes starts*/}
        <Route
          path='/settings/appbuilder/*'
          element={
            <SuspensedView>
              <AppBuilder />
            </SuspensedView>
          }
        />
        <Route
          path='/settings/appbuilder/sections/section/:name/*'
          element={
            <SuspensedView>
              <AppDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/settings/appbuilder/sections/section/:name/:child_name'
          element={
            <SuspensedView>
              <LeafDetailsPage />
            </SuspensedView>
          }
        />
        {/* App Builder Routes ends*/}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* Analytics Routes Starts ... */}
        <Route
          path='/analytics/sold-products/*'
          element={
            <SuspensedView>
              <AnalyticsDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/sold-products/aggregated-product-report/*'
          element={
            <SuspensedView>
              <AggregatedProductReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/sold-products/all/*'
          element={
            <SuspensedView>
              <AnalyticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/sold-products/all/:id'
          element={
            <SuspensedView>
              <PDAPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/sold-products/report/:id'
          element={
            <SuspensedView>
              <CustomersReport />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/idle-customers/*'
          element={
            <SuspensedView>
              <IdleCustomerAnalysis />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/idle-customers/inactive-customers/*'
          element={
            <SuspensedView>
              <InactiveCustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/idle-customers/anonymously-active-customers/*'
          element={
            <SuspensedView>
              <AnonymouslyActiveCustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/idle-customers/recently-inactive-customers/*'
          element={
            <SuspensedView>
              <RecentlyInactiveCustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/automation/rules/*'
          element={
            <SuspensedView>
              <Automation />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/automation/appliedrules/*'
          element={
            <SuspensedView>
              <UnhideProductRules />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/on-hold/:id'
          element={
            <SuspensedView>
              <PDAPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/consignments/*'
          element={
            <SuspensedView>
              <AnalyticConsignmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/consignments/vendors/*'
          element={
            <SuspensedView>
              <AggregatedVendorsTablePage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/consignments/all/*'
          element={
            <SuspensedView>
              <ConsignmentProducts />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/consignments/all/:id'
          element={
            <SuspensedView>
              <ConsignmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/on-hold/dashboard/*'
          element={
            <SuspensedView>
              <AnalyticConsignmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/on-hold/:id'
          element={
            <SuspensedView>
              <ConsignmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/on-hold/products/*'
          element={
            <SuspensedView>
              <AnalyticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/replenishment/*'
          element={
            <SuspensedView>
              <ReplenishmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/inventory-report/*'
          element={
            <SuspensedView>
              <InventoryReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/customquery/*'
          element={
            <SuspensedView>
              <CustomQueryDataPage />
            </SuspensedView>
          }
        />
        <Route
          path='/analytics/product-limit-report/*'
          element={
            <SuspensedView>
              <ProductLimitPage />
            </SuspensedView>
          }
        />
        {/* Users Routes Starts ... */}
        <Route
          path='/settings/users/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        <Route
          path='/settings/api-tokens'
          element={
            <SuspensedView>
              <ApiTokenPage />
            </SuspensedView>
          }
        />
        <Route
          path='/settings/mails/*'
          element={
            <SuspensedView>
              <MailTemplatePage />
            </SuspensedView>
          }
        />
        <Route
          path='/settings/jobs/*'
          element={
            <SuspensedView>
              <JobsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/settings/jobs/details/:id/:name'
          element={
            <SuspensedView>
              <JobDetailsPage />
            </SuspensedView>
          }
        />
        {/* Access Denied route */}
        <Route path='*' element={<Navigate to='/error/403' />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
