import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectMembersPageContext} from '../../context'
import MultiSelect from '../../../../components/MultiSelect/MultiSelect'
import {RadioGroup} from '../../../../components/RadioGroup'
import {AddBoardMembersSchema, AddBoardTeamsSchema} from '../../validations'
import {AddBoardMembersModalTypes} from '../../interfaces'

const AddBoardMembersModal = ({show, onClose}: AddBoardMembersModalTypes) => {
  const {
    membersList,
    addProjectMembers,
    isLoadingOperation,
    isLoadingProjectMembersList,
    ProjectsTeamsList,
    addProjectTeams,
  } = useContext(ProjectMembersPageContext)

  const [selectionType, setSelectionType] = useState<'members' | 'teams'>('members')

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors, isValid, isDirty},
  } = useForm({
    defaultValues: {
      usernames: [],
      teams: [],
    },
    resolver: yupResolver(selectionType === 'teams' ? AddBoardTeamsSchema : AddBoardMembersSchema),
  })

  const handleSelectionChange = (type: 'members' | 'teams') => {
    setSelectionType(type)
    reset({usernames: [], teams: []})
  }

  const onAddMember = (data: any) => {
    if (selectionType === 'teams') {
      const payload = {
        teams: data.teams,
      }
      addProjectTeams(payload)
    } else {
      const payload = {
        usernames: data.usernames,
      }
      addProjectMembers(payload)
    }
  }

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onAction={handleSubmit(onAddMember)}
      title='Add Members or Teams'
      actionName='Add'
      actionBtnClass='btn-primary'
      isOperationLoading={isLoadingOperation}
      isDataLoading={isLoadingProjectMembersList}
      isDisabled={!isDirty || !isValid || isLoadingOperation || isLoadingProjectMembersList}
      isCloseOnBackdrop={false}
      body={
        <>
          <div className='mb-10'>
            <div className='d-flex'>
              <RadioGroup
                id='select-members'
                className='me-10'
                name='selectionType'
                label='Members'
                value='members'
                checked={selectionType === 'members'}
                onChange={() => handleSelectionChange('members')}
              />
              <RadioGroup
                id='select-teams'
                name='selectionType'
                label='Teams'
                value='teams'
                checked={selectionType === 'teams'}
                onChange={() => handleSelectionChange('teams')}
              />
            </div>
          </div>
          {selectionType === 'members' ? (
            <MultiSelect
              id='add-project-members'
              control={control}
              label='Members'
              isRequired={true}
              registerKey='usernames'
              options={membersList}
              labelKey='name'
              valueKey='username'
              error={errors?.usernames}
            />
          ) : (
            <MultiSelect
              id='add-project-teams'
              control={control}
              label='Teams'
              isRequired={true}
              registerKey='teams'
              options={ProjectsTeamsList}
              labelKey='name'
              valueKey='id'
              valueType={'number'}
              error={errors?.teams}
            />
          )}
        </>
      }
    />
  )
}

export default AddBoardMembersModal
