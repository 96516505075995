import {convertUTCToLocal, formatDate} from '../../utils/date'

const Date = ({date, isDynamicFormat = false}: any) => {
  return (
    <div className='app-default-date-cell position-relative'>
      <span className='app-default-date-area position-relative'>
        {isDynamicFormat ? convertUTCToLocal(date, true, true) : formatDate(date, true)}
        <span className='popover app-default-date-tooltip fw-semibold lh-1 fs-8'>
          {isDynamicFormat ? convertUTCToLocal(date,false, false) : formatDate(date, false)}
        </span>
      </span>
    </div>
  )
}

export default Date
