import {Modal} from 'react-bootstrap'

import RoleForm from '../form/RoleForm'

const AddRoleModal = ({isEdit, show, onClose, editId}: any) => {
  if (!show) {
    return null
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_trigger_build'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-large'
      aria-hidden='true'
      onHide={onClose}
    >
      <div className='modal-content'>
        <RoleForm isEdit={isEdit} onClose={onClose} editId={editId} />
      </div>
    </Modal>
  )
}

export default AddRoleModal
