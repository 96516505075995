import {useCallback} from 'react'
import {$getSelection, LexicalEditor} from 'lexical'
import {$patchStyleText} from '@lexical/selection'
import DropDown, {DropDownItem} from '../../../ui/DropDown'
import {FONT_FAMILY_OPTIONS, FONT_SIZE_OPTIONS} from '../utils'

export function FontDropDown({
  editor,
  value,
  style,
  disabled = false,
}: {
  editor: LexicalEditor
  value: string
  style: string
  disabled?: boolean
}): JSX.Element {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection()
        if (selection !== null) {
          $patchStyleText(selection, {
            [style]: option,
          })
        }
      })
    },
    [editor, style]
  )

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={'toolbar-item ' + style}
      buttonLabel={value}
      buttonIconClassName={style === 'font-family' ? 'icon block-type font-family' : ''}
      buttonAriaLabel={
        style === 'font-family'
          ? 'Formatting options for font family'
          : 'Formatting options for font size'
      }
    >
      {(style === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(([option, text]) => (
        <DropDownItem
          className={`item ${value === option ? 'active dropdown-item-active' : ''} ${
            style === 'font-size' ? 'fontsize-item' : ''
          }`}
          onClick={() => handleClick(option)}
          key={option}
        >
          <span className='text'>{text}</span>
        </DropDownItem>
      ))}
    </DropDown>
  )
}
