/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react'
import {hotkeys} from '../utils/fullScreenHotkey'

interface FullscreenProps {
  hotkey?: string | null
  children: React.ReactNode
}

const FullScreenWrapper: React.FC<FullscreenProps> = ({hotkey = null, children}) => {
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const [isFullscreen, setIsFullscreen] = useState(false)

  const toggleFullscreen = (fullscreenMode?: boolean) => {
    const targetMode = fullscreenMode ?? !isFullscreen

    if (targetMode && nodeRef.current) {
      nodeRef.current.requestFullscreen().catch((err) => console.error('Fullscreen error:', err))
    } else if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => console.error('Exit fullscreen error:', err))
    }

    setIsFullscreen(targetMode)
  }

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement === nodeRef.current)
    }

    if (hotkey) {
      const unsubscribe = hotkeys.subscribe((screenKeys) => {
        screenKeys.add(hotkey, () => toggleFullscreen())
      })
      document.addEventListener('fullscreenchange', handleFullscreenChange)

      return () => {
        unsubscribe()
        document.removeEventListener('fullscreenchange', handleFullscreenChange)
      }
    }
  }, [hotkey])

  return (
    <div tabIndex={0} className='wx-fullscreen' ref={nodeRef}>
      {children}
      <div className='wx-fullscreen-icon'>
        <button
          className='btn btn-primary'
          onClick={() => toggleFullscreen()}
          aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
        >
          <i className={`wxi-${isFullscreen ? 'collapse' : 'expand'} ms-1 fs-1 py-4`} />
        </button>
      </div>
    </div>
  )
}

export default FullScreenWrapper
