import {useContext} from 'react'
import {ProjectsListPageContext} from '../../context'
import ActiveProjectsTableRow from './ActiveProjectsTableRow'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import {formatProjectDetails} from '../../parsings'
import {activeProjectsTableColumns} from '../../utils'

export default function ActiveProjectsTable() {
  const {projectsList, isLoading, filters, onSortingChange, searchValue} =
    useContext(ProjectsListPageContext)

  return (
    <DynamicTable
      id='active-projects-table'
      data={projectsList}
      sortableColumns={activeProjectsTableColumns}
      TableRow={ActiveProjectsTableRow}
      onSortingChange={onSortingChange}
      filters={filters}
      loading={isLoading}
      noDataMessage='No active projects found.'
      permissionPath='projects_all projects'
      rowParsingFunction={formatProjectDetails}
      searchValue={searchValue}
    />
  )
}
