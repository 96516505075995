import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {Outlet, Route, Routes} from 'react-router-dom'
import ProductLimitReport from './layouts/ProductLimitReport'

const ProductLimitBreadCrumbs: Array<PageLink> = [
  {
    title: 'Analytics',
    path: '/analytics/sold-products',
    isSeparator: false,
    isActive: false,
  },
]

const ProductLimitPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path=''
          element={
            <>
              <PageTitle breadcrumbs={ProductLimitBreadCrumbs}>Product Limit Report</PageTitle>
              <ProductLimitReport />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProductLimitPage
