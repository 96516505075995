import {useState} from 'react'
import useApi from '../../../../services/useApi'

function useGlobalDefaultColumnsOperations() {
  const Api = useApi()
  const [isLoading, setIsLoading] = useState(false)

  async function createGlobalDefaultColumn(defaultColumnDetails: any, setError: Function) {
    return Api.post(`/agile/projects/default/columns`, defaultColumnDetails, {
      setLoading: setIsLoading,
      successMessage: 'Default column created successfully',
      refetch: 'project-default-columns',
      setUseFormError: setError,
    })
  }

  async function updateGlobalDefaultColumn(
    defaultColumnId: string,
    defaultColumnDetails: any,
    dirtyFields: any,
    setError: Function
  ) {
    return Api.patch(`/agile/projects/default/columns/${defaultColumnId}`, defaultColumnDetails, {
      dirtyFields: dirtyFields,
      setLoading: setIsLoading,
      successMessage: 'Default column updated successfully',
      refetch: 'project-default-columns',
      setUseFormError: setError,
    })
  }

  async function updateGlobalDefaultColumnsTableSorting(defaultColumnsTableRowOrder: any) {
    return Api.patch(`/agile/projects/default/columns`, defaultColumnsTableRowOrder)
  }

  async function deleteGlobalDefaultColumn(defaultColumnId: string) {
    return Api.delete(
      `/agile/projects/default/columns/${defaultColumnId}`,
      {},
      {
        setLoading: setIsLoading,
        successMessage: 'Default column deleted successfully',
        refetch: 'project-default-columns',
      }
    )
  }

  return {
    createGlobalDefaultColumn,
    updateGlobalDefaultColumn,
    updateGlobalDefaultColumnsTableSorting,
    deleteGlobalDefaultColumn,
    isLoadingGlobalDefaultColumnOperation: isLoading,
  }
}

export default useGlobalDefaultColumnsOperations
