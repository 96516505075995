// cSpell: ignore asignee_name
import {generateInitials} from '../../../../utils/common'
import './ganttChar.scss'

const TaskTimeline = (data: any) => {
  const isParentTask = data?.data?.$level === 1
  const {asignee_name, text} = data?.data
  return (
    <div
      className={`timeline-task rounded-pill text-gray-800 px-1 d-flex align-items-center gap-2 `}
    >
      {!isParentTask && (
        <div className='timeline-icon bg-light-primary text-primary d-flex align-items-center justify-content-center'>
          {generateInitials(asignee_name)}
        </div>
      )}

      {!isParentTask ? (
        <span className='timeline-name text-truncate fw-semibold'>{asignee_name}</span>
      ) : (
        <span className='px-2 text-truncate'>{text}</span>
      )}
    </div>
  )
}

export default TaskTimeline
