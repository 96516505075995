import {useContext} from 'react'
import {BoardSettingsPageContext} from '../../context'

const BoardCustomFieldsActionDropdown = ({setShowModal, setDeleteModal, customFieldData}: any) => {
  const {updateCustomField} = useContext(BoardSettingsPageContext)
  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setDeleteModal(true)
    document.body.click()
  }

  const handleCustomFieldVisibility = () => {
    updateCustomField(customFieldData?.id, {isVisible: !customFieldData?.isVisible})
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={handleEditClick}
      >
        Edit
      </span>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={handleCustomFieldVisibility}
      >
        {customFieldData?.isVisible ? 'Inactive' : 'Active'}
      </span>
      <span
        onClick={handleDeleteClick}
        className='text-dark text-hover-danger fw-bold fs-6 px-2 py-1 cursor-pointer'
      >
        Delete
      </span>
    </div>
  )
}

export default BoardCustomFieldsActionDropdown
