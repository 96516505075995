import {useEffect, useState} from 'react'
import useApi from '../../services/useApi'
import useFilters from '../useFilters'
import { useAppSelector } from '../../redux/useTypedSelector'

export const useGetNotification = () => {
  const Api = useApi()
  const [Notifications, setNotifications] = useState<any[]>([])
  const {isLoading} = useAppSelector((state) => state.auth)

  const initialFilters = {
    page: 1,
    limit: 20,
    notification_filter: '',
  }

  const {filters, setMultipleFilters, setSingleFilter} = useFilters(initialFilters)

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(
    `/notifications`,
    {
      queryId: 'product-notifications-listing',
      filters: filters,
      isToast: false,
    },
    {
      enabled : !isLoading,
      onSuccess: (response: any) => {
        if (filters.page > 1) {
          setNotifications((prev: any) => [...prev, ...(response?.data || [])])
        }
      },
    }
  )
  const onRefetch = () => {
    refetch()
    setMultipleFilters({
      notification_filter: '',
      page: 1,
    })
  }

  useEffect(() => {
    if (filters.page === 1 && response?.data) {
      setNotifications(response.data)
    }
  }, [response?.data, filters.page])

  const onPageChange = (pageNumber: number | string) => {
    setSingleFilter('page', pageNumber)
  }

  const onChangeNotificationType = (type: any) => {
    setMultipleFilters({
      notification_filter: type,
      page: 1,
    })
  }

  return {
    Notifications: Notifications,
    pagination: response?.meta,
    onLoadMore: onPageChange,
    currentPage: filters.page,
    loading: isFetching,
    onChangeNotificationType,
    refetch: onRefetch,
  }
}
