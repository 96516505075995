import {useQuery} from 'react-query'
import {getPermissions} from '../services'

export default function useGetPermissions() {
  const {
    data: permissions,
    refetch,
    isFetching,
  } = useQuery(`permissions`, () => {
    return getPermissions(`/settings/users/roles/permissions`)
  })

  return {
    permissions: permissions || [],
    refetch,
    isFetching,
  }
}
