import React from 'react'

interface InitialsProps {
  text: string
}

const bgColors = ['warning', 'danger', 'info', 'success']

function getRandomColor(arr: any) {
  // get random index value
  const randomIndex = Math.floor(Math.random() * arr.length)
  return arr[randomIndex]
}

function Initials(props: InitialsProps) {
  const {text} = props
  const bg_color = getRandomColor(bgColors)

  return (
    <div className={`symbol-label fs-5 fw-semibold bg-light-${bg_color} text-${bg_color}`}>
      {text}
    </div>
  )
}

export default Initials
