import {useState} from 'react'
import NewCustomerReport from './NewCustomerReport'
import ReorderRateReport from './ReorderRateReport'

function ReportDashboard() {
  const [productName, setProductName] = useState('')
  return (
    <div className='layout-sold-product-inner'>
      <div className='mb-10 mt-10'>
        <h4 className='fw-semibold mb-0'>{productName}</h4>
      </div>
      <div className='mb-10'>
        <ReorderRateReport setProductName={setProductName}/>
      </div>
      <div className='mb-10'>
        <NewCustomerReport />
      </div>
    </div>
  )
}

export default ReportDashboard
