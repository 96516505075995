import {Link} from 'react-router-dom'
import ProgressCircle from '../../../../projectReports/components/ProgressCircle'

const MembersReportTableRow = ({row, actionComponent, filters}: any) => {
  return (
    <tr>
      {/* Assignee Column */}
      <td>{row.assignee}</td>

      {/* Total Task Column */}
      <td>
        <Link
          to={`/projects/reports/member-task?projectId=${encodeURIComponent(
            filters['project_id']
          )}&emailId=${encodeURIComponent(row['email'])}`}
          target='_blank'
          className='text-dark text-hover-primary fs-6 text-decoration-underline'
        >
          {row.totalCards}
        </Link>
      </td>

      {/* Estimation Column */}
      <td>{row.totalEstimation}</td>

      {/* Spent Time Column */}
      <td className={` ${row.isOverEstimation ? ' text-danger' : ''}`}>{row.totalSpentTime}</td>

      {/* Remaining Time Column */}
      <td>{row.totalRemainingTime}</td>

      {/* Progress Column */}
      <td>
        <div className='d-flex justify-content-end align-items-end gap-2 me-5'>
          <span>{row.progressPercentage}</span>
          <ProgressCircle progress={parseFloat(row.progressPercentage)} />
        </div>
      </td>

      {/* Action Column */}
      {actionComponent && <td>{actionComponent(row)}</td>}
    </tr>
  )
}

export default MembersReportTableRow
