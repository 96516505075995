import {useState} from 'react'
import useToastify from '../../../hook/useToastify'
import { updateDefaultColumnsTableSorting } from '../services'

function useColumnsOperations() {
  const {toastMessage} = useToastify()
  const [isLoading, setIsLoading] = useState(false)

  async function updateGlobalDefaultColumnsTableSorting(data: any) {
    setIsLoading(true)
    const res: any = await updateDefaultColumnsTableSorting(data)
    if (res.status === 200) {
      toastMessage('success', `Columns updated successfully`)
      setIsLoading(false)
      return res
    } else {
      toastMessage('error', `${res.data.message}`)
      setIsLoading(false)
    }
  }

  return {
    updateGlobalDefaultColumnsTableSorting,
    isLoadingGlobalDefaultColumnOperation: isLoading,
  }
}

export default useColumnsOperations
