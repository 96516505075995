import {Pagination} from '../../../utils/pagination'
import {IdleCustomersDashboardContext} from '../context'
import {useGetInactiveCustomers} from '../hooks/useGetInactiveCustomer'
import useMeta from '../../../hook/useMeta'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import InactiveCustomerListing from '../sections/table/InactiveCustomerListing'

const InactiveCustomerBreadCrums: Array<PageLink> = [
  {
    title: 'Idle Customers',
    path: `/analytics/idle-customers`,
    isSeparator: false,
    isActive: false,
  },
]

const InactiveCustomerPage = () => {
  useMeta('Inactive Customer')

  const {
    inactiveCustomers,
    isLoading: inactiveCustomersLoading,
    loadMore: loadMoreInactiveCustomers,
    pagination,
    onPageChange,
    onInactiveCustomerClickSorting,
    InactiveCustomerFilters,
  }: any = useGetInactiveCustomers(30)

  const contextValue = {
    inactiveCustomers,
    inactiveCustomersLoading,
    loadMoreInactiveCustomers,
    totalInactiveCustomers: 0,
    recentlyInactiveCustomers: [],
    recentlyInactiveCustomersLoading: false,
    totalRecentlyInactiveCustomers: 0,
    loadMoreRecentlyInactiveCustomers: () => {},
    anonymousCustomers: [],
    anonymousCustomersLoading: false,
    totalAnonymousCustomers: 0,
    loadMoreAnonymousCustomers: () => {},
    onInactiveCustomerClickSorting,
    InactiveCustomerFilters,
    onRecentlyInactiveCustomerClickSorting: () => {},
    RecentlyInactiveCustomerFilters: {},
    onAnonymouslyActiveCustomerClickSorting: () => {},
    AnonymouslyActiveCustomerFilters: {},
  }

  return (
    <>
      <IdleCustomersDashboardContext.Provider value={contextValue}>
        <PageTitle breadcrumbs={InactiveCustomerBreadCrums}>Top User with 0 Order</PageTitle>
        <InactiveCustomerListing />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </IdleCustomersDashboardContext.Provider>
    </>
  )
}

export default InactiveCustomerPage
