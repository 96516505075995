import {arrayMove} from '@dnd-kit/sortable'
import {countColumns, countModules} from './handleTasksCounters'

export function handleDragOver(
  event: any,
  setTasks: any,
  setUpdatedTaskDetails: any,
  setTasksCounter: any
) {
  const {active, over} = event
  if (!over || active.id === over.id) return

  // Extract types and IDs from the active and over elements.
  const activeType = active.data.current?.type
  const overType = over.data.current?.type
  const activeTaskId = active.id
  const overID = over.id
  const overColumn = overType === 'Column' ? over?.data?.current?.column : over?.data?.current?.task
  const overCID = overColumn?.cID
  const overModuleID = overColumn?.moduleID

  if (activeType !== 'Task') return

  // Update task details with new column/module IDs where the task is being moved to.
  setUpdatedTaskDetails((existingDetails: any) => ({
    ...existingDetails,
    newCID: overCID || null,
    newModuleID: overModuleID || null,
  }))

  // Update the state of tasks after the drag event.
  setTasks((tasks: any) => {
    // Find the index of the dragged task using its ticketID.
    const activeIndex = tasks.findIndex((t: any) => t.ticketID === activeTaskId)
    if (activeIndex === -1) return tasks

    let updatedTasks = tasks

    // If the target (over) is a task:
    if (overType === 'Task') {
      const overIndex = tasks.findIndex((t: any) => t.ticketID === overID)
      if (overIndex === -1) return tasks

      const sameColumn = tasks[activeIndex].columnID === tasks[overIndex].columnID
      // Update the column and module IDs of the active task if moved to a different column.
      if (!sameColumn) {
        updatedTasks[activeIndex].columnID = tasks[overIndex].columnID
        updatedTasks[activeIndex].moduleID = tasks[overIndex].moduleID
        updatedTasks[activeIndex].cID = tasks[overIndex].cID
      }

      // Reorder the tasks by moving the active task to the position of the over task.
      updatedTasks = arrayMove(updatedTasks, activeIndex, overIndex)
    } else if (overType === 'Column') {
      updatedTasks[activeIndex].columnID = overID
      updatedTasks[activeIndex].moduleID = overModuleID
      updatedTasks[activeIndex].cID = overCID
    }

    // Count and update the number of tasks per column and module using external counters.
    setTasksCounter({
      columns: countColumns(updatedTasks),
      modules: countModules(updatedTasks),
    })

    return [...updatedTasks]
  })
}
