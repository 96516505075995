import {useEffect, useState} from 'react'
import {parseNumber} from '../../utils/parseNumber'
import {validateNumberInputField} from '../../utils/validateNumberInputField'
import InputPriceTypes from './InputPriceTypes'
import {capitalizeFirstLetter, convertToNumber} from '../../utils/string'

function InputPrice({
  id,
  name,
  placeholder,
  error,
  label,
  register,
  isRequired,
  min,
  max,
  className = '',
  inputClass = '',
  onChange,
  onBlur,
  inputPriceRef,
  priceSymbol = '$',
  isFloat = false,
  decimalLimit = 2,
  isRoundOff = false,
  maxLimit = Infinity,
  minLimit = -Infinity,
  totalLimit = Infinity,
  isFormatted = true,
  isReadOnly = false,
  disabled = false,
  maxLength = Infinity,
  onFocus,
  value,
  customErrorMessage,
  setIsValid,
  isErrorWarning = false,
  defaultValue,
  errorClass = '',
  isCheckValidationOnLoad = true,
  labelClass = '',
  ...rest
}: InputPriceTypes) {
  const [errorMessage, setErrorMessage] = useState<any>(null)
  const {
    name: registerName = name,
    onChange: registerOnChange = onChange,
    onBlur: registerOnBlur = onBlur,
    ref: registerRef = inputPriceRef,
  } = register && register.name ? register : {}

  useEffect(() => {
    if (value && setIsValid && isCheckValidationOnLoad) {
      const convertedPrice = parseNumber(value, null)
      if (convertedPrice === null) {
        if (isRequired) {
          setErrorMessage(
            `${capitalizeFirstLetter(label ? label : name ? name : 'this')} is required.`
          )
        } else {
          return
        }
        setIsValid?.(false)
      } else if (convertedPrice && convertedPrice > maxLimit) {
        setIsValid(false)
        setErrorMessage(
          customErrorMessage?.maxLimitError
            ? customErrorMessage.maxLimitError(maxLimit)
            : `Max allowed value is ${maxLimit}`
        )
      } else if (convertedPrice && convertedPrice < minLimit) {
        setIsValid(false)
        setErrorMessage(
          customErrorMessage?.minLimitError
            ? customErrorMessage.minLimitError(minLimit)
            : `Min allowed value is ${minLimit}`
        )
      } else if (convertedPrice && convertedPrice > totalLimit) {
        setIsValid(false)
        setErrorMessage(
          customErrorMessage?.totalLimitError
            ? customErrorMessage.totalLimitError(totalLimit)
            : `Total allowed value is ${totalLimit}`
        )
      } else {
        setIsValid(true)
        setErrorMessage(null)
      }
    }
    // eslint-disable-next-line
  }, [value, minLimit, maxLimit])

  const handleChange = (e: any) => {
    const inputValue = convertToNumber(e.target.value)
    if (isErrorWarning) {
      if (inputValue === null) {
        if (isRequired) {
          setErrorMessage(
            `${capitalizeFirstLetter(label ? label : name ? name : 'this')} is required.`
          )
        } else {
          return
        }
        setIsValid?.(false)
      } else if (inputValue > (parseNumber(maxLimit) || Infinity)) {
        setErrorMessage(
          customErrorMessage?.maxLimitError
            ? customErrorMessage.maxLimitError(maxLimit)
            : `Max allowed value is ${maxLimit}`
        )
        setIsValid?.(false)
      } else if (inputValue < (parseNumber(minLimit) || -Infinity)) {
        setErrorMessage(
          customErrorMessage?.minLimitError
            ? customErrorMessage.minLimitError(minLimit)
            : `Min allowed value is ${minLimit}`
        )
        setIsValid?.(false)
      } else if (inputValue > (parseNumber(totalLimit) || Infinity)) {
        setErrorMessage(
          customErrorMessage?.totalLimitError
            ? customErrorMessage.totalLimitError(totalLimit)
            : `Total allowed value is ${totalLimit}`
        )
      } else {
        setErrorMessage(null)
        setIsValid?.(true)
      }
    }

    if (onChange) onChange(e)
    if (registerOnChange) registerOnChange(e)
  }

  const handleBlur = (e: any) => {
    if (onBlur) onBlur(e)
    if (registerOnBlur) registerOnBlur(e)
  }

  const handleRef = (e: any) => {
    if (inputPriceRef)
      (inputPriceRef as React.MutableRefObject<HTMLTextAreaElement | null>).current = e
    if (registerRef) registerRef(e)
  }

  return (
    <div className={className}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={`input-group ${error ? 'is-invalid' : ''}`}>
        <span className='input-group-text pt-0 pb-0'>{priceSymbol}</span>
        <input
          type='text'
          className={`form-control no-arrows ${inputClass} ${
            error || errorMessage ? 'is-invalid' : ''
          }`}
          aria-label='Amount (to the nearest dollar)'
          id={id}
          name={registerName || name}
          ref={handleRef}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          defaultValue={defaultValue}
          readOnly={isReadOnly}
          disabled={disabled}
          {...(value ? {value: value} : {})}
          maxLength={maxLength}
          onInput={(e: any) => {
            validateNumberInputField(
              e,
              isFloat,
              decimalLimit,
              isRoundOff,
              maxLimit,
              isFormatted,
              isErrorWarning
            )
          }}
          onFocus={onFocus}
          {...rest}
        />
      </div>
      {isErrorWarning && (minLimit || maxLimit) && errorMessage && (
        <p className={`text-danger mt-2 mb-0 ${errorClass}`}>{errorMessage}</p>
      )}
      {error && error?.message ? (
        <p className={`invalid-feedback ${errorClass}`}>{error.message}</p>
      ) : null}
    </div>
  )
}

export default InputPrice
