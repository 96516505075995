import {useContext} from 'react'
import {ProjectsListPageContext} from '../../context'
import ArchivedProjectsTableRow from './ArchivedProjectsTableRow'
import {DynamicTable} from '../../../../components/DynamicTable'
import {formatProjectDetails} from '../../parsings'
import {archivedProjectsTableColumns} from '../../utils'

export default function ArchivedProjectsTable() {
  const {projectsList, isLoading, filters, onSortingChange, searchValue} =
    useContext(ProjectsListPageContext)

  return (
    <DynamicTable
      id='archived-projects-table'
      data={projectsList}
      sortableColumns={archivedProjectsTableColumns}
      TableRow={ArchivedProjectsTableRow}
      onSortingChange={onSortingChange}
      filters={filters}
      loading={isLoading}
      noDataMessage='No archived projects found.'
      permissionPath='projects_all projects'
      rowParsingFunction={formatProjectDetails}
      searchValue={searchValue}
    />
  )
}
