import {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {AgileBoardPageContext, ProjectDetailsContext} from '../context'
import {agileTicketData} from '../parsings'
import {ReachSelect} from '../../../components/reachSelect'
import {OverlayDropdowns} from './OverlayDropdowns'
import {defaultAgileBoardTicketValues, generateTicketDetailsPath} from '../utils'
import InputDate from '../../../components/InputDate/InputDate'
import {useForm} from 'react-hook-form'
import {formatDate} from '../../../utils/date'
import {generateInitials} from '../../../utils/common'

export const TicketLayout = ({ticketData, isDragging}: any) => {
  const {
    hasBoardPermission,
    projectStatus,
    projectId,
    projectName,
    projectMembers,
    isLoadingProjectMembers,
    updateTicket,
    isLoadingAgileBoardData,
  } = useContext(AgileBoardPageContext)
  const [isDefaultValueAssigned, setIsDefaultValueAssigned] = useState(false)

  const ticket: any = agileTicketData(ticketData)
  const {projectDetails} = useContext(ProjectDetailsContext)
  const isResolved = projectDetails?.resolved_column_id === ticketData?.cID

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    setError,
    formState: {dirtyFields},
  } = useForm({
    defaultValues: defaultAgileBoardTicketValues(ticket),
  })

  const formValues = watch()

  const onSubmit = () => {
    updateTicket(watch(), ticket?.ticketId, dirtyFields, setError)
    document.body.click()
  }

  useEffect(() => {
    if (!isDefaultValueAssigned && !isLoadingAgileBoardData) {
      reset(defaultAgileBoardTicketValues(ticket))
      setIsDefaultValueAssigned(true)
    }
  }, [ticket, reset, isDefaultValueAssigned, isLoadingAgileBoardData])

  return (
    <Link
      to={generateTicketDetailsPath(ticket, {projectStatus, projectId, projectName})}
      className='board-card-item w-100 border border-gray-300 rounded-2 px-4 py-3 position-relative bg-white'
    >
      <div className={`d-flex flex-column gap-3 ${isDragging ? 'opacity-0' : ''}`}>
        <div className='d-flex align-items-center'>
          <span className={`${ticket?.priorityColor} rounded-circle me-3 w-10px h-10px`} />
          <span
            className={`ticket-card-id text-primary fw-semibold ${
              isResolved && 'text-decoration-line-through'
            }`}
          >
            {ticket?.ticketInitial}
          </span>
        </div>

        <span className='text-gray-800 ticket-card-title fw-semibold'>{ticket?.ticketTitle}</span>

        <div className='d-flex justify-content-between align-items-center gap-3'>
          <OverlayDropdowns
            hasValue={!!formValues?.assignedTo}
            disabled={!hasBoardPermission}
            triggerIcon={
              formValues?.assignedTo ? (
                <div className='d-flex align-items-center gap-3'>
                  <div
                    className={`w-30px h-30px rounded-circle d-flex justify-content-center align-items-center fw-semibold fs-8 bg-light-${ticket.assigneeInitialColor} text-${ticket.assigneeInitialColor}`}
                  >
                    {generateInitials(formValues?.assigneeName)}
                  </div>
                  <div className='fs-6 fw-semibold text-dark'>{formValues?.assigneeName}</div>
                </div>
              ) : (
                <i className='fa-solid fa-user-plus text-gray-700' />
              )
            }
          >
            <ReachSelect
              control={control}
              registerKey='assignedTo'
              id='ticket-assignee-dropdown'
              options={projectMembers}
              labelKey='name'
              valueKey='username'
              isLoading={isLoadingProjectMembers}
              placeholderValue='Select Assignee'
              menuIsOpen={true}
              autoFocus={true}
              isFloatingMenu={false}
              menuShouldScrollIntoView={false}
              className='min-w-150px mw-150px'
              onChange={(e: any) => {
                setValue('assigneeName', e.label)
                handleSubmit(onSubmit)()
              }}
              disableDropdownIndicator={true}
            />
          </OverlayDropdowns>

          <OverlayDropdowns
            hasValue={!!formValues?.dueDateLabel}
            disabled={!hasBoardPermission}
            triggerIcon={
              formValues?.dueDateLabel ? (
                <div className='fs-6 fw-semibold text-dark'>{formValues?.dueDateLabel}</div>
              ) : (
                <i className='fa-regular fs-4 fa-calendar-minus text-gray-700' />
              )
            }
            className='bg-body'
          >
            <InputDate
              control={control}
              registerKey='dueDate'
              id='ticket-estimate-date-picker'
              menuClassName='position-static shadow-none border-0'
              onOk={(date: any) => {
                setValue('dueDateLabel', formatDate(date, true, true, false))
                handleSubmit(onSubmit)()
              }}
              onClean={() => {
                setValue('dueDateLabel', null)
                handleSubmit(onSubmit)()
              }}
              floatingElement={false}
              autoFocus={true}
            />
          </OverlayDropdowns>
        </div>
      </div>
    </Link>
  )
}
