import React from 'react'
import RoleTable from './sections/roles/RoleTable'
import {UserRoleContext} from './context'
import useGetRoles from './hooks/useGetRoles'
import useRoles from './hooks/useRoles'
import RoleCardHeader from './sections/roles/RoleCardHeader'
import useMeta from '../../hook/useMeta'

const UserRoles = () => {
  useMeta('User Roles')

  const {roles} = useGetRoles()
  const {deleteRole, createNewRole, updateRole, isLoading ,isFetching} = useRoles()
  
  const contextValue = {
    roles,
    isLoading,
    createNewRole,
    updateRole,
    deleteRole,
    isFetching
  }

  return (
    <UserRoleContext.Provider value={contextValue}>
      <div className='card'>
        <div className='card-body p-0'>
          <RoleCardHeader />

          <div className='table-responsive'>
            <RoleTable />
          </div>
        </div>
      </div>
    </UserRoleContext.Provider>
  )
}

export {UserRoles}
