import React, {useState} from 'react'
import AddRoleModal from '../modals/AddRoleModal'
import usePermission from '../../../../hook/usePermission'

function RoleCardHeader() {
  const [modal, setModal] = useState(false)
  const {hasPermission} = usePermission()

  const toggleModal = () => setModal(!modal)
  const onClose = () => setModal(false)

  return (
    
      <div className='mb-10 d-flex justify-content-end'>
        {hasPermission('settings_users', 'write') ? (
          <button
            id='trigger-build'
            type='button'
            className='btn flex-end btn btn-primary'
            onClick={toggleModal}
          >
            <span className='indicator-label'>+ Add Role</span>
          </button>
        ) : null}
        <AddRoleModal isEdit={false} show={modal} onClose={onClose} />
      </div>
    
  )
}

export default RoleCardHeader
