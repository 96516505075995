import React, {useContext} from 'react'
import {MultiSelect} from '../../../../components/MultiSelect/'
import Search from '../../../../components/Search'
import PreDefinedDateRange from '../../../../components/DateRangePicker/PreDefinedDateRange'
import {CustomerReportPageContext} from '../../contexts'
import {Select} from '../../../../components/Select/'

const CustomerHeader = () => {
  const {
    onDateChange,
    onRepFilterChange,
    onTagFilterChange,
    onSearch,
    Representatives,
    customerTags,
    isLoading,
  } = useContext(CustomerReportPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex gap-5'>
        <div>
          <Search
            onSearch={(searchTerm: string) => {
              onSearch(searchTerm)
            }}
          />
        </div>
        <MultiSelect
          options={customerTags}
          isLoading={isLoading}
          className='w-200px'
          labelKey='tag_label'
          valueKey='tag_id'
          handleSelected={(values: any) => {
            const extractedValues = values.map((item: {value: string}) => item.value).join(',')
            onTagFilterChange(extractedValues)
          }}
          placeholder='Select Customer tag'
          allValuesSelectedText={'Customer tag: All'}
        />
        <div className='w-200px'>
          <Select
            defaultValue=''
            onChange={(e: any) => onRepFilterChange(e.target.value)}
            options={[{label: 'Customer Rep : All', value: ''}, ...Representatives]}
            id={'reps'}
          />
        </div>
      </div>

      <div className='min-w-200px'>
        <div className='w-100'>
          <PreDefinedDateRange
            cb={onDateChange}
            getLbl={() => {}}
            exclude={['Current Year', 'Today', 'Yesterday', 'Last 7 Days']}
            isClearable={true}
            includeCurrantDay={true}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomerHeader
