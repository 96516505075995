// cSpell: ignore cartdetails
import {useState} from 'react'
import useApi from '../../services/useApi'

const useNotificationOperation = () => {
  const Api = useApi()
  const [isLoading, setLoading] = useState(false)

  const updateNotificationStatus = (id: any, payload: any) => {
    return Api.patch(`/notifications/${id}`, payload, {
      setLoading: setLoading,
    })
  }

  return {
    updateNotificationStatus,
    isLoading,
  }
}

export default useNotificationOperation
