import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'

const initialFilters = {
  search: true,
  page: true,
  sort_by: 'created_at/-1',
  limit: 30,
}

export const useGetGlobalProjectsMembersList = () => {
  const Api = useApi()

  const {filters, onPageChange, onSortingChange, onSearch, searchValue} = useFilters(
    initialFilters,
    {
      sortType: 'number',
    }
  )

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/members`, {
    queryId: 'global-projects-members',
    filters: filters,
  })

  return {
    globalProjectsMembersList: response?.data || [],
    isLoading: isFetching,
    pagination: response?.pagination || {},
    onPageChange,
    onSearch,
    searchValue,
    filters,
    onSortingChange,
  }
}
