import {createContext} from 'react'

export const TasksReportPageContext = createContext({
  hasProjectSettingsPermission: false,
  tasksReportsData: [] as any,
  filtersData: [],
  isLoading: false,
  onSortingChange: (key: string, value: string) => {},
  onProjectChange: (id: any) => {},
  onSearch: (e: string) => {},
  filters: {},
  searchValue: '',
  refetch: () => {},
})

export const MembersReportPageContext = createContext({
  hasProjectSettingsPermission: false,
  membersReportData: [] as any,
  filtersData: [],
  isLoading: false,
  onSortingChange: (key: string, value: string) => {},
  onProjectChange: (id: any) => {},
  onSearch: (e: string) => {},
  filters: {},
  searchValue: '',
  refetch: () => {},
})

export const TimeSheetReportPageContext = createContext({
  projectsDropdownList: [],
  isLoadingProjectDropdownList: false,
  filtersData: [],
  hasProjectSettingsPermission: false,
  timeSheetData: [] as any,
  timeSheetMeta: {},
  isLoadingTimeSheetData: false,
  onSortingChange: (key: string, value: string) => {},
  onSearch: (e: string) => {},
  onProjectChange: (id: any) => {},
  filters: {},
  searchValue: '',
  onDateChange: (startDate: any, endDate: any) => {},
  refetch: () => {},
})

export const MatrixReportPageContext = createContext({
  projectsDropdownList: [] as any,
  isLoadingProjectDropdownList: false,

  hasProjectSettingsPermission: false,
  xAxisMatrixData: [] as any,
  yAxisMatrixData: [] as any,
  isLoadingMatrixData: false,
  onSortingChange: (key: string, value: string) => {},
  onSearch: (e: string) => {},
  filters: {},
  searchValue: '',
  onProjectChange: (id: any) => {},
  onXAxisChange: (e: any) => {},
  onYAxisChange: (e: any) => {},
  onBothChange: (valueX: any, valueY: any) => {},
})
