import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'

const initialFilters = {
  x_axis: 'Module',
  y_axis: 'Assignee',
  project_id: '',
  sort_by: 'value/1',
}

const useGetMatrixData = () => {
  const Api = useApi()
  const {filters, onSearch, onSortingChange, searchValue, setMultipleFilters} = useFilters(
    initialFilters,
    {
      sortType: 'number',
    }
  )

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/matrix`,
    {
      queryId: 'project-report-matrix',
      filters: filters,
    },
    {
      enabled: filters.project_id !== '',
    }
  )

  const onProjectChange = (id: any) => {
    setMultipleFilters({...filters, project_id: id})
  }

  const onXAxisChange = (value: any) => {
    setMultipleFilters({...filters, x_axis: value})
  }

  const onYAxisChange = (value: any) => {
    setMultipleFilters({...filters, y_axis: value})
  }

  const onBothChange = (x_axis: any, y_axis: any) => {
    setMultipleFilters({...filters, x_axis: x_axis, y_axis: y_axis})
  }

  return {
    xAxisMatrixData: response?.meta || [],
    yAxisMatrixData: response?.data || {},
    filters,
    isLoading: isFetching,
    onSortingChange,
    onSearch,
    searchValue,
    onProjectChange,
    onXAxisChange,
    onYAxisChange,
    onBothChange,
  }
}

export default useGetMatrixData
