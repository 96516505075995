import useApi from '../../../../services/useApi'
import useFilters from '../../../../hook/useFilters'
import {ProjectDetailsContext} from '../../context'
import {useContext, useMemo} from 'react'
import {isEmpty} from '../../../../utils/common'

export const useGetAgileBoardTicketList = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)

  const initialFilters = {
    page: 1,
    limit: 30,
    sort_by: 'column_name/1',
    search: true,
  }

  const {filters, onSearch, onPageChange, onSortingChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(`/agile/projects/${projectId}/cards/listview`, {
    queryId: 'agile-board-ticket-list',
    filters: filters,
  })

  // Restructure tickets by module_id with module title
  const groupedTickets = useMemo(() => {
    if (isEmpty(response?.data?.cards_report?.data)) return []
    const tickets = response?.data?.cards_report?.data || []

    const groupedByModule = tickets.reduce((acc: {[key: string]: any}, ticket: any) => {
      const moduleId = ticket.module_id || 'uncategorized'
      const moduleTitle = ticket.module_name || 'Uncategorized'

      if (!acc[moduleId]) {
        acc[moduleId] = {
          moduleId,
          moduleTitle,
          data: [],
        }
      }
      acc[moduleId].data.push(ticket)
      return acc
    }, {})

    // Convert the grouped object to array of objects
    return Object.values(groupedByModule)
  }, [response?.data?.cards_report?.data])

  return {
    agileBoardTicketList: response?.data?.cards_report?.data || [],
    groupedTickets: groupedTickets || [],
    pagination: response?.data?.cards_report?.meta?.pagination || {},
    filtersData: response?.data?.filters || [],
    isLoading: isFetching,
    filters,
    onSearch,
    onPageChange,
    onSortingChange,
    refetch,
  }
}
