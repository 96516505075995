import {useContext, useState} from 'react'
import GlobalProjectMembersModal from '../modals/GlobalProjectMembersModal'
import {ProjectsMembersPageContext} from '../../context'
import Search from '../../../../components/Search'

const ProjectsMembersPageHeader = () => {
  const [showModal, setShowModal] = useState(false)
  const {onSearch, hasProjectSettingsPermission} = useContext(ProjectsMembersPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch && onSearch(searchTerm)
        }}
      />
      {hasProjectSettingsPermission && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Add Members
        </button>
      )}

      {showModal && (
        <GlobalProjectMembersModal
          projectMemberModalDetails={null}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  )
}

export default ProjectsMembersPageHeader
