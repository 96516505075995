import {useContext} from 'react'
import {DynamicTable} from '../../../../../components/DynamicTable'
import { SuggestedCustomerReportPageContext} from '../../../contexts'
import {SuggestedCustomerTableColumns} from '../../../utils'
import SuggestedCustomerTableRow from './SuggestedCustomerTableRow'

const SuggestedCustomerTable = () => {
  const {suggestedCustomersData, isLoading, filters, onSortingChange} =
    useContext(SuggestedCustomerReportPageContext)

  return (
    <DynamicTable
      id='suggested-customer-table'
      data={suggestedCustomersData}
      sortableColumns={SuggestedCustomerTableColumns}
      loading={isLoading}
      filters={filters}
      onSortingChange={onSortingChange}
      TableRow={SuggestedCustomerTableRow}
      permissionPath='orders_bulk orders_reports'
    />
  )
}

export default SuggestedCustomerTable
