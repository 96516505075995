import {ArrowNarrowRight} from '../../../../_metronic/assets/icons'
import {CheckBox} from '../../../../components/CheckBox copy'

const RoleItem = ({permission, onSelectCheckbox, checkboxValues, padding = 0}: any) => {
  if (!permission) return null

  const updateChildrenCheckboxes = (permission: any, operation: string, checked: boolean) => {
    if (!permission.children || Object.keys(permission.children).length === 0) return
    Object.values(permission.children).forEach((child: any) => {
      // Ensure checkboxValues[child.id] is an object
      if (!checkboxValues[child.name]) {
        checkboxValues[child.name] = {}
      }
      checkboxValues[child.name] = {
        ...checkboxValues[child.name],
        [operation]: checked,
      }
      updateChildrenCheckboxes(child, operation, checked)
    })
  }

  const handleCheckboxChange = (id: string, operation: string) => {
    const isCurrentlyChecked = checkboxValues[id]?.[operation]
    const checked = !isCurrentlyChecked

    // Ensure checkboxValues[id] is an object
    if (!checkboxValues[id]) {
      checkboxValues[id] = {}
    }

    if (operation === 'read' && !checked) {
      if (checkboxValues[id]?.['write']) {
        onSelectCheckbox(id, 'write', false)
        updateChildrenCheckboxes(permission, 'write', false)
      }
    }

    if (operation === 'write' && checked) {
      if (!checkboxValues[id]?.['read']) {
        onSelectCheckbox(id, 'read', true)
        updateChildrenCheckboxes(permission, 'read', true)
      }
    }

    // Update the checkbox state for the current operation
    onSelectCheckbox(id, operation, checked)
    updateChildrenCheckboxes(permission, operation, checked)
  }

  return (
    <div
      className={`${padding === 0 ? 'mt-5 pt-5 section-loop border-top' : 'role-subitem mt-3'}`}
      style={{paddingLeft: padding}}
    >
      <div
        className={`${
          padding === 0 ? 'role-item mb-5' : ''
        } d-flex justify-content-between flex-nowrap`}
      >
        <div className='role-title mw-400px d-flex'>
          {padding > 0 && (
            <div className='me-2'>
              <ArrowNarrowRight />
            </div>
          )}
          <p className='text-capitalize mb-0 text-uppercase'>{permission.label}</p>
        </div>
        <div className='role-action d-flex w-250px justify-content-end'>
          {permission.operations?.map((operation: string, index: number) => (
            <div
              key={index}
              className='form-check form-check-custom form-check-solid form-check-sm w-100px'
            >
              <CheckBox
                id={`flexCheck_${permission.name}_${operation}`}
                checked={checkboxValues[permission.name]?.[operation] || false}
                onChange={() => handleCheckboxChange(permission.name, operation)}
                label={operation}
                className={'text-capitalize'}
              />
            </div>
          ))}
        </div>
      </div>
      {Object.values(permission.children || {}).map((child: any) => (
        <RoleItem
          key={child.id}
          permission={child}
          onSelectCheckbox={onSelectCheckbox}
          checkboxValues={checkboxValues}
          padding={padding + 20}
        />
      ))}
    </div>
  )
}

export default RoleItem
