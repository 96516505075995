import {useState} from 'react'
import {getRoleDetailsById} from '../services'

export default function useGetRoleById() {
  const [isLoadingDetails, setIsLoadingDetails] = useState(true)

  const getRoleDetails = async (id: any) => {
    setIsLoadingDetails(true)
    const response = await getRoleDetailsById(`${id.trim()}`)

    setIsLoadingDetails(false)
    return response || {}
  }

  return {
    getRoleDetails,
    isLoadingDetails: isLoadingDetails,
  }
}
