import {Pagination} from '../../../utils/pagination'
import {IdleCustomersDashboardContext} from '../context'
import useMeta from '../../../hook/useMeta'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import RecentlyInactiveCustomerListing from '../sections/table/RecentlyInActiveCustomers'
import {useGetCustomersWithNoRecentOrders} from '../hooks/useGetCustomerWithNoRecentOrders'

const RecentlyInactiveCustomerBreadCrums: Array<PageLink> = [
  {
    title: 'Idle Customers',
    path: `/analytics/idle-customers`,
    isSeparator: false,
    isActive: false,
  },
]

const RecentlyInactiveCustomerPage = () => {
  useMeta('Recently Inactive Customer')

  const {
    recentlyInactiveCustomers,
    isLoading: recentlyInactiveCustomersLoading,
    loadMore: loadMoreRecentlyInactiveCustomers,
    pagination,
    onPageChange,
    onRecentlyInactiveCustomerClickSorting,
    RecentlyInactiveCustomerFilters,
  }: any = useGetCustomersWithNoRecentOrders(30)

  const contextValue = {
    inactiveCustomers: [],
    inactiveCustomersLoading: false,
    loadMoreInactiveCustomers: () => {},
    totalInactiveCustomers: 0,
    recentlyInactiveCustomers,
    recentlyInactiveCustomersLoading,
    totalRecentlyInactiveCustomers: 0,
    loadMoreRecentlyInactiveCustomers,
    anonymousCustomers: [],
    anonymousCustomersLoading: false,
    totalAnonymousCustomers: 0,
    loadMoreAnonymousCustomers: () => {},
    onInactiveCustomerClickSorting: () => {},
    InactiveCustomerFilters: {},
    onRecentlyInactiveCustomerClickSorting,
    RecentlyInactiveCustomerFilters,
    onAnonymouslyActiveCustomerClickSorting: () => {},
    AnonymouslyActiveCustomerFilters: {},
  }

  return (
    <>
      <IdleCustomersDashboardContext.Provider value={contextValue}>
        <PageTitle breadcrumbs={RecentlyInactiveCustomerBreadCrums}>
        Idle User (Last Order Placed Before 6 Months)
        </PageTitle>
        <RecentlyInactiveCustomerListing />
        <Pagination pagination={pagination} onPageChange={onPageChange} />
      </IdleCustomersDashboardContext.Provider>
    </>
  )
}

export default RecentlyInactiveCustomerPage
