import {Modal} from 'react-bootstrap'
import RuleForm from '../form/RuleForm'

const CreateRule = ({show, onClose}: any) => {
  return (
    <Modal
      className='modal fade'
      id='kt_modal_create_navigation'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-l'
      aria-hidden='true'
      onHide={onClose}
    >
      <RuleForm onClose={onClose} isEdit={false} />
    </Modal>
  )
}

export default CreateRule
