import {useEffect, useRef} from 'react'
import './ContentEditable.css'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'

type Props = {
  className?: string
  placeholderClassName?: string
  placeholder: string
  isReadOnly?: boolean
  disabled?: boolean
}

export default function LexicalContentEditable({
  className,
  placeholder,
  placeholderClassName,
  isReadOnly,
  disabled,
}: Props): JSX.Element {
  const textBoxEditorRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (textBoxEditorRef?.current) {
      if (isReadOnly || disabled) {
        textBoxEditorRef.current.setAttribute('contenteditable', 'false')
      } else {
        textBoxEditorRef.current.setAttribute('contenteditable', 'true')
      }
    }
  })

  return (
    <ContentEditable
      className={`ContentEditable__root ${className ?? ''}`}
      ref={textBoxEditorRef}
      aria-placeholder={placeholder}
      placeholder={
        <div className={placeholderClassName ?? 'ContentEditable__placeholder'}>{placeholder}</div>
      }
    />
  )
}
