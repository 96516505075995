import {createContext} from 'react'

export const TextEditorContext = createContext({
  inputClass: '',
  editorClass: '',
  disabled: false,
  value: undefined as any,
  defaultValue: null as any,
  textEditorRef: null as any,
  maxLength: Infinity as any,
  isChangeOnSave: false,
  isLoadingOperation: undefined as any,
  isLoadingData: undefined as any,
  isReadOnly: false,
  isClearChanges: false,
  placeholder: undefined as any,
  onBlur: undefined as any,
  setIsValid: undefined as any,
  charCount: 0,
  setCharCount: (value: number) => {},
})

