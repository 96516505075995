import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import InquiresNavigation from './components/InquiresNavigation'
import InquiresAll from './layouts/InquiresAll'

const InquiresPageBreadCrumbs: Array<PageLink> = [
  {
    title: 'Products',
    path: '/products/all-products',
    isSeparator: false,
    isActive: false,
  },
]

function InquiresPage() {
  return (
    <Routes>
      <Route
        element={
          <>
            <InquiresNavigation />
            <Outlet />
          </>
        }
      >
        <Route
          path='all-inquiries'
          element={
            <>
              <PageTitle breadcrumbs={InquiresPageBreadCrumbs}>Inquires</PageTitle>
              <InquiresAll tab={''} />
            </>
          }
        />
        <Route
          path='archived'
          element={
            <>
              <PageTitle breadcrumbs={InquiresPageBreadCrumbs}>Inquires</PageTitle>
              <InquiresAll tab={'Archived'} />
            </>
          }
        />
        <Route index element={<Navigate to='/products/inquiries/all-inquiries' />} />
      </Route>
    </Routes>
  )
}

export default InquiresPage
