/* eslint-disable react-hooks/exhaustive-deps */
import {useContext} from 'react'
import {UserContext} from '../context'
import Search from '../../../components/Search'

const UserSearch = () => {
  const {onSearch} = useContext(UserContext)

  return (
    <>
      <Search
        onSearch={(searchTerm: string) => {
          onSearch && onSearch(searchTerm)
        }}
      />
    </>
  )
}

export default UserSearch
