import { useState, useCallback } from 'react';

const useDrawerToggle = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen(prevState => !prevState);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  return { isDrawerOpen, toggleDrawer, closeDrawer };
};

export default useDrawerToggle;
