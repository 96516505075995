import {Link, useSearchParams, useLocation} from 'react-router-dom'
import {NavLinkProps} from '../NavigationTypes'
import {KTSVG} from '../../../_metronic/helpers'

export const NavLink = ({
  keyValue,
  label,
  baseUrl,
  icon,
  disabled = false,
  paramKey,
}: NavLinkProps) => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const currentView = searchParams.get(paramKey)
  const isActive = currentView === keyValue

  return (
    <li className='nav-item'>
      <Link
        className={`nav-link text-active-primary me-6 ${isActive ? 'active' : ''} ${
          disabled ? 'disabled opacity-50 pe-none' : ''
        }`}
        to={`${baseUrl}?${paramKey}=${keyValue}`}
        state={location.state}
        onClick={(e) => disabled && e.preventDefault()}
      >
        {icon && <KTSVG path={icon} className='me-2' />}
        {label}
      </Link>
    </li>
  )
}
