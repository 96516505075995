import {createContext, ReactNode, useCallback, useContext, useMemo, useState} from 'react'

import {FEATURES_SETTINGS} from '../editorSettings'

type SettingsContextShape = {
  setOption: (name: any, value: boolean) => void
  settings: Record<any, boolean>
}

const Context = createContext({
  setOption: (name: any, value: boolean) => {
    return
  },
  settings: FEATURES_SETTINGS,
})

export const SettingsContext = ({children}: {children: ReactNode}): JSX.Element => {
  const [settings, setSettings] = useState(FEATURES_SETTINGS)

  const setOption = useCallback((setting: any, value: boolean) => {
    setSettings((options) => ({
      ...options,
      [setting]: value,
    }))
    setURLParam(setting, value)
  }, [])

  const contextValue = useMemo(() => {
    return {setOption, settings}
  }, [setOption, settings])

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const useSettings = (): SettingsContextShape => {
  return useContext(Context)
}

function setURLParam(param: any, value: null | boolean) {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  if (value !== param) {
    params.set(param, String(value))
  } else {
    params.delete(param)
  }
  url.search = params.toString()
  window.history.pushState(null, '', url.toString())
}
