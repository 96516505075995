import {useContext} from 'react'
import DndTable from '../../dndTable/DndTable'
import {SideMenuContext} from '../context'
import GlobalDefaultColumnsTableRow from './ColumnsTableRow'

const ColumnsTable = () => {
  const {
    updatedDefaultColumn,
    setUpdatedDefaultColumn,
    isLoadingGlobalDefaultColumnsList,
    permissionPath,
  } = useContext(SideMenuContext)

  const handleUpdatedShortedTableRows = (tableData: any) => {
    setUpdatedDefaultColumn(tableData)
    sessionStorage.setItem(
      'sortedColumnIds',
      JSON.stringify(tableData.map((item: any) => item.value))
    )
  }

  return (
    <DndTable
      id='global-table-settings-columns'
      data={updatedDefaultColumn}
      columns={[
        {key: 'columnName', label: 'NAME', headerClass: 'min-w-250px'},
        {key: 'visibility', label: 'VISIBILITY', headerClass: 'w-80px text-center'},
      ]}
      uniqueId='value'
      TableRow={GlobalDefaultColumnsTableRow}
      isLoading={isLoadingGlobalDefaultColumnsList}
      onChange={handleUpdatedShortedTableRows}
      permissionPath={permissionPath}
    />
  )
}

export default ColumnsTable
