export const colors: any = {
  purple: {
    light: '#EAE5FF',
    dark: '#2D12B2',
    main: '#EDE9FF',
  },
  orange: {
    light: '#FFF4E5',
    dark: '#B27012',
  },
  blue: {
    light: '#E5F0FF',
    chat: '#4D97FF',
    link: '#196EE5',
    main: '#4D97FF',
    dark: '#004BB2',
    task: '#EAF3FF',
  },
  red: {
    light: '#FFEAE5',
    main: '#B22D12',
    dark: '#B22D12',
  },
  green: {
    light: '#E5FFEB',
    main: '#51BB66',
    dark: '#0D8023',
    follow: '#E4F7EA',
  },
  white: {
    main: '#ffffff',
  },
  dark: {
    main: '#000000',
  },
  grey: {
    g30: '#40444D',
    g50: '#707580',
    g85: '#CED2D9',
    g90: '#E8EAEE',
    g92: '#E4E6EB',
    g95: '#EDEFF2',
    g98: '#F7F8FA',
    bg: '#F2F3F5',
    g99: '#EBEDF2',
    g97: '#F2F5FA',
    g100: '#f5f8fa',
  },
  func: {
    positive: '#1DB954',
    negative: '#DE2B2B',
    info: '#196EE5',
    alert: '#FFC44D',
  },
  social: {
    twitter: '#1DA1F2',
    facebook: '#4267B2',
    linkedin: '#0E76A8',
    tiktok: '#000000',
    whatsapp: '#4FCE5D',
  },
  text: {
    primary: '#000000',
    secondary: '#32363E',
    tertiary: '#585C66',
  },
  brown: {
    visit: '#FFF6E9',
  },
}

export function getColor(colorName = 'white') {
  let color: any = colors.white.main

  const path: any = colorName.split('-').filter(Boolean)
  const pathLength: any = path.length
  const mainPath: any = path[0]

  const colorNode = colors[mainPath]

  if (!colorNode) {
    console.warn(`Color node "${mainPath}" is missing in the palette`)
    return color
  }

  if (pathLength === 1) {
    if (!colorNode.main) {
      console.warn(`Color node "${mainPath}" has no "main" color`)
      return color
    }

    color = colorNode.main
  }
  if (pathLength > 1) {
    const colorVariantPath = path[1]
    const colorVariant = colorNode[colorVariantPath]

    if (!colorVariant) {
      console.warn(`Color node "${mainPath}" has no "${colorVariantPath}" color`)
      return color
    }

    color = colorVariant
  }

  return color
}

export function getRandomColor() {
  const bgColors = ['warning', 'danger', 'info', 'success', 'primary']
  // get random index value
  const randomIndex = Math.floor(Math.random() * bgColors.length)
  return bgColors[randomIndex]
}
