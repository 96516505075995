import {useState, useEffect} from 'react'
import Loading from '../modules/loading'

interface Items {
  id: string
  title: string
  content: React.ReactNode
  isLoading?: boolean
  show?: boolean
}

const Accordion = ({
  items,
  defaultOpen = 'All',
  className = '',
  headerClass = '',
  headerToggleClass = '',
  bodyClass = '',
}: {
  items: Items[]
  defaultOpen?: 'All' | false | string[]
  className?: string
  headerClass?: string
  headerToggleClass?: string
  bodyClass?: string
}) => {
  const [activeIds, setActiveIds] = useState<string[]>([])

  useEffect(() => {
    if (!items || items.length === 0) {
      return
    }

    if (defaultOpen === 'All') {
      setActiveIds(items.map((item: any) => item.id))
    } else if (defaultOpen === false) {
      setActiveIds([])
    } else if (Array.isArray(defaultOpen)) {
      setActiveIds(defaultOpen.map(String))
    }
  }, [defaultOpen, items])

  const handleToggle = (id: string) => {
    if (activeIds.includes(id)) {
      setActiveIds(activeIds.filter((i) => i !== id))
    } else {
      setActiveIds([...activeIds, id])
    }
  }

  return (
    <div className={`accordion ${className}`}>
      {!items || items.length === 0 ? (
        <div className='fw-bold text-center pt-3'>No Records available to display.</div>
      ) : (
        items
          .filter((item: any) => item.show !== false)
          .map((item: any) => (
            <div key={item.id} className='accordion-loop-item'>
              <h2 className={`accordion-header ${headerClass}`}>
                <button
                  className={`d-flex align-items-center accordion-button ${
                    headerToggleClass ? headerToggleClass : 'p-5 text-dark fw-bold fs-4 '
                  } ${activeIds.includes(item.id) ? '' : 'collapsed'}`}
                  type='button'
                  onClick={() => handleToggle(item.id)}
                >
                  <span>{item.title}</span>
                </button>
              </h2>
              <div
                className={`accordion-collapse collapse ${
                  activeIds.includes(item.id) ? 'show' : ''
                }`}
              >
                <div
                  className={`accordion-body position-relative ${bodyClass} ${
                    item?.isLoading ? 'min-h-300px' : ''
                  }`}
                >
                  {item?.isLoading && <Loading />}
                  {item.content}
                </div>
              </div>
            </div>
          ))
      )}
    </div>
  )
}

export default Accordion
