import useApi from '../../../../services/useApi'
import {boardColumnsData} from '../../parsings'
import {useQuery} from 'react-query'
import {useContext, useEffect, useState} from 'react'
import {ProjectDetailsContext} from '../../context'
import useFilters from '../../../../hook/useFilters'

const useGetBoardColumnsList = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const {data}: any = useQuery(['projectMappingData']) || null
  const tableId = data?.tableId || null
  const columnKey = data?.columnKey || null
  const [isInitialDropdownListFetched, setIsInitialDropdownListFetched] = useState(false)

  const initialDropdownFilters = {
    page: true,
    limit: 50,
    search: '',
    project_id: projectId,
  }

  const {filters, onSearch, onPageChange} = useFilters(initialDropdownFilters)

  useEffect(() => {
    setIsInitialDropdownListFetched(false)
  }, [filters])

  // Get board columns listing
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/columns`, {
    queryId: 'board-columns',
  })

  // dropdown list for column mapping
  const {data: dropdownListResponse, isFetching: isLoadingColumnMappingDropdownList} =
    Api.useGetQuery(
      `/agile/projects/dbtable/${tableId}/column/${columnKey}/mapping`,
      {
        queryId: `${tableId}-${columnKey}-status-dropdown-list`,
        filters: filters,
        onSuccess: () => setIsInitialDropdownListFetched(true),
      },
      {
        enabled: !isInitialDropdownListFetched && !!tableId && !!columnKey,
        cacheTime: 0,
        staleTime: 0,
      }
    )

  return {
    boardColumnsList: boardColumnsData(response?.data),
    columnMappingDropdownList: dropdownListResponse?.data?.data || [],
    onSearchDropdownOptions: onSearch,
    onDropdownPageChange: onPageChange,
    columnMappingDropdownPagination: dropdownListResponse?.data?.meta || {},
    isLoading: isFetching,
    isLoadingColumnMappingDropdownList: isLoadingColumnMappingDropdownList,
  }
}

export default useGetBoardColumnsList
