import {GlobalCustomFieldActionsDropdownTypes} from '../../interfaces'

const GlobalCustomFieldActionsDropdown = ({
  customFieldData,
  setShowModal,
  setShowDeleteModal,
}: GlobalCustomFieldActionsDropdownTypes) => {
  const handleEditClick = () => {
    setShowModal(true)
    document.body.click()
  }

  const handleDeleteClick = () => {
    setShowDeleteModal(true)
    document.body.click()
  }

  return (
    <div className=' d-flex align-items-start flex-column'>
      <span
        className='text-dark fw-bold text-hover-primary fs-6 px-2 py-1 cursor-pointer'
        onClick={() => handleEditClick()}
      >
        Edit
      </span>
      {customFieldData?.used_in_projects === 0 && (
        <span
          onClick={() => handleDeleteClick()}
          className='text-dark fw-bold text-hover-danger fs-6 px-2 py-1 cursor-pointer'
        >
          Delete
        </span>
      )}
    </div>
  )
}

export default GlobalCustomFieldActionsDropdown
