import * as Yup from 'yup'

export const createTicketValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Ticket Name is required.')
    .max(255, 'Maximum character limit is 255.'),
})

export const AddBoardMembersSchema = Yup.object().shape({
  usernames: Yup.array().required('Members is required').min(1, 'Please add at least one member'),
})


export const AddBoardTeamsSchema = Yup.object().shape({
  teams: Yup.array().required('Teams is required').min(1, 'Please add at least one team'),
})

export const AddBoardColumnSchema = Yup.object().shape({
  name: Yup.string().required('Column Name is required'),
})

export const AddBoardModuleSchema = Yup.object().shape({
  name: Yup.string().required('Module Name is required'),
})

export const AddBoardCustomFieldsSchema = (isEdit: boolean) =>
  Yup.object().shape({
    type: Yup.string().required('Type is required'),
    customfieldMetaIds: Yup.array().when('type', {
      is: (type: string) => type === 'global' && !isEdit,
      then: (schema) => schema.min(1, 'Minimum one Custom Field is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    name: Yup.string().when('type', {
      is: (type: string) => type === 'custom' || isEdit,
      then: (schema) => schema.required('Field Name is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    datatype: Yup.string().when('type', {
      is: 'custom',
      then: (schema) => schema.required('Field Type is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    groupValues: Yup.array().when(['type', 'datatype'], {
      is: (type: string, datatype: string) =>
        type === 'custom' && (datatype === 'select' || datatype === 'multi select'),
      then: (schema) =>
        schema.of(
          Yup.object().shape({
            value: Yup.string()
              .required('Option is required')
              .notOneOf([''], 'Option cannot be empty'),
          })
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
  })
