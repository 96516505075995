import useApi from '../../../../services/useApi'

export const useGetTeamMemberDetailsById = (teamId: number) => {
  const Api = useApi()

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/teams/${teamId}`,
    {},
    {
      enabled: !!teamId,
      staleTime: 0,
      cacheTime: 0,
    }
  )

  return {
    teamMemberDetails: response?.data || [],
    isLoading: isFetching,
  }
}
