import {createContext} from 'react'

export const UserContext = createContext({
  users: [],
  isLoading: false,
  isFetchingUsers: false,
  pagination: [],
  userLogin: (e: string) => {},
  onPageChange: (e: string | number | void) => {},
  addUser: (e: any) => {},
  updateUser: (data: any, username: string) => {},
  deleteUser: (user_name: any) => {},
  onSearch: (searchValue: string) => {},
  onSortingChange: (key: string, value: string) => {},
  filters: {},
})

export const UserRoleContext = createContext({
  roles: [],
  isLoading: false,
  isFetching: false,
  createNewRole: (data: any) => {},
  updateRole: (data: any, role_id: string) => {},
  deleteRole: (roleId: string) => {},
})
