interface MenuItemAddition {
    path: string[]
    staticObj: any
    position?: number
  }
  
  export function addStaticMenuItems(menuData: any, staticMenuItems?: MenuItemAddition[]) {
    if (!staticMenuItems) {
      return menuData // Return the original data if staticMenuItems not given
    }
  
    let mutableMenuData = JSON.parse(JSON.stringify(menuData))
  
    staticMenuItems.forEach(({path, staticObj, position = 0}) => {
      mutableMenuData = addMenuItem(mutableMenuData, path, staticObj, position)
    })
  
    return mutableMenuData
  }
  
  function addMenuItem(menuData: any, path: any, staticObj: any, position: number = 0) {
    // Deep clone the menuData to ensure we can modify it
    let mutableMenuData = JSON.parse(JSON.stringify(menuData))
  
    if (!path.length) {
      throw new Error('Path should not be empty')
    }
  
    const [current, ...remainingPath] = path
  
    if (!mutableMenuData[current]) {
      throw new Error(`Path not found: ${current}`)
    }
  
    if (remainingPath.length === 0) {
      mutableMenuData[current].children = mutableMenuData[current].children || {}
  
      const key = staticObj.label.toLowerCase().replace(/ /g, '_')
  
      if (position !== 0) {
        const entries = Object.entries(mutableMenuData[current].children)
        entries.splice(position - 1, 0, [key, staticObj])
        mutableMenuData[current].children = Object.fromEntries(entries)
      } else {
        mutableMenuData[current].children[key] = staticObj
      }
    } else {
      mutableMenuData[current].children = addMenuItem(
        mutableMenuData[current].children,
        remainingPath,
        staticObj,
        position
      )
    }
  
    return mutableMenuData
  }
  