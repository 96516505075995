/* eslint-disable react-hooks/exhaustive-deps */
import {Suspense, useEffect} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {I18nProvider} from './i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from './_metronic/layout/core'
import {MasterInit} from './_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {useAppDispatch, useAppSelector} from './redux/useTypedSelector'
import {setRedirect, userLogin} from './feature/authSlice'

const App = () => {
  const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID as string
  const location = useLocation()
  const {user} = useAppSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const redirect_url: any = location.pathname

  useEffect(() => {
    const handleLogin = async () => {
      if (location.search.includes('token') && location.search.includes('tenant_id')) {
        const searchParams = new URLSearchParams(location.search)
        const token: string | null = searchParams.get('token')
        const tenantId: string | null = searchParams.get('tenant_id')

        if (token && tenantId) {
          const payload = {
            'X-AUTH-TOKEN': token,
            'X-TENANT-ID': tenantId,
          }
          const res = await dispatch(userLogin(payload))
          if (res?.payload?.access_token) {
            navigate('/dashboard')
          }
        }
      }
    }

    handleLogin()
  }, [location.search, dispatch])

  useEffect(() => {
    dispatch(setRedirect(redirect_url))
  }, [])

  useEffect(() => {
    if (user === null) {
      navigate('/login')
    }
  }, [user, navigate])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <GoogleOAuthProvider clientId={google_client_id}>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </GoogleOAuthProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
