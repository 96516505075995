import {useContext} from 'react'
import {ProjectMembersPageContext} from '../../../context'
import DynamicTable from '../../../../../components/DynamicTable/DynamicTable'
import MemberPageTableRow from './MemberPageTableRow'
import {memberPageTableColumns} from '../../../utils'

export default function MemberPageTable() {
  const {
    projectMembersList,
    isLoadingProjectMembersList,
    hasMembersPermission,
    filters,
    onSortingChange,
  } = useContext(ProjectMembersPageContext)

  return (
    <div className='card-body p-0'>
      <DynamicTable
        data={projectMembersList}
        TableRow={MemberPageTableRow}
        loading={isLoadingProjectMembersList}
        noDataMessage='No members found.'
        sortableColumns={memberPageTableColumns}
        hasPermission={hasMembersPermission}
        filters={filters}
        onSortingChange={onSortingChange}
      />
    </div>
  )
}
