import {HasValueOrNot} from '../../../../utils/common'
import {formatDate} from '../../../../utils/date'
import { capitalizeFirstLetter } from '../../../../utils/string'

type HideProducts = {
  product_name: string
  variant_sku: string
  product_id: number  
  out_of_stock_days: number
  hide_product_name_prefix: string
  hide_classification: string
  created_at: string
  is_active: boolean
  is_executed: boolean
  executed_at: string
}

type ProductsRules = {
  product_sku: string
  product_name: string
  disable_promotion: boolean
  promotion_threshold_quantity: number
  promotion_id: number
  promotion_disable_date: string
  disable_promotion_triggered: boolean
  hide_product: boolean
  hide_out_of_stock_days: number
  hide_product_name_prefix: string
  hide_category_id: number   
  hide_classification: string
  customer_rep: string
  created_at: string
  is_active: boolean
}

export default function parseHideProducts(data: HideProducts) {
  return {
    product_name: HasValueOrNot(data?.['product_name']),
    variant_sku: data['variant_sku'],
    product_id: data['product_id'],
    hide_product_name_prefix: data['hide_product_name_prefix'] || '-',
    hide_classification: data['hide_classification'],
    out_of_stock_days: data['out_of_stock_days'],
    created_at: formatDate(data['created_at'], false),
    is_active:
      data['is_active'] !== null ? capitalizeFirstLetter(data['is_active'].toString()) : '-',
    is_executed:
      data['is_executed'] !== null ? capitalizeFirstLetter(data['is_executed'].toString()) : '-',
    executed_at: formatDate(data['executed_at'], false),
  }
}

export function parseHideProductsRules(data: ProductsRules) {
  return {
    product_sku: data?.['product_sku'],
    product_name: HasValueOrNot(data?.['product_name']),
    disable_promotion:
      data['disable_promotion'] !== null
        ? capitalizeFirstLetter(data['disable_promotion'].toString())
        : '-',
    promotion_threshold_quantity: data?.['promotion_threshold_quantity'],
    promotion_id: data?.['promotion_id'],
    promotion_disable_date: data?.['promotion_disable_date'],
    disable_promotion_triggered:
      data['disable_promotion_triggered'] !== null
        ? capitalizeFirstLetter(data['disable_promotion_triggered'].toString())
        : '-',
    hide_product:
      data['hide_product'] !== null ? capitalizeFirstLetter(data['hide_product'].toString()) : '-',
    hide_out_of_stock_days: data?.['hide_out_of_stock_days'],
    hide_product_name_prefix: data?.['hide_product_name_prefix'] || '',
    hide_category_id: data?.['hide_category_id'],
    hide_classification: data?.['hide_classification'],
    customer_rep: data?.['customer_rep'],
    created_at: formatDate(data['created_at'], false),
    is_active:
      data['is_active'] !== null ? capitalizeFirstLetter(data['is_active'].toString()) : '-',
  }
}



