import {useContext, useState} from 'react'
import OverlayComponent from '../../../../_metronic/layout/components/Popover'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {ProjectMembersPageContext} from '../../context'
import ProjectMemberActionDropdown from '../actionDropdowns/ProjectMemberActionDropdown'
import TransferOwnershipModal from '../modals/TransferOwnershipModal'

const MemberActionsOverlay = ({memberDetails}: any) => {
  const {removeProjectMember, isLoadingOperation, isLoadingProjectMembersList, projectName} =
    useContext(ProjectMembersPageContext)
  const [showDeleteModal, setDeleteModal] = useState(false)
  const [showManageModal, setManageModal] = useState(false)

  const onRemove = async (memberId: any) => {
    await removeProjectMember(memberId)
  }

  return (
    <>
      <OverlayComponent
        btnIcon={<i className='las la-ellipsis-h fs-2x'></i>}
        children={
          <ProjectMemberActionDropdown
            memberDetails={memberDetails}
            setDeleteModal={setDeleteModal}
            setManageModal={setManageModal}
          />
        }
      />

      {showDeleteModal && (
        <ConfirmationModal
          uniqueID={memberDetails?.member_id}
          show={showDeleteModal}
          onClose={() => setDeleteModal(false)}
          isOperationLoading={isLoadingOperation}
          isDataLoading={isLoadingProjectMembersList}
          onAction={onRemove}
          body={
            <p>
              Do you really want to remove '<strong>{memberDetails?.name}</strong>' from '
              <strong>{projectName}</strong>' project?
            </p>
          }
        />
      )}

      {showManageModal && (
        <TransferOwnershipModal showManageModal={showManageModal} setManageModal={setManageModal} />
      )}
    </>
  )
}

export default MemberActionsOverlay
