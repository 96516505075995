import {useContext} from 'react'
import Search from '../../../../components/Search'
import {SalesRepReportPageContext} from '../../contexts'

const SalesRepHeader = () => {
  const {onSearch} = useContext(SalesRepReportPageContext)
  return (
    <div className='d-flex justify-content-between mb-10'>
      <div>
        <Search
          onSearch={(searchTerm: string) => {
            onSearch(searchTerm)
          }}
        />
      </div>
    </div>
  )
}

export default SalesRepHeader
