import {useContext, useState} from 'react'
import DefaultColumnsModal from '../modals/DefaultColumnsModal'
import {ProjectsDefaultColumnsPageContext} from '../../context'
import Search from '../../../../components/Search'

const ProjectsDefaultColumnsHeader = () => {
  const [showModal, setShowModal] = useState(false)
  const {onSearch, hasProjectSettingsPermission} = useContext(ProjectsDefaultColumnsPageContext)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div className='d-flex align-items-center'>
        <Search
          onSearch={(searchTerm: string) => {
            onSearch && onSearch(searchTerm)
          }}
        />
      </div>
      {hasProjectSettingsPermission && (
        <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
          <i className='fa-solid fa-plus'></i>Add Column
        </button>
      )}
      {showModal && (
        <DefaultColumnsModal show={showModal} isEdit={false} onClose={() => setShowModal(false)} />
      )}
    </div>
  )
}

export default ProjectsDefaultColumnsHeader
