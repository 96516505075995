import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'


export const useGetProjectsTeamsList = (status: any) => {
  const Api = useApi()
  const initialFilters = {
    search: true,
    status: status,
    sort_by: 'created_at/-1',
  }

  const {filters, onPageChange, onSortingChange, onSearch, searchValue} = useFilters(
    initialFilters,
    {
      sortType: 'number',
    }
  )

  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/teams`, {
    queryId: 'projects-teams',
    filters: filters,
  })

  return {
    ProjectsTeamsList: response?.data || [],
    isLoading: isFetching,
    pagination: response?.pagination || {},
    onPageChange,
    onSearch,
    searchValue,
    filters,
    onSortingChange,
  }
}
