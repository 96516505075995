import {isEmpty} from '../../../utils/common'

// Function to convert Unix timestamp to Date object with time set to midnight
const unixToDate = (timestamp: number | null, isEndDate: boolean = false): Date => {
  if (!timestamp) return new Date(new Date().setHours(0, 0, 0, 0))
  const date = new Date(timestamp * 1000)
  if (isEndDate) {
    // Add one day to end date
    date.setDate(date.getDate() + 1)
  }
  return new Date(date.setHours(0, 0, 0, 0))
}

// Function to parse duration string to number of days
const parseDuration = (duration: string, item: any): number => {
  if (!duration || duration === '0m') return 0

  const weeks = (duration.match(/(\d+)w/) || [])[1] || 0
  const days = (duration.match(/(\d+)d/) || [])[1] || 0

  return Number(weeks) * 5 + Number(days)
}

// Transform Gantt chart data
export const transformGanttData = (data: any[], config: any): any[] => {
  if (isEmpty(data)) return []
  const transformedTasks: any[] = []
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0))

  data.forEach((module) => {
    // Filter valid child tasks first to determine if module should be included
    const validChildTasks = module.columnItems.filter((item: any) => {
      const endDate = item[config.end] ? new Date(item[config.end] * 1000) : null
      return !endDate || endDate >= currentDate
    })

    // Only include module if it has valid child tasks
    if (validChildTasks.length > 0) {
      const defaultDate = new Date()
      const defaultEndDate = new Date(defaultDate)
      defaultEndDate.setDate(defaultEndDate.getDate() + 1)

      // Calculate average progress of child tasks
      const totalProgress = validChildTasks.reduce((sum: number, item: any) => {
        const taskProgress = item[config.progress] || 0
        return sum + (taskProgress > 100 ? 100 : taskProgress)
      }, 0)
      const averageProgress = Math.round(totalProgress / validChildTasks.length)

      transformedTasks.push({
        id: module.mID,
        text: module.moduleName,
        progress: averageProgress, // Set the calculated average progress
        type: 'summary',
        open: config.open ?? true,
      })

      // Calculate module start and end dates using only valid tasks
      const moduleStartDates = validChildTasks
        .map((item: any) => item[config.start])
        .filter((date: any): date is number => date !== null)

      const moduleEndDates = validChildTasks
        .map((item: any) => item[config.end])
        .filter((date: any): date is number => date !== null)

      // Update parent task dates if we have valid dates
      if (moduleStartDates.length && moduleEndDates.length) {
        const parentTask = transformedTasks[transformedTasks.length - 1]
        const startDate = new Date(Math.min(...moduleStartDates) * 1000)
        const endDate = new Date(Math.max(...moduleEndDates) * 1000)
        endDate.setDate(endDate.getDate() + 1) // Add one day to end date
        parentTask.start = new Date(startDate.setHours(0, 0, 0, 0))
        parentTask.end = new Date(endDate.setHours(0, 0, 0, 0))
      }

      // Add only valid child tasks
      validChildTasks.forEach((item: any) => {
        const {id, text, start, end, duration, progress, type, parent, ...rest} = item
        transformedTasks.push({
          id: item[config.id],
          text: item[config.text],
          start: unixToDate(item[config.start], false),
          end: unixToDate(item[config.end], true), // Pass true for end date
          duration: parseDuration(item[config.duration], item),
          progress: item[config.progress],
          parent: module.mID,
          type: 'task',
          ...rest,
        })
      })
    }
  })

  return transformedTasks
}

export const highlightTime = (d: any, u: any) => {
  const isDayOff = (date: any) => {
    const d = date.getDay()
    return d === 0 || d === 6
  }

  const isHourOff = (date: any) => {
    const h = date.getHours()
    return h < 8 || h === 12 || h > 17
  }

  if (u === 'day' && isDayOff(d)) return 'wx-weekend'
  if (u === 'hour' && (isDayOff(d) || isHourOff(d))) return 'wx-weekend'
  return ''
}
