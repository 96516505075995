import {$getRoot} from 'lexical'
import {$generateNodesFromDOM} from './htmlConversions'
import {Position} from '../nodes/InlineImageNode'

// Helper to merge refs
export function mergeRefs<T>(...refs: React.Ref<T>[]) {
  return (instance: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance)
      } else if (ref && typeof ref === 'object') {
        ;(ref as React.MutableRefObject<T | null>).current = instance
      }
    })
  }
}

export function convertHTMLToLexical(editor: any, defaultEditorValue: string | null) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(
    defaultEditorValue ? defaultEditorValue : '<p></p>',
    'text/html'
  )
  const nodes = $generateNodesFromDOM(editor, doc)
  nodes.forEach((node) => editor.update(() => $getRoot().append(node)))
}

export function styleStringToObject(
  styleString: string,
  position?: Position
): Record<string, string> {
  const styleObject = styleString.split(';').reduce((acc, declaration) => {
    const [property, value] = declaration.split(':').map((part) => part.trim())
    if (property && value) {
      acc[property] = value
    }
    return acc
  }, {} as Record<string, string>)

  if (!styleObject.float && position) {
    styleObject.float = position
  }

  return styleObject
}
