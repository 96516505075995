import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {MemberProjectsListModalTypes} from '../../interfaces'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import {memberProjectsListModalColumns} from '../../utils'
import useGetMemberProjectsList from '../../hooks/members/useGetMemberProjectsList'
import {getBadgeColor} from '../../../../utils/badge'
import {capitalizeFirstLetter} from '../../../../utils/string'

const MemberProjectsListModal = ({show, onClose, memberId}: MemberProjectsListModalTypes) => {
  const {projectMembersList, isLoading} = useGetMemberProjectsList(memberId)

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={'Projects'}
      bodyClass='mh-550px overflow-auto'
      body={
        <DynamicTable
          data={projectMembersList}
          sortableColumns={memberProjectsListModalColumns}
          loading={isLoading}
          tableClass='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'
          noDataMessage='No project members found'
          TableRow={({row}: any) => {
            return (
              <tr>
                <td>{row?.project_name}</td>
                <td>
                  <div className='text-end'>
                    <span className={`badge ${getBadgeColor(row['status'], 'light')} badge-lg`}>
                      <div className='align-items-center'>
                        {capitalizeFirstLetter(row['status'])}
                      </div>
                    </span>
                  </div>
                </td>
              </tr>
            )
          }}
        />
      }
    />
  )
}

export default MemberProjectsListModal
