import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'
import { useContext } from 'react'
import { ProjectDetailsContext } from '../../context'

export const useGetMembersList = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)

  const initialFilters = {
    page: true,
    search: true,
    limit: 30,
    sort_by: 'created_at/-1',
  }

  const {filters, onSearch, onPageChange, onSortingChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {data: response, isFetching} = Api.useGetQuery(`agile/projects/${projectId}/members`, {
    queryId: 'project-members-list',
    filters: filters,
  })

  return {
    projectMembersList: response?.data?.data || [],
    isLoading: isFetching,
    pagination: response?.meta?.pagination || {},
    onPageChange,
    filters,
    onSearch,
    onSortingChange,
  }
}
