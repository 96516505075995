import {useEffect, useReducer, useCallback, useState, useRef} from 'react'
import Vector from '../../images/Vector.png'
import {SampleCSVDownload} from '../SampleCSVDownload'
import {CSVImporter} from '../CSVImporter'
import {DynamicTable} from '../DynamicTable'
import {DynamicMetaFieldsProps, Field} from './customFIeldsTypes'
import {flattenErrorRefs, validateCustomFields} from './utils'
import {InputNumber} from '../InputNumber'
import {InputText} from '../InputText'
import {InputPrice} from '../InputPrice'
import {convertToNumber} from '../../utils/string'
import {isEmpty} from '../../utils/common'

const CustomFields = ({
  id,
  onChange,
  values,
  defaultValues,
  config,
  onError,
  className,
  headerClass,
  tableSectionClass,
  tableClass,
  isDisableAddBtn = false,
  readOnly = false,
  isDisabled = false,
  isDeletable = true,
  submitBtnRef = null,
  isRequired = false
}: DynamicMetaFieldsProps) => {
  // Action Types for Reducer
  type Action =
    | {type: 'SET'; payload: Field[]}
    | {type: 'ADD'}
    | {type: 'DELETE'; payload: number}
    | {type: 'CHANGE'; payload: {index: number; key: string; newValue: string}}
    | {type: 'ADD_VALUE'; payload: {index: number; key: string}}
    | {type: 'DELETE_VALUE'; payload: {index: number; key: string; valueIndex: number}}
    | {
        type: 'CHANGE_VALUE'
        payload: {index: number; key: string; valueIndex: number; newValue: string}
      }

  // Initial State Function - Initializes fields based on config and defaultValues
  const initialState = (defaultValues: Field[] | undefined): Field[] =>
    defaultValues?.map((field: any) => ({
      ...field,
      ...Object.fromEntries(
        config.tableConfig.data.map((col) => [
          col.key,
          col.isMultiple
            ? Array.isArray(field[col.key])
              ? field[col.key]
              : col.type === 'number' || col.type === 'price'
              ? [null]
              : ['']
            : col.type === 'number' || col.type === 'price'
            ? 0
            : field[col.key] || '',
        ])
      ),
    })) || []

  // Reducer Function - Handles state transitions based on action types
  const customFieldReducer = (state: Field[], action: Action): Field[] => {
    switch (action.type) {
      case 'SET':
        return action.payload
      case 'ADD':
        return [
          ...state,
          Object.fromEntries(
            config.tableConfig.data.map((col: any) => [
              col.key,
              col.isMultiple
                ? col.type === 'number' || col.type === 'price'
                  ? [null]
                  : ['']
                : col.type === 'number' || col.type === 'price'
                ? null
                : '',
            ])
          ),
        ]
      case 'DELETE':
        return state.filter((_, index) => index !== action.payload)
      case 'CHANGE':
        return state.map((field, index) =>
          index === action.payload.index
            ? {...field, [action.payload.key]: action.payload.newValue}
            : field
        )
      case 'ADD_VALUE':
        return state.map((field, index) =>
          index === action.payload.index
            ? {
                ...field,
                [action.payload.key]: [...(field[action.payload.key] as string[]), ''],
              }
            : field
        )
      case 'DELETE_VALUE':
        return state.map((field, index) =>
          index === action.payload.index
            ? {
                ...field,
                [action.payload.key]: (field[action.payload.key] as string[]).filter(
                  (_, i) => i !== action.payload.valueIndex
                ),
              }
            : field
        )
      case 'CHANGE_VALUE':
        return state.map((field, index) =>
          index === action.payload.index
            ? {
                ...field,
                [action.payload.key]: (field[action.payload.key] as string[]).map((v, i) =>
                  i === action.payload.valueIndex ? action.payload.newValue : v
                ),
              }
            : field
        )
      default:
        return state
    }
  }

  // State and References Initialization
  const [customFields, dispatch] = useReducer(customFieldReducer, initialState(defaultValues))
  const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState(false)
  const [isAssignedDefaultValues, setIsAssignedDefaultValues] = useState(false)
  const inputRefs = useRef<any>({})
  const [fieldErrors, setFieldErrors] = useState<any>({})
  const [showMaxLimitMessage, setShowMaxLimitMessage] = useState(false)

  useEffect(() => {
    validateCustomFields(
      customFields,
      config,
      onError,
      setFieldErrors,
      onChange,
      setShowMaxLimitMessage
    )
    // eslint-disable-next-line
  }, [customFields, config])

  useEffect(() => {
    const handleClick = () => {
      setIsSubmitBtnClicked(true)
    }

    const addClickListener = (buttonElement: HTMLElement | null) => {
      if (buttonElement) {
        buttonElement.addEventListener('click', handleClick)
      }
    }

    const removeClickListener = (buttonElement: HTMLElement | null) => {
      if (buttonElement) {
        buttonElement.removeEventListener('click', handleClick)
      }
    }

    if (submitBtnRef) {
      if (Array.isArray(submitBtnRef)) {
        submitBtnRef.forEach((ref) => addClickListener(ref?.current))
      } else {
        Object.keys(submitBtnRef).forEach((key) => {
          addClickListener(submitBtnRef[key]?.current)
        })
      }

      // Cleanup the event listeners on component unmount
      return () => {
        if (Array.isArray(submitBtnRef)) {
          submitBtnRef.forEach((ref) => removeClickListener(ref?.current))
        } else {
          Object.keys(submitBtnRef).forEach((key) => {
            removeClickListener(submitBtnRef[key]?.current)
          })
        }
        setIsSubmitBtnClicked(false)
      }
    }
  }, [submitBtnRef])

  // Effect to Handle Default Values - Dispatches 'SET' action when defaultValues change
  const prevDefaultValuesRef = useRef(defaultValues)
  const prevValuesRef = useRef(values)
  useEffect(() => {
    if (
      !isEmpty(defaultValues) &&
      JSON.stringify(prevDefaultValuesRef.current) !== JSON.stringify(defaultValues) &&
      !isAssignedDefaultValues
    ) {
      dispatch({type: 'SET', payload: defaultValues ?? []})
      prevDefaultValuesRef.current = defaultValues
      setIsAssignedDefaultValues(true)
    }
    if (JSON.stringify(prevValuesRef.current) !== JSON.stringify(values)) {
      dispatch({type: 'SET', payload: values ?? []})
      prevValuesRef.current = values
    }
  }, [defaultValues, isAssignedDefaultValues, values])

  // Effect to Handle Field Errors - Calls onError when fieldErrors change
  useEffect(() => {
    const scrollAndFocusErrorField = () => {
      // store only invalid inputs in single array
      const errorFields = flattenErrorRefs(inputRefs?.current)
      // Only scroll when all fields have lost focus
      for (const ref of errorFields) {
        if (ref && isSubmitBtnClicked) {
          ref.focus()
          ref.scrollIntoView({behavior: 'smooth', block: 'center'})
          break
        }
      }
    }
    scrollAndFocusErrorField()
    //eslint-disable-next-line
  }, [fieldErrors, isSubmitBtnClicked])

  // Field Change Handler - Dispatches CHANGE or CHANGE_VALUE action based on input
  const handleFieldChange = useCallback(
    (index: number, key: string, newValue: any, valueIndex?: number) => {
      if (valueIndex !== undefined) {
        dispatch({type: 'CHANGE_VALUE', payload: {index, key, valueIndex, newValue}})
      } else {
        dispatch({type: 'CHANGE', payload: {index, key, newValue}})
      }
    },
    []
  )

  // Value Action Handler - Handles adding or deleting values in multiple fields
  const handleValueAction = useCallback(
    (index: number, key: string, action: 'ADD' | 'DELETE', valueIndex?: number) => {
      const columnConfig = config.tableConfig.data.find((col) => col.key === key)

      if (action === 'ADD') {
        if (columnConfig && columnConfig.isMultiple && columnConfig.maxFields !== undefined) {
          const currentValues = customFields[index][key] as string[]
          if (currentValues.length >= columnConfig.maxFields) {
            return // Exit if maxFields limit is reached
          }
        }
        dispatch({type: 'ADD_VALUE', payload: {index, key}})
      } else if (valueIndex !== undefined) {
        dispatch({type: 'DELETE_VALUE', payload: {index, key, valueIndex}})
      }
    },
    [customFields, config.tableConfig.data]
  )

  // Render Add Button - Adds new fields based on configuration
  const renderAddButton = () => {
    if (readOnly || isDisabled || showMaxLimitMessage) return null

    return (
      <button
        type='button'
        className='btn btn-light-primary'
        onClick={() => {
          if (config.maxFields === undefined || customFields.length < config.maxFields) {
            dispatch({type: 'ADD'})
            setShowMaxLimitMessage(false)
          } else {
            setShowMaxLimitMessage(true)
          }
        }}
      >
        + <span className=''>{config.addButtonLabel || 'Add Custom Field'}</span>
      </button>
    )
  }

  // Render Sample CSV Download - Provides download functionality for sample CSV
  const renderSampleCSVDownload = () => {
    if (!config.csvFile?.downloadSampleCsv) return null
    return (
      <button
        type='button'
        className='btn text-decoration-underline me-5'
        onClick={() =>
          SampleCSVDownload(
            config?.csvFile?.downloadSampleCsv?.sampleCsvData || [],
            config?.csvFile?.downloadSampleCsv?.sampleCsvFileName || 'sample'
          )
        }
      >
        Download Sample CSV
      </button>
    )
  }

  // Generate CSV Import Config
  const generateCSVImportConfig = () => {
    return {
      ...config?.csvFile?.importConfig,
      maxRows: config.maxFields ?? Infinity,
      requiredHeaders: config.tableConfig.data.map((col) => col.label),
      isNullable: config?.csvFile?.importConfig?.isNullable ?? false,
      allowDuplicateRow: config?.csvFile?.importConfig?.allowDuplicateRow ?? false,
      isReplaceable: config?.csvFile?.importConfig?.isReplaceable ?? true,
      replacePartially: config?.csvFile?.importConfig?.replacePartially ?? true,
      customErrors: {
        duplicateWithDefault: (value: any, rowNumber: number, headerName: string) =>
          `${headerName}: <strong>${value}</strong>, Row No. <strong>${rowNumber}</strong>`,
      },
    }
  }

  // Render CSV Importer - Provides import data from csv functionality
  const renderCSVImporter = () => {
    if (!config.csvFile?.importConfig || readOnly) return null
    return (
      <CSVImporter
        id={`${id}-csv-importer`}
        config={generateCSVImportConfig()}
        onSuccess={(data: Field[]) => {
          dispatch({type: 'SET', payload: data})
        }}
        existingData={customFields}
      />
    )
  }

  // Determine whether the delete icon column should be shown.
  const shouldShowDeleteIconColumn = () => {
    if (config.tableConfig.data.length === 0) return false
    const isMultipleFlags = config.tableConfig.data.map((col) => col.isMultiple ?? false)
    // Condition 1: All columns contain isMultiple: false
    const allFalse = isMultipleFlags.every((flag) => !flag)
    // Condition 2: All columns contain isMultiple: true
    const allTrue = isMultipleFlags.every((flag) => flag)
    // Condition 3: The first column contains isMultiple: true
    const firstTrue = isMultipleFlags[0]
    return allFalse || allTrue || firstTrue
  }

  // Render Table - Generate Dynamic Table as per config
  const renderTable = () => (
    <DynamicTable
      tableClass={`gy-3 align-top mb-0 ${tableClass ? tableClass : 'table-row-gray-300'}`}
      sortableColumns={[
        ...config.tableConfig.data.map((col) => ({
          key: col.key,
          label: col.label,
          style: col.style ?? '',
        })),
        !readOnly && isDeletable && !isDisabled && shouldShowDeleteIconColumn()
          ? {key: 'deleteIcon', label: ''}
          : null,
      ].filter(Boolean)}
      data={customFields.map((field, index) => ({
        ...Object.fromEntries(
          config.tableConfig.data.map((col, colIndex) => [
            col.key,
            col.type === 'static' ? (
              <>
                <div>{field[col.key]}</div>
                {fieldErrors[index]?.[col.key]?.[0]?.message && (
                  <>
                    <div className='is-invalid d-none'></div>
                    <div className='invalid-feedback'>
                      {fieldErrors[index]?.[col.key]?.[0]?.message}
                    </div>
                  </>
                )}
              </>
            ) : col.isMultiple ? (
              <>
                {(field[col.key] as string[]).map((value, valueIndex) => (
                  <div key={valueIndex} className='mb-2 d-flex align-items-start'>
                    <div className='w-100'>
                      {col.type === 'number' ? (
                        <InputNumber
                          id={`input-${index}-${col.key}-${valueIndex}`}
                          error={fieldErrors[index]?.[col.key]?.[valueIndex]}
                          inputNumberRef={(el: any) => {
                            if (!inputRefs.current[index]) {
                              inputRefs.current[index] = {}
                            }
                            if (!inputRefs.current[index][col.key]) {
                              inputRefs.current[index][col.key] = {}
                            }
                            inputRefs.current[index][col.key][valueIndex] = el!
                          }}
                          value={value}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              col.key,
                              convertToNumber(e.target.value),
                              valueIndex
                            )
                          }
                          placeholder={col.placeholder || ''}
                          maxLength={col.maxLength || 255}
                          readOnly={readOnly || col.isReadOnly}
                          disabled={isDisabled}
                          onFocus={() => setIsSubmitBtnClicked(false)}
                          isFloat={col.isFloat}
                        />
                      ) : col.type === 'price' ? (
                        <InputPrice
                          id={`input-${index}-${col.key}-${valueIndex}`}
                          error={fieldErrors[index]?.[col.key]?.[valueIndex]}
                          inputPriceRef={(el: any) => {
                            if (!inputRefs.current[index]) {
                              inputRefs.current[index] = {}
                            }
                            if (!inputRefs.current[index][col.key]) {
                              inputRefs.current[index][col.key] = {}
                            }
                            inputRefs.current[index][col.key][valueIndex] = el!
                          }}
                          value={value}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              col.key,
                              convertToNumber(e.target.value),
                              valueIndex
                            )
                          }
                          placeholder={col.placeholder || ''}
                          maxLength={col.maxLength || 255}
                          readOnly={readOnly || col.isReadOnly}
                          disabled={isDisabled}
                          onFocus={() => setIsSubmitBtnClicked(false)}
                          isFloat={col.isFloat}
                        />
                      ) : (
                        col.type === 'text' && (
                          <InputText
                            id={`input-${index}-${col.key}-${valueIndex}`}
                            error={fieldErrors[index]?.[col.key]?.[valueIndex]}
                            inputTextRef={(el: any) => {
                              if (!inputRefs.current[index]) {
                                inputRefs.current[index] = {}
                              }
                              if (!inputRefs.current[index][col.key]) {
                                inputRefs.current[index][col.key] = {}
                              }
                              inputRefs.current[index][col.key][valueIndex] = el!
                            }}
                            value={value}
                            onChange={(e) =>
                              handleFieldChange(index, col.key, e.target.value, valueIndex)
                            }
                            placeholder={col.placeholder || ''}
                            maxLength={col.maxLength || 255}
                            readOnly={readOnly || col.isReadOnly}
                            disabled={isDisabled}
                            onFocus={() => setIsSubmitBtnClicked(false)}
                          />
                        )
                      )}
                    </div>
                    {!readOnly &&
                      !isDisabled &&
                      !col.isReadOnly &&
                      (field[col.key] as string[]).length > 1 &&
                      col.isDeletable && (
                        <img
                          src={Vector}
                          alt='Delete'
                          className='delete-icon mt-3 ms-3 cursor-pointer'
                          onClick={() => handleValueAction(index, col.key, 'DELETE', valueIndex)}
                        />
                      )}
                  </div>
                ))}
                {!readOnly && !col.isReadOnly && !isDisabled && (
                  <button
                    type='button'
                    className='btn btn-sm btn-outline-primary'
                    onClick={() => handleValueAction(index, col.key, 'ADD')}
                  >
                    + Add Another {col.label}
                  </button>
                )}
              </>
            ) : (
              <>
                {col.type === 'number' ? (
                  <InputNumber
                    id={`input-${index}-${col.key}`}
                    error={fieldErrors[index]?.[col.key]?.[0]}
                    inputNumberRef={(el: any) => {
                      if (!inputRefs.current[index]) {
                        inputRefs.current[index] = {}
                      }
                      if (!inputRefs.current[index][col.key]) {
                        inputRefs.current[index][col.key] = {}
                      }
                      inputRefs.current[index][col.key][index] = el!
                    }}
                    value={field[col.key] || null}
                    onChange={(e) =>
                      handleFieldChange(index, col.key, convertToNumber(e.target.value))
                    }
                    placeholder={col.placeholder || ''}
                    maxLength={col.maxLength || 255}
                    readOnly={readOnly || col.isReadOnly}
                    disabled={isDisabled}
                    onFocus={() => setIsSubmitBtnClicked(false)}
                    isFloat={col.isFloat}
                  />
                ) : col.type === 'price' ? (
                  <InputPrice
                    id={`input-${index}-${col.key}`}
                    error={fieldErrors[index]?.[col.key]?.[0]}
                    inputPriceRef={(el: any) => {
                      if (!inputRefs.current[index]) {
                        inputRefs.current[index] = {}
                      }
                      if (!inputRefs.current[index][col.key]) {
                        inputRefs.current[index][col.key] = {}
                      }
                      inputRefs.current[index][col.key][index] = el!
                    }}
                    value={field[col.key] || null}
                    onChange={(e) =>
                      handleFieldChange(index, col.key, convertToNumber(e.target.value))
                    }
                    placeholder={col.placeholder || ''}
                    maxLength={col.maxLength || 255}
                    readOnly={readOnly || col.isReadOnly}
                    disabled={isDisabled}
                    onFocus={() => setIsSubmitBtnClicked(false)}
                    isFloat={col.isFloat}
                  />
                ) : (
                  <InputText
                    id={`input-${index}-${col.key}`}
                    error={fieldErrors[index]?.[col.key]?.[0]}
                    inputTextRef={(el: any) => {
                      if (!inputRefs.current[index]) {
                        inputRefs.current[index] = {}
                      }
                      if (!inputRefs.current[index][col.key]) {
                        inputRefs.current[index][col.key] = {}
                      }
                      inputRefs.current[index][col.key][index] = el!
                    }}
                    value={field[col.key] || ''}
                    onChange={(e) => handleFieldChange(index, col.key, e.target.value)}
                    placeholder={col.placeholder || ''}
                    maxLength={col.maxLength || 255}
                    readOnly={readOnly || col.isReadOnly}
                    disabled={isDisabled}
                    onFocus={() => setIsSubmitBtnClicked(false)}
                  />
                )}
                {!config.tableConfig.data.every((column: any) => column.isMultiple !== true) &&
                  !readOnly &&
                  !isDisabled &&
                  !col.isMultiple &&
                  isDeletable &&
                  colIndex === 0 && (
                    <button
                      type='button'
                      className='btn btn-sm text-danger px-0 mt-3'
                      onClick={() => dispatch({type: 'DELETE', payload: index})}
                    >
                      {config?.deleteBtnLabel ?? `Delete ${col.label.toLowerCase()}`}
                    </button>
                  )}
              </>
            ),
          ])
        ),
        deleteIcon: !readOnly && !isDisabled && (
          <div className='mt-2'>
            {config.tableConfig.data[0].isMultiple ? (
              <i
                className='fa-regular fa-trash-can fs-3 text-danger cursor-pointer'
                onClick={() => dispatch({type: 'DELETE', payload: index})}
              ></i>
            ) : (
              <img
                src={Vector}
                alt='Delete'
                className='delete-icon cursor-pointer'
                onClick={() => dispatch({type: 'DELETE', payload: index})}
              />
            )}
          </div>
        ),
      }))}
      noDataMessage={config.tableConfig.noDataMessage || 'No data available'}
    />
  )

  // Return Final UI Structure
  return (
    <div id={id} className={className}>
      <div className={headerClass}>
        <div className='d-flex justify-content-between align-items-center'>
          {config.title && (
            <h3 className={`m-0 ${isRequired ? 'required' : ''} ${config.titleClass ?? ''}`}>
              {config.title}
            </h3>
          )}
          {config.csvFile && (
            <div className='d-flex'>
              {config.csvFile.downloadSampleCsv &&
                !readOnly &&
                !isDisabled &&
                renderSampleCSVDownload()}
              {config.csvFile.importConfig && !readOnly && !isDisabled && renderCSVImporter()}
            </div>
          )}
        </div>
        {config.maxFields && config.enableMaxFieldMessage && (
          <p>{`${config.title} will only allow adding ${config.maxFields} fields per product`}</p>
        )}
      </div>

      <div className={tableSectionClass}>
        {renderTable()}
        {showMaxLimitMessage && config.maxFields !== undefined && (
          <div className='alert alert-danger'>
            Max Limit of {config.maxFields} {config?.title} Reached.
          </div>
        )}
        {!isDisableAddBtn && renderAddButton()}
      </div>
    </div>
  )
}

export default CustomFields
