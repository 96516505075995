import {useState} from 'react'
import {getInquiryDetails, updateInquiryStatus} from '../services'
import useToastify from '../../../hook/useToastify'

export default function useGetInquiriesDetails() {
  const {toastMessage} = useToastify()
  const [fetchLoading, setFetchLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)

  // Function to fetch inquiry details
  const fetchInquiries = async (id: any) => {
    try {
      setFetchLoading(true)
      const response = await getInquiryDetails(`/products/inquiries/${id}`)
      return response?.length ? response[0] : {}
    } catch (err: any) {
      toastMessage('Error fetching inquiries', 'error')
    } finally {
      setFetchLoading(false)
    }
  }

  // Function to update inquiry status
  const onUpdateStatus = async (inquiryId: number | string, status: string) => {
    setUpdateLoading(true)
    const payload = {status: status}
    try {
      const response = await updateInquiryStatus(`/products/inquiries/${inquiryId}`, payload)
      if (response.status === 200) {
        if (status === 'Archived') {
          toastMessage('success', 'Inquiry moved to Archived')
        } else {
          toastMessage('success', 'Inquiry updated successfully.')
        }
      } else {
        toastMessage('error', response.message || 'Error updating status.')
      }
      return response
    } catch (error) {
      toastMessage('error', 'Failed to update inquiry status.')
    } finally {
      setUpdateLoading(false)
    }
  }

  return {
    fetchLoading,
    updateLoading,
    fetchInquiries,
    onUpdateStatus,
  }
}
