import useApi from '../../../services/useApi'
import {TicketDetailsContext} from '../context'
import {useContext} from 'react'

const useGetCustomFieldsList = () => {
  const Api = useApi()
  const {projectId, ticketId} = useContext(TicketDetailsContext)

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/${projectId}/${ticketId}/custom-fields`,
    {
      queryId: `${ticketId}-additional-custom-fields`,
      isErrorPageRedirection: false,
      isToast: false,
      isCaching: false,
    },
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      cacheTime: 0,
      staleTime: 0,
    }
  )

  return {
    customFieldsList: response?.data || [],
    isLoading: isFetching,
  }
}

export default useGetCustomFieldsList
